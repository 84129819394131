import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Header from '../../components/header/Header';
import Loading from '../../components/common/Loading';
import { autologin } from '../../api/selfieWebService';
import useQueryParam from '../../customHook/useQueryParam';
import { PublicRoutesList } from '../../utils/menuAndRoutesUtil';
import i18n from '../../common/i18n';
import { useLoginVerificationCode } from '../../services/loginService';

const Autologin = () => {
  const queryParam = useQueryParam();
  const history = useHistory();
  const { processLoginResult } = useLoginVerificationCode();

  const goToManualLogin = () => {
    history.push({
      pathname: PublicRoutesList.loginDNIValidationStep,
      state: {
        message: i18n.autologinError,
      },
    });
  };

  const init = async () => {
    try {
      // Realiza autologin
      const authenticateCustomerResponse = await autologin(queryParam.get('data'));
      const gender = queryParam.get('gender');

      await processLoginResult(authenticateCustomerResponse, false, gender);
      window.location = authenticateCustomerResponse.data.redirectTo;
    } catch (error) {
      goToManualLogin();
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      <Header />
      <Box m={6} mt={16} />
      <Loading />
    </>
  );
};

export default Autologin;
