import PropTypes from 'prop-types';
import { useState, createContext, useContext } from 'react';

const RefinancingContext = createContext(undefined);

export const RefinancingProvider = ({ refinancing, children }) => {
  const [refinancingContext, setRefinancingContext] = useState(refinancing);

  return (
    <RefinancingContext.Provider value={{ refinancingContext, setRefinancingContext }}>
      {children}
    </RefinancingContext.Provider>
  );
};

RefinancingProvider.propTypes = {
  refinancing: PropTypes.shape(),
  children: PropTypes.node.isRequired,

};

RefinancingProvider.defaultProps = {
  refinancing: null,
};

export const useRefinancingContext = () => useContext(RefinancingContext);
