import i18n from '../common/i18n';
import getApiErrorMessageDescription from './apiErrorKeysDictionary';
import { ERROR, SUCCESS } from './apiServicesConst';
import { referenceConstant } from './referenceConstant';

const checkTypeOfFunc = (param) => typeof param === 'function';

const searchTranslationForCodeErrorFromResponse = (response) => {
  let codeErrorKey = '';
  if (response.data.status !== 200 && response.data.status !== 201) {
    codeErrorKey = response.data.message;
  }
  return getApiErrorMessageDescription(codeErrorKey);
};

const createApiMessage = (response, referenceLabels, isInitError) => {
  let message;
  if (isInitError) {
    message = {
      status: ERROR,
      title: i18n.onInitErrorTitle,
      subtitle: i18n.onInitErrorSubtitle,
    };
  } else if (response.status === 200 || response.status === 201) {
    message = {
      status: SUCCESS,
      title: checkTypeOfFunc(referenceConstant[referenceLabels].titleSuccess)
        ? referenceConstant[referenceLabels].titleSuccess(response)
        : referenceConstant[referenceLabels]?.titleSuccess || '',
      subtitle: checkTypeOfFunc(referenceConstant[referenceLabels].subtitleSuccess)
        ? referenceConstant[referenceLabels].subtitleSuccess(response)
        : referenceConstant[referenceLabels]?.subtitleSuccess || '',
      buttonLabel: referenceConstant[referenceLabels]?.buttonLabelSuccess || '',
      link: response.link,
    };
  } else {
    message = {
      status: ERROR,
      title: checkTypeOfFunc(referenceConstant[referenceLabels].titleError)
        ? referenceConstant[referenceLabels].titleError(response)
        : referenceConstant[referenceLabels]?.titleError || '',
      subtitle: checkTypeOfFunc(referenceConstant[referenceLabels].subtitleError)
        ? referenceConstant[referenceLabels].subtitleError(response)
        : referenceConstant[referenceLabels]?.subtitleError || '',
      apiErrorExplanation: searchTranslationForCodeErrorFromResponse(response) || '',
      buttonLabel: referenceConstant[referenceLabels]?.buttonLabelError || '',
      link: response.link,
    };
  }

  return message;
};
export default createApiMessage;
