import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import i18n from '../common/i18n';
import Loading from './common/Loading';
import { getCvuPayment } from '../api/selfieWebService';
import CopyToClipboardButton from './common/CopyToClipboardButton';
import PrePaidLoanInfoBox from './PrePaidLoanInfoBox';
import useHomeNavigation from '../customHook/useHomeNavigation';

const PrePaidPaymentMethodBankTransfer = ({ loan, cancelationData, onChange }) => {
  const goHome = useHomeNavigation();

  const [loading, setLoading] = useState(true);
  const [cvu, setCVU] = useState();

  const init = async () => {
    try {
      setLoading(true);
      const res = await getCvuPayment(loan.externalId);
      setCVU(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container justify="center">
          <Grid item xs={11} md={6}>
            <Box m={3} />
            <Typography variant="h2">{i18n.prePaidLoanPaymentMethodBankTransferTitle}</Typography>
            <Box m={5} />
            <Typography variant="h5">
              {i18n.prePaidLoanPaymentMethodBankTransferDescription[0]}
            </Typography>
            <Box m={2} />
            <Typography variant="h5">
              <strong>{i18n.prePaidLoanPaymentMethodBankTransferDescription[1]}</strong>
            </Typography>
            <Box m={5} />
            <Typography variant="h5" align="left">
              <strong>{i18n.prePaidLoanPaymentMethodBankTransferSection}</strong>
            </Typography>
            <hr />
            <Box mt={2} mb={5} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" align="left">
                <strong>{i18n.prePaidLoanPaymentMethodBankTransferCVU}</strong>
                <Typography>{cvu}</Typography>
              </Typography>
              <CopyToClipboardButton value={cvu} width="20px" />
            </Box>
            <PrePaidLoanInfoBox loan={loan} cancelationData={cancelationData} />
            <Box m={5} />
            <Typography>
              <strong>{i18n.prePaidLoanPaymentMethodAskFreeDebt}</strong>
            </Typography>
            <Box m={5} />
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={goHome}
            >
              {i18n.backToTop}
            </Button>
            <Box mt={3} />
            <Button
              onClick={onChange}
              color="primary"
            >
              {i18n.prePaidLoanPaymentMethodChangeMethod}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

PrePaidPaymentMethodBankTransfer.propTypes = {
  loan: PropTypes.shape().isRequired,
  cancelationData: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PrePaidPaymentMethodBankTransfer;
