import parse from 'html-react-parser';
import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import HeaderNav from '../../components/header/Header';
import i18n from '../../common/i18n';
import { fillMessageWith } from '../../utils/functionsUtil';
import Loading from '../../components/common/Loading';
import { moneyFormatter } from '../../utils/formatterUtil';
import CountDown from '../../components/CountDown';
import MessageResponse from '../../components/common/MessageResponse';
import { GET_REFINANCING_INFO_ERROR } from '../../utils/referenceConstant';
import warningImg from '../../assets/imgs/warning-red.svg';
import { getEnv, URL_WHATSAPP } from '../../api/env';
import { useRefinancingContext } from '../../contexts/RefinancingContext';
import { useRefinancingHomeData } from '../../services/refinancingService';

export const useRefinancingCommonStyles = makeStyles((theme) => ({
  body: {
    marginTop: '120px',

    paddingLeft: '50px',
    paddingRight: '50px',

    '& h1': {
      fontFamily: 'Open Sans',
      fontSize: '36px',
      fontWeight: '700',
      lineHeight: '42px',
      color: '#212121',
      marginBottom: '16px',
    },

    '& a': {
      color: '#f2025d',
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',

      paddingLeft: '24px',
      paddingRight: '24px',

      '& h1': {
        fontSize: '20px',
        lineHeight: '27px',
        marginBottom: '8px',
      },
    },
  },

  ctaBox: {
    padding: '50px',

    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: '0px',
      borderTop: '1px solid lightgrey',
      padding: '20px',
      width: '-webkit-fill-available',
      backgroundColor: 'white',
      zIndex: 1000,
    },
  },
}));

const useStyles = makeStyles(() => ({
  steps: {
    marginTop: '24px',
  },

  step: {
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& p': {
      marginLeft: '16px',
    },
  },
}));

const RefinancingHome = () => {
  const commonClasses = useRefinancingCommonStyles();
  const classes = useStyles();

  const { refinancingContext } = useRefinancingContext();
  const {
    loading, disableLink, responseError, handleContinue, handleTimeOver,
  } = useRefinancingHomeData();

  const getWhatsappLink = () =>
    fillMessageWith(i18n.RefinancingHome.whatsapp, getEnv(URL_WHATSAPP));

  return (
    <>
      <HeaderNav nomenu />
      {loading && (
        <Loading />
      )}

      {!loading && !disableLink && !responseError && (
        <>
          <Grid container className={commonClasses.body}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" align="left">
                {fillMessageWith(i18n.RefinancingHome.title, refinancingContext.customerFullName)}
              </Typography>
              <Typography align="left">
                {parse(fillMessageWith(i18n.RefinancingHome.subtitle, moneyFormatter(refinancingContext.totalAmount)))}
              </Typography>
              <CountDown
                timeLimit={refinancingContext.time}
                label={i18n.RefinancingHome.countDown}
                onTimeOver={handleTimeOver}
              />
              <Typography align="left">
                {i18n.RefinancingHome.stepsTitle}
              </Typography>
              <Grid container className={classes.steps}>
                {i18n.RefinancingHome.steps.map((step) => (
                  <Grid item xs={12} key={step.description} className={classes.step}>
                    <img src={step.image} alt="PASO" />
                    <Typography align="left">{step.description}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={commonClasses.ctaBox}>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                fullWidth
              >
                {i18n.RefinancingHome.ctaContinue}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {!loading && disableLink && !responseError && (
        <Grid container className={commonClasses.body} justify="center" spacing={2}>
          <Grid item xs={10} md={6}>
            <img src={warningImg} alt="WARNING" style={{ marginBottom: '24px' }} />
            <Typography variant="h1" style={{ marginBottom: '20px' }}>{i18n.RefinancingHome.linkExpired[0]}</Typography>
            <Typography variant="h5">
              {i18n.RefinancingHome.linkExpired[1]}
              <a href={getWhatsappLink()} target="_blank" rel="noreferrer">{i18n.RefinancingHome.linkExpired[2]}</a>
            </Typography>
          </Grid>
        </Grid>
      )}

      {responseError && (
        <Grid container className={commonClasses.body} justify="center">
          <Grid item xs={10} md={6}>
            <MessageResponse
              response={responseError}
              referenceLabels={GET_REFINANCING_INFO_ERROR}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RefinancingHome;
