import { Grid } from '@material-ui/core';
import CardCustomerDebtor from '../../../components/CardCustomerDebtor';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardCustomerDebtor = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <CardCustomerDebtor
            totalInstallmentDebt="3"
            totalPunitiveDebt="250"
            totalDebt="500"
            onClickPay={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardCustomerDebtor;
