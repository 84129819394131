import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography, Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const StyledRating = withStyles({
  iconFilled: {
    color: '#E72064',
  },
  label: {
    width: '100%',
    margin: 'auto',
  },
  icon: {
    justifyContent: 'center',
  },
})(Rating);

const RatingInput = ({
  name, ...props
}) => {
  const icon = <StarIcon style={{ width: '38px', height: '38px' }} />;
  const emptyIcon = <StarBorderIcon style={{ width: '38px', height: '38px' }} />;
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <StyledRating
            style={{ width: '100%' }}
            icon={icon}
            emptyIcon={emptyIcon}
            value={field.value}
            onChange={(e) => {
              const newValue = field.value === Number(e.target.value) ? 0 : e.target.value;
              field.onChange(Number(newValue));
            }}
            {...props}
          />
          <Box m={1} />
          <Grid container>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              <Typography style={{ fontSize: '12px', color: '#979797' }}>Nada satisfecho</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Typography style={{ fontSize: '12px', color: '#979797' }}>Muy satisfecho</Typography>
            </Grid>
          </Grid>
          <Box m={1} />
          {!!errors[name] && (
            <Typography className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {errors[name]?.message}
            </Typography>
          )}
        </>
      )}
    />
  );
};

RatingInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RatingInput;
