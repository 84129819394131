import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';
import { removeCurrentUser, removeCustomerAccountStatus } from '../../api/userStorage';
import TakeSurveyModal from '../../components/TakeSurveyModal';
import Loading from '../../components/common/Loading';

function Logout({ expired }) {
  const { setCurrentUser } = useAuth();
  const { setCustomerAccountStatus } = useCustomerAccountStatus();
  const [showSurvey, setShowSurvey] = useState(false);

  const cleanStorageAndRedirect = () => {
    removeCustomerAccountStatus();
    setCustomerAccountStatus(undefined);
    removeCurrentUser();
    setCurrentUser(undefined);
    // al ser una ruta Private, cuando setea currentUser=undefined, redirecciona a login
  };

  const init = () => {
    if (!expired) {
      setShowSurvey(true);
    } else {
      cleanStorageAndRedirect();
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      <Loading />
      {showSurvey && (
        <TakeSurveyModal onClose={cleanStorageAndRedirect} />
      )}
    </>
  );
}
Logout.propTypes = {
  expired: PropTypes.bool,

};
Logout.defaultProps = {
  expired: false,

};
export default Logout;
