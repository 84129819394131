import { Grid } from '@material-ui/core';
import AvatarUser from '../../../components/AvatarUser';

const WikiAvatarUser = () => {
  const data = {
    username: 'username',
    dni: '1234567890',
    mobile: '1122223333',
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <AvatarUser data={data} />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiAvatarUser;
