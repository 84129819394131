import { Box, Grid, Typography } from '@material-ui/core';
import Loading from '../../../components/common/Loading';

/* eslint arrow-body-style: ["error", "always"] */
const WikiLoading = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio (Default height: 50vh):
          </Typography>
          <Box m={3} />
          <Loading />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Height 100vh:
          </Typography>
          <Box m={3} />
          <Loading height="100vh" />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiLoading;
