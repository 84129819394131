import parse from 'html-react-parser';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MessageResponse from '../../components/common/MessageResponse';
import LoginVerificationCodeStep from '../../components/LoginVerificationCodeStep';
import Header from '../../components/header/Header';
import { LOGIN_VERIFICATION_CODE } from '../../utils/referenceConstant';
import { useLoginVerificationCode } from '../../services/loginService';
import { useLoginData } from '../../contexts/LoginDataContext';
import i18n from '../../common/i18n';
import { capitalizeSentence, fillMessageWith } from '../../utils/functionsUtil';
import useStyle from './loginStyle';

const LoginVerificationCode = () => {
  const classes = useStyle();
  const { currentLoginData } = useLoginData();
  const {
    loading, invalidCodeAlert, response, goToChangeIdentity, goToCodeNoReception, goToBack,
    goToChangeNumber, onSubmitVerificationCode, onReSendVerificationCode,
  } = useLoginVerificationCode();

  return (
    <>
      <Header />
      <Grid container className={classes.container}>
        <Grid item xs={1} md={1} />
        <Grid item xs={10} md={5} style={{ marginTop: '120px' }}>
          {response === undefined ? (
            <>
              <Typography
                variant="h1"
                align="left"
                style={{
                  color: '#212121', fontFamily: 'Open sans', fontSize: '1.5rem', marginBottom: '8px',
                }}
              >
                {fillMessageWith(i18n.loginVerificationCodeWelcome, capitalizeSentence(currentLoginData.fullname))}
              </Typography>
              <Typography align="left">
                {parse(fillMessageWith(i18n.loginVerificationCodeDescription, currentLoginData.phoneNumber))}
              </Typography>
              <Button
                onClick={goToChangeNumber}
                style={{ marginTop: '12px', display: 'flex', padding: '0px' }}
              >
                {i18n.loginVerificationCodeChangeNumber}
              </Button>
              <LoginVerificationCodeStep
                onSubmit={onSubmitVerificationCode}
                loading={loading}
                invalidCodeAlert={invalidCodeAlert}
                onReSendVerificationCode={onReSendVerificationCode}
                goToCodeNoReception={goToCodeNoReception}
                customerEmail={currentLoginData.customerEmail}
                onBack={goToChangeIdentity}
              />
              {currentLoginData.smsCode && (
                <Alert>
                  <Typography>
                    Codigo sms (solo se muestra para pruebas!):
                    {' '}
                    {currentLoginData.smsCode}
                  </Typography>
                </Alert>
              )}
            </>
          ) : (
            <>
              <MessageResponse
                response={response}
                referenceLabels={LOGIN_VERIFICATION_CODE}
                onErrorPrimary={goToBack}
                canGoHome={false}
              />
            </>
          )}
        </Grid>
        <Grid item xs={1} md={6} />
      </Grid>
    </>
  );
};

export default LoginVerificationCode;
