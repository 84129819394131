import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Grid, Typography, Button, makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import Loading from '../components/common/Loading';
import TextInput from '../components/common/TextInput';
import BiometricValidatorDefaultError from '../components/BiometricValidatorDefaultError';
import BiometricValidatorNoHit from '../components/BiometricValidatorNoHit';
import { prepaidCardChangePinSchema } from '../forms/schemaValidations/schemaValidations';
import MessageResponse from '../components/common/MessageResponse';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import useQueryParam from '../customHook/useQueryParam';
import { validateBiometricResult, changePinPrePaidCard } from '../api/selfieWebService';
import { CHANGE_PIN } from '../utils/referenceConstant';

const useStyles = makeStyles({
  containerCenter: {
    marginTop: '30px',
    textAlign: 'start',
  },
  cardInfoTitle: {
    fontWeight: '800',
    fontSize: '20px',
    fontFamily: 'Poppins',
    marginLeft: '3px',
  },
  labelStyle: {
    '& .MuiFormLabel-root': {
      fontSize: '16px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9E9E9E !important',
      },
    },
  },
});

const PrePaidCardChangePin = () => {
  // Context
  const classes = useStyles();
  const [initLoading, setInitLoading] = useState(false);
  const [resultBiometric, setResultBiometric] = useState();
  const [response, setResponse] = useState();
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();
  const queryParam = useQueryParam();
  const { currentUser } = useAuth();

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(prepaidCardChangePinSchema),
  });

  const goToMyCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardKey));
  };

  const goBack = () => {
    history.push(getPathRouteFromKey(i18n.myCardChangePinKeyPreview));
  };

  const retry = () => {
    setResponse(undefined);
  };

  const goToValidateBiometricResult = async (externalId, idtx) => {
    try {
      const res = await validateBiometricResult(idtx, externalId);
      const decision = res.data?.decision;
      switch (decision) {
        case 'HIT': setResultBiometric('HIT'); break;
        case 'NO_HIT': setResultBiometric('NO_HIT'); break;
        default: setResultBiometric('DEFAULT'); break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initPrePaidCardChangePin = async () => {
    setInitLoading(true);
    setResultBiometric('DEFAULT');
    // EMULATOR HIT (externaltxid), 4ID HIT (externaltrxId), Both err (externalId)
    const externalId = queryParam.get('externaltxid')
    || queryParam.get('externalID')
    || queryParam.get('externaltrxid');
    try {
      if (externalId) {
        await goToValidateBiometricResult(externalId, queryParam.get('idtx'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInitLoading(false);
    }
  };

  const changePin = async (e) => {
    setInitLoading(true);
    try {
      if (currentUser.customerTaxId !== undefined) {
        const res = await changePinPrePaidCard(currentUser.customerTaxId, e.pinCode);
        if (res.status === 200) {
          setResponse({ status: 200 });
        } else {
          setResponse(getError(undefined));
        }
      }
    } catch (error) {
      setResponse(getError(error));
    }
    setInitLoading(false);
  };

  const subtitleMessageResponse = () => (
    <>
      <Typography>{i18n.prePaidCardChangePinResultSuccessSubtitle}</Typography>
      <Box m={1} />
      <Typography><strong>{i18n.prePaidCardChangePinResultSuccessSubtitle2}</strong></Typography>
    </>
  );

  useEffect(() => initPrePaidCardChangePin(), []);
  // Initial State is valid ...
  useEffect(() => setIsValid(methods.formState.isValid && methods.formState.isDirty), [methods]);

  return (
    <>
      {initLoading === true ? (<Loading />) : (
        <>
          {response !== undefined && (
            <MessageResponse
              response={response}
              referenceLabels={CHANGE_PIN}
              successGreen
              subtitleMessageResponse={subtitleMessageResponse}
              onSuccessPrimary={goToMyCard}
              onErrorPrimary={retry}
              canGoHome={false}
              customCTA={{
                action: goToMyCard,
                label: i18n.prePaidCardChangePinResultOnSuccessAndErrorSecondary,
                notShowOnSuccess: true,
              }}
            />
          )}

          {response === undefined && (
            <>
              {resultBiometric === 'NO_HIT' && (
                <BiometricValidatorNoHit subtitle={i18n.prePaidCardChangePinNoHitError} goBack={goBack} />
              )}
              {resultBiometric === 'DEFAULT' && (
                <BiometricValidatorDefaultError retry={goBack} goBack={goToMyCard} />
              )}
              {resultBiometric === 'HIT' && (
                <Grid container justify="center" className={classes.containerCenter}>
                  <Grid item xs={10} md={4} lg={3}>
                    <Grid item>
                      <Typography className={classes.cardInfoTitle}>
                        {i18n.prePaidCardChangePinPreviewTitle}
                      </Typography>
                    </Grid>
                    <Box m={2} />
                    <Grid item>
                      <Typography style={{ fontSize: '14px' }}>{i18n.prePaidCardChangePinFormSubtitle}</Typography>
                      <Box m={3} />
                      <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(changePin)}>
                          <TextInput
                            name="pinCode"
                            label={i18n.prePaidCardChangePinFormLabelInput1}
                            variant="outlined"
                            className={classes.labelStyle}
                            fullWidth
                            inputProps={{ minLength: 4, maxLength: 4 }}
                            mandatory
                          />
                          <Box m={3} />
                          <TextInput
                            name="pinCode2"
                            className={classes.labelStyle}
                            label={i18n.prePaidCardChangePinFormLabelInput2}
                            variant="outlined"
                            fullWidth
                            inputProps={{ minLength: 4, maxLength: 4 }}
                            mandatory
                          />
                          <Grid item style={{ marginTop: '30px' }}>
                            <Button
                              style={{ fontSize: '18px' }}
                              type="submit"
                              variant="contained"
                              color="primary"
                              fullWidth
                              disabled={!isValid}
                            >
                              {i18n.prePaidCardChangePinFormButton}
                            </Button>
                          </Grid>
                          <Grid item style={{ marginTop: '15px' }}>
                            <Button
                              style={{ fontSize: '18px' }}
                              onClick={goToMyCard}
                              variant="outlined"
                              fullWidth
                            >
                              {i18n.prePaidCardChangePinFormButtonGoBack}
                            </Button>
                          </Grid>
                        </form>
                      </FormProvider>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PrePaidCardChangePin;
