import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  Box, Button, Container, Grid,
} from '@material-ui/core';
import { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@react-hook/media-query';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import i18n from '../common/i18n';
import imgOnline from '../assets/imgs/pago-online.svg';
import imgSuccess from '../assets/imgs/success_green.svg';
import ShareIcon from '../assets/imgs/share-icon.svg';
import ActionWarning from './common/ActionWarning';
import { moneyFormatter } from '../utils/formatterUtil';
import LoadingButton from './common/LoadingButton';
import LoanSelection from './LoanSelection';
import { getPaymentInformation, getQrCodePayment } from '../api/selfieWebService';
import useHomeNavigation from '../customHook/useHomeNavigation';
import Loading from './common/Loading';
import QRCodePayment from './QRCodePayment';

const useStyles = makeStyles((theme) => ({
  principalColor: {
    color: theme.palette.primary.main,
  },
  message: {
    fontFamily: 'Open sans',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '0.9rem',
  },
  button: {
    padding: '0px',
    marginLeft: '3px',
    marginBottom: '3px',
  },
}));

const PaymentLoanSelection = ({
  loans, paymentMethodKey, onResetMethodSelection, loading,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width:600px)');
  const history = useHistory();
  const goHome = useHomeNavigation();

  const [initLoading, setInitLoading] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState(undefined);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [amountToPay, setAmountToPay] = useState(undefined);
  const [selectedLoanId, setSelectedLoanId] = useState(undefined);
  const [qrValue, setQrValue] = useState();
  const [activeInvalidPaymentMethod, setActiveInvalidPaymentMethod] = useState(undefined);
  const exportRef = useRef();

  const activeLoanAreCardPaymentMethod = (idLoan) => {
    const loan = loans.filter((element) => element.externalId === idLoan)[0];
    if (loan && (loan.paymentMethod === 'DIRECT_DEBIT_CARD' || loan.paymentMethod === 'DIRECT_DEBIT')) {
      setActiveInvalidPaymentMethod(true);
    } else {
      setActiveInvalidPaymentMethod(false);
    }
  };

  const resolveAmountToPay = (idLoan, paymentInfo) => {
    const loan = loans.filter((element) => element.externalId === idLoan)[0];
    setAmountToPay(paymentInfo.finalAmount);
    if (loan.productId === 46) {
      setAmountToPay(loan.revolvingLineAmount);
    }
  };

  const onClickLoan = async (idLoan) => {
    await setInitLoading(true);
    try {
      const res = await getPaymentInformation(idLoan);
      setPaymentInformation(res.data);
      setHasDiscount(res.data.discountAmount > 0);
      resolveAmountToPay(idLoan, res.data);
      if (res.data.finalAmount <= 0) {
        setAlreadyPaid(true);
        setActiveInvalidPaymentMethod(false);
      } else {
        activeLoanAreCardPaymentMethod(idLoan);
      }

      setSelectedLoanId(idLoan);

      if (paymentMethodKey === i18n.payInstallmentQRKey) {
        const qrRes = await getQrCodePayment(idLoan);
        if (qrRes.data) {
          setQrValue(qrRes.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
    await setInitLoading(false);
  };

  const onShare = async () => {
    const canvas = await html2canvas(exportRef.current);
    const dataUrl = canvas.toDataURL('image/png');
    const blob = await (await fetch(dataUrl)).blob();
    const filesArray = [new File([blob], 'qrcode.png', { type: blob.type, lastModified: new Date().getTime() })];
    const shareData = {
      files: filesArray,
    };
    navigator.share(shareData);
  };

  const goToPaymentMethodSelection = () => {
    history.push({
      pathname: getPathRouteFromKey(paymentMethodKey),
      search: `id=${selectedLoanId}`,
    });
  };

  return (
    <>
      <Container>
        <Box m={6} />
        <Typography variant="h2" style={{ fontSize: '1.5rem', marginBottom: '30px' }}>
          {i18n.payInstallmentLoanSelection}
        </Typography>
        <LoanSelection loans={loans} onClickLoan={onClickLoan} useDefaultLoanSelected={false} />
        {initLoading && (
          <Loading />
        )}
        {!initLoading && (
          <>
            {activeInvalidPaymentMethod ? (
              <>
                <ActionWarning
                  onClick={activeLoanAreCardPaymentMethod}
                  continueMessageBtn={i18n.payInstallmenInvalidPaymentMethodActionWarningNextButton}
                />
              </>
            ) : (
              <>
                {/* Casuística 1: Abonada */}
                {alreadyPaid && (
                  <Box m={6}>
                    <Box style={{ marginBottom: '30px' }}>
                      <img src={imgSuccess} height="100px" alt="Cuota abonada" />
                    </Box>
                    <Typography variant="h4" style={{ fontSize: '1.2rem' }}>
                      {i18n.payInstallmentOnlineIsUpToDateTitle}
                    </Typography>
                    <Typography variant="p" style={{ fontSize: '1.1rem' }}>
                      {i18n.payInstallmentOnlineIsUpToDateSubtitle}
                    </Typography>
                  </Box>
                )}
                {!alreadyPaid && paymentInformation && (paymentMethodKey === i18n.PaymentDebitCardFormKey
                  || paymentMethodKey === i18n.PaymentMPFormKey) && (
                  <>
                    <Box m={6} />
                    <Typography variant="h4" style={{ fontSize: '1.2rem' }}>
                      {paymentInformation.message}
                    </Typography>
                    <Box m={6} />
                    <Grid container justify="center">
                      <Grid item xs={12} md={6}>
                        <img src={imgOnline} height="100px" alt="Pago online" />
                        <Box m={2} />
                        <Typography variant="h4">
                          {i18n.payInstallmentGoingToPay}
                        </Typography>
                        <Typography variant="h4" className={classes.principalColor}>
                          {moneyFormatter(amountToPay)}
                        </Typography>
                      </Grid>
                    </Grid>
                    { hasDiscount && (
                    <>
                      <Box m={6} />
                      <Typography variant="p" style={{ fontSize: '1.1rem' }}>
                        {i18n.payInstallmentOnlineDiscountMessage}
                        <b>{` ${paymentInformation.discountMessage} `}</b>
                      </Typography>
                    </>
                    )}
                  </>
                )}
                {!alreadyPaid && paymentInformation && paymentMethodKey === i18n.payInstallmentQRKey && (
                  <>
                    <Box m={6} />
                    <Typography variant="h6" className={classes.message}>
                      {isMobile ? i18n.payInstallmentQRCodeSubtileIfMobile : i18n.payInstallmentQRCodeSubtile }
                    </Typography>
                    <Box m={2} />
                    <Grid container justify="center">
                      <Grid item>
                        {qrValue && (
                          <Box ref={exportRef}>
                            <QRCodePayment value={qrValue} loading={initLoading} />
                          </Box>
                        )}
                        <Box m={3} />
                        <Typography variant="h4" style={{ fontSize: '1.2rem' }}>
                          {paymentInformation.message}
                        </Typography>
                        <Box m={2} />
                        <Typography variant="h4">
                          {i18n.payInstallmentQRGoingToPay}
                        </Typography>
                        <Typography variant="h4" className={classes.principalColor}>
                          {moneyFormatter(amountToPay)}
                        </Typography>
                        { hasDiscount && (
                        <>
                          <Box m={3} />
                          <Typography variant="p" style={{ fontSize: '1.1rem' }}>
                            {i18n.payInstallmentOnlineDiscountMessage}
                            <b>{` ${paymentInformation.discountMessage} `}</b>
                          </Typography>
                        </>
                        )}
                        <Box m={2} />
                        {isMobile && (
                          <>
                            <Typography variant="h6" className={classes.message}>
                              {i18n.payInstallmentQRCodeShareImageMessage}
                            </Typography>
                            <Box m={2} />
                            <Button
                              onClick={onShare}
                              variant="outlined"
                              color="primary"
                              fullWidth
                            >
                              <Typography>
                                {i18n.payInstallmentQRCodeShareImageButton}
                                <IconButton className={classes.button}>
                                  <img src={ShareIcon} alt="share" />
                                </IconButton>
                              </Typography>
                            </Button>
                            <Box m={2} />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
                <Box m={3} />
                <Grid container justify="center">
                  <Grid item xs={12} md={4}>
                    {!alreadyPaid && paymentInformation && (paymentMethodKey === i18n.PaymentDebitCardFormKey
                      || paymentMethodKey === i18n.PaymentMPFormKey) && (
                      <>
                        <Box m={6} />
                        <LoadingButton
                          style={{ width: '100%' }}
                          variant="contained"
                          color="primary"
                          type="submit"
                          loading={loading}
                          onPress={goToPaymentMethodSelection}
                        >
                          {i18n.payInstallmentOnlinePrimaryCTA}
                        </LoadingButton>
                        <Box m={3} />
                      </>
                    )}
                    {alreadyPaid && (
                      <Button
                        variant="outlined"
                        color="primary"
                        loading={loading}
                        onClick={goHome}
                      >
                        <Typography className={classes.fontSize}>
                          &nbsp;
                          {i18n.backToTop}
                        </Typography>
                      </Button>
                    )}
                    {!alreadyPaid && (
                      <>
                        <Button
                          startIcon={<ArrowBack />}
                          onClick={onResetMethodSelection}
                        >
                          {i18n.payInstallmentSecondaryCTA}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Box m={6} />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

PaymentLoanSelection.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape({
    externalId: PropTypes.string.isRequired,
    currentInstallment: PropTypes.shape({ totalAmount: PropTypes.number.isRequired }).isRequired,
  })).isRequired,
  paymentMethodKey: PropTypes.string.isRequired,
  onResetMethodSelection: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PaymentLoanSelection;
