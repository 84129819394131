import { Grid } from '@material-ui/core';
import CardInvoice from '../../../components/CardInvoices';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardInvoice = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <CardInvoice
            paymentNumber="1234"
            amount="1500"
            date="01/01/2023"
            onClickDownload={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardInvoice;
