import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import useHomeNavigation from '../customHook/useHomeNavigation';
import i18n from '../common/i18n';

const PrePaidEndMonth = () => {
  const goHome = useHomeNavigation();

  const goToHome = () => {
    goHome();
  };

  return (
    <>
      <Box m={6} />
      <Grid justify="center">
        <Grid item xs={12} md={12}>
          <Typography variant="h5">{i18n.prePaidLoanEndMonthErrorDescription1}</Typography>
          <Typography variant="h5">{i18n.prePaidLoanEndMonthErrorDescription2}</Typography>
        </Grid>
        <Box m={6} />
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={3}>
            <Button variant="outlined" color="primary" onClick={goToHome} fullWidth disableRipple>
              {i18n.backToTop}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PrePaidEndMonth;
