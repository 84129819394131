import { useHistory } from 'react-router-dom';
import {
  Box, Button, Grid, makeStyles, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useAuth } from '../../contexts/AuthContext';
import i18n from '../../common/i18n';
import { capitalizeSentence, fillMessageWith } from '../../utils/functionsUtil';
import { getPathRouteFromKey } from '../../utils/menuAndRoutesUtil';
import CardDebtor from '../CardDebtor';
import CardRevolvingLine from '../CardRevolvingLine';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: '24px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subheader: {
    marginBottom: '32px',
  },
  offset: {
    position: 'relative',
    top: '-50px',
  },
}));

const HomeHeader = () => {
  const { currentUser } = useAuth();
  const { hasDebt, hasRevolvingLine } = useCustomerAccountStatus();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid
        container
        justifyContent="center"
        className={classes.container}
        style={hasDebt() || hasRevolvingLine() ? { paddingBottom: '75px' } : { paddingBottom: '25px' }}
      >
        <Grid item xs={12} md={8} style={{ paddingLeft: '24px', paddingRight: '24px' }} className={classes.header}>
          <Typography variant="h1" align="left" style={{ fontSize: '1.25rem' }}>
            {fillMessageWith(i18n.homeHeaderWelcome, capitalizeSentence(currentUser.customerFirstName.toLowerCase()))}
          </Typography>
          <Button
            variant="text"
            style={{ color: 'white', textDecorationLine: 'underline' }}
            onClick={() => history.push(getPathRouteFromKey(i18n.helpMenuKey))}
          >
            {i18n.helpHeaderTitle}
          </Button>
        </Grid>
      </Grid>
      <Box className={clsx(classes.subheader, { [classes.offset]: hasDebt() || hasRevolvingLine() })}>
        <CardDebtor />
        <Box style={hasDebt() ? { marginTop: '24px' } : {}}>
          <CardRevolvingLine />
        </Box>
      </Box>
    </>
  );
};

export default HomeHeader;
