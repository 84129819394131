import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

const CustomDialog = ({
  open, setOpen, dialogData, onClick,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      {dialogData({ onClick, onClose: handleClose })}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  dialogData: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

CustomDialog.defaultProps = {
  onClick: () => console.log('onClickFunction'),
};

export default CustomDialog;
