/* eslint-disable react/no-danger */
import { useState } from 'react';
import {
  Box, Button, Grid, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import CardButton from '../components/CardButton';
import { QUESTIONS, SECTIONS } from '../utils/faqsContants';
import CustomAccordion from '../components/common/CustomAccordion';

const FAQs = () => {
  const [sectionSelected, setSectionSelected] = useState();
  const [textSearched, setTextSearched] = useState();

  const handleSelectSection = (id) => {
    setSectionSelected(id);
  };

  const goBack = () => {
    setSectionSelected(undefined);
    setTextSearched(undefined);
  };

  const handleSearch = (event) => {
    if (event.target.value.length >= 3) {
      setTextSearched(event.target.value);
    } else {
      setTextSearched(undefined);
    }
  };

  const searchFilterMatch = (question) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = question.body;

    // Obtener el texto sin las etiquetas
    let body = tmp.textContent || tmp.innerText;

    // Reemplazo caracteres acentuados
    body = body.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const title = question.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Realizar la búsqueda en el texto sin etiquetas
    const regex = new RegExp(textSearched, 'gi');
    return body.match(regex) || title.includes(textSearched);
  };

  const getFilteredQuestionList = () =>
    QUESTIONS.filter((question) => searchFilterMatch(question));

  return (
    <>
      <Header title={i18n.helpMenuLabel} />
      <Box mt={5} mb={5}>
        <Grid container justify="center">
          <Grid item xs={10}>
            <Typography variant="h2">{i18n.FAQs.title}</Typography>
            <Box mt={5} />
            <TextField
              variant="outlined"
              name="search"
              label={i18n.FAQs.search}
              fullWidth
              onChange={handleSearch}
              InputProps={{
                endAdornment: <InputAdornment><SearchIcon /></InputAdornment>,
              }}
            />
            <Box mt={5} />
            {/* ********** Lista de secciones ********** */}
            {!sectionSelected && !textSearched && (
              <>
                { SECTIONS.map((section) => (
                  <Box mb={3}>
                    <CardButton
                      title={section.title}
                      subtitle={section.subtitle}
                      onClick={() => handleSelectSection(section.id)}
                      hasSeparator
                    />
                  </Box>
                )) }
              </>
            )}

            {/* ********** Lista de preguntas por secciones ********** */}
            {sectionSelected && !textSearched && (
              <>
                { QUESTIONS.filter((question) => question.idSection === sectionSelected).map((question) => (
                  <>
                    <Box mt={3} mb={3}>
                      <CustomAccordion title={question.title}>
                        <div dangerouslySetInnerHTML={{ __html: question.body }} />
                      </CustomAccordion>
                    </Box>
                  </>
                )) }
                <Button
                  onClick={goBack}
                  variant="text"
                  color="primary"
                >
                  {i18n.FAQs.return}
                </Button>
              </>
            )}

            {/* ********** Lista de preguntas filtradas por search ********** */}
            {textSearched && (
              <>
                {getFilteredQuestionList().length > 0 ? (
                  <>
                    { getFilteredQuestionList().map((question) => (
                      <>
                        <Box mt={3} mb={3}>
                          <CustomAccordion title={question.title}>
                            <div dangerouslySetInnerHTML={{ __html: question.body }} />
                          </CustomAccordion>
                        </Box>
                      </>
                    )) }
                  </>
                ) : (
                  <Box mb={3}>
                    <Alert severity="warning">
                      <Typography>{i18n.FAQs.notResult}</Typography>
                    </Alert>
                  </Box>
                )}
                <Button
                  onClick={goBack}
                  variant="text"
                  color="primary"
                >
                  {i18n.FAQs.return}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FAQs;
