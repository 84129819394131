import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PublicRoutesList, RefinancingRoutesList } from '../utils/menuAndRoutesUtil';
import RefinancingHome from '../pages/refinancing/refinancingHome';
import RefinancingInstalmentPlan from '../pages/refinancing/refinancingInstallmentPlan';
import RefinancingSelfie from '../pages/refinancing/refinancingSelfie';
import RefinancingPaymentMethod from '../pages/refinancing/refinancingPaymentMethod';
import RefinancingSummary from '../pages/refinancing/refinancingSummary';
import RefinancingFinal from '../pages/refinancing/refinancingFinal';
import { RefinancingProvider, useRefinancingContext } from '../contexts/RefinancingContext';
import RefinancingBiometricResult from '../pages/refinancing/refinancingBiometricResult';
import useQueryParam from '../customHook/useQueryParam';

const RefinancingRoute = ({ children, ...props }) => {
  const { refinancingContext } = useRefinancingContext();
  const queryParam = useQueryParam();

  return (
    <Route
      {...props}
      render={() => (
        refinancingContext ? (
          <>
            {children}
          </>
        ) : (
          <Redirect to={{
            pathname: RefinancingRoutesList.home,
            search: `?hash=${queryParam.get('hash')}`,
          }}
          />
        )
      )}
    />
  );
};

RefinancingRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const RefinancingRouter = () => (
  <RefinancingProvider>
    <Switch>
      <Route exact path={RefinancingRoutesList.home}>
        <RefinancingHome />
      </Route>
      <RefinancingRoute exact path={RefinancingRoutesList.installmentPlan}>
        <RefinancingInstalmentPlan />
      </RefinancingRoute>
      <RefinancingRoute exact path={RefinancingRoutesList.selfie}>
        <RefinancingSelfie />
      </RefinancingRoute>
      <Route exact path={RefinancingRoutesList.biometricResult}>
        <RefinancingBiometricResult />
      </Route>
      <RefinancingRoute exact path={RefinancingRoutesList.paymentMethod}>
        <RefinancingPaymentMethod />
      </RefinancingRoute>
      <RefinancingRoute exact path={RefinancingRoutesList.summary}>
        <RefinancingSummary />
      </RefinancingRoute>
      <RefinancingRoute exact path={RefinancingRoutesList.final}>
        <RefinancingFinal />
      </RefinancingRoute>
      <Route>
        <Redirect from="*" to={PublicRoutesList.welcome} />
      </Route>
    </Switch>
  </RefinancingProvider>
);

export default RefinancingRouter;
