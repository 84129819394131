import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from '../common/i18n';
import { userLocationDataSchema } from '../forms/schemaValidations/schemaValidations';
import LoadingButton from './common/LoadingButton';
import TextInput from './common/TextInput';

import {
  getStateList, getDistrictList, getCityList,
} from '../api/selfieWebService';

const UserLocationData = ({
  data, onSubmit, loading, onEditForm, onCancelEditForm, onInitError,
}) => {
  const [editLocationForm, setEditLocationForm] = useState(false);
  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(userLocationDataSchema),
  });

  // Utilizado para esperar a que este toda la info de location para cargar el formulario
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  // Utilizado para el combo de provincias
  const [stateList, setStateList] = useState([]);
  const [customerState, setCustomerState] = useState({});

  // Utilizado para el combo de partidos
  const [districtList, setDistrictList] = useState([]);
  const [customerDistrict, setCustomerDistrict] = useState({});

  // Utilizado para el combo de localidades
  const [cityList, setCityList] = useState([]);
  const [customerCity, setCustomerCity] = useState({});

  const getAllLocationData = async () => {
    try {
      // Obtengo y populo el listado de provincias y selecciono por defecto la del cliente
      const stateListResponse = await getStateList();
      const stateListFinal = [{ id: null, description: '' }, ...stateListResponse.data];
      setStateList(stateListFinal);
      setCustomerState(stateListFinal[0]);
      stateListResponse.data.forEach((state) => {
        if (state.id === data.state) {
          setCustomerState(state);
        }
      });

      if (data.state !== '' && data.state !== undefined) {
        // Obtengo y populo el listado de partidos y selecciono por defecto el del cliente
        const districtListResponse = await getDistrictList(data.state);
        const districtListFinal = [{ id: null, description: '' }, ...districtListResponse.data];
        setDistrictList(districtListFinal);
        setCustomerDistrict(districtListFinal[0]);
        districtListResponse.data.forEach((district) => {
          if (district.id === data.district) {
            setCustomerDistrict(district);
          }
        });

        // Obtengo y populo el listado de localidades y selecciono por defecto la del cliente
        const cityListResponse = await getCityList(data.state);
        const cityListFinal = [{ id: null, description: '' }, ...cityListResponse.data];
        setCityList(cityListFinal);
        setCustomerCity(cityListFinal[0]);
        cityListResponse.data.forEach((city) => {
          if (city.id === data.city) {
            setCustomerCity(city);
          }
        });
      } else {
        const districtListFinal = [{ id: null, description: '' }];
        setDistrictList(districtListFinal);
        setCustomerDistrict(districtListFinal[0]);

        const cityListFinal = [{ id: null, description: '' }];
        setCityList(cityListFinal);
        setCustomerCity(cityListFinal[0]);
      }

      // Indico que ya se cargo toda la info para poder mostrar y popular el formulario
      setAllDataLoaded(true);
    } catch (error) {
      onInitError(error);
    }
  };

  // Utilizado para obtener el listado de provincias, partidos y localidades una vez recibida la info
  // desde el componente padre
  useEffect(() => { if (data.loaded) getAllLocationData(); }, [data]);

  useEffect(() => { methods.reset(data); }, [data]);

  const handleClickEditLocationForm = () => {
    setEditLocationForm(true);
    onEditForm();
  };

  const resetCombos = async () => {
    try {
      stateList.forEach((state) => {
        if (state.id === data.state) {
          setCustomerState(state);
          methods.setValue('state', data.state);
        }
      });

      if (data.state !== '' && data.state !== undefined) {
        const districtListResponse = await getDistrictList(data.state);
        const districtListFinal = [{ id: null, description: '' }, ...districtListResponse.data];
        setDistrictList(districtListFinal);
        setCustomerDistrict(districtListFinal[0]);
        districtListResponse.data.forEach((district) => {
          if (district.id === data.district) {
            setCustomerDistrict(district);
            methods.setValue('district', data.district);
          }
        });

        const cityListResponse = await getCityList(data.state);
        const cityListFinal = [{ id: null, description: '' }, ...cityListResponse.data];
        setCityList(cityListFinal);
        setCustomerCity(cityListFinal[0]);
        cityListResponse.data.forEach((city) => {
          if (city.id === data.city) {
            setCustomerCity(city);
            methods.setValue('city', data.city);
          }
        });
      }
    } catch (error) {
      onInitError(error);
    }
  };

  const handleCancelButton = () => {
    methods.reset(data);
    resetCombos();
    setEditLocationForm(false);
    onCancelEditForm();
  };

  const reloadDistrictsAndCities = async (state) => {
    try {
      // Obtengo y populo el listado de partidos y selecciono por defecto el del cliente
      setCustomerDistrict(null);
      methods.setValue('district', null);
      const districtListResponse = await getDistrictList(state);
      const districtListFinal = [{ id: null, description: '' }, ...districtListResponse.data];
      setDistrictList(districtListFinal);
      setCustomerDistrict(districtListFinal[0]);

      // Obtengo y populo el listado de localidades y selecciono por defecto la del cliente
      setCustomerCity(null);
      methods.setValue('city', null);
      const cityListResponse = await getCityList(state);
      const cityListFinal = [{ id: null, description: '' }, ...cityListResponse.data];
      setCityList(cityListFinal);
      setCustomerCity(cityListFinal[0]);
    } catch (error) {
      onInitError(error);
    }
  };

  // Acciones realizadas al cambiar de provincia
  const handleOnChangeState = (event, state) => {
    if (state !== null) {
      methods.setValue('state', state.id);
      setCustomerState(state);
      reloadDistrictsAndCities(state.id);
    } else {
      methods.setValue('state', stateList[0].id);
      setCustomerState(stateList[0]);
    }
  };

  // Acciones realizadas al cambiar de partido
  const handleOnChangeDistrict = (event, district) => {
    if (district !== null) {
      methods.setValue('district', district.id);
      setCustomerDistrict(district);
    } else {
      methods.setValue('district', districtList[0].id);
      setCustomerDistrict(districtList[0]);
    }
  };

  // Acciones realizadas al cambiar de localidad
  const handleOnChangeCity = (event, city) => {
    if (city !== null) {
      methods.setValue('city', city.id);
      setCustomerCity(city);
    } else {
      methods.setValue('city', cityList[0].id);
      setCustomerCity(cityList[0]);
    }
  };

  return (
    <>
      {allDataLoaded && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Box m={2} />
                <Typography variant="h2">{i18n.userLocationDataH2}</Typography>
                <Box m={1} />
                {!editLocationForm && (
                  <Button onClick={handleClickEditLocationForm}>
                    {i18n.userLocationDataCTAUpdate}
                  </Button>
                )}
              </Grid>
              <Box m={2} />
              <Grid item container alignItems="top" spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    value={customerState}
                    onChange={handleOnChangeState}
                    options={stateList}
                    disabled={!editLocationForm}
                    getOptionLabel={(state) => state?.description}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        name="state"
                        mandatory
                        label={i18n.userLocationDataInputProvince}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    value={customerDistrict}
                    onChange={handleOnChangeDistrict}
                    options={districtList}
                    disabled={!editLocationForm}
                    getOptionLabel={(district) => district?.description}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        name="district"
                        mandatory
                        label={i18n.userLocationDataInputDistrict}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    value={customerCity}
                    onChange={handleOnChangeCity}
                    options={cityList}
                    disabled={!editLocationForm}
                    getOptionLabel={(city) => city?.description}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        name="city"
                        mandatory
                        label={i18n.userLocationDataInputLocation}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    name="street"
                    mandatory
                    label={i18n.userLocationDataInputStreet}
                    fullWidth
                    disabled={!editLocationForm}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    name="number"
                    mandatory
                    label={i18n.userLocationDataInputNumber}
                    fullWidth
                    disabled={!editLocationForm}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    name="floor"
                    label={i18n.userLocationDataInputFloor}
                    fullWidth
                    disabled={!editLocationForm}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    name="apartment"
                    label={i18n.userLocationDataInputDepartment}
                    fullWidth
                    disabled={!editLocationForm}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    name="zipCode"
                    mandatory
                    label={i18n.userLocationDataInputPostalCode}
                    fullWidth
                    disabled={!editLocationForm}
                  />
                </Grid>
                <Box m={3} />
                {editLocationForm && (
                  <Grid item container direction="row" justify="center" alignItems="center" spacing={3}>
                    <Grid item sm={4} xs={12}>
                      <LoadingButton
                        style={{ width: '100%' }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={loading}
                      >
                        {i18n.userDataCTAprimary}
                      </LoadingButton>
                      <Button onClick={handleCancelButton}>
                        {i18n.userDataCTAsecondary}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>

            </Grid>

          </form>
        </FormProvider>
      )}
    </>
  );
};
UserLocationData.propTypes = {
  data: PropTypes.shape({
    state: PropTypes.string,
    district: PropTypes.number,
    city: PropTypes.number,
    loaded: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onEditForm: PropTypes.func.isRequired,
  onCancelEditForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onInitError: PropTypes.func.isRequired,
};
UserLocationData.defaultProps = {
  data: undefined,

};
export default UserLocationData;
