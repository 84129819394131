import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Box, Button, Dialog, Grid, makeStyles, IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useNotification } from '../contexts/NotificationContext';
import { markAsReadNotification } from '../api/selfieWebService';
import i18n from '../common/i18n';

const useStyles = makeStyles({
  mainContainer: {
    textAlign: 'center',
  },
  actionContainer: {
    padding: '30px',
  },
});

const NotificationDialogHandler = () => {
  const classes = useStyles();
  const { contextNotification, setContextNotification } = useNotification();
  const [open, setOpen] = useState(true);
  const [notificationStepList, setNotificationStepList] = useState();
  const [currentStep, setCurrentStep] = useState(0);

  const sortNotificationSteps = (steps) => steps.slice().sort((a, b) => a.order - b.order);

  const init = () => {
    // Si tiene notificaciones
    if (contextNotification && contextNotification.steps) {
      // Obtiene el listado de steps ordenados a mostrar
      const steps = sortNotificationSteps(contextNotification.steps);
      setNotificationStepList(steps);
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleClose = async () => {
    try {
      setOpen(false);
      await markAsReadNotification(contextNotification.id);
    } catch (error) {
      console.log(error);
    } finally {
      setContextNotification(undefined);
    }
  };

  const hasMoreNotificationSteps = () => currentStep < notificationStepList.length - 1;

  const getModalBody = () => (
    <>
      <Grid container style={{ padding: '0px' }}>
        <Grid item xs={12} style={{ textAlign: 'end' }}>
          <IconButton style={{ zIndex: 100 }}>
            <Close onClick={handleClose} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container className={classes.mainContainer} style={{ paddingTop: '0px' }}>
        <Grid item xs={12}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: notificationStepList[currentStep].htmlCode }} />
        </Grid>
        <Box mt={10} />
        <Grid item xs={12} className={classes.actionContainer}>
          {hasMoreNotificationSteps() ? (
            <Button onClick={handleNextStep} variant="contained" color="primary" fullWidth>
              {i18n.notificationDialogHandler.buttonNext}
            </Button>
          ) : (
            <Button onClick={handleClose} variant="contained" color="primary" fullWidth>
              {i18n.notificationDialogHandler.buttonClose}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );

  useEffect(() => init(), []);

  return (
    <>
      {notificationStepList && (
        <Dialog
          open={open}
          onClose={handleClose}
          setOpen={setOpen}
          fullWidth
          maxWidth="xs"
        >
          {getModalBody()}
        </Dialog>
      )}
    </>
  );
};

NotificationDialogHandler.propTypes = {
  notification: PropTypes.shape({}),
};

NotificationDialogHandler.defaultProps = {
  notification: undefined,
};

export default NotificationDialogHandler;
