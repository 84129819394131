import {
  Box, Grid, Container, makeStyles, Typography, Button,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  reprintPrePaidCard, getCustomerEditableData, getStateList, getDistrictList, getCityList,
} from '../api/selfieWebService';
import i18n from '../common/i18n';
import TextInput from '../components/common/TextInput';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import Loading from '../components/common/Loading';
import { useAuth } from '../contexts/AuthContext';
import { getError, fillMessageWith, replaceNullValuesByUndefined } from '../utils/functionsUtil';
import { PRE_PAID_CARD_REPRINT } from '../utils/referenceConstant';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { prepaidCardAddressSchema } from '../forms/schemaValidations/schemaValidations';

const useStyles = makeStyles({
  blockTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 800,
    textAlign: 'left',
    lineHeight: '33px',
  },
  blockMessage: {
    fontFamily: 'Open sans',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    paddingTop: '6px',
    lineHeight: '20px',
  },
  blockMessageCost: {
    fontFamily: 'Open sans',
    fontSize: '14px',
    fontWeight: 400,
    paddingTop: '6px',
    alignSelf: 'center',
    lineHeight: '70px',
    borderRadius: '4px',
    backgroundColor: '#D7E7FF',
  },
});

const PrePaidCardReprint = () => {
  // Context
  const classes = useStyles();
  const { currentUser } = useAuth();
  const history = useHistory();
  const [response, setResponse] = useState(undefined);
  const [initLoading, setInitLoading] = useState(false);
  const serviceCost = '826';
  const [preloadData, setPreloadData] = useState(undefined);
  const methods = useForm({
    defaultValues: preloadData,
    resolver: yupResolver(prepaidCardAddressSchema),
  });

  const goBack = () => {
    history.goBack();
  };

  const goToMyCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardKey));
  };

  const reload = () => {
    setResponse(undefined);
    setInitLoading(false);
  };

  const reprintCard = async (formData) => {
    try {
      setInitLoading(true);

      const address = { ...formData };
      // remove empty fields
      Object.keys(address).forEach((key) => {
        if (address[key] === undefined || address[key].trim() === '') {
          delete address[key];
        }
      });

      if (currentUser.customerTaxId !== undefined) {
        const res = await reprintPrePaidCard(currentUser.customerTaxId, address);
        if (res.status === 200) {
          setResponse({ status: 200 });
        } else {
          setResponse(getError(undefined));
        }
      }
      setInitLoading(false);
    } catch (error) {
      setResponse(getError(error));
    }
  };

  const initPrepaidCardReprint = async () => {
    setInitLoading(true);
    let locationData = {};
    let userState;
    let userDistrict;
    let userCity;

    try {
      const customerDataResponse = await getCustomerEditableData(currentUser.customerId);
      if (customerDataResponse.status === 200 && customerDataResponse.data.locationInfo) {
        locationData = replaceNullValuesByUndefined(customerDataResponse.data.locationInfo);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      if (locationData?.state) {
        const stateListResponse = await getStateList();
        stateListResponse.data.forEach((state) => {
          if (state.id === locationData.state) {
            userState = state;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    try {
      if (userState?.id) {
        const districtListResponse = await getDistrictList(userState.id);
        districtListResponse.data.forEach((district) => {
          if (district.id === locationData.district) {
            userDistrict = district;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    try {
      if (userState?.id) {
        const cityListResponse = await getCityList(userState.id);
        cityListResponse.data.forEach((city) => {
          if (city.id === locationData.city) {
            userCity = city;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    const userPreloadData = {
      province: userState?.description,
      city: userDistrict?.description,
      neighborhood: userCity?.description,
      street: locationData.street,
      number: locationData.number?.replace(/\D/g, ''),
      floor: locationData.floor,
      apartment: locationData.apartment,
      zipCode: locationData.zipCode,
      additionalInfo: '',
    };
    await setPreloadData(userPreloadData);

    setInitLoading(false);
  };

  useEffect(() => { methods.reset(preloadData); initPrepaidCardReprint(); }, []);

  return (
    <>
      <Header title={i18n.PrepaidCardHeaderTitle} />
      <Box m={1} />
      <Container>

        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={PRE_PAID_CARD_REPRINT}
            onErrorPrimary={reload}
            onSuccessPrimary={goToMyCard}
            canGoHome
            successGreen
          />
        )}

        {response === undefined && initLoading === true && (
          <Loading />
        )}

        {response === undefined && initLoading === false && (
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(reprintCard)}>
                <Box m={3} />
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Typography className={classes.blockTitle}>
                      {i18n.prePaidCardReprintTitle}
                    </Typography>
                    <Box m={1} />
                    <Typography className={classes.blockMessage}>
                      {i18n.prePaidCardReprintMessage}
                    </Typography>
                  </Grid>
                </Grid>
                <Box m={3} />
                <Grid container justify="center" spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      name="region"
                      defaultValue={preloadData?.province}
                      label={i18n.prePaidCardReprintInputProvinceLabel}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      mandatory
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      name="city"
                      defaultValue={preloadData?.city}
                      label={i18n.prePaidCardReprintInputCityLabel}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      mandatory
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      name="neighborhood"
                      defaultValue={preloadData?.neighborhood}
                      label={i18n.prePaidCardReprintInputNeighborhoodLabel}
                      fullWidth
                      inputProps={{ maxLength: 80 }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      name="street_name"
                      defaultValue={preloadData?.street}
                      label={i18n.prePaidCardReprintInputStreetNameLabel}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      mandatory
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={6}>
                    <TextInput
                      name="street_number"
                      defaultValue={preloadData?.number}
                      label={i18n.prePaidCardReprintInputStreetNumberLabel}
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                      mandatory
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={6}>
                    <TextInput
                      name="floor"
                      defaultValue={preloadData?.floor}
                      label={i18n.prePaidCardReprintInputFloorLabel}
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={6}>
                    <TextInput
                      name="apartment"
                      defaultValue={preloadData?.apartment}
                      label={i18n.prePaidCardReprintInputApartmentLabel}
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={6}>
                    <TextInput
                      name="zip_code"
                      defaultValue={preloadData?.zipCode}
                      label={i18n.prePaidCardReprintInputZipCodeLabel}
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      mandatory
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      name="additional_info"
                      defaultValue={preloadData?.additionalInfo}
                      label={i18n.prePaidCardReprintInputAdditionalInfoLabel}
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                    />
                  </Grid>
                </Grid>
                <Box m={3} />
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Typography className={classes.blockMessageCost}>
                      {fillMessageWith(i18n.prePaidCardReprintMessageCost,
                        serviceCost)}
                    </Typography>
                  </Grid>
                </Grid>
                <Box m={3} />
                <Grid container justify="center" spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {i18n.prePaidCardReprintButtonConfirm}
                    </Button>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Button
                      onClick={goBack}
                      variant="outlined"
                      color="primary"
                      fullWidth
                    >
                      {i18n.prePaidCardReprintButtonCancel}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </>
        )}

      </Container>
    </>
  );
};

export default PrePaidCardReprint;
