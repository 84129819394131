import PropTypes from 'prop-types';
import {
  Box, Card, CardContent, Grid, makeStyles, Typography, Button,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals, dateFormatter } from '../utils/formatterUtil';
import ShoppinCart from '../assets/imgs/shoppin-cart.svg';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    textTransform: 'none',
    display: 'block',
    marginTop: '26px',
  },
  cardLastMovementsTitle: {
    fontWeight: 700,
    textAlign: 'left',
    fontSize: '16px',
  },
  cardMovementsTitle: {
    fontWeight: 700,
    textAlign: 'left',
    fontSize: '20px',
  },
  cardSubtitle: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#E72064',
  },
  tableCol1Title: {
    fontWeight: 700,
    fontSize: '14px',
  },
  tableCol1Subtitle: {
    fontWeight: 400,
    fontSize: '14px',
  },
  tableCol2Title: {
    fontWeight: 700,
    fontSize: '16px',
  },
  tableCol2Subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  circleGray: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    border: '1px solid #F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  tableImage: {
    maxHeight: '50%',
    maxWidth: '65%',
  },
}));

function PrepaidCardMovements({
  paymentHistory, paymentHistoryLast, viewAllHistory, onCTABack, onCTAViewAll,
}) {
  const classes = useStyles();
  const entryType = {
    CREDIT: { label: '+ ', color: 'green' },
    DEBIT: { label: '- ', color: '' },
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>

        {/* go back button */}
        {viewAllHistory && (
          <Grid item xs={5} sm={4} md={3} className={classes.alignLeft}>
            <Button
              startIcon={<ArrowBack />}
              onClick={onCTABack}
              style={{ color: 'black' }}
            >
              {i18n.PrepaidCardBackButton}
            </Button>
            <Box m={3} />
          </Grid>
        )}

        <Grid item xs={12} container direction="row">

          {/* movements title */}
          <Grid item xs={7} sm={8} md={9}>
            {!viewAllHistory ? (
              <Typography className={classes.cardLastMovementsTitle}>
                {i18n.PrepaidCardLastMovementsTitle}
              </Typography>
            ) : (
              <Typography className={classes.cardMovementsTitle}>
                {i18n.PrepaidCardMovementsTitle }
              </Typography>
            )}
          </Grid>

          {/* view all movements button */}
          {!viewAllHistory && (
            <>
              <Grid item xs={5} sm={4} md={3} className={classes.alignRight}>
                <Button onClick={onCTAViewAll}>
                  <Typography className={classes.cardSubtitle}>
                    {i18n.PrepaidCardMovementsViewAll}
                  </Typography>
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        {/* movements list */}
        {(viewAllHistory ? paymentHistory : paymentHistoryLast).map((payment) => (
          <Grid item container direction="row" spacing={3} style={{ marginTop: '23px' }} key={payment.createdAt}>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <div className={classes.circleGray}>
                <img
                  className={classes.tableImage}
                  src={payment.merchant?.merchantLogoUrl ? payment.merchant.merchantLogoUrl : ShoppinCart}
                  alt="logo"
                />
              </div>
            </Grid>
            <Grid item xs={5} sm={6} md={8} className={classes.alignLeft}>
              <Typography className={classes.tableCol1Title}>
                {i18n.PREPAID_CARD_TRANSACTION_TYPE[payment.type]}
              </Typography>
              <Typography className={classes.tableCol1Subtitle}>
                {payment.merchant?.merchantName ? payment.merchant?.merchantName : ''}
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4} md={3} className={classes.alignRight}>
              <Typography
                className={classes.tableCol2Title}
                style={{ color: entryType[payment.transactions[0]?.entryType].color }}
              >
                {entryType[payment.transactions[0]?.entryType].label}
                {moneyFormatterWithoutDecimals(payment.transactions[0]?.totalAmount)}
              </Typography>
              <Typography className={classes.tableCol2Subtitle}>
                {dateFormatter(payment.createdAt)}
              </Typography>
            </Grid>
          </Grid>
        ))}

      </CardContent>
    </Card>
  );
}
PrepaidCardMovements.propTypes = {
  paymentHistory: PropTypes.arrayOf(PropTypes.objectOf(Object)).isRequired,
  paymentHistoryLast: PropTypes.arrayOf(PropTypes.objectOf(Object)).isRequired,
  viewAllHistory: PropTypes.bool.isRequired,
  onCTABack: PropTypes.func.isRequired,
  onCTAViewAll: PropTypes.func.isRequired,
};
export default PrepaidCardMovements;
