import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
  },
  subtitle: {
    fontWeight: '600',
    color: 'white',
  },
}));

function Header({ title, subtitle, additionalTitle }) {
  const classes = useStyles();

  return (
    <>
      <Box component="header" className={classes.header}>
        <Typography variant="h1">{title}</Typography>
        {subtitle !== undefined && (
          <>
            <Box m={3} />
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </>
        )}
        {additionalTitle !== undefined && (
          <>
            <Box m={1} />
            <Typography variant="h1">{additionalTitle}</Typography>
          </>
        )}
      </Box>
    </>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  additionalTitle: PropTypes.string,
};
Header.defaultProps = {
  title: '',
  subtitle: undefined,
  additionalTitle: undefined,
};

export default Header;
