import { Box, Grid, Typography } from '@material-ui/core';
import calculatorImg from '../../assets/imgs/calculator_animated.svg';
import i18n from '../../common/i18n';

/* eslint arrow-body-style:off */
const CalculatorLoading = () => {
  return (
    <>
      <Box mt={10} />
      <Grid container>
        <Grid item xs={12} md={12}>
          <img src={calculatorImg} alt="calculando" width="100px" />
          <br />
          <Typography>
            {i18n.CalculatorLoading.message[0]}
            <br />
            {i18n.CalculatorLoading.message[1]}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CalculatorLoading;
