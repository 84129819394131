import { Grid } from '@material-ui/core';
import HeaderNav from '../../components/header/Header';
import BiometricResult from '../../components/BiometricResult';
import { useRefinancingBiometricResult } from '../../services/refinancingService';
import BiometricValidatorDefaultError from '../../components/BiometricValidatorDefaultError';
import { useRefinancingCommonStyles } from './refinancingHome';

const RefinancingBiometricResult = () => {
  const commonClasses = useRefinancingCommonStyles();
  const {
    showNotValidated, setShowNotValidated, handleContinue, handleBack, handleGoInit,
  } = useRefinancingBiometricResult();
  return (
    <>
      <HeaderNav nomenu />
      {!showNotValidated ? (
        <BiometricResult
          onHit={handleContinue}
          onNoHit={() => setShowNotValidated(true)}
          onErrRnp={() => setShowNotValidated(true)}
          onNotFound={() => setShowNotValidated(true)}
          onBack={() => setShowNotValidated(true)}
        />
      ) : (
        <Grid container className={commonClasses.body}>
          <Grid item xs={12}>
            <BiometricValidatorDefaultError retry={handleBack} goBack={handleGoInit} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RefinancingBiometricResult;
