import { } from '@material-ui/core/styles';
import {
  Box, Button, Container, Typography, Grid,
} from '@material-ui/core';
import '../index.css';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import CardCustomerDebtorAccordion from './CardCustomerDebtorAccordion';
import Header from './common/Header';
import PaymentMethodOnline from './paymentMethodExplanations/PaymentMethodOnline';
import PaymentMethodOnlineCreditMp from './paymentMethodExplanations/PaymentMethodOnlineCreditMp';

const PaymentMethodSelection = () => {
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Header title={i18n.payInstallmentHeaderTitle} />
      {customerIsUpToDate === false && (
        <CardCustomerDebtorAccordion
          totalInstallmentDebt={totalInstallmentDebt}
          totalPunitiveDebt={totalPunitiveDebt}
          totalDebt={totalDebt}
        />
      )}
      <Container maxWidth="lg">
        <Box m={6} />
        <Typography variant="h2">
          {i18n.payInstallmentMethodOnlineTitle}
          {' '}
        </Typography>
        <Box m={6} />
        <Grid container justify="center">
          <Grid item md={8}>
            <PaymentMethodOnline />
            <Box m={3} />
            <PaymentMethodOnlineCreditMp />
          </Grid>
        </Grid>
        <Box m={6} />
        <Typography variant="p">{i18n.payInstallmentBCRA}</Typography>
        <Box m={6} />
        <Button
          startIcon={<ArrowBack />}
          onClick={goBack}
        >
          {i18n.paymentDebitCardBackCTA}
        </Button>
      </Container>

    </>

  );
};

export default PaymentMethodSelection;
