import { Box, Grid, Typography } from '@material-ui/core';
import LoadingButton from '../../../components/common/LoadingButton';

/* eslint arrow-body-style: ["error", "always"] */
const WikiLoadingButton = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Text:
          </Typography>
          <Box m={3} />
          <LoadingButton>
            Text
          </LoadingButton>
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Text + onPress
          </Typography>
          <Box m={3} />
          <LoadingButton onPress={() => { alert('hola'); /* eslint-disable-line no-alert */ }}>
            Text
          </LoadingButton>
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Text + onPress + disabled
          </Typography>
          <Box m={3} />
          <LoadingButton onPress={() => { alert('hola'); /* eslint-disable-line no-alert */ }} disabled>
            Text
          </LoadingButton>
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Text + onPress + loading
          </Typography>
          <Box m={3} />
          <LoadingButton onPress={() => { alert('hola'); /* eslint-disable-line no-alert */ }} loading>
            Text
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default WikiLoadingButton;
