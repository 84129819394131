import { Grid } from '@material-ui/core';
import CardCustomerLegalStudy from '../../../components/CardCustomerLegalStudy';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardCustomerLegalStudy = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <CardCustomerLegalStudy />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardCustomerLegalStudy;
