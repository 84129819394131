import {
  Box, Container, Grid, Button, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import { useAuth } from '../contexts/AuthContext';
import { findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine } from '../api/selfieWebService';
import {
  getError, fillMessageWith,
  mapCodeToDescription, capitalizeSentence,
} from '../utils/functionsUtil';
import Loading from '../components/common/Loading';
import { getPathRouteFromKey, getPathRouteFromKeyAndParameters } from '../utils/menuAndRoutesUtil';
import CardInstallments from '../components/CardInstallments';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';

const useStyles = makeStyles(() => ({
  link: {
    padding: '0px',
    fontWeight: '700',
    textDecoration: 'underline',
  },
}));

function LoanInstallmentsAllList() {
  const [initLoading, setInitLoading] = useState(false);
  const history = useHistory();
  const [sortedInstallments, setSortedInstallments] = useState([]);
  const [loanData, setLoanData] = useState();
  const [response, setResponse] = useState();
  const classes = useStyles();
  const { idLoan } = useParams();
  const { currentUser } = useAuth();

  const filterLoan = (loan) => {
    if (loan) {
      setLoanData(loan);
      setSortedInstallments(loan.installments.sort((a, b) => (a.number - b.number)));
    } else {
      history.push(getPathRouteFromKey(i18n.myLoansKey));
    }
  };

  const initLoanInstallmentsAllList = async () => {
    try {
      setInitLoading(true);
      if (idLoan) {
        const res = await findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine(
          { customerTaxId: currentUser.customerTaxId },
        );
        if (res.data.length > 0) {
          const loan = res.data.filter((element) => element.externalId === idLoan);
          filterLoan(loan[0]);
        }
      } else {
        history.push(getPathRouteFromKey(i18n.myLoansKey));
      }
      setInitLoading(false);
    } catch (error) {
      setResponse(getError(error));
    }
  };
  useEffect(() => initLoanInstallmentsAllList(), []);

  const goBack = () => {
    history.push(
      getPathRouteFromKeyAndParameters(i18n.myInstallmentsKey, [loanData.externalId]),
    );
  };

  const headerTitle = loanData
    ? fillMessageWith(
      i18n.loanSelectionInstallmentsTitle,
      loanData.subproductId ? fillMessageWith(
        mapCodeToDescription(loanData.subproductId, i18n.subproductsGroup),
        capitalizeSentence(loanData.commerceName),
      ) : '',
      moneyFormatterWithoutDecimals(loanData?.disbursedAmount),
    ) : '';

  return (
    <>
      {loanData && (
        <Header title={headerTitle} />
      )}
      <Box m={3} />
      <Container>
        {response === undefined && initLoading === true && (
          <Loading />
        )}
        {response === undefined && initLoading === false && (
          <>
            <Box m={4} />
            <Grid container justify="center">
              <Grid item xs={11} sm={8} md={6}>
                <Typography variant="h2" style={{ textAlign: 'left' }}>
                  {i18n.loanInstallmentsAllListTitle}
                </Typography>
                <Box m={2} />
                {sortedInstallments && sortedInstallments.map((installment) => (
                  <CardInstallments
                    style={{ paddingBottom: '10px' }}
                    key={installment.number}
                    number={installment.number}
                    status={installment.status}
                    amount={installment.totalAmount - installment.waiverAmount}
                    dueDate={installment.dueDate}
                    isCurrentInstallment={installment.number === loanData.currentInstallment.number}
                  />
                ))}
                <Box m={2} />

                <Grid item style={{ textAlign: 'left' }}>
                  <Button variant="text" className={classes.link} onClick={goBack}>
                    {i18n.loanInstallmentsAllListBackButton}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

export default LoanInstallmentsAllList;
