import { Box, Grid, Typography } from '@material-ui/core';
import CardVerticalButton from '../../../components/CardVerticalButton';
import Change from '../../../assets/imgs/change-yellow.svg';

const WikiCardVerticalButton = () => (
  <>
    <Typography variant="h5" noWrap>
      Logo from source:
    </Typography>
    <Box m={1} />
    <Grid container justify="center">

      <Grid item xs={12} md={10}>
        <CardVerticalButton
          title="Titulo"
          logo={Change}
          onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
        />
      </Grid>
    </Grid>
    <hr />
    <Typography variant="h5" noWrap>
      Logo from node:
    </Typography>
    <Box m={1} />
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        <CardVerticalButton
          title="Titulo"
          logo={<img src={Change} alt="change" />}
          onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
        />
      </Grid>
    </Grid>
  </>
);

export default WikiCardVerticalButton;
