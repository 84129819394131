import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: '10px',
    borderColor: theme.palette.primary.main,
  },
  cuota: {
    fontSize: '1.2rem',
    '@media (max-width:600px)': {
      fontSize: '1.1rem',
    },
  },
}));

function CardInvoice({
  paymentNumber, amount, date, onClickDownload,
}) {
  const classes = useStyles();

  const handleDownloadInvoice = () => {
    onClickDownload(paymentNumber);
  };

  return (
    <Card className={classes.card} variant="outlined" square>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5">
              <b>
                {moneyFormatter(amount)}
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary"><b>{i18n.invoicesLastInvoice}</b></Typography>
            <Box m={3} />
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">
              Nº&nbsp;
              {paymentNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {date}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box m={2} />
            <Button
              startIcon={<GetAppIcon />}
              onClick={handleDownloadInvoice}
            >
              <b>Descargar</b>

            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
CardInvoice.propTypes = {
  paymentNumber: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  onClickDownload: PropTypes.func.isRequired,
};
export default CardInvoice;
