import { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Grid, TextField, Typography, makeStyles, useMediaQuery,
} from '@material-ui/core';
import Header from './common/Header';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith, sendDataLayerEvent } from '../utils/functionsUtil';
import ActionFooterFixed from './common/ActionFooterFixed';

const useStyles = makeStyles(() => ({
  inputContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const RevolvingLineAmountStep = ({ maxAmountAvailable, onContinue }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState(false);

  const isValidAmount = () => amount && amount > 0 && amount <= maxAmountAvailable;

  const handleInputChange = (e) => {
    const { value } = e.target;
    setAmount(value);
  };

  const handleInputBlur = () => (isValidAmount() ? setError(false) : setError(true));

  const handleOnContinue = () => {
    onContinue(amount);
    sendDataLayerEvent({
      event: 'lc_monto',
    });
  };

  return (
    <>
      <Header title={i18n.RevolvingLine.Header.title} />
      <Box mt={5} mb={10}>
        <Grid container justify="center">
          <Grid item xs={10}>
            <Typography variant="h2">
              {parse(fillMessageWith(i18n.RevolvingLine.AmountStep.title, moneyFormatter(maxAmountAvailable)))}
            </Typography>
            <Box mt={5} className={isMobile ? classes.inputContainerMobile : ''}>
              <Typography style={{ marginBottom: '20px' }}>{i18n.RevolvingLine.AmountStep.label}</Typography>
              <TextField
                required
                variant="outlined"
                name="amount"
                label={i18n.RevolvingLine.AmountStep.inputLabel}
                type="number"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                fullWidth
                error={error}
                helperText={
                  error ? fillMessageWith(i18n.RevolvingLine.AmountStep.error, moneyFormatter(maxAmountAvailable)) : ''
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ActionFooterFixed
        ctaLabel={i18n.RevolvingLine.AmountStep.ctaContinue}
        onCTA={handleOnContinue}
        disabled={!isValidAmount()}
      />
    </>
  );
};

RevolvingLineAmountStep.propTypes = {
  maxAmountAvailable: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default RevolvingLineAmountStep;
