import PropTypes from 'prop-types';
import {
  Box, Button, makeStyles, useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fixedFooter: {
    position: 'fixed',
    bottom: '0px',
    borderTop: '1px solid lightgrey',
    padding: '20px',
    width: '-webkit-fill-available',
    backgroundColor: 'white',
    zIndex: 1000,
  },
}));

const ActionFooterFixed = ({
  ctaLabel, onCTA, disabled, children,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box className={classes.fixedFooter}>
      { children }
      <Button
        variant="contained"
        color="primary"
        onClick={onCTA}
        fullWidth={isMobile}
        disabled={disabled}
      >
        {ctaLabel}
      </Button>
    </Box>
  );
};

ActionFooterFixed.propTypes = {
  ctaLabel: PropTypes.string.isRequired,
  onCTA: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

ActionFooterFixed.defaultProps = {
  disabled: false,
  children: undefined,
};

export default ActionFooterFixed;
