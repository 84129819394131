import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderColor: theme.palette.primary.main,
    textTransform: 'none',
    height: '140px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontFamily: 'Open sans',
    fontWeight: 700,
    textAlign: 'left',
    '@media (max-width:600px)': {
      fontSize: '1rem',
    },
  },
  cardSubtitle: {
    fontFamily: 'Open sans',
    fontWeight: 400,
    textAlign: 'left',
    fontSize: '1.2rem',
    '@media (max-width:600px)': {
      fontSize: '1rem',
    },
  },
  alignCenter: {
    margin: 'auto',
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#ED8CB2',
      color: 'white',
    },
  },
  separator: {
    border: '1px ridge',
    width: '100%',
  },
}));

function CardButton({
  title, subtitle, urlImage, onClick, hasSeparator, ...props
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined" {...props}>
      <CardActionArea onClick={onClick} style={{ height: '100%' }}>
        <CardContent>
          <Grid item container direction="row" spacing={3}>
            {urlImage && (
              <Grid item xs={5} sm={4}>
                <img src={urlImage} className="img-celular" height="70px" alt="" />
              </Grid>
            )}
            <Grid item xs={urlImage ? 7 : 12} sm={urlImage ? 8 : 12} className={classes.alignCenter}>
              {typeof title === 'string' && (
                <Typography variant="h5" component="h2" className={classes.cardTitle}>{title}</Typography>
              )}
              {typeof title !== 'string' && (title)}
              {hasSeparator && (
                <hr className={classes.separator} />
              )}
              {typeof subtitle === 'string' && (
                <Typography variant="h5" component="h2" className={classes.cardSubtitle}>{subtitle}</Typography>
              )}
              {typeof subtitle !== 'string' && (subtitle)}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
CardButton.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  urlImage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  hasSeparator: PropTypes.bool,
};
CardButton.defaultProps = {
  title: '',
  subtitle: '',
  urlImage: undefined,
  hasSeparator: false,
};
export default CardButton;
