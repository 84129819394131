import {
  Box, Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ArrowBack } from '@material-ui/icons';
import i18n from '../common/i18n';
import { generatePrePaidLoan, downloadPrePaidLoanDocument } from '../api/selfieWebService';
import { getError, generateLinkWithFileInResponse } from '../utils/functionsUtil';
import { useAuth } from '../contexts/AuthContext';
import Loading from './common/Loading';
import LoadingButton from './common/LoadingButton';
import PrePaidLoanInfoBox from './PrePaidLoanInfoBox';

const useStyles = makeStyles(() => ({
  amountText: {
    color: '#E72064',
    fontFamily: 'Poppins',
    fontSize: '28px',
  },
  description: {
    textAlign: 'center',
    '@media (max-width:600px)': {
      textAlign: 'left',
    },
  },
}));

const PrePaidLoanData = ({
  loan, cancelationData, raiseResponse, onGoBack, onGenerate,
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const downloadDocument = async () => {
    try {
      setLoadingDownload(true);
      const res = await downloadPrePaidLoanDocument(
        cancelationData.idLoan,
        cancelationData.minAmount,
        `${currentUser.customerId}`,
      );
      generateLinkWithFileInResponse(res);
      onGenerate();
    } catch (error) {
      const err = { ...getError(error), description: i18n.prePaidLoanTitleDownload };
      raiseResponse(err, false);
    } finally {
      setLoadingDownload(false);
    }
  };

  const generatePrePaid = async () => {
    try {
      setLoading(true);
      await generatePrePaidLoan(cancelationData.idLoan, cancelationData.minAmount, `${currentUser.customerId}`);
      await downloadDocument();
    } catch (error) {
      const err = { ...getError(error), description: i18n.prePaidLoanTitleGenerate };
      raiseResponse(err, false);
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <>
      {loading && (
        <Loading />
      )}
      {!loading && (
        <Grid container justify="center">
          <Grid item xs={11} md={6}>
            <Box m={3} />
            <Typography variant="h2">{i18n.prePaidLoanGenerateTitle}</Typography>
            <Box m={5} />
            <Typography variant="h5" className={classes.description}>
              {i18n.prePaidLoanGenerateDescription[0]}
            </Typography>
            <Box m={2} />
            <Typography variant="h5" className={classes.description}>
              {i18n.prePaidLoanGenerateDescription[1]}
            </Typography>
            <Box m={5} />
            <PrePaidLoanInfoBox loan={loan} cancelationData={cancelationData} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Grid container justify="center">
              <Grid item xs={12} md={6}>
                <Box m={5} />
                <Alert severity="info" align="left">{i18n.prePaidLoanAlertMessage}</Alert>
                <Box m={5} />
                <LoadingButton
                  onPress={generatePrePaid}
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  loading={loadingDownload}
                >
                  {i18n.prePaidLoanDownloadCTA}
                </LoadingButton>
                <Box m={3} />
                <Button
                  onClick={onGoBack}
                  color="primary"
                  startIcon={<ArrowBack />}
                >
                  {i18n.prePaidLoanBackCTA}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

PrePaidLoanData.propTypes = {
  loan: PropTypes.shape().isRequired,
  cancelationData: PropTypes.shape({
    idLoan: PropTypes.string.isRequired,
    minAmount: PropTypes.string.isRequired,
  }).isRequired,
  raiseResponse: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
};
export default PrePaidLoanData;
