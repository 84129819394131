import {
  Box, Grid, MenuItem, Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import i18n from '../common/i18n';
import LoadingButton from './common/LoadingButton';
import UploadFile from './common/UploadFile';
import { uploadPaymentReceiptSchema } from '../forms/schemaValidations/schemaValidations';
import SimpleSelect from './common/SimpleSelect';

const UploadPaymentReceipt = ({
  data, options, itemLabelKey, itemKey, onSubmit, loading,
}) => {
  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(uploadPaymentReceiptSchema),
  });

  const [paymentType, setPaymentType] = useState();

  useEffect(() => { methods.reset(data); }, [data]);

  const handleUploadChange = (path, file, imgContent) => {
    methods.setValue('pathFile', path);
    methods.setValue('fileName', file.name);
    methods.setValue('imgUploaded', imgContent);
    methods.setValue('file', file);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box m={3} />
        <SimpleSelect
          mandatory
          label={i18n.paymentReceiptLabelSelect}
          name="typeReceiptSelect"
          onClick={(item) => {
            if (item.target.value !== undefined && item.target.value !== 0) {
              setPaymentType(item.target.value);
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option[itemKey]} value={option[itemKey]}>
              {option[itemLabelKey]}
            </MenuItem>
          ))}
        </SimpleSelect>

        {paymentType === 1 && (
          <>
            <Box m={3} />
            <Grid container justify="center" style={{ textAlign: 'left' }}>
              <Grid item xs={12} md={12}>
                <Typography component="h4" variant="h5">
                  {i18n.paymentReceiptTicketTextFirst}
                  <b>
                    {i18n.paymentReceiptTicketTextSecond}
                  </b>
                  {i18n.paymentReceiptTicketTextThird}
                </Typography>
                <Box mt={3}>
                  <Typography component="h4" variant="h6">
                    {i18n.paymentReceiptTicketTextFourth}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>

        )}
        {paymentType !== 1 && paymentType !== undefined && (
          <Grid container justify="center">
            <Grid item xs={12} md={12}>
              <UploadFile
                name="uploadFile"
                uploadChange={handleUploadChange}
                id="typeReceipt"
                uploadFileLabelButton={i18n.paymentReceiptUploadFileLabelButton}
                style={{ width: '100%' }}
              />
            </Grid>
            <Box m={3} />
            <Grid container justify="center">
              <Grid item xs={12} md={6}>
                <LoadingButton
                  style={{ width: '100%' }}
                  type="submit"
                  loading={loading}
                >
                  {i18n.paymentReceiptCTAprimary}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};
UploadPaymentReceipt.propTypes = {
  data: PropTypes.shape({ typeReceiptSelect: PropTypes.string }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  itemLabelKey: PropTypes.string,
  itemKey: PropTypes.string,
};
UploadPaymentReceipt.defaultProps = {
  itemLabelKey: 'label',
  itemKey: 'value',

};

export default UploadPaymentReceipt;
