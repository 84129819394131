import {
  Box, Container, Grid, Button, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import Toast from '../components/common/Toast';
import {
  findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine,
  getLoanFileFromTypeFile, getPaymentVoucherFile, getLoanProductDetails, getPaymentsDetails,
} from '../api/selfieWebService';
import {
  getError, generateLinkWithFileInResponse, fillMessageWith, mapCodeToDescription, capitalizeSentence,
} from '../utils/functionsUtil';
import Loading from '../components/common/Loading';
import { getPathRouteFromKey, getPathRouteFromKeyAndParameters } from '../utils/menuAndRoutesUtil';
import CardInstallments from '../components/CardInstallments';
import {
  moneyFormatter, dateFormatter, dateLongFormatter, moneyFormatterWithoutDecimals,
} from '../utils/formatterUtil';
import { useAuth } from '../contexts/AuthContext';
import CardVerticalButton from '../components/CardVerticalButton';
import downloadIcon from '../assets/imgs/download-outlined-icon.svg';
// import cancelIcon from '../assets/imgs/cancel-outlined-icon.svg';
import TakeSurveyModal from '../components/TakeSurveyModal';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.secondary.main,
    width: '28px',
    height: '28px',
  },
  link: {
    padding: '0px',
    fontWeight: '700',
    textDecoration: 'underline',
    fontSize: '14px',
  },
}));

function LoanInstallmentsList() {
  const classes = useStyles();
  const history = useHistory();
  const [initLoading, setInitLoading] = useState(false);
  const [sortedInstallments, setSortedInstallments] = useState([]);
  const [filteredInstallments, setFilteredInstallments] = useState(undefined);
  const [loanData, setLoanData] = useState();
  const [response, setResponse] = useState();
  const [paymentsInfo, setPaymentsInfo] = useState(undefined);
  const [downloadFileSuccess, setDownloadFileSuccess] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);
  const [hasDisclaimerFile, setHasDisclaimerFile] = useState(false);
  const [hasRapipagoFile, setHasRapipagoFile] = useState(false);
  const [showPaymentVoucher, setShowPaymentVoucher] = useState(false);
  const { currentUser } = useAuth();

  const { idLoan } = useParams();

  const typeDisclaimer = 'DISCLAIMER';
  const typeRapipago = 'RAPIPAGO';
  const [loanProductDetailsResponse, setloanProductDetailsResponse] = useState({});

  const handleCloseToast = () => {
    setOpenToast(false);
    setDownloadFileSuccess(undefined);
  };

  const showPayButton = () => (
    (loanData.currentInstallment === 'PENDING'
      || loanData.currentInstallment === 'OVERDUE'
      || loanData.currentInstallment === 'PARTIAL')
  );

  const filterInstallmentsShow = () => {
    let installmentsResponse;
    if (sortedInstallments.length > 1) {
      const oneAboveCurrent = Number(loanData.currentInstallment.number) + 1;
      installmentsResponse = sortedInstallments.filter(
        (installment) => (oneAboveCurrent >= Number(installment.number)),
      );
      if (installmentsResponse.length > 4) {
        const twoBelowCurrent = Number(loanData.currentInstallment.number) - 2;
        installmentsResponse = installmentsResponse.filter(
          (installment) => (twoBelowCurrent <= Number(installment.number)),
        );
      }
    }
    return installmentsResponse;
  };

  const getLoanProductDetailsInfo = async (idCredit) => {
    const res = await getLoanProductDetails(idCredit);
    setloanProductDetailsResponse(res.data);
  };

  const resolvePaymentsDetails = async () => {
    try {
      const res = await getPaymentsDetails(currentUser.customerId);
      if (res.data) {
        setPaymentsInfo(res.data.filter((element) => element.loanId === idLoan));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterLoan = async (loan) => {
    if (loan) {
      loan.files?.forEach((element) => {
        switch (element.type) {
          case typeDisclaimer: setHasDisclaimerFile(true); break;
          case typeRapipago: setHasRapipagoFile(true); break;
          default: break;
        }
      });
      setLoanData(loan);
      setSortedInstallments(loan.installments?.sort((a, b) => (a.number - b.number)));
      await getLoanProductDetailsInfo(loan.externalId);
    } else {
      history.push(getPathRouteFromKey(i18n.myLoansKey));
    }
  };

  const initLoanInstallmentsList = async () => {
    try {
      setInitLoading(true);
      if (idLoan) {
        const res = await findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine(
          { customerTaxId: currentUser.customerTaxId },
        );
        if (res.data.length > 0) {
          const loan = res.data.filter((element) => element.externalId === idLoan);
          filterLoan(loan[0]);
          setShowPaymentVoucher(loan[0].paymentMethod === 'VOLUNTARY');
        }
        // chequea si tiene pagos en vuelo
        resolvePaymentsDetails();
      } else {
        history.push(getPathRouteFromKey(i18n.myLoansKey));
      }

      setInitLoading(false);
    } catch (error) {
      setResponse(getError(error));
    }
  };
  useEffect(() => {
    initLoanInstallmentsList();
  }, []);
  useEffect(() => { setFilteredInstallments(filterInstallmentsShow()); }, [sortedInstallments]);

  const goToPayInstallment = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  // const goToPreCanLoan = () => {
  //   history.push(getPathRouteFromKey(i18n.prePaidLoanKey));
  // };

  const download = async (typeFile) => {
    try {
      const res = await getLoanFileFromTypeFile(currentUser.customerTaxId, loanData.externalId, typeFile);
      generateLinkWithFileInResponse(res);
      setDownloadFileSuccess(true);
      setOpenToast(true);
    } catch (error) {
      setDownloadFileSuccess(false);
      setOpenToast(true);
    }
  };

  const downloadPaymentVoucher = async () => {
    try {
      const res = await getPaymentVoucherFile(currentUser.customerId, loanData.externalId);
      generateLinkWithFileInResponse(res);
      setDownloadFileSuccess(true);
      setOpenToast(true);
    } catch (error) {
      setDownloadFileSuccess(false);
      setOpenToast(true);
    }
  };

  const headerTitle = loanData
    ? fillMessageWith(
      i18n.loanSelectionInstallmentsTitle,
      loanData.subproductId ? fillMessageWith(
        mapCodeToDescription(loanData.subproductId, i18n.subproductsGroup),
        capitalizeSentence(loanData.commerceName),
      ) : '',
      moneyFormatterWithoutDecimals(loanData?.disbursedAmount),
    ) : '';

  return (
    <>
      <TakeSurveyModal delay="10000" />
      {loanData && (
        <Header title={headerTitle} />
      )}
      <Box m={3} />
      <Container>
        {response === undefined && initLoading === true && (
          <Loading />
        )}
        {response === undefined && initLoading === false && (
          <>
            <Box m={6} />

            <Grid container justify="center">
              <Grid item xs={11} sm={8} md={6}>

                <Grid container style={{ textAlign: 'left' }}>
                  { paymentsInfo && paymentsInfo.length > 0 && paymentsInfo[0].amount && paymentsInfo[0].date && (
                  <>
                    <Grid item xs={12}>
                      <Alert severity="info" onClose={() => { setPaymentsInfo(undefined); }}>
                        <Typography style={{ fontSize: '14px' }}>
                          <b>{i18n.loanInstallmentsRecentPaymentsMessage}</b>
                        </Typography>
                        <Typography style={{ fontSize: '14px' }}>
                          {fillMessageWith(
                            i18n.loanInstallmentsRecentPaymentsDetail,
                            moneyFormatter(paymentsInfo[0].amount),
                            dateFormatter(paymentsInfo[0].date.trim().split(' ')[0]),
                          )}
                          { paymentsInfo && paymentsInfo.length > 1 && (paymentsInfo).slice(1).map((payInfo) => (
                            <>
                              {payInfo && payInfo.amount && payInfo.date && (
                              <>
                                {fillMessageWith(
                                  i18n.loanInstallmentsRecentPaymentsDetailExt,
                                  moneyFormatter(payInfo.amount),
                                  dateFormatter(payInfo.date.trim().split(' ')[0]),
                                )}
                              </>
                              )}
                            </>
                          ))}
                        </Typography>
                      </Alert>
                    </Grid>
                    <Box m={2} />
                  </>
                  )}
                </Grid>

                <Grid container style={{ textAlign: 'left' }}>
                  <Grid item xs={showPayButton ? 9 : 12}>
                    <Typography variant="h4" style={{ fontSize: '18px' }}>
                      {fillMessageWith(
                        i18n.loanInstallmentsListParagraph1,
                        loanData?.currentInstallment?.number,
                        loanData?.installmentCount,
                      )}
                    </Typography>
                    <Typography variant="h2" color="primary">
                      {moneyFormatter(loanData?.currentInstallment?.totalAmount)}
                    </Typography>
                  </Grid>
                  {showPayButton && (
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ fontSize: '1rem' }}
                        onClick={goToPayInstallment}
                      >
                        Pagar
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12} style={{ fontSize: '16px' }}>
                    <Typography variant="body">
                      <strong>
                        {fillMessageWith(
                          i18n.loanInstallmentsListParagraph2,
                          dateLongFormatter(loanData?.currentInstallment?.dueDate || new Date()),
                        )}
                      </strong>
                    </Typography>
                    <Box m={1} />
                    <Typography variant="body">
                      {fillMessageWith(
                        i18n.loanInstallmentsListParagraph3, dateFormatter(loanData?.startDate || new Date()),
                      )}
                    </Typography>
                    <Box m={0} />
                    <Typography variant="body">
                      {fillMessageWith(i18n.loanInstallmentsListParagraph4, loanData?.externalId)}
                    </Typography>
                    <Box m={1} />
                  </Grid>

                  {(Object.keys(loanProductDetailsResponse).length !== 0
                    && (loanData.subproductId === 69 || loanData.subproductId === 91))
                    && (
                      <Grid item xs={12}>
                        <Typography style={{ fontSize: '16px', fontWeight: '700' }}>
                          {fillMessageWith(
                            i18n.loanInstallmentsListParagraph5,
                            fillMessageWith(
                              mapCodeToDescription(loanData.subproductId, i18n.subproductsGroup),
                              capitalizeSentence(loanData.commerceName),
                            ),
                          )}
                        </Typography>
                        <Box m={1} />
                        <Typography variant="body">
                          {fillMessageWith(i18n.loanInstallmentsListParagraph6, loanProductDetailsResponse.companyName)}
                        </Typography>
                        <Box m={0} />
                        {(loanProductDetailsResponse.form !== null && loanData.subproductId === 69) && (
                          <>
                            <Typography variant="body">
                              {fillMessageWith(
                                i18n.loanInstallmentsListParagraph7,
                                loanProductDetailsResponse.form.IDC,
                              )}
                            </Typography>
                          </>
                        )}
                        <Box m={2} />
                      </Grid>
                    )}
                  <Typography variant="h2" style={{ marginTop: '20px' }}>{i18n.loanInstallmentsListTitle}</Typography>
                </Grid>
                <Box m={2} />
                {filteredInstallments && filteredInstallments.map((installment) => (
                  <CardInstallments
                    style={{ paddingBottom: '10px' }}
                    key={installment.number}
                    number={installment.number}
                    status={installment.status}
                    amount={installment.totalAmount - installment.waiverAmount}
                    dueDate={installment.dueDate}
                    isCurrentInstallment={installment.number === loanData.currentInstallment.number}
                  />
                ))}
                <Box m={2} />

                {sortedInstallments.length > 4 && (
                  <Grid item style={{ textAlign: 'left' }}>
                    <Button
                      variant="text"
                      className={classes.link}
                      onClick={() =>
                        history.push(getPathRouteFromKeyAndParameters(
                          i18n.myInstallmentsViewAllKey, [loanData.externalId],
                        ))}
                    >
                      {i18n.loanInstallmentsListViewAll}
                    </Button>
                  </Grid>
                )}

                <Box m={1} />
                <Grid container justify="center" spacing={1} style={{ paddingTop: '9px' }}>
                  {showPaymentVoucher && (
                    <Grid item xs={hasDisclaimerFile ? 4 : 6}>
                      <CardVerticalButton
                        title={i18n.loanInstallmentsListDownloadPaymentVoucher}
                        onClick={downloadPaymentVoucher}
                        logo={<img src={downloadIcon} className={classes.iconButton} alt="download" />}
                      />
                    </Grid>
                  )}
                  {hasRapipagoFile && (
                    <Grid item xs={hasDisclaimerFile ? 4 : 6}>
                      <CardVerticalButton
                        title={
                          // isEcommerce ? i18n.loanInstallmentsListDownloadPaymentReceipt :
                          i18n.loanInstallmentsListDownloadPaymentReceiptServices
                        }
                        onClick={() => download(typeRapipago)}
                        logo={<img src={downloadIcon} className={classes.iconButton} alt="download" />}
                      />
                    </Grid>
                  )}
                  {hasDisclaimerFile && (
                    <Grid item xs={showPaymentVoucher || hasRapipagoFile ? 4 : 6}>
                      <CardVerticalButton
                        title={i18n.loanInstallmentsListDownloadContractFile}
                        onClick={() => download(typeDisclaimer)}
                        logo={<img src={downloadIcon} className={classes.iconButton} alt="download" />}
                      />
                    </Grid>
                  )}
                  {/* <Grid
                    item
                    xs={
                      (showPaymentVoucher || hasRapipagoFile) && hasDisclaimerFile ? 4
                        : ((showPaymentVoucher || hasRapipagoFile) || hasDisclaimerFile ? 6 : 12)
                    }
                  >
                    <CardVerticalButton
                      title={i18n.loanInstallmentsListLoanPreCan}
                      onClick={goToPreCanLoan}
                      logo={<img src={cancelIcon} className={classes.iconButton} alt="download" />}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Container>

      <Box m={9} />
      {downloadFileSuccess && (
        <Toast
          messageOnSuccess={i18n.loanInstallmentsListToastDownloadSuccessMessage}
          open={openToast}
          onClose={handleCloseToast}
        />

      )}
      {!downloadFileSuccess && (
        <Toast
          messageOnError={i18n.loanInstallmentsListToastDownloadErrorMessage}
          open={openToast}
          onClose={handleCloseToast}
        />
      )}
    </>
  );
}

export default LoanInstallmentsList;
