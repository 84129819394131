import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import CardButton from './CardButton';
import { fillMessageWith, mapCodeToDescription, capitalizeSentence } from '../utils/functionsUtil';
import { moneyFormatter } from '../utils/formatterUtil';
import { getPathRouteFromKeyAndParameters } from '../utils/menuAndRoutesUtil';
import i18n from '../common/i18n';

const LoanSelectionInstallments = ({ loans }) => {
  const history = useHistory();
  const selectLoan = (loanData) => {
    history.push(
      getPathRouteFromKeyAndParameters(i18n.myInstallmentsKey, [loanData.externalId]),
    );
  };

  const titleNode = (loan) => (
    <Typography variant="h5" component="h2" style={{ fontSize: '18px' }}>
      <strong>
        {fillMessageWith(
          i18n.loanSelectionInstallmentsTitle,
          fillMessageWith(
            mapCodeToDescription(loan.subproductId, i18n.subproductsGroup),
            capitalizeSentence(loan.commerceName),
          ),
          moneyFormatter(loan.disbursedAmount),
        )}
      </strong>
    </Typography>
  );

  const subtitleNode = (loan) => (
    <Typography variant="body2" style={{ fontSize: '14px' }}>
      {fillMessageWith(
        i18n.loanSelectionInstallmentsSubtitle,
        loan.installmentCount,
        moneyFormatter(loan?.currentInstallment?.totalAmount),
      )}
    </Typography>
  );

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11} sm={6}>
          {loans.map((loan) => (
            <div key={loan.loanId}>
              <CardButton
                style={{ height: 'auto', textAlign: 'left' }}
                title={titleNode(loan)}
                subtitle={subtitleNode(loan)}
                onClick={() => selectLoan(loan)}
                hasSeparator
              />
              <Box m={3} />
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
LoanSelectionInstallments.propTypes = {
  loans: PropTypes.arrayOf(
    PropTypes.shape({
      currentInstallment: PropTypes.shape({ number: PropTypes.number.isRequired }).isRequired,
      installments: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string.isRequired })).isRequired,
      paymentMethod: PropTypes.string,
    }),
  ).isRequired,
};

export default LoanSelectionInstallments;
