import {
  Box, Button, Grid, makeStyles, Typography,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PropTypes from 'prop-types';
import i18n from '../../common/i18n';
import useHomeNavigation from '../../customHook/useHomeNavigation';
import { getEnv, URL_WHATSAPP } from '../../api/env';

const useStyles = makeStyles({
  whatsapp: {
    borderRadius: '25px',
    backgroundColor: '#00bb2d',
    color: 'white',
    textTransform: 'none',
    fontWeight: '700',
    fontFamily: 'Open sans',
    '&:hover': {
      backgroundColor: '#00911f',
    },
  },
});

const ActionNotAvailable = ({
  title, subTitle, tipMessage, labelForWhatsappButton, textForWhatsappLink, goHomeButton,
}) => {
  const classes = useStyles();
  const goHome = useHomeNavigation();
  const goToHome = () => {
    goHome();
  };

  const goWhatsappCac = () => {
    window.open(getEnv(URL_WHATSAPP) + (textForWhatsappLink || ''));
  };
  return (
    <>
      <Box m={3} />
      <Grid container justify="center">
        <Grid item xs={11} md={10}>
          <Box m={3} />
          <Typography variant="h2">
            {title}
          </Typography>
          {subTitle && (
            <>
              <Box m={3} />
              <Typography variant="h5">
                {subTitle}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      {tipMessage && (
        <>
          <Box m={4} />
          <Grid container justify="center">
            <Grid item xs={11} md={10}>
              <Box m={3} />
              <Typography>
                {tipMessage}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {labelForWhatsappButton && (
        <>
          <Box m={3} />
          <Grid container justify="center">
            <Grid item xs={11} sm={8} md={4}>
              <Button
                variant="contained"
                className={classes.whatsapp}
                onClick={goWhatsappCac}
                startIcon={<WhatsAppIcon />}
              >
                {labelForWhatsappButton}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {goHomeButton
   && (
   <>
     <Box m={3} />
     <Grid container justify="center">
       <Grid item xs={10} md={4}>
         <Button onClick={goToHome} style={{ marginTop: '10px' }} variant="outlined">
           {i18n.backToTop}
         </Button>
       </Grid>
     </Grid>
   </>
   )}
      <Box m={3} />
    </>
  );
};
ActionNotAvailable.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  labelForWhatsappButton: PropTypes.string,
  textForWhatsappLink: PropTypes.string,
  tipMessage: PropTypes.string,
  goHomeButton: PropTypes.bool,

};
ActionNotAvailable.defaultProps = {
  subTitle: undefined,
  labelForWhatsappButton: undefined,
  textForWhatsappLink: undefined,
  tipMessage: undefined,
  goHomeButton: true,
};
export default ActionNotAvailable;
