import PropTypes from 'prop-types';
import {
  Box, Typography, makeStyles,
} from '@material-ui/core';
import parse from 'html-react-parser';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

const useStyles = makeStyles(() => ({
  footer: {
    textAlign: 'justify',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '10px',
    fontWeight: 500,
  },
  cft: {
    fontSize: '30px',
    fontFamily: 'Open Sans, sans-serif',
    marginTop: '20px',
    color: '#4a4a4a',
  },
  tna: {
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    color: '#4a4a4a',
  },
  tea: {
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    color: '#4a4a4a',
  },
}));

const LegalFooter = ({ loanDetailCost }) => {
  const classes = useStyles();

  return (
    <Box mt={4} mb={10}>
      <Typography className={classes.footer}>
        {parse(i18n.RevolvingLine.SummaryStep.footer)}
      </Typography>
      <Typography className={classes.cft}>
        {fillMessageWith(i18n.RevolvingLine.SummaryStep.CFTA, loanDetailCost.cft)}
      </Typography>
      <Typography className={classes.tna}>
        {fillMessageWith(i18n.RevolvingLine.SummaryStep.TNA, loanDetailCost.tna)}
      </Typography>
      <Typography className={classes.tea}>
        {fillMessageWith(i18n.RevolvingLine.SummaryStep.TEA, loanDetailCost.tea)}
      </Typography>
    </Box>
  );
};

LegalFooter.propTypes = {
  loanDetailCost: PropTypes.shape().isRequired,
};

export default LegalFooter;
