import parse from 'html-react-parser';
import {
  Box, Typography, makeStyles,
  Card, CardActionArea, CardContent, Radio,
  Grid,
  Button,
} from '@material-ui/core';
import HeaderNav from '../../components/header/Header';
import Loading from '../../components/common/Loading';
import { fillMessageWith, isMobileOperatingSystem } from '../../utils/functionsUtil';
import i18n from '../../common/i18n';
import { moneyFormatter } from '../../utils/formatterUtil';
import { useRefinancingCommonStyles } from './refinancingHome';
import { useRefinancingContext } from '../../contexts/RefinancingContext';
import MessageResponse from '../../components/common/MessageResponse';
import { useRefinancingInstallmentPlan } from '../../services/refinancingService';

const useStyles = makeStyles(() => ({
  selectedCard: {
    border: '2px solid #F2025D',
    backgroundColor: '#F2025D0D',
  },
}));

const RefinancingInstalmentPlan = () => {
  const commonClasses = useRefinancingCommonStyles();
  const classes = useStyles();

  const {
    // eslint-disable-next-line max-len
    loading, installmentPlan, responseError, handleContinue, handleBack, selectedInstallment, selectInstallment, isSelected, errorMessage,
  } = useRefinancingInstallmentPlan();

  const { refinancingContext } = useRefinancingContext();

  return (
    <>
      <HeaderNav nomenu />
      {loading && (
        <Loading />
      )}

      {!loading && !responseError && (
        <>
          <Grid container className={commonClasses.body}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" align="left">
                {parse(fillMessageWith(
                  i18n.RefinancingInstalmentPlan.title,
                  moneyFormatter(refinancingContext.debtAmount),
                ))}
              </Typography>
              <Typography align="left">
                {parse(fillMessageWith(
                  i18n.RefinancingInstalmentPlan.refinancing, moneyFormatter(refinancingContext.refinancingAmount),
                ))}
              </Typography>
              <Box mt={2} />
              <Typography align="left">
                {i18n.RefinancingInstalmentPlan.chooseInstallment}
              </Typography>
              {installmentPlan.map((installment) => (
                <Box mt={2} key={installment.installment} mb={2}>
                  <Card
                    className={isSelected(installment) ? classes.selectedCard : ''}
                    variant="outlined"
                  >
                    <CardActionArea onClick={() => selectInstallment(installment)}>
                      <CardContent style={{ padding: '0px' }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Radio
                            color="primary"
                            checked={isSelected(installment)}
                          />
                          <Typography>
                            {parse(fillMessageWith(
                              installment.installment > 1
                                ? i18n.RefinancingInstalmentPlan.installments[1]
                                : i18n.RefinancingInstalmentPlan.installments[0],
                              installment.installment,
                              moneyFormatter(installment.amount),
                            ))}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid
                container
                className={commonClasses.ctaBox}
                spacing={isMobileOperatingSystem() ? 2 : 0}
                direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    disabled={!selectedInstallment}
                    fullWidth
                  >
                    {i18n.RefinancingInstalmentPlan.ctaContinue}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleBack}
                    fullWidth
                  >
                    {i18n.RefinancingInstalmentPlan.ctaBack}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {responseError && (
        <Grid container className={commonClasses.body} justify="center">
          <Grid item xs={10} md={6}>
            <MessageResponse
              response={responseError}
              referenceLabels={errorMessage}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RefinancingInstalmentPlan;
