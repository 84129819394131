import {
  Box, Button, Grid, MenuItem, Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import i18n from '../common/i18n';
import LoadingButton from './common/LoadingButton';
import { userClaimSchema } from '../forms/schemaValidations/schemaValidations';
import SimpleSelect from './common/SimpleSelect';
import TextInput from './common/TextInput';

const UserClaim = ({
  data, options, itemLabelKey, itemKey, onSubmit, loading,
}) => {
  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(userClaimSchema),
  });

  const [linkEditEmail, setLinkEditEmail] = useState();
  const [customerEmailDisable, setCustomerEmailDisable] = useState();
  const initSettings = async () => {
    if (methods.getValues('email') !== undefined && methods.getValues('email') !== null) {
      setCustomerEmailDisable(true);
      setLinkEditEmail(true);
    }
  };

  useEffect(() => { methods.reset(data); initSettings(); }, [data]);

  const handleEditEmail = () => {
    setLinkEditEmail(false);
    setCustomerEmailDisable(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box m={3} />
        <SimpleSelect mandatory label={i18n.claimsLabelSelect} name="typeClaimSelect">
          {options.map((option) => (
            <MenuItem key={option[itemKey]} value={option[itemKey]}>
              {option[itemLabelKey]}
            </MenuItem>
          ))}
        </SimpleSelect>
        <Box m={3} />

        <Typography style={{ fontSize: '14px', textAlign: 'left' }}>
          <b>
            {i18n.claimsTitleComments}
          </b>
          <Box m={3} />
        </Typography>

        <TextInput
          name="comments"
          label={i18n.claimsInputComments}
          fullWidth
          multiline
          rows={5}
          maxrows={10}
        />
        <Box m={6} />
        <Typography>
          <b>
            {i18n.claimsEmailForResponseMessage}
          </b>
        </Typography>
        <Box m={3} />

        <TextInput
          name="email"
          label={i18n.claimsInputEmail}
          fullWidth
          mandatory
          disabled={customerEmailDisable}
        />
        <Box m={3} />
        {customerEmailDisable && linkEditEmail && (
          <Button onClick={handleEditEmail}>{i18n.claimsWordingChangeEmail}</Button>
        )}
        <br />
        {!customerEmailDisable && (
          <Typography>{i18n.claimsWordingChangeEmailAlert}</Typography>
        )}
        <Box m={3} />
        <Grid container justify="center">
          <Grid item xs={12} md={5}>
            <LoadingButton
              style={{ width: '100%' }}
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              {i18n.claimsCTAprimary}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
UserClaim.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    typeClaimSelect: PropTypes.string,
    comments: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  itemLabelKey: PropTypes.string,
  itemKey: PropTypes.string,
};
UserClaim.defaultProps = {
  itemLabelKey: 'id',
  itemKey: 'customDescription',

};

export default UserClaim;
