import { Base64 } from 'js-base64';

export const numberFormatterWithDecimals = (element) => (
  `${new Intl.NumberFormat('es-AR', {
    currency: 'ARS',
    minimumFractionDigits: 2,
  }).format(element)}`
);

export const moneyFormatterWithDecimals = (element) => (
  `$${new Intl.NumberFormat('es-AR', {
    currency: 'ARS',
    minimumFractionDigits: 2,
  }).format(element)}`
);

export const moneyFormatter = (element) => (
  `$${new Intl.NumberFormat('es-AR', {
    currency: 'ARS',
    minimumFractionDigits: 2,
  }).format(element).replace(',00', '')}`
);

export const moneyFormatterWithoutDecimals = (element) => (
  `$${new Intl.NumberFormat('es-AR', {
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    roundingMode: 'trunc',
  }).format(element)}`
);

export const moneyFormatterSplitDecimals = (element) => {
  const amounts = `$${new Intl.NumberFormat('es-AR', {
    currency: 'ARS',
    minimumFractionDigits: 2,
  }).format(element)}`;
  return amounts.split(',');
};

export const dateFormatter = (date) => {
  const options = {
    timeZone: 'UTC',
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const dateLongFormatter = (date) => {
  const options = {
    timeZone: 'UTC',
    dateStyle: 'long',
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const dateTimeFormatter = (date) => {
  const options = {
    timeZone: 'America/Argentina/Buenos_Aires',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const amountFormatter = (value) => { // 1234 -> 12.34
  let response = value;
  const lengthResponse = response.length;
  if (lengthResponse < 3) {
    response.padStart(3, '0');
  }
  response = `${value.substring(0, lengthResponse - 2)}.${value.substring(lengthResponse - 2)}`;
  return response;
};

export const base64ToBuffer = (base64) => Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));

export const bufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return btoa(binary);
};

export const arrayBufferToBase64 = (buffer) => {
  const b64 = Base64.fromUint8Array(new Uint8Array(buffer));
  return b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};
