import { Box, Grid, Typography } from '@material-ui/core';
import CardButton from '../../../components/CardButton';
import imgHelp from '../../../assets/imgs/ayuda.svg';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardButton = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <CardButton />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title:
          </Typography>
          <Box m={3} />
          <CardButton title="Title" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle:
          </Typography>
          <Box m={3} />
          <CardButton title="Title" subtitle="Subtitle" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle + image:
          </Typography>
          <Box m={3} />
          <CardButton title="Title" subtitle="Subtitle" urlImage={imgHelp} />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle + image + onClick:
          </Typography>
          <Box m={3} />
          <CardButton
            title="Title"
            subtitle="Subtitle"
            urlImage={imgHelp}
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle + image + onClick + separator:
          </Typography>
          <Box m={3} />
          <CardButton
            title="Title"
            subtitle="Subtitle"
            urlImage={imgHelp}
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
            hasSeparator
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            TitleNode + SubtitleNode + image + onClick + separator:
          </Typography>
          <Box m={3} />
          <CardButton
            title={<Typography> TitleNode </Typography>}
            subtitle={<Typography> SubtitleNode </Typography>}
            urlImage={imgHelp}
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
            hasSeparator
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardButton;
