import { Box, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import Header from '../components/common/Header';
import i18n from '../common/i18n';
import { getInvoices, getInvoiceFile } from '../api/selfieWebService';
import MessageResponse from '../components/common/MessageResponse';
import Invoices from '../components/Invoices';
import { generateLinkWithFileInResponse, getError } from '../utils/functionsUtil';
import { CUSTOMER_INVOICES } from '../utils/referenceConstant';
import useHomeNavigation from '../customHook/useHomeNavigation';
import Toast from '../components/common/Toast';
import { useAuth } from '../contexts/AuthContext';
import ActionNotAvailable from '../components/common/ActionNotAvailable';
import Loading from '../components/common/Loading';

function CustomerInvoices() {
  // Context
  const { currentUser } = useAuth();
  const [invoices, setInvoices] = useState(undefined);
  const [emptyInvoices, setEmptyInvoices] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [downloadFileSuccess, setDownloadFileSuccess] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [initError, setInitError] = useState(false);

  const goHome = useHomeNavigation();

  const initCustomerInvoices = async () => {
    try {
      setInitLoading(true);
      const res = await getInvoices(currentUser.customerId);
      if (res.data.length > 0) {
        setInvoices(res.data);
      } else {
        setEmptyInvoices(true);
      }
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };

  useEffect(() => initCustomerInvoices(), []);

  const downloadInvoiceFile = async (paymentIdSelected) => {
    try {
      const res = await getInvoiceFile(currentUser.customerId, paymentIdSelected);
      generateLinkWithFileInResponse(res);
      setDownloadFileSuccess(true);
      setOpenToast(true);
    } catch (error) {
      setDownloadFileSuccess(false);
      setOpenToast(true);
    }
  };

  const downloadInvoice = (paymentIdSelected) => {
    downloadInvoiceFile(paymentIdSelected);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setDownloadFileSuccess(undefined);
  };

  const goToHome = () => {
    goHome();
  };

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initCustomerInvoices();
  };
  return (
    <>
      <Header title={i18n.invoicesHeaderTitle} />
      <Container maxWidth="lg">

        {response === undefined && initLoading === true && (
          <Loading />
        )}
        {response === undefined && initLoading === false && (
          <>
            {!emptyInvoices ? (
              <>
                <Grid container justify="center">
                  <Grid item xs={11} md={5}>
                    <Box m={6} />
                    <Alert severity="info">{i18n.invoicesAlertMessage}</Alert>
                  </Grid>
                </Grid>
                <Box m={6} />
                {invoices !== undefined && (
                  <Invoices invoices={invoices} onClickDownload={downloadInvoice} />
                )}
                {downloadFileSuccess && (
                  <Toast
                    messageOnSuccess={i18n.customerInvoicesToastSuccessMessage}
                    open={openToast}
                    onClose={handleCloseToast}
                  />
                )}
                {!downloadFileSuccess && (
                  <Toast
                    messageOnError={i18n.customerInvoicesToastErrorMessage}
                    open={openToast}
                    onClose={handleCloseToast}
                  />
                )}
              </>
            ) : (
              <>
                <Box m={3} />
                <ActionNotAvailable
                  title={i18n.customerInvoicesActionNotAvailableTitle}
                  subTitle={i18n.customerInvoicesActionNotAvailableSubTitle}
                />
              </>

            )}
          </>
        )}
        {response !== undefined && (
          <MessageResponse
            response={response}
            onSuccessPrimary={goToHome}
            onErrorPrimary={reload}
            referenceLabels={CUSTOMER_INVOICES}
            isInitError={initError}
          />
        )}
      </Container>
    </>
  );
}

export default CustomerInvoices;
