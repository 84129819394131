import {
  Box, Grid,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import Header from '../components/common/Header';
import i18n from '../common/i18n';

import { findFinishedLoansByCustomerTaxId } from '../api/selfieWebService';
import MessageResponse from '../components/common/MessageResponse';
import ActionNotAvailable from '../components/common/ActionNotAvailable';

import { CUSTOMER_FREE_DEBT } from '../utils/referenceConstant';
import FreeDebt from '../components/FreeDebt';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import Loading from '../components/common/Loading';

const CustomerFreeDebt = () => {
  // Context
  const { currentUser } = useAuth();
  const [loans, setLoans] = useState();
  const [noFinishedLoans, setNoFinishedLoans] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [initLoading, setInitLoading] = useState(false);

  const initCustomerFreeDebt = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await findFinishedLoansByCustomerTaxId({ customerTaxId: currentUser.customerTaxId });
        if (res.data.length > 0) {
          setLoans(res.data);
        } else {
          setNoFinishedLoans(true);
        }
      }
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };
  useEffect(() => initCustomerFreeDebt(), []);

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initCustomerFreeDebt();
  };

  return (
    <>
      <Header title={i18n.customerFreeDebtHeaderTitle} />
      {response === undefined && initLoading === true && (
        <Loading />
      )}
      {response === undefined && initLoading === false && (
        <>
          {!noFinishedLoans && loans !== undefined
            && (
              <>
                <Grid container justify="center">
                  <Grid item xs={11} md={5}>
                    <Box m={6} />
                    <Alert severity="info">{i18n.customerFreeDebtAlertMessage}</Alert>
                  </Grid>
                </Grid>
                <FreeDebt />
              </>
            )}
          {noFinishedLoans && (
            <ActionNotAvailable
              title={i18n.customerFreeDebtActionNotAvailableTitle}
              subTitle={i18n.customerFreeDebtActionNotAvailableSubTitle}
            />
          )}
        </>
      )}
      {response !== undefined && (
        <MessageResponse
          response={response}
          referenceLabels={CUSTOMER_FREE_DEBT}
          onErrorPrimary={reload}
          isInitError={initError}
        />
      )}
    </>
  );
};

export default CustomerFreeDebt;
