import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getPathRouteFromKey } from '../../utils/menuAndRoutesUtil';
import i18n from '../../common/i18n';
import { isMobileOperatingSystem } from '../../utils/functionsUtil';
import { showWebAuthnRegister } from '../../api/userStorage';
import { hasPermissionGrantedOrDenied } from '../../utils/firebase';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';
import { HOME_OPTIONS } from '../../constants/selfieConstants';

const useHomeController = () => {
  const history = useHistory();
  const { hasDebt, hasRevolvingLine, hasPrepaidCard } = useCustomerAccountStatus();
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [homeOptions, setHomeOptions] = useState();

  const prepareHomeMenuOptionsAvailable = () => {
    // Para todas las casuisticas, si el cliente tiene tarjeta se reemplaza la opcion de mis prestamos por mi tarjeta.
    const option = hasPrepaidCard() ? HOME_OPTIONS.homeMyCard : HOME_OPTIONS.homeMyLoans;
    if (hasDebt()) {
      setHomeOptions([
        option,
        HOME_OPTIONS.homePayInstallment,
        HOME_OPTIONS.homeMyPayments,
      ]);
    } else if (hasRevolvingLine()) {
      setHomeOptions([
        HOME_OPTIONS.homeNewLoan,
        HOME_OPTIONS.homePayService,
        HOME_OPTIONS.homeRecharge,
        option,
        HOME_OPTIONS.homePayInstallment,
        HOME_OPTIONS.homePayQR,
      ]);
    } else {
      setHomeOptions([
        HOME_OPTIONS.homeNewLoan,
        HOME_OPTIONS.homePayService,
        HOME_OPTIONS.homeRecharge,
        option,
        HOME_OPTIONS.homePayInstallment,
        HOME_OPTIONS.homeMyPayments,
      ]);
    }
  };

  const init = () => {
    if (!currentUser.hasCredentials) {
      // Si no tiene contraseña generada
      history.push(getPathRouteFromKey(i18n.generatePassword));
    } else if (isMobileOperatingSystem() && showWebAuthnRegister(currentUser.customerTaxId) && !currentUser.skipWebAuthnRegister) {
      // Si no tiene metodo de desbloqueo registrado y no omitio
      history.push(getPathRouteFromKey(i18n.webAuthnRegister));
    } else if (isMobileOperatingSystem() && !hasPermissionGrantedOrDenied() && !currentUser.skipNotification) {
      // Si no se subscribio o no omitio subscribirse a las notificaciones
      history.push(getPathRouteFromKey(i18n.pushNotificationSubscription));
    } else {
      prepareHomeMenuOptionsAvailable();
      setLoading(false);
    }
  };

  useEffect(init, []);

  return {
    loading, homeOptions,
  };
};

export default useHomeController;
