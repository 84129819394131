import { Button } from '@material-ui/core';
import NotificationDialogHandler from '../../../components/NotificationDialogHandler';
import { useNotification } from '../../../contexts/NotificationContext';

const WikiNotificationDialogHandler = () => {
  const { contextNotification, setContextNotification } = useNotification();

  const openNotification = () => {
    const notification = {
      id: 'fakeId',
      steps: [
        {
          order: 1,
          htmlCode: '<b>Hola</b><br/> Soy el primer step!',
        },
        {
          order: 2,
          htmlCode: '<b>Hola</b><br/> Soy el último step!',
        },
      ],
    };
    setContextNotification(notification);
  };

  return (
    <>
      <Button onClick={openNotification}>Ver Notificacion</Button>
      {contextNotification && (
        <NotificationDialogHandler />
      )}
    </>
  );
};

export default WikiNotificationDialogHandler;
