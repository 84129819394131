import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Grid, Box, Button,
} from '@material-ui/core';
import ErrorVerificationImage from '../assets/imgs/error-verification.svg';
import i18n from '../common/i18n';

const useStyles = makeStyles({
  imgCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
    marginBottom: 15,
  },
});

const BiometricValidatorNoHit = ({ goBack, subtitle }) => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={11} md={8}>
        <img className={classes.imgCenter} src={ErrorVerificationImage} alt="Selfie" width="200px" />
        <Box m={3} />
        <Typography variant="h2" style={{ fontSize: '2rem' }}>
          {i18n.biometricValidatorNoHitTitle}
        </Typography>
        <Box m={3} />
        <Typography variant="h5" className={classes.h3}>{subtitle}</Typography>
      </Grid>
      <Grid item xs={11} md={10}>
        <Box m={3} />
        <Typography variant="h5">{i18n.biometricValidatorNoHitProcessDescription}</Typography>
      </Grid>
      <Grid item xs={11} md={4}>
        <Box m={3} />
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={goBack}
        >
          {i18n.biometricValidatorRetry}
        </Button>
        <Box m={3} />
      </Grid>
    </Grid>
  );
};

BiometricValidatorNoHit.propTypes = {
  goBack: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

BiometricValidatorNoHit.defaultProps = {
  subtitle: '',
};

export default BiometricValidatorNoHit;
