import {
  Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCardMp from '../../assets/imgs/payment/img_credit-card-mp.png';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  details: {
    textAlign: 'center',
    fontSize: '0.8rem',

  },
  padding: {
    padding: '25px',
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid #D5D5D5',
  },
});

function PaymentMethodOnline() {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: '0.8rem', textAlign: 'left' }}>
          ¿Cómo pago con tarjeta de crédito o dinero en cuenta en Mercado Pago?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            <Typography className={classes.title}>
              Al ingresar a esta opcion serás redireccionado a la pagina de Mercado Pago
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <img
              src={CreditCardMp}
              width="auto"
              height="250px"
              alt="Imagen de tarjeta de débito"
              style={{
                marginTop: '25px',
              }}
            />
          </Grid>
          <Box m={2} />
          <Typography className={classes.details}>
            Allí tendrás el detalle del pago que estas por realizar, nro de prestamos y saldo de deuda.
            Puedes seleccionar dinero en cuenta o una tarjeta de crédito ya carga o cargar una nueva.
          </Typography>
          <Box m={2} />
          <Grid xs={12}>
            <Typography className={classes.details}>
              Una vez pago recordá subir el comprobante.
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PaymentMethodOnline;
