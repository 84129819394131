import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import useBiometricService from '../services/biometricService';
import Loading from './common/Loading';

const BiometricResult = ({
  onHit, onNoHit, onErrRnp, onNotFound, onBack,
}) => {
  const { processCallbackAndGetResult } = useBiometricService();

  const init = async () => {
    const result = await processCallbackAndGetResult();
    const { decision } = result;
    switch (decision) {
      case 'HIT': onHit(); break;
      case 'NO_HIT': onNoHit(); break;
      case 'ERR_RNP': onErrRnp(); break;
      case 'NOT_FOUND': onNotFound(); break;
      default: onBack();
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Loading />
        </Grid>
      </Grid>
    </>
  );
};

BiometricResult.propTypes = {
  onHit: PropTypes.func.isRequired,
  onNoHit: PropTypes.func.isRequired,
  onErrRnp: PropTypes.func.isRequired,
  onNotFound: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default BiometricResult;
