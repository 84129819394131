import {
  Box, Grid, Typography, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import imgImportant from '../../assets/imgs/important.svg';
import useHomeNavigation from '../../customHook/useHomeNavigation';
import i18n from '../../common/i18n';

const ActionWarning = ({ onClick, continueMessageBtn }) => {
  const goHome = useHomeNavigation();
  return (
    <>
      <Box m={3} />
      <Grid container justify="center">
        <Grid item xs={11} md={10}>
          <Box style={{ marginBottom: '30px' }}>
            <img src={imgImportant} height="100px" alt="Cuota abonada" />
          </Box>
        </Grid>
        <Grid item xs={11} md={10} style={{ marginBottom: '20px' }}>
          <Typography variant="h5">
            {i18n.actionWarningTitle}
          </Typography>
        </Grid>
        <Grid item xs={11} md={10}>
          <Typography variant="h5">
            <b>IMPORTANTE: </b>
            {i18n.actionWarningTipMessage}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ marginTop: '25px', marginBottom: '15px' }}>
        <Grid item xs={8} md={4} style={{ margin: '5px' }}>
          <Button onClick={onClick} variant="contained" style={{ width: '100%' }}>
            {continueMessageBtn}
          </Button>
        </Grid>
        <Grid item xs={8} md={4} style={{ margin: '5px' }}>
          <Button onClick={goHome} variant="outlined">
            {i18n.backToTop}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

ActionWarning.propTypes = {
  onClick: PropTypes.func.isRequired,
  continueMessageBtn: PropTypes.string,
};

ActionWarning.defaultProps = {
  continueMessageBtn: i18n.actionWarningContinueButton,
};
export default ActionWarning;
