import {
  Box, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getPrePaidLoanInformation } from '../api/selfieWebService';
import i18n from '../common/i18n';
import Loading from './common/Loading';
import PrePaidLoanData from './PrePaidLoanData';
import PrePaidLoanDismiss from './PrePaidLoanDismiss';
import { getError, getErrorCodeFromResponse, isEnableToPrepaid } from '../utils/functionsUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import PrePaidEndMonth from './PrePaidEndMonth';
import { ERROR_CREATE_PREPAID_LOAN_END_MONTH } from '../utils/PaymentConstant';
import PrePaidLoanSelectionRadio from './PrePaidLoanSelectionRadio';
import PrePaidLoanPaymentMethodSelection from './PrePaidLoanPaymentMethodSelection';
import PrePaidLoanPaymentMethodQR from './PrePaidLoanPaymentMethodQR';
import PrePaidLoanPaymentMethodBankTransfer from './PrePaidLoanPaymentMethodBankTransfer';

const PrePaidLoanSelection = ({
  loans, raiseResponse, onLoanSelected, onInit, idLoanSelected,
}) => {
  const history = useHistory();
  const [initLoadingPrePaidInfo, setInitLoadingPrePaidInfo] = useState(false);
  const [enableToPrePaid, setEnableToPrePaid] = useState(undefined);
  const [loanCancelationData, setLoanCancelationData] = useState();
  const [showEndMonth, setShowEndMonth] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState();
  const [showSelectPaymentMethod, setShowSelectPaymentMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(undefined);

  const handleLoanSelection = async (idLoan) => {
    onLoanSelected();
    setInitLoadingPrePaidInfo(true);
    const loanSelected = loans.find((loan) => loan.externalId === idLoan);
    setSelectedLoan(loanSelected);
    try {
      const isEnableToPrePaid = isEnableToPrepaid(loanSelected.disbursementDate);
      setEnableToPrePaid(isEnableToPrePaid);
      if (isEnableToPrePaid) {
        const res = await getPrePaidLoanInformation(idLoan);
        if (res.data) {
          const loanDataResult = {
            idLoan,
            minAmount: res.data.importeMinimo,
            dateFrom: res.data.f_vigencia_inicio,
            dateTo: res.data.f_vigencia_fin,
          };
          setLoanCancelationData(loanDataResult);
        }
      }
    } catch (error) {
      raiseResponse(getError(error), true);
    }
    setInitLoadingPrePaidInfo(false);
  };

  const handleOrRaiseResponse = async (response, isInitError) => {
    const errorCode = await getErrorCodeFromResponse(response);
    if (errorCode === ERROR_CREATE_PREPAID_LOAN_END_MONTH) {
      setShowEndMonth(true);
    } else {
      raiseResponse(response, isInitError);
    }
  };

  const handleOnGenerate = () => {
    setShowSelectPaymentMethod(true);
  };

  const goToSelection = () => {
    if (idLoanSelected !== undefined) {
      history.push(getPathRouteFromKey(i18n.revolvingLineDetail));
    } else {
      setLoanCancelationData(undefined);
      setSelectedLoan(undefined);
      onInit();
    }
  };

  const init = async () => {
    if (idLoanSelected !== undefined) {
      handleLoanSelection(idLoanSelected);
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      {!loanCancelationData && !initLoadingPrePaidInfo && !selectedLoan && (
        <>
          <Box m={6} />
          <Typography variant="h2">
            {i18n.prePaidLoanSubTitle}
          </Typography>

          <Box m={5} />
          {/* Pantalla seleccion de credito */}
          <PrePaidLoanSelectionRadio loans={loans} onContinue={handleLoanSelection} />
          <Box m={3} />
        </>
      )}

      {initLoadingPrePaidInfo === true && (
        <Loading />
      )}

      {initLoadingPrePaidInfo === false && !showEndMonth && (
        <>
          {enableToPrePaid === true && loanCancelationData !== undefined && !showSelectPaymentMethod && (
            <>
              {/* Pantalla para generar cancelacion */}
              <PrePaidLoanData
                loan={selectedLoan}
                cancelationData={loanCancelationData}
                raiseResponse={handleOrRaiseResponse}
                onGenerate={handleOnGenerate}
                onGoBack={goToSelection}
              />
            </>
          )}
          {enableToPrePaid === false && (
            <PrePaidLoanDismiss />
          )}
        </>
      )}

      {/* Pantalla seleccion metodo de pago */}
      {showSelectPaymentMethod && !paymentMethod && !showEndMonth && (
        <PrePaidLoanPaymentMethodSelection
          loan={selectedLoan}
          cancelationData={loanCancelationData}
          onGoBack={() => setShowSelectPaymentMethod(false)}
          onSelect={(method) => setPaymentMethod(method)}
        />
      )}

      {/* Pantalla metodo de pago QR */}
      {paymentMethod === i18n.payInstallmentQRKey && !showEndMonth && (
        <PrePaidLoanPaymentMethodQR
          loan={selectedLoan}
          cancelationData={loanCancelationData}
          onChange={() => setPaymentMethod(undefined)}
        />
      )}

      {/* Pantalla metodo de pago Transferencia bancaria o deposito */}
      {paymentMethod === i18n.payInstallmentBankTrxKey && !showEndMonth && (
        <PrePaidLoanPaymentMethodBankTransfer
          loan={selectedLoan}
          cancelationData={loanCancelationData}
          onChange={() => setPaymentMethod(undefined)}
        />
      )}

      {showEndMonth && (
        <PrePaidEndMonth />
      )}
      <Box m={3} />
    </>
  );
};
PrePaidLoanSelection.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  raiseResponse: PropTypes.func.isRequired,
  onLoanSelected: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  idLoanSelected: PropTypes.number,
};
PrePaidLoanSelection.defaultProps = {
  idLoanSelected: undefined,
};
export default PrePaidLoanSelection;
