import { Grid } from '@material-ui/core';
import CardInstallments from '../../../components/CardInstallments';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardInstallments = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <CardInstallments
            number="1234"
            status="PARTIAL"
            amount="1500"
            dueDate="01/01/2023"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CardInstallments
            number="1234"
            status="PARTIAL"
            amount="1500"
            dueDate="01/01/2023"
            partialPaymentAmount="250"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CardInstallments
            number="1234"
            status="PAID"
            amount="1500"
            dueDate="01/01/2023"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CardInstallments
            number="1234"
            status="PENDING"
            amount="1500"
            dueDate="01/01/2023"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CardInstallments
            number="1234"
            status="OVERDUE"
            amount="1500"
            dueDate="01/01/2023"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CardInstallments
            number="1234"
            status="OVERDUE"
            amount="1500"
            dueDate="01/01/2023"
            isCurrentInstallment
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardInstallments;
