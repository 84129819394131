/* eslint-disable indent */
import { useCallback, useMemo } from 'react';

import i18n from '../common/i18n';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useAuth } from '../contexts/AuthContext';

const useRouteValidation = () => {
  const { currentUser } = useAuth();
  const { customerAccountStatus } = useCustomerAccountStatus();

  const hasLegalStudyAssignmentOrPendingToAssign = () => (customerAccountStatus?.managmentLegalStudyStatus !== null);
  const customerIsDebtor = () => (customerAccountStatus?.customerIsUpToDate === false);
  const customerIsInBlacklist = () => (customerAccountStatus?.customerIsInBlacklist === true);
  const customerHasNotPrepaidCard = () => (customerAccountStatus?.customerHasPrepaidCard !== true);
  const customerHasNotRevolvingLineOffer = () =>
    (!(customerAccountStatus?.customerRevolvingLine?.maxAmount
      || customerAccountStatus?.customerRevolvingLine?.campaignOffer));
  const customerHasNotActiveLoanWithDebitCardPaymentOrIsNotUpToDate = () =>
    (!(customerAccountStatus?.customerIsUpToDate === true
      && customerAccountStatus?.hasActiveLoanWithDebitCardPayment === true));

  const needToSetPassword = () => !currentUser?.hasCredentials;

  const accessRules = [
    {
      ruleFunction: () => needToSetPassword(),
      pathsNotAllowedIfTrue:
        [
          getPathRouteFromKey(i18n.userProfileKey),
          getPathRouteFromKey(i18n.myInstallmentsViewAllKey),
          getPathRouteFromKey(i18n.myInstallmentsKey),
          getPathRouteFromKey(i18n.myLoansKey),
          getPathRouteFromKey(i18n.PaymentMethodSelectionOnlineKey),
          getPathRouteFromKey(i18n.HowPaymentMethodOnlineKey),
          getPathRouteFromKey(i18n.PaymentDebitCardFormKey),
          getPathRouteFromKey(i18n.PaymentMPFormKey),
          getPathRouteFromKey(i18n.PaymentStatus),
          getPathRouteFromKey(i18n.myCardActivateKey),
          getPathRouteFromKey(i18n.myCardDetailsKey),
          getPathRouteFromKey(i18n.myCardDisableKey),
          getPathRouteFromKey(i18n.myCardReprintKey),
          getPathRouteFromKey(i18n.myCardChangePinKey),
          getPathRouteFromKey(i18n.myCardChangePinKeyPreview),
          getPathRouteFromKey(i18n.myCardKey),
          getPathRouteFromKey(i18n.payInstallmentKey),
          getPathRouteFromKey(i18n.sendPaymentReceiptKey),
          getPathRouteFromKey(i18n.prePaidLoanKey),
          getPathRouteFromKey(i18n.customerFreeDebtKey),
          getPathRouteFromKey(i18n.loanPaymentVouchersKey),
          getPathRouteFromKey(i18n.moreProductsKey),
          getPathRouteFromKey(i18n.myBillsKey),
          getPathRouteFromKey(i18n.newLoanKey),
          getPathRouteFromKey(i18n.questionsKey),
          getPathRouteFromKey(i18n.surveyKey),
          getPathRouteFromKey(i18n.helpMenuKey),
          getPathRouteFromKey(i18n.myPaymentsMenuKey),
          getPathRouteFromKey(i18n.revolvingLineOffer),
          getPathRouteFromKey(i18n.revolvingLine),
          getPathRouteFromKey(i18n.revolvingLineDetail),
          getPathRouteFromKey(i18n.revolvingLineDetailInstallments),
          getPathRouteFromKey(i18n.revolvingLineDetailLoans),
          getPathRouteFromKey(i18n.revolvingLineDismiss),
          '/registro-desbloqueo',
        ],
    },
    {
      ruleFunction: () => hasLegalStudyAssignmentOrPendingToAssign(),
      pathsNotAllowedIfTrue:
        [
          getPathRouteFromKey(i18n.userProfileKey),
          getPathRouteFromKey(i18n.myLoansKey),
          getPathRouteFromKey(i18n.myInstallmentsKey),
          getPathRouteFromKey(i18n.myInstallmentsViewAllKey),
          getPathRouteFromKey(i18n.newLoanKey),
          getPathRouteFromKey(i18n.revolvingLineOffer),
          getPathRouteFromKey(i18n.payInstallmentKey),
          getPathRouteFromKey(i18n.sendPaymentReceiptKey),
          getPathRouteFromKey(i18n.prePaidLoanKey),
          getPathRouteFromKey(i18n.customerFreeDebtKey),
          getPathRouteFromKey(i18n.loanPaymentVouchersKey),
          getPathRouteFromKey(i18n.moreProductsKey),
          getPathRouteFromKey(i18n.myBillsKey),
          getPathRouteFromKey(i18n.myCardKey),
        ],
    },
    {
      ruleFunction: () => customerIsDebtor(),
      pathsNotAllowedIfTrue:
        [
          getPathRouteFromKey(i18n.newLoanKey),
          getPathRouteFromKey(i18n.moreProductsKey),
        ],
    },
    {
      ruleFunction: () => customerHasNotActiveLoanWithDebitCardPaymentOrIsNotUpToDate(),
      pathsNotAllowedIfTrue:
        [],
    },
    {
      ruleFunction: () => customerHasNotRevolvingLineOffer(),
      pathsNotAllowedIfTrue:
        [
        getPathRouteFromKey(i18n.revolvingLineOffer),
        ],
    },
    {
      ruleFunction: () => customerHasNotPrepaidCard(),
      pathsNotAvailableIfTrue:
        [
        getPathRouteFromKey(i18n.myCardKey),
        ],
    },
    {
      ruleFunction: () => customerIsInBlacklist(),
      pathsNotAvailableIfTrue:
        [
          getPathRouteFromKey(i18n.newLoanKey),
        ],
    },
  ];

  const getRoutesNotAllowed = useMemo(() => {
    let routesNotAllowed = [];
    accessRules.forEach((rule) => {
      if (rule.ruleFunction()) {
        routesNotAllowed = routesNotAllowed.concat(rule.pathsNotAllowedIfTrue);
      }
    });
    return routesNotAllowed;
  }, []);

  const getRoutesNotAvailable = useMemo(() => {
    let routesNotAvailable = [];
    accessRules.forEach((rule) => {
      if (rule.ruleFunction()) {
        routesNotAvailable = routesNotAvailable.concat(rule.pathsNotAvailableIfTrue);
      }
    });
    return routesNotAvailable;
  }, []);

  const isNotAllowed = useCallback((path) => {
    const routesNotAllowed = getRoutesNotAllowed;
    return routesNotAllowed.includes(path);
  }, []);

  const isNotAvailable = useCallback((path) => {
    const routesNotAvailable = getRoutesNotAvailable;
    return routesNotAvailable.includes(path);
  }, []);

  return { isNotAllowed, isNotAvailable };
};

export default useRouteValidation;
