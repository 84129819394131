import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const ClickeableBanner = ({
  imageUrl, onClick,
}) => {
  const style = {
    width: '100%',
    height: 'auto',
    borderRadius: '12px',
    cursor: 'pointer',
  };

  return (
    <>
      <Grid container onClick={onClick} justifyContent="center">
        <Grid item xs={12}>
          <img
            src={imageUrl}
            alt="BANNER"
            style={style}
          />
        </Grid>
      </Grid>
    </>
  );
};

ClickeableBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default ClickeableBanner;
