import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logoBind from '../../assets/imgs/payment/logo-bind.png';
import logoGalicia from '../../assets/imgs/payment/logo-galicia.svg';
import logoSantander from '../../assets/imgs/payment/logo-santander.png';
import logoMacro from '../../assets/imgs/payment/logo-macro.png';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  details: {
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  padding: {
    padding: '25px',
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid #D5D5D5',
  },
});

function PaymentMethodBankTrx() {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: '0.8rem', textAlign: 'center' }}>
          ¿Cómo pago con transferencia?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="center">
          <Grid item container justify="center">
            <Grid item xs={12} md={12}>
              <Typography className={classes.title}>
                Realizá una transferencia a cualquiera de estas cuentas:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.padding} style={{ marginBottom: '10px' }}>
              <img src={logoBind} height="35px" alt="Logo banco Industrial" />
              <Typography className={classes.details}>
                <b>Banco:</b>
                {' '}
                Banco Industrial
                <br />
                <b>Cuenta número:</b>
                {' '}
                4097/1
                <br />
                <b>CBU:</b>
                {' '}
                3220001805000040970014
                <br />
                <b>Razón social:</b>
                {' '}
                Credishopp S.A.
                <br />
                <b>CUIT:</b>
                {' '}
                30-71091158-0
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.padding}>
              <img src={logoSantander} height="28px" alt="Logo banco Santander" style={{ marginBottom: '10px' }} />
              <Typography className={classes.details}>
                <b>Banco:</b>
                {' '}
                Banco Santander
                <br />
                <b>Cuenta número:</b>
                {' '}
                34098/2
                <br />
                <b>CBU:</b>
                {' '}
                0720000720000003409822
                <br />
                <b>Razón social:</b>
                {' '}
                Credishopp S.A.
                <br />
                <b>CUIT:</b>
                {' '}
                30-71091158-0
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.padding}>
              <img src={logoGalicia} height="30px" alt="Logo Banco Galicia" style={{ marginBottom: '10px' }} />
              <Typography className={classes.details}>
                <b>Banco:</b>
                {' '}
                Banco Galicia
                <br />
                <b>Cuenta número:</b>
                {' '}
                7740229996
                <br />
                <b>CBU:</b>
                {' '}
                0070999020000077402262
                <br />
                <b>Razón social:</b>
                {' '}
                Credishopp S.A.
                <br />
                <b>CUIT:</b>
                {' '}
                30-71091158-0
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.padding}>
              <img src={logoMacro} height="30px" alt="Logo banco Macro" style={{ marginBottom: '10px' }} />
              <Typography className={classes.details}>
                <b>Banco:</b>
                {' '}
                Banco Macro
                <br />
                <b>Cuenta número:</b>
                {' '}
                330209412479680
                <br />
                <b>CBU:</b>
                {' '}
                2850302630094124796801
                <br />
                <b>Razón social:</b>
                {' '}
                Credishopp S.A.
                <br />
                <b>CUIT:</b>
                {' '}
                30-71091158-0
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography className={classes.title}>
                Una vez pago recordá subir el comprobante.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PaymentMethodBankTrx;
