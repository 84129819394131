import PropTypes from 'prop-types';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import { useState } from 'react';
import imgSuccess from '../../assets/imgs/success.svg';
import imgSuccessGreen from '../../assets/imgs/success_green.svg';
import imgError from '../../assets/imgs/error.svg';
import { ERROR, SUCCESS } from '../../utils/apiServicesConst';
import i18n from '../../common/i18n';
import useHomeNavigation from '../../customHook/useHomeNavigation';
import createApiMessage from '../../utils/createApiMessage';
import CustomDialog from './CustomDialog';

const MessageResponse = ({
  response, onSuccessPrimary, needAddGoToHomeButtonOnSuccess,
  onErrorPrimary, referenceLabels, canGoHome, isInitError, link, successGreen,
  subtitleMessageResponse, customCTA, customImgSuccess, customImgError, disableImg,
}) => {
  const message = createApiMessage(response, referenceLabels, isInitError, link);
  const goHome = useHomeNavigation();
  const [openDialog, setOpenDialog] = useState(false);

  const onClick = () => {
    if (message.status === SUCCESS && onSuccessPrimary) {
      onSuccessPrimary();
    } else if (message.status === ERROR && onErrorPrimary) {
      onErrorPrimary();
    }
  };

  const goToHome = () => {
    goHome();
  };

  return (
    <>
      <Box m={3} />
      <Grid container justifyContent="center">
        <Grid item xs={10} md={10}>
          {message.status === SUCCESS && (
            <img
              src={customImgSuccess || (successGreen === true ? imgSuccessGreen : imgSuccess)}
              className="success"
              width="130px"
              alt=""
              style={{ marginBottom: '28px' }}
            />
          )}
          {message.status === ERROR && !disableImg && (
            <>
              <img src={customImgError || imgError} className="error" width="130px" alt="" />
              <Box m={3} />
            </>
          )}
          <Typography variant="h2">
            {message.title}
          </Typography>
          {message.status === ERROR && (
            <>
              <Box m={3} />
              <Typography variant="h4">
                {message.apiErrorExplanation}
              </Typography>
            </>
          )}
          <Box m={3} />
          <Typography variant="h5">
            {subtitleMessageResponse !== undefined ? (
              subtitleMessageResponse()
            ) : message.subtitle}
            {message.link && (
              <>
                <Button
                  style={{ background: 'none', padding: '0', textDecoration: 'underline' }}
                  onClick={message.link.dialogData ? () => { setOpenDialog(true); } : message.onClick}
                >
                  <Typography variant="h5" style={{ paddingBottom: '4px' }}>
                    {message.link.label}
                  </Typography>
                </Button>
                {message.link.dialogData && (
                  <CustomDialog open={openDialog} setOpen={setOpenDialog} dialogData={message.link.dialogData} />
                )}
              </>
            )}
          </Typography>

        </Grid>
      </Grid>
      <Box m={6} />
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={11} sm={8}>
          {((message.status === SUCCESS && onSuccessPrimary) || (message.status === ERROR && onErrorPrimary))
            && isInitError === false && message.buttonLabel && (
              <Button variant="contained" color="primary" onClick={onClick} fullWidth disableRipple>
                {message.buttonLabel}
              </Button>
          )}
          {/* esto permite agregar un boton custom */}
          {
            customCTA
            && (
              (message.status === SUCCESS && !customCTA.notShowOnSuccess)
              || (message.status === ERROR && !customCTA.notShowOnError)
            )
            && (
              <Button onClick={customCTA.action} variant="text" fullWidth style={{ marginTop: '10px' }}>
                {customCTA.label}
              </Button>
            )
          }
          {/* esto permite un boton volver por defecto */}
          {
            (
              (message.status === SUCCESS && isInitError === false && needAddGoToHomeButtonOnSuccess)
              || (message.status === ERROR && canGoHome)
            )
            && (
              <Button onClick={goToHome} variant="outlined" fullWidth style={{ marginTop: '10px' }}>
                {i18n.backToTop}
              </Button>
            )
          }
        </Grid>
      </Grid>
    </>
  );
};

MessageResponse.propTypes = {
  response: PropTypes.shape({ data: PropTypes.shape() }).isRequired,
  onSuccessPrimary: PropTypes.func,
  successGreen: PropTypes.bool,
  needAddGoToHomeButtonOnSuccess: PropTypes.bool,
  onErrorPrimary: PropTypes.func,
  canGoHome: PropTypes.bool,
  referenceLabels: PropTypes.string.isRequired,
  isInitError: PropTypes.bool,
  link: PropTypes.shape({ data: PropTypes.shape() }),
  subtitleMessageResponse: PropTypes.element,
  customCTA: PropTypes.shape(
    {
      action: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      notShowOnSuccess: PropTypes.bool,
      notShowOnError: PropTypes.bool,
    },
  ),
  customImgSuccess: PropTypes.string,
  customImgError: PropTypes.string,
  disableImg: PropTypes.bool,
};
MessageResponse.defaultProps = {
  onSuccessPrimary: undefined,
  successGreen: false,
  onErrorPrimary: undefined,
  canGoHome: true,
  isInitError: false,
  needAddGoToHomeButtonOnSuccess: false,
  link: undefined,
  customCTA: undefined,
  customImgSuccess: undefined,
  customImgError: undefined,
  disableImg: false,
  subtitleMessageResponse: undefined,
};
export default MessageResponse;
