import { useState } from 'react';
import {
  Box, Grid, Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import RatingInput from '../../../components/common/RatingInput';

const WikiRatingInput = () => {
  const methods = useForm();
  const [newValue, setNewValue] = useState();
  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <Typography variant="h5" noWrap>RatingInput:</Typography>
              <Box m={3} />
              {/* Tambien se puede obtener el valor mediante methods / handleSubmit, cuando no se usa el onChange */}
              <RatingInput
                name="ratingInput"
                onChange={(e) => setNewValue(e.target.value)}
              />
              <Box m={3} />
              <Typography variant="h5" noWrap>El valor escogido es: </Typography>
              <Typography variant="h5" noWrap>{newValue}</Typography>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default WikiRatingInput;
