import { Grid } from '@material-ui/core';
import BindBankInfo from '../../../components/BindBankInfo';

/* eslint arrow-body-style: ["error", "always"] */
const WikiBindBankInfo = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <BindBankInfo />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiBindBankInfo;
