import {
  Button, Box, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useState } from 'react';
import i18n from '../common/i18n';
import imgDownload from '../assets/imgs/downloadDocument.svg';
import { generateLinkWithFileInResponse } from '../utils/functionsUtil';

import { getPaymentVoucherFile } from '../api/selfieWebService';
import Toast from './common/Toast';
import { useAuth } from '../contexts/AuthContext';

const PaymentVoucher = ({ loanData }) => {
  // Context
  const { currentUser } = useAuth();
  const [downloadFileSuccess, setDownloadFileSuccess] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);

  const downloadPaymentVoucher = async () => {
    try {
      const res = await getPaymentVoucherFile(currentUser.customerId, loanData.externalId);
      generateLinkWithFileInResponse(res);
      setDownloadFileSuccess(true);
      setOpenToast(true);
    } catch (error) {
      setDownloadFileSuccess(false);
      setOpenToast(true);
    }
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setDownloadFileSuccess(undefined);
  };
  return (
    <>
      <Typography variant="h5">
        <b>
          {i18n.loanPaymentVouchersCTA}
          {' del '}
          {i18n.loanSelectionLabel[0].toLowerCase() + i18n.loanSelectionLabel.substring(1) + loanData.externalId}
        </b>
      </Typography>
      <Grid container justify="center">
        <Grid item xs={11} md={11}>
          <Box m={3} />
          <img src={imgDownload} className="Descargar" width="100px" alt="" />
          <Box m={3} />
        </Grid>
        <Grid container justify="center">
          <Grid item xs={11} sm={6} md={3}>
            <Button
              onClick={downloadPaymentVoucher}
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              fullWidth
              disableRipple
            >
              {i18n.loanPaymentVouchersCTA}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={11} md={11}>
          {downloadFileSuccess && (
            <Toast
              messageOnSuccess={i18n.loanPaymentVouchersToastSuccessMessage}
              open={openToast}
              onClose={handleCloseToast}
            />
          )}
          {!downloadFileSuccess && (
            <Toast
              messageOnError={i18n.loanPaymentVouchersToastErrorMessage}
              open={openToast}
              onClose={handleCloseToast}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
PaymentVoucher.propTypes = {
  loanData: PropTypes.shape({
    externalId: PropTypes.string.isRequired,
  }).isRequired,
};
export default PaymentVoucher;
