import PropTypes from 'prop-types';
import {
  Grid, Typography, Box, Button,
} from '@material-ui/core';
import i18n from '../common/i18n';

const PrepaidCardPauseInfo = ({
  onClick, onClose,
}) => (
  <Grid container justify="center" style={{ marginTop: '30px' }}>
    <Grid item xs={10} style={{ textAlign: 'center' }}>
      <Typography style={{ fontWeight: '700', marginBottom: '20px' }}>{i18n.PrepaidCardPauseDialogTitle}</Typography>
      <Typography>{i18n.PrepaidCardPauseDialogSubtitle}</Typography>
    </Grid>
    <Grid item xs={8} sm={6} md={5} lg={4}>
      <Box m={3} />
      <Button onClick={onClick} variant="contained" color="primary" fullWidth>
        {i18n.PrepaidCardPauseDialogButton}
      </Button>
      <Box m={1} />
      <Button onClick={onClose} variant="outlined" fullWidth>
        Cancelar
      </Button>
      <Box m={3} />
    </Grid>
  </Grid>
);

PrepaidCardPauseInfo.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PrepaidCardPauseInfo;
