import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Grid, Box, Button,
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@react-hook/media-query';
import i18n from '../common/i18n';
import SelfieImage from '../assets/imgs/validation-selfie.svg';

const useStyles = makeStyles({
  imgCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
    marginBottom: 15,
  },
});

const BiometricValidatorPreview = ({ goBack, goNext, qrValue }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const history = useHistory();

  const defaultGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Grid container justify="center" style={{ textAlign: 'start' }}>
        <Grid item xs={11} md={8}>
          <Box m={3} />
          <Typography variant="h2" style={{ fontSize: '2rem' }}>
            {i18n.loginBiometricValidatorTitleStep2}
          </Typography>
          <Box m={3} />
          <Typography variant="h5">{i18n.loginBiometricValidatorSubTitleStep2}</Typography>
        </Grid>
        {isMobile && (
          <>
            <Grid item xs={11} md={8}>
              <Box m={3} />
              <Typography variant="h5">{i18n.loginBiometricValidatorProcessDescriptionStep2Mobile}</Typography>
              <Box m={6} />
              <img className={classes.imgCenter} src={SelfieImage} alt="Selfie" />
            </Grid>
          </>
        )}
        {!isMobile && (
          <>
            <Grid item xs={11} md={8}>
              <Typography variant="h5">{i18n.loginBiometricValidatorProcessDescriptionStep2Desktop}</Typography>
              <Box m={3} />
              <Typography variant="h5"><b>{i18n.loginBiometricValidatorProcessDescriptionStep3Desktop}</b></Typography>
              <Box m={3} />
            </Grid>
            <Grid item xs={11} md={8} style={{ textAlign: 'center' }}>
              <QRCode
                size={256}
                style={{ height: 'auto' }}
                value={qrValue}
                viewBox="0 0 256 256"
              />
            </Grid>
          </>
        )}
        <Grid item xs={11} md={8}>
          <Box m={6} />
          {isMobile && (
            <>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={goNext}
              >
                {i18n.biometricValidatorContinue}
              </Button>
              <Box m={3} />
            </>
          )}
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={goBack !== undefined ? goBack : defaultGoBack}
          >
            {i18n.biometricValidatorBack}
          </Button>
          <Box m={3} />
        </Grid>
      </Grid>
    </>
  );
};

BiometricValidatorPreview.propTypes = {
  goBack: PropTypes.func,
  goNext: PropTypes.func.isRequired,
  qrValue: PropTypes.string.isRequired,
};
BiometricValidatorPreview.defaultProps = {
  goBack: undefined,
};

export default BiometricValidatorPreview;
