import PropTypes from 'prop-types';
import {
  Button, Typography, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import i18n from '../common/i18n';
import logoBind from '../assets/imgs/payment/logo-bind.png';
import CopyToClipboardButton from './common/CopyToClipboardButton';

const useStyles = makeStyles({
  textCenter: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: '21px',
  },
  centerImage: {
    alignContent: 'center',
    margin: 'auto',
    display: 'block',
    marginBottom: '10px',
    marginTop: '23px',
    height: '35px',
  },
  actionButton: {
    width: '80%',
    marginTop: '31px',
    marginBottom: '22px',
  },
});

const BindBankInfo = ({ onClose }) => {
  const classes = useStyles();
  const gridInfo = useRef();

  const fillGridInfo = () => {
    gridInfo.current = ([
      {
        key: i18n.dialogPayCancellationBankLabel,
        value: i18n.dialogPayCancellationBankValue,
        includeCopyButton: false,
      },
      {
        key: i18n.dialogPayCancellationAccountNumberLabel,
        value: i18n.dialogPayCancellationAccountNumberValue,
        includeCopyButton: false,
      },
      {
        key: i18n.dialogPayCancellationCbuLabel,
        value: i18n.dialogPayCancellationCbuValue,
        includeCopyButton: true,
      },
      {
        key: i18n.dialogPayCancellationAliasLabel,
        value: i18n.dialogPayCancellationAliasValue,
        includeCopyButton: true,
      },
      {
        key: i18n.dialogPayCancellationBusinessNameLabel,
        value: i18n.dialogPayCancellationBusinessNameValue,
        includeCopyButton: false,
      },
      {
        key: i18n.dialogPayCancellationCuitLabel,
        value: i18n.dialogPayCancellationCuitValue,
        includeCopyButton: false,
      },
    ]);
  };
  fillGridInfo();
  return (
    <Grid container justify="center">
      <Grid item>
        <Typography variant="h2" className={classes.title}>
          { i18n.dialogPayCancellationTitle }
        </Typography>
      </Grid>
      <Grid item className={classes.textCenter}>
        <img src={logoBind} className={classes.centerImage} alt="Logo Bind" />
        { (gridInfo.current).map((element) => (
          <Typography key={element.key}>
            <b>
              { `${element.key}: `}
            </b>
            { element.value }
            { element.includeCopyButton && (
              <CopyToClipboardButton value={element.value} />
            )}
          </Typography>
        ))}
      </Grid>
      <Grid item className={classes.actionButton}>
        <Button onClick={onClose} autoFocus variant="contained" color="primary" fullWidth>
          {i18n.dialogPayCancellationCuitCloseButton}
        </Button>
      </Grid>
    </Grid>
  );
};

BindBankInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BindBankInfo;
