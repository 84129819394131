import { PropTypes } from 'prop-types';
import {
  Box, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: '10px',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'left',
    fontWeight: 500,
  },
  button: {
    padding: '0px',
  },
}));

const CustomAccordion = ({ title, children }) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <Box className={classes.container} onClick={handleCollapse}>
        <Typography variant="h5" className={classes.title}>{title}</Typography>
        <IconButton onClick={handleCollapse} className={classes.button}>
          {collapse ? (
            <ExpandLessIcon fontSize="large" />
          ) : (
            <ExpandMoreIcon fontSize="large" />
          )}
        </IconButton>
      </Box>
      {collapse && (
        <>
          {children}
        </>
      )}
    </>
  );
};

CustomAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomAccordion;
