import parse from 'html-react-parser';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import logo from '../../assets/imgs/logo.svg';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import { usePaymentQRVoucher } from '../../services/paymentQRService';
import { moneyFormatterWithDecimals } from '../../utils/formatterUtil';
import { fillMessageWith } from '../../utils/functionsUtil';
import Loading from '../../components/common/Loading';
import PaymentQRMessageResponse from './paymentQRMessageResponse';
import useHomeNavigation from '../../customHook/useHomeNavigation';

const PaymentQRVoucher = () => {
  const classes = usePaymentQRStyles();
  const goHome = useHomeNavigation();
  const {
    loading, responseError, errorMessage, onShare, exportVoucher, voucherData,
  } = usePaymentQRVoucher();

  if (loading) {
    return <Loading />;
  }

  if (responseError) {
    return (
      <Grid container className={classes.body} justifyContent="center">
        <Grid item xs={10} md={6}>
          <PaymentQRMessageResponse
            response={responseError}
            referenceLabels={errorMessage}
          />
        </Grid>
      </Grid>
    );
  }

  if (voucherData) {
    const {
      operationDate, amount, reference, operationId,
    } = voucherData;
    return (
      <>
        <Grid container className={classes.body} ref={exportVoucher}>
          <Grid item xs={12}>
            <Grid container style={{ textAlign: 'start' }} spacing={1}>
              <Grid item xs={10}>
                <img src={logo} alt="Logo" width="160px" style={{ marginBottom: '10px' }} />
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ fontWeight: '600' }}>
                  {i18n.PaymentQR.Voucher.title}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {operationDate && (
                  <Typography style={{ fontSize: '12px' }}>
                    {parse(fillMessageWith(i18n.PaymentQR.Voucher.createdDate,
                      operationDate.dayOfWeek,
                      operationDate.day,
                      operationDate.month,
                      operationDate.year,
                      operationDate.hours,
                      operationDate.minutes))}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={10}>
                <hr className={classes.separator} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container style={{ textAlign: 'start' }} spacing={2}>
              <Grid item xs={10}>
                <Typography style={{ fontSize: '12px' }}>
                  {i18n.PaymentQR.Voucher.amount}
                </Typography>
                <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                  {moneyFormatterWithDecimals(amount)}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ fontSize: '12px' }}>
                  {i18n.PaymentQR.Voucher.commerce}
                </Typography>
                <Typography style={{ fontSize: '14px', fontWeight: '700' }}>
                  {reference}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ fontSize: '12px' }}>
                  {i18n.PaymentQR.Voucher.paymentMethod}
                </Typography>
                <Typography style={{ fontSize: '14px', fontWeight: '700' }}>
                  {i18n.PaymentQR.Voucher.credicuotas}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <hr className={classes.separator} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container style={{ textAlign: 'start' }} spacing={2}>
              <Grid item xs={10}>
                <Typography style={{ fontSize: '12px' }}>
                  {i18n.PaymentQR.Voucher.operationID}
                </Typography>
                <Typography style={{ fontSize: '14px' }}>
                  {operationId}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box m={1} />
        </Grid>

        <Grid container className={classes.body}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <Button variant="contained" color="primary" onClick={onShare} fullWidth disableRipple>
                  {i18n.PaymentQR.Voucher.ctaShare}
                </Button>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Button onClick={goHome} variant="outlined" fullWidth>
                  {i18n.backToTop}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return <></>;
};

export default PaymentQRVoucher;
