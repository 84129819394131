import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Grid, Box, Button,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorVerificationImage from '../assets/imgs/error-verification.svg';
import i18n from '../common/i18n';

const useStyles = makeStyles({
  imgCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
    marginBottom: 15,
  },
  tips: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
});

const BiometricValidatorDefaultError = ({ retry, goBack }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid container justify="center">
        <Grid item xs={11} md={10}>
          <Box m={6} />
          <img className={classes.imgCenter} src={ErrorVerificationImage} alt="Selfie" width="130px" />
        </Grid>
        <Grid item xs={11} md={8}>
          <Box m={1} />
          <Typography variant="h2" style={{ fontSize: '20px', fontWeight: '800' }}>
            {i18n.biometricValidatorDefaultErrorTitle}
          </Typography>
          <Box m={2} />
          <Typography>
            {i18n.biometricValidatorDefaultErrorSubtitle}
          </Typography>
        </Grid>
        <Grid item xs={11} md={5} align="left">
          <Box m={2} />
          <Typography style={{ fontSize: '14px' }}>{i18n.biometricValidatorDefaultErrorProcessDescription1}</Typography>
          <Box m={1} />
          <Typography style={{ fontSize: '14px' }} className={classes.tips}>
            <CheckCircleOutlineIcon color="primary" />
            <span style={{ marginLeft: '5px' }}>{i18n.biometricValidatorDefaultErrorProcessDescription2}</span>
          </Typography>
          <Typography style={{ fontSize: '14px' }} className={classes.tips}>
            <CheckCircleOutlineIcon color="primary" />
            <span style={{ marginLeft: '5px' }}>{i18n.biometricValidatorDefaultErrorProcessDescription3}</span>
          </Typography>
          <Typography style={{ fontSize: '14px' }} className={classes.tips}>
            <CheckCircleOutlineIcon color="primary" />
            <span style={{ marginLeft: '5px' }}>{i18n.biometricValidatorDefaultErrorProcessDescription4}</span>
          </Typography>
        </Grid>
      </Grid>
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={10} md={5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={retry}
          >
            {i18n.biometricValidatorRetry}
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box m={3} />
        </Grid>
        <Grid item xs={10} md={5}>
          <Button
            variant="outlined"
            fullWidth
            onClick={goBack}
          >
            {i18n.backToTop}
          </Button>
          <Box m={3} />
        </Grid>
      </Grid>
    </Grid>
  );
};

BiometricValidatorDefaultError.propTypes = {
  retry: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default BiometricValidatorDefaultError;
