import {
  makeStyles, Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import { useAuth } from '../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

const AvatarUser = ({ data }) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const capitalLetters = currentUser?.customerFirstName.charAt(0) + currentUser?.customerSurname.charAt(0);
  return (
    <>
      <Grid container justify="center">
        <Grid item>
          <Avatar className={classes.large}>
            <Typography style={{ fontSize: '2rem' }}>{capitalLetters}</Typography>
          </Avatar>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '15px' }}>
          <Typography component="h3" variant="h2" style={{ marginBottom: '5px' }}>{data.username}</Typography>
          <Typography component="h4" variant="h6" style={{ fontWeight: 700 }}>
            {i18n.avatarUserLabelDNI}
            {data.dni}
          </Typography>
          <Typography component="h4" variant="h6">
            {i18n.avatarUserLabelCellphone}
            {data.mobile}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

AvatarUser.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
    dni: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
  }).isRequired,
};

export default AvatarUser;
