import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    textTransform: 'none',
    height: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontFamily: 'Open sans',
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '14px',
  },
  alignCenter: {
    margin: 'auto',
  },
}));

function CardVerticalButton({
  title, onClick, logo,
}) {
  const classes = useStyles();
  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent style={{ padding: '8px' }}>
          <Grid item container direction="column">
            <Grid item className={classes.alignCenter}>
              {typeof logo === 'string' && (
                <img src={logo} height="24px" alt="" />
              )}
              {typeof logo !== 'string' && (logo)}
            </Grid>
            <Grid item className={classes.alignCenter}>
              <Typography className={classes.cardTitle}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
CardVerticalButton.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};
CardVerticalButton.defaultProps = {
  title: '',
  logo: '',
};
export default CardVerticalButton;
