import {
  BottomNavigation, BottomNavigationAction, makeStyles, useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useNavigationMenuController from '../customHook/controller/useNavigationMenuController';

const useStyles = makeStyles((theme) => ({
  icon: {
    '& svg': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

const NavigationMenu = ({ onOpenMenu }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    showMenu, options, selectedOption, handleChange,
  } = useNavigationMenuController(onOpenMenu);

  return (
    <>
      {isMobile && showMenu && (
        <BottomNavigation
          showLabels
          value={selectedOption}
          onChange={handleChange}
          style={{
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            borderRadius: '10px 10px 0 0',
            borderTop: '1px solid lightgrey',
            zIndex: 1000,
            backgroundColor: 'white',
            height: 'auto',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          {options.map((option) => (
            <BottomNavigationAction
              key={option.label}
              label={option.label}
              value={option.label}
              className={selectedOption === option.label ? classes.icon : ''}
              icon={<option.img />}
              style={{ paddingTop: '12px', paddingBottom: '12px' }}
            />
          ))}
        </BottomNavigation>
      )}
    </>
  );
};

NavigationMenu.propTypes = {
  onOpenMenu: PropTypes.func.isRequired,
};

export default NavigationMenu;
