const apiErrorKeysDictionary = {
  KEY_UNKNOWN: '',
  INVALID_CUSTOMER_EMAIL_ERROR: 'El email que se intentó modificar es inválido', // validacion de email en consultas
  INVALID_PHONE_NUMBER_ERROR: 'El número de teléfono que se intentó modificar es inválido',
  CUSTOMER_NOT_FOUND: 'No encontramos datos para el dni ingresado',
  ERROR_ASSOCIATE_CARD: 'Error asociando la tarjeta',
  ERROR_DISBURSE_PREPAID_CARD: 'No se pudo desembolsar el préstamo',
  ERROR_GETTING_PREPAID_CARD_LOAN_DATA: 'Error obteniendo los datos del prestamo',
  ERROR_GETTING_PREPAID_CARD_CUSTOMER_DATA: 'Error obteniendo los datos del customer',
  ERROR_GETTING_PREPAID_CARD: 'Error obteniendo los datos de la tarjeta',
};
const getApiErrorMessageDescription = (key) =>
  (apiErrorKeysDictionary[key] ? apiErrorKeysDictionary[key] : apiErrorKeysDictionary.KEY_UNKNOWN);

export default getApiErrorMessageDescription;
