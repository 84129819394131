import parse from 'html-react-parser';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BarCode from '../../assets/imgs/payment/barcode.svg';
import MercadoPago from '../../assets/imgs/payment/mercadopago-app.svg';
import CelularMano from '../../assets/imgs/payment/celular-mano.svg';
import Celular from '../../assets/imgs/payment/celular.svg';
import i18n from '../../common/i18n';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  details: {
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  padding: {
    padding: '25px',
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid #D5D5D5',
  },
});

function PaymentMethodCash() {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: '0.8rem', textAlign: 'center' }}>
          {i18n.paymentMethodCashHow}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            <Typography className={classes.title}>
              {i18n.paymentMethodCashOptions}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <img
              src={BarCode}
              height="50px"
              alt="Código de barras"
              style={{ marginBottom: '5px', marginTop: '25px' }}
            />
            <Typography className={classes.details} style={{ marginBottom: '15px' }}>
              {i18n.paymentMethodCashDescription}
            </Typography>
            <hr />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: '25px' }}>
            <Typography className={classes.title}>
              {i18n.paymentMethodCashStepsTitle}
            </Typography>
          </Grid>
          <Grid xs={12} md={3} style={{ padding: '10px' }}>
            <img
              src={MercadoPago}
              height="50px"
              alt="Código de barras"
              style={{ marginBottom: '5px', marginTop: '25px' }}
            />
            <Typography className={classes.details}>{i18n.paymentMethodCashSteps[0]}</Typography>
          </Grid>
          <Grid xs={12} md={3} style={{ padding: '10px' }}>
            <img
              src={Celular}
              height="40px"
              alt="Código de barras"
              style={{ marginBottom: '5px', marginTop: '25px' }}
            />
            <Typography className={classes.details}>
              {parse(i18n.paymentMethodCashSteps[1])}
            </Typography>
          </Grid>
          <Grid xs={12} md={3} style={{ padding: '10px' }}>
            <img
              src={BarCode}
              height="40px"
              alt="Código de barras"
              style={{ marginBottom: '5px', marginTop: '25px' }}
            />
            <Typography className={classes.details}>
              {i18n.paymentMethodCashSteps[2]}
            </Typography>
          </Grid>
          <Grid xs={12} md={3} style={{ padding: '10px' }}>
            <img
              src={CelularMano}
              height="40px"
              alt="Código de barras"
              style={{ marginBottom: '5px', marginTop: '25px' }}
            />
            <Typography className={classes.details}>{i18n.paymentMethodCashSteps[3]}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PaymentMethodCash;
