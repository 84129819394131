import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import Loading from '../../components/common/Loading';
import HeaderNav from '../../components/header/Header';
import { isMobileOperatingSystem } from '../../utils/functionsUtil';
import i18n from '../../common/i18n';
import { useRefinancingCommonStyles } from './refinancingHome';
import { useRefinancingSelfie } from '../../services/refinancingService';

const RefinancingSelfie = () => {
  const commonClasses = useRefinancingCommonStyles();

  const {
    loading, handleContinue, handleBack, qr,
  } = useRefinancingSelfie();

  return (
    <>
      <HeaderNav nomenu />
      {loading && (
        <Loading />
      )}
      {!loading && (
        <>
          <Grid container className={commonClasses.body}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" align="left">
                {i18n.RefinancingSelfie.title}
              </Typography>
              {isMobileOperatingSystem() ? (
                <>
                  <Typography align="left">
                    {i18n.RefinancingSelfie.description[0]}
                  </Typography>
                  <Box mt={2} />
                  <Typography align="left">
                    {i18n.RefinancingSelfie.description[1]}
                  </Typography>
                  <Box mt={6}>
                    <img src="/images/refinanciacion/selfie.svg" alt="SELFIE" />
                  </Box>
                </>
              ) : (
                <>
                  <Typography align="left">
                    {i18n.RefinancingSelfie.description[2]}
                  </Typography>
                  <Box mt={4} />
                  <QRCode
                    size={256}
                    style={{ height: 'auto' }}
                    value={qr}
                    viewBox="0 0 256 256"
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid
                container
                className={commonClasses.ctaBox}
                spacing={isMobileOperatingSystem() ? 2 : 0}
                direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
              >
                {isMobileOperatingSystem() && (
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleContinue}
                      fullWidth
                    >
                      {i18n.RefinancingSelfie.ctaContinue}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={isMobileOperatingSystem() ? 6 : 12}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleBack}
                    fullWidth
                  >
                    {i18n.RefinancingSelfie.ctaBack}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default RefinancingSelfie;
