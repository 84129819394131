import { Box, Grid, Typography } from '@material-ui/core';
import ActionWarning from '../../../components/common/ActionWarning';

/* eslint arrow-body-style: ["error", "always"] */
const WikiActionWarning = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <ActionWarning />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Funcion/acción botón continuar:
          </Typography>
          <Box m={3} />
          <ActionWarning onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }} />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Cambio label botón continuar:
          </Typography>
          <Box m={3} />
          <ActionWarning
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
            continueMessageBtn="Otro label"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiActionWarning;
