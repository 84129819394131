import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import useRouteValidation from '../customHook/useRouteValidation';

const MenuSectionListItem = ({ menuItems, onHandleItemClick }) => {
  const location = useLocation();
  const { isNotAllowed, isNotAvailable } = useRouteValidation();

  const handleItemClick = () => {
    onHandleItemClick(false);
  };
  return (
    <>
      {menuItems.filter((menuItem) => !isNotAvailable(menuItem.path)).map((menuItem) => {
        const disabled = isNotAllowed(menuItem.path);
        const aux = {
          selected: menuItem.path === location.pathname,
          disabled,
          component: Link,
          button: menuItem.path !== location.pathname,
          to: { pathname: menuItem.path },
          target: menuItem.component === 'externalLink' ? '_blank' : undefined,
        };
        return (
          <ListItem
            key={menuItem.text}
            onClick={handleItemClick}
            {...aux}
          >
            <ListItemText>{menuItem.text}</ListItemText>
          </ListItem>
        );
      })}
    </>
  );
};
MenuSectionListItem.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHandleItemClick: PropTypes.func.isRequired,
};
export default MenuSectionListItem;
