import { Box, Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';

import i18n from '../common/i18n';
import Header from '../components/common/Header';
import {
  getPaymentVoucherTypeList,
  sendPaymentNotification,
  findActiveLoansByCustomerTaxId,
} from '../api/selfieWebService';
import UploadPaymentReceipt from '../components/UploadPaymentReceipt';
import MessageResponse from '../components/common/MessageResponse';
import { PAYMENT_RECEIPT } from '../utils/referenceConstant';
import { useAuth } from '../contexts/AuthContext';
import ActionNotAvailable from '../components/common/ActionNotAvailable';
import { getError } from '../utils/functionsUtil';
import Loading from '../components/common/Loading';
import TakeSurveyModal from '../components/TakeSurveyModal';

const PaymentReceipt = () => {
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [formData] = useState({ typeReceiptSelect: '' });
  const [typeReceiptOptions, setTypeReceiptOptions] = useState([]);
  const { currentUser } = useAuth();
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [noActiveLoans, setNoActiveLoans] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  const getLabelByPaymentVoucherType = (labelKey) => {
    switch (labelKey) {
      case 'TXN_KEY_LABEL': return i18n.paymentReceiptTypeReceiptTransfer;
      case 'TICKET_KEY_LABEL': return i18n.paymentReceiptTypeReceiptTicket;
      default: return '';
    }
  };

  const resolvePaymentVoucherType = (result) => {
    const paymentVoucherTypeList = [];
    result.forEach((pvt) => {
      const paymentVoucherType = {
        value: pvt.id,
        label: getLabelByPaymentVoucherType(pvt.labelKey),
      };
      paymentVoucherTypeList.push(paymentVoucherType);
    });
    return paymentVoucherTypeList;
  };

  const getTypeDocOptions = async () => {
    try {
      const result = await getPaymentVoucherTypeList();
      const paymentVoucherTypeList = resolvePaymentVoucherType(result.data);
      setTypeReceiptOptions(paymentVoucherTypeList);
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };

  const initHasActiveLoans = async () => {
    try {
      setInitLoading(true);
      const res = await findActiveLoansByCustomerTaxId({ customerTaxId: currentUser.customerTaxId });

      if (res.data.length > 0) {
        setNoActiveLoans(false);
        getTypeDocOptions();
      } else {
        setInitLoading(false);
        setNoActiveLoans(true);
      }
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };

  useEffect(() => initHasActiveLoans(), []);

  const onSubmitUploadPaymentReceipt = async (data) => {
    try {
      setLoading(true);
      const postData = new FormData();
      postData.append('file', data.file);
      postData.append('paymentVoucherType', data.typeReceiptSelect);
      const res = await sendPaymentNotification(currentUser.customerTaxId, postData);
      setResponse(res);
      setShowSurvey(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResponse(getError(error));
    }
  };

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initHasActiveLoans();
  };

  return (
    <>
      <Header title={i18n.paymentReceiptHeaderTitle} />
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={10} sm={6}>
          {response === undefined && initLoading === true && (
            <Loading />
          )}
          {response === undefined && !noActiveLoans && initLoading === false && (
            <UploadPaymentReceipt
              data={formData}
              options={typeReceiptOptions}
              itemLabelKey="label"
              itemKey="value"
              onSubmit={onSubmitUploadPaymentReceipt}
              loading={loading}
            />
          )}
          {response === undefined && noActiveLoans && initLoading === false && (
            <ActionNotAvailable
              title={i18n.paymentReceiptActionNotAvailableTitle}
              subTitle={i18n.paymentReceiptActionNotAvailableSubTitle}
            />
          )}
          {response !== undefined && (
            <MessageResponse
              response={response}
              onSuccessPrimary={reload}
              needAddGoToHomeButtonOnSuccess
              onErrorPrimary={reload}
              referenceLabels={PAYMENT_RECEIPT}
              isInitError={initError}
            />
          )}
        </Grid>
      </Grid>
      {showSurvey && (
        <TakeSurveyModal delay="5500" />
      )}
    </>
  );
};

export default PaymentReceipt;
