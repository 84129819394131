import {
  makeStyles,
} from '@material-ui/core';

export const usePaymentQRStyles = makeStyles((theme) => ({
  body: {
    marginTop: '40px',

    paddingLeft: '50px',
    paddingRight: '50px',

    '& h1': {
      fontFamily: 'Open Sans',
      fontSize: '36px',
      fontWeight: '700',
      lineHeight: '42px',
      color: '#212121',
      marginBottom: '16px',
    },

    '& a': {
      color: '#f2025d',
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',

      paddingLeft: '24px',
      paddingRight: '24px',

      '& h1': {
        fontSize: '20px',
        lineHeight: '27px',
        marginBottom: '8px',
      },
    },
  },

  ctaBox: {
    padding: '50px',

    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: '0px',
      borderTop: '1px solid lightgrey',
      padding: '20px',
      width: '-webkit-fill-available',
      backgroundColor: 'white',
      zIndex: 1000,
    },
  },

  noBorder: {
    border: 'none',
  },

  table: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tr: {
    textAlign: 'center',
  },
  td: {
    textAlign: 'center',
    verticalAlign: 'top',
  },

  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  input: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    padding: '0px',
  },
  ghost: {
    fontSize: '38px',
    fontWeight: '700',
    padding: '0px',
  },
  ghostSmall: {
    fontSize: '28px',
    fontWeight: '700',
    padding: '0px',
  },
  amountInput: {
    color: 'transparent',
    fontSize: '38px',
    fontWeight: '700',
    textAlign: 'center',
    verticalAlign: 'top',
    caretColor: 'transparent',
    padding: '0px',
  },
  amountInputSmall: {
    color: 'transparent',
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'center',
    verticalAlign: 'top',
    caretColor: 'transparent',
    padding: '0px',
  },
  decimalInput: {
    fontSize: '20px',
    fontWeight: '700',
    textAlign: 'center',
    padding: '0px',
    caretColor: 'transparent',
  },
  decimalInputSmall: {
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
    padding: '0px',
    caretColor: 'transparent',
  },

  amountInputFinal: {
    fontSize: '32px',
    fontWeight: '700',
    textAlign: 'left',
  },

  selectedCard: {
    border: '2px solid #F2025D',
    backgroundColor: '#F2025D0D',
  },

  qrReader: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'absolute',
  },
  qrReaderVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  qrCancelBtn: {
    position: 'absolute',
    fill: 'none',
    left: '90%',
    top: '5%',
    cursor: 'pointer',
  },
  qrReaderBox: {
    left: '10% !important',
    top: '10% !important',
    width: '80% !important',
    height: '80% !important',
  },
  qrReaderFrame: {
    position: 'absolute',
    fill: 'none',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  qrReaderMessage: {
    position: 'absolute',
    fill: 'none',
    width: '100%',
    height: '100%',
    top: '85%',
    fontSize: '16px',
    color: 'white',
  },

  buttonContainer: {
    position: 'relative',
    height: '40px',
    borderRadius: '30px',
    backgroundColor: '#c37892',
  },
  buttonText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Open sans',
    fontSize: '0.875rem',
    fontWeight: '700',
    color: 'white',
  },
  buttonProgressBar: {
    height: '100%',
    backgroundColor: '#e72064',
  },

  separator: {
    border: '1px ridge',
    width: '100%',
  },

}));

export default usePaymentQRStyles;
