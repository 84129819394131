import { Box, Grid, Typography } from '@material-ui/core';
import CheckBox from '../../../components/common/CheckBox';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCheckBox = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <CheckBox />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo:
          </Typography>
          <Box m={3} />
          <CheckBox title="Titulo" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo:
          </Typography>
          <Box m={3} />
          <CheckBox title="Titulo" subtitle="Subtitulo" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo + onClick:
          </Typography>
          <Box m={3} />
          <CheckBox
            title="Titulo"
            subtitle="Subtitulo"
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo + onClick + checked:
          </Typography>
          <Box m={3} />
          <CheckBox
            title="Titulo"
            subtitle="Subtitulo"
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
            checked="true"
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo + onClick + Disabled:
          </Typography>
          <Box m={3} />
          <CheckBox
            title="Titulo"
            subtitle="Subtitulo"
            onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCheckBox;
