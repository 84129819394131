import { Grid } from '@material-ui/core';
import CardButton from '../../../components/CardUserConfirmation';

const WikiCardUserConfirmation = () => (
  <>
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        <CardButton
          name="Pepito"
          cuit="2030100200"
          onClick={() => { alert('hola'); /* eslint-disable-line no-alert */ }}
        />
      </Grid>
    </Grid>
  </>
);

export default WikiCardUserConfirmation;
