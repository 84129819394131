import PropTypes from 'prop-types';
import {
  Box, Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import i18n from '../common/i18n';
import PrePaidLoanInfoBox from './PrePaidLoanInfoBox';
import { fillMessageWith } from '../utils/functionsUtil';
import CardButton from './CardButton';
import imgQR from '../assets/imgs/pago-qr.svg';
import imgBankTrx from '../assets/imgs/pago-transferencia.svg';

const useStyles = makeStyles(() => ({
  description: {
    textAlign: 'center',
    '@media (max-width:600px)': {
      textAlign: 'left',
    },
  },
}));

const PrePaidLoanPaymentMethodSelection = ({
  loan, cancelationData, onGoBack, onSelect,
}) => {
  const classes = useStyles();

  const handleSelection = (method) => {
    onSelect(method);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11} md={6}>
          <Box m={3} />
          <Typography variant="h2">{i18n.prePaidLoanPaymentMethodSelectionTitle}</Typography>
          <Box m={5} />
          <Typography variant="h5" className={classes.description}>
            {fillMessageWith(
              i18n.prePaidLoanPaymentMethodSelectionDescription,
              cancelationData.dateFrom,
              cancelationData.dateTo,
            )}
          </Typography>
          <Box m={5} />
          <PrePaidLoanInfoBox loan={loan} cancelationData={cancelationData} />
          <Box m={5} />
          <Grid container justify="center">
            <Grid item xs={12}>
              <CardButton
                title={i18n.prePaidLoanPaymentMethodSelectionQR[0]}
                subtitle={i18n.prePaidLoanPaymentMethodSelectionQR[1]}
                urlImage={imgQR}
                onClick={() => handleSelection(i18n.payInstallmentQRKey)}
              />
              <Box m={3} />
              <CardButton
                title={i18n.prePaidLoanPaymentMethodSelectionBankTransfer}
                urlImage={imgBankTrx}
                onClick={() => handleSelection(i18n.payInstallmentBankTrxKey)}
              />
              <Box m={3} />
              <Button
                onClick={onGoBack}
                color="primary"
                startIcon={<ArrowBack />}
              >
                {i18n.prePaidLoanBackCTA}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PrePaidLoanPaymentMethodSelection.propTypes = {
  loan: PropTypes.shape().isRequired,
  cancelationData: PropTypes.shape().isRequired,
  onGoBack: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PrePaidLoanPaymentMethodSelection;
