import {
  Checkbox, Grid, Typography,
} from '@material-ui/core';
import useWebAuthnRegisterController from '../customHook/controller/useWebAuthnRegisterController';
import i18n from '../common/i18n';
import LoadingButton from '../components/common/LoadingButton';
import MessageResponse from '../components/common/MessageResponse';
import { WEB_AUTHN_REGISTER_SUCCESS } from '../utils/referenceConstant';

const WebAuthnRegister = () => {
  const {
    loading, omitForever, handleChange, omit, register, responseSuccess, onContinue,
  } = useWebAuthnRegisterController();

  if (responseSuccess) {
    return (
      <MessageResponse
        response={responseSuccess}
        referenceLabels={WEB_AUTHN_REGISTER_SUCCESS}
        onSuccessPrimary={onContinue}
        successGreen
      />
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10} md={8}>
        <img src="/images/metodo_desbloqueo.svg" alt="Modo desbloqueo" style={{ marginTop: '50px' }} />
        <Typography variant="h2" component="h1" style={{ marginTop: '20px' }}>
          {i18n.WebAuthnRegister.title}
        </Typography>
        <Typography style={{ marginTop: '12px' }}>
          {i18n.WebAuthnRegister.description}
        </Typography>
        <Typography style={{ marginTop: '32px' }}>
          <Checkbox color="primary" onChange={handleChange} />
          {i18n.WebAuthnRegister.dontShowAgain}
        </Typography>
        <LoadingButton
          variant="contained"
          onPress={register}
          style={{ width: '100%' }}
          loading={loading}
          disabled={omitForever}
        >
          {i18n.WebAuthnRegister.cta}
        </LoadingButton>
        <LoadingButton
          secondary
          variant="outlined"
          onPress={omit}
          style={{ width: '100%', marginTop: '6px' }}
          loading={loading}
        >
          {i18n.WebAuthnRegister.omit}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default WebAuthnRegister;
