import {
  Box, Container,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useQueryParam from '../customHook/useQueryParam';
import { findActiveLoansByCustomerTaxId } from '../api/selfieWebService';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import Loading from '../components/common/Loading';
import PrePaidLoanNotActive from '../components/PrePaidLoanNotActive';
import PrePaidLoanSelection from '../components/PrePaidLoanSelection';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import { PRE_PAID_LOAN } from '../utils/referenceConstant';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

const PrePaidLoan = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  const queryParam = useQueryParam();

  const [loans, setLoans] = useState(undefined);
  const [idLoanSelected, setIdLoanSelected] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(true);

  const raiseResponse = (res, isInitError) => {
    setResponse(res);
    setInitError(isInitError);
  };

  const initPrePaidLoan = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await findActiveLoansByCustomerTaxId({ customerTaxId: currentUser.customerTaxId });
        if (res.data.length > 0) {
          setLoans(res.data);
          const idLoan = queryParam.get('idLoan');
          if (idLoan !== null) {
            setIdLoanSelected(idLoan);
            queryParam.delete('idLoan');
          }
        }
      }
      setInitLoading(false);
    } catch (error) {
      raiseResponse(getError(error), true);
    }
  };

  const reload = () => {
    setLoans(undefined);
    raiseResponse(undefined, false);
    initPrePaidLoan();
  };

  const onlinePayment = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  useEffect(() => initPrePaidLoan(), []);

  return (
    <>
      <Header
        title={i18n.prePaidLoanHeaderTitle}
        subtitle={showSubtitle ? i18n.prePaidLoanHeaderSubtitle : undefined}
      />
      <Box m={3} />
      <Container>

        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={PRE_PAID_LOAN}
            onSuccessPrimary={onlinePayment}
            onErrorPrimary={reload}
            isInitError={initError}
            needAddGoToHomeButtonOnSuccess
            canGoHome
          />
        )}

        {response === undefined && initLoading === true && (
          <Loading />
        )}

        {response === undefined && initLoading === false && loans === undefined && (
          <PrePaidLoanNotActive />
        )}

        {response === undefined && initLoading === false && loans !== undefined && (
          <PrePaidLoanSelection
            loans={loans}
            idLoanSelected={idLoanSelected}
            raiseResponse={raiseResponse}
            onLoanSelected={() => setShowSubtitle(false)}
            onInit={() => setShowSubtitle(true)}
          />
        )}

      </Container>
    </>
  );
};

export default PrePaidLoan;
