import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import '../index.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import i18n from '../common/i18n';

const useStyles = makeStyles(() => ({
  gap: {
    gap: '15px',
  },
  chip: {
    margin: '5px',
  },
}));
const LoanSelection = ({ loans, onClickLoan, useDefaultLoanSelected }) => {
  const classes = useStyles();
  const [loanSelected, setLoanSelected] = useState();

  const initSelector = () => {
    // inicializa la seleccion en el primer prestamo de la lista
    if (loanSelected === undefined && useDefaultLoanSelected) {
      onClickLoan(loans[0].externalId);
      setLoanSelected(loans[0].externalId);
    }
  };
  useEffect(() => initSelector(), []);

  const handleOnClickLoan = (idLoan) => () => {
    onClickLoan(idLoan);
    setLoanSelected(idLoan);
  };

  const resolveLoanDescription = (loan) =>
    (loan.productId === 46 ? i18n.resolvingLineOfferMenuLabel : i18n.loanSelectionLabel + loan.externalId);

  return (
    <div className="scrollmenu">
      {loans.map((loan) => {
        const selected = loanSelected === loan.externalId ? {} : { variant: 'outlined' };
        return (
          <Chip
            key={loan.externalId}
            label={resolveLoanDescription(loan)}
            onClick={handleOnClickLoan(loan.externalId)}
            clickable
            color="primary"
            className={classes.chip}
            {...selected}
          />
        );
      })}
    </div>

  );
};

LoanSelection.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape({ externalId: PropTypes.string.isRequired })).isRequired,
  onClickLoan: PropTypes.func.isRequired,
  useDefaultLoanSelected: PropTypes.bool,
};

LoanSelection.defaultProps = {
  useDefaultLoanSelected: true,
};

export default LoanSelection;
