import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { capitalizeSentence, fillMessageWith, mapCodeToDescription } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #e0e0e0',
  },
  headerBox: {
    padding: '20px 20px',
    backgroundColor: '#f5f5f5',
  },
  headerText: {
    '@media (max-width:600px)': {
      textAlign: 'left',
    },
  },
  descriptionBox: {
    padding: '20px 20px',
    '@media (max-width:600px)': {
      textAlign: 'left',
    },
  },
  amount: {
    fontWeight: 700,
    fontSize: '20px',
    '@media (max-width:600px)': {
      textAlign: 'left',
    },
  },
}));

const PrePaidLoanInfoBox = ({ loan, cancelationData }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Box className={classes.container}>
            <Box className={classes.headerBox}>
              <Typography variant="h5" component="h2" className={classes.headerText}>
                <strong>
                  {fillMessageWith(
                    mapCodeToDescription(loan.subproductId, i18n.subproductsGroup),
                    capitalizeSentence(loan.commerceName),
                  )}
                </strong>
              </Typography>
              <Typography className={classes.headerText}>
                {fillMessageWith(
                  loan.installmentCount > 1 ? i18n.prePaidLoanSelectionTitle[2] : i18n.prePaidLoanSelectionTitle[1],
                  loan.installmentCount,
                  moneyFormatterWithoutDecimals(loan.currentInstallment.totalAmount),
                )}
              </Typography>
            </Box>
            <Box className={classes.descriptionBox}>
              <Typography className={classes.descriptionText}>{i18n.prePaidLoanGeneratePrePaidAmount}</Typography>
              <Typography className={classes.amount}>
                {moneyFormatterWithoutDecimals(cancelationData.minAmount)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

PrePaidLoanInfoBox.propTypes = {
  loan: PropTypes.shape().isRequired,
  cancelationData: PropTypes.shape().isRequired,
};

export default PrePaidLoanInfoBox;
