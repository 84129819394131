import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Grid, RadioGroup,
} from '@material-ui/core';
import PrePaidLoanSelectionRadioOption from './PrePaidLoanSelectionRadioOption';
import i18n from '../common/i18n';

const PrePaidLoanSelectionRadio = ({ loans, onContinue }) => {
  const [selectedLoanId, setSelectedLoanId] = useState();

  const handleChange = (event, loanId) => {
    setSelectedLoanId(loanId);
  };

  const handleContinue = () => {
    onContinue(selectedLoanId);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <RadioGroup
            name="loan"
            onChange={handleChange}
            value={selectedLoanId}
          >
            {loans.map((loan) => (
              <PrePaidLoanSelectionRadioOption
                key={loan.externalId}
                loan={loan}
                checked={selectedLoanId === loan.externalId}
                selected={selectedLoanId}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box mt={3} />
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleContinue}
            disabled={!selectedLoanId}
          >
            {i18n.prePaidLoanSelectionCTA}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PrePaidLoanSelectionRadio.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default PrePaidLoanSelectionRadio;
