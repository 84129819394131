import {
  Box, Button, Typography, Card, CardActionArea, CardContent, Radio, Grid,
} from '@material-ui/core';
import CustomArrowBack from '../../components/common/CustomArrowBack';
import Loading from '../../components/common/Loading';
import { fillMessageWith, isMobileOperatingSystem } from '../../utils/functionsUtil';
import i18n from '../../common/i18n';
import {
  moneyFormatter, moneyFormatterSplitDecimals, numberFormatterWithDecimals, moneyFormatterWithoutDecimals,
} from '../../utils/formatterUtil';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import { usePaymentQRContext } from '../../contexts/PaymentQRContext';
import PaymentQRMessageResponse from './paymentQRMessageResponse';
import { usePaymentQRInstallmentPlan } from '../../services/paymentQRService';
import {
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS,
} from '../../utils/referenceConstant';

const PaymentQR = () => {
  const classes = usePaymentQRStyles();
  const { paymentQRContext } = usePaymentQRContext();
  const {
    loading, responseError, errorMessage, handleContinue, handleBack, handleRetry, showPaymentQRAgreement,
    installmentPlan, selectedInstallment, selectInstallment, isSelected, isProcessingPayment, processingProgress,
  } = usePaymentQRInstallmentPlan();

  const clearValueOnlyNumbers = (value) => Number(value.replace(/\D+/g, ''));

  return (
    <>
      {loading && (
        <Loading />
      )}

      {!loading && !responseError && (
        <>
          <Grid container className={classes.body}>

            <Grid item xs={12}>
              <CustomArrowBack ctaLabel={i18n.PaymentQR.ctaBack} handleBack={handleBack} />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <Typography style={{ fontSize: '20px', textAlign: 'left' }}>
                {i18n.PaymentQR.InstallmentPlan.titleHowToPay}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <div style={{ backgroundColor: '#F5F5F5', padding: '16px', borderRadius: '8px' }}>
                <Typography style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}>
                  {paymentQRContext?.sellerName}
                </Typography>
                <table>
                  <tr>
                    <td>
                      <Typography className={classes.amountInputFinal}>
                        {moneyFormatterWithoutDecimals(String(clearValueOnlyNumbers(`${paymentQRContext.selectedAmount}`)))}
                      </Typography>
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                      <Typography className={classes.decimalInputSmall}>
                        {paymentQRContext?.selectedAmountDecimals}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </div>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <Typography align="left">
                {i18n.PaymentQR.InstallmentPlan.chooseInstallment}
              </Typography>
              {installmentPlan.map((installment) => (
                <Box mt={2} key={installment.installment} mb={2}>
                  <Card
                    className={isSelected(installment) ? classes.selectedCard : ''}
                    variant="outlined"
                  >
                    <CardActionArea onClick={() => selectInstallment(installment)}>
                      <CardContent style={{ padding: '0px' }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Radio
                            color="primary"
                            checked={isSelected(installment)}
                            disabled={isProcessingPayment}
                          />
                          <table style={{ marginLeft: '0', marginRight: 'auto' }}>
                            <tr className={classes.tr}>
                              <td className={classes.td}>
                                <Typography style={{ fontSize: '14px', fontWeight: '700' }}>
                                  {`${installment.installment}x ${moneyFormatterSplitDecimals(installment.amount)[0]}`}
                                </Typography>
                              </td>
                              <td width="12px" className={classes.td}>
                                <Typography style={{ fontSize: '10px', fontWeight: '700' }}>
                                  {`${moneyFormatterSplitDecimals(installment.amount)[1]}`}
                                </Typography>
                              </td>
                            </tr>
                          </table>
                          {installment.cft && installment.cft > 0 ? (
                            <Typography style={{ padding: '9px', fontSize: '12px' }}>
                              {fillMessageWith(i18n.PaymentQR.InstallmentPlan.cft, numberFormatterWithDecimals(installment.cft))}
                            </Typography>
                          ) : (
                            <Typography style={{ padding: '9px', fontSize: '12px', color: '#DA0067' }}>
                              {i18n.PaymentQR.InstallmentPlan.noInterests}
                            </Typography>
                          )}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.ctaBox}
            style={{ gap: '16px' }}
            justifyContent="center"
            direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
          >
            {selectedInstallment && (
              <Grid item xs={12}>
                <Typography style={{ fontSize: '12px' }}>
                  {i18n.PaymentQR.InstallmentPlan.ctaAgreements[0]}
                  <Button
                    style={{ padding: '0px', marginBottom: '2px', fontSize: '12px' }}
                    onClick={showPaymentQRAgreement}
                  >
                    {i18n.PaymentQR.InstallmentPlan.ctaAgreements[1]}
                  </Button>
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Box
                className={classes.buttonContainer}
                onClick={handleContinue}
                style={{
                  backgroundColor: !selectedInstallment || isProcessingPayment ? '#c37892' : '#e72064',
                  cursor: !selectedInstallment || isProcessingPayment ? 'none' : 'pointer',
                  pointerEvents: !selectedInstallment || isProcessingPayment ? 'none' : 'auto',
                }}
              >
                <Box className={classes.buttonText}>
                  {i18n.PaymentQR.InstallmentPlan.ctaPay}
                </Box>
                {isProcessingPayment && (
                  <div
                    className={classes.buttonProgressBar}
                    style={{
                      width: `${processingProgress}%`,
                      borderRadius: processingProgress > 95 ? '30px' : '30px 0px 0px 30px',
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (
        <Grid container className={classes.body} justifyContent="center">
          <Grid item xs={10} md={6}>
            <PaymentQRMessageResponse
              response={responseError}
              referenceLabels={errorMessage}
              handleRetry={handleRetry}
              subtitleMessageResponse={
                errorMessage === PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS
                  ? () => (
                    <>
                      <Typography>
                        <strong>
                          {fillMessageWith(i18n.PaymentQR.Final.subtitle,
                            selectedInstallment.installment,
                            moneyFormatter(selectedInstallment.amount))}
                        </strong>
                      </Typography>
                      <Box m={1} />
                      <Typography>
                        {fillMessageWith(i18n.PaymentQR.Final.description, paymentQRContext.operationId)}
                      </Typography>
                    </>
                  )
                  : undefined
              }
            />
          </Grid>
        </Grid>
      )}

    </>
  );
};

export default PaymentQR;
