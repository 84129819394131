import { Box, Grid, Typography } from '@material-ui/core';
import MessageResponse from '../../../components/common/MessageResponse';
import { PAYMENT_RECEIPT } from '../../../utils/referenceConstant';
import imgHappyFace from '../../../assets/imgs/face-happy.svg';
import imgSadFace from '../../../assets/imgs/face-sad.svg';

/* eslint arrow-body-style: ["error", "always"] */
const WikiMessageResponse = () => {
  const responseSuccess = {
    status: 200,
  };

  const responseError = {
    data: {
      status: 500,
    },
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Is init error:
          </Typography>
          <Box m={3} />
          <MessageResponse isInitError />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response OK:
          </Typography>
          <Box m={3} />
          <MessageResponse response={responseSuccess} referenceLabels={PAYMENT_RECEIPT} />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response OK + OnSuccess Action:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseSuccess}
            referenceLabels={PAYMENT_RECEIPT}
            onSuccess={() => { alert('hola'); }} // eslint-disable-line no-alert
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response OK + OnSuccess Action + Home button:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseSuccess}
            referenceLabels={PAYMENT_RECEIPT}
            onSuccess={() => { alert('hola'); }} // eslint-disable-line no-alert
            needAddGoToHomeButtonOnSuccess
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response OK + OnSuccess Action + Home button + Success green:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseSuccess}
            referenceLabels={PAYMENT_RECEIPT}
            onSuccess={() => { alert('hola'); }} // eslint-disable-line no-alert
            needAddGoToHomeButtonOnSuccess
            successGreen
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response OK + OnSuccess Action + Home button + customImgSuccess:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseSuccess}
            referenceLabels={PAYMENT_RECEIPT}
            onSuccess={() => { alert('hola'); }} // eslint-disable-line no-alert
            needAddGoToHomeButtonOnSuccess
            customImgSuccess={imgHappyFace}
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response Error:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseError}
            referenceLabels={PAYMENT_RECEIPT}
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response Error + OnError:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseError}
            referenceLabels={PAYMENT_RECEIPT}
            onError={() => { alert('hola'); }} // eslint-disable-line no-alert
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Response Error + OnError + customImgError:
          </Typography>
          <Box m={3} />
          <MessageResponse
            response={responseError}
            referenceLabels={PAYMENT_RECEIPT}
            onError={() => { alert('hola'); }} // eslint-disable-line no-alert
            customImgError={imgSadFace}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiMessageResponse;
