import { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import Header from './common/Header';
import i18n from '../common/i18n';
import { dateFormatter, moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith, generateLinkWithFileInResponse, getCardType } from '../utils/functionsUtil';
import ActionFooterFixed from './common/ActionFooterFixed';
import LegalFooter from './LegalFooter';
import { getAgreementDocument } from '../api/selfieWebService';

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    marginTop: '30px',
    backgroundColor: '#f5f5f5',
    padding: '20px',
  },
  detailRow: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const RevolvingLineSummaryStep = ({
  onContinue, amount, installment, loanDetailCost, prepaidCard, loanId, paymentMethod,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const showAgreement = async () => {
    try {
      setLoading(true);
      const res = await getAgreementDocument(loanId);
      generateLinkWithFileInResponse(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLastFourDigits = (debitCardNumber) => debitCardNumber.slice(-4);

  return (
    <>
      <Header title={i18n.RevolvingLine.Header.title} />
      <Box mt={5} mb={10}>
        <Grid container justify="center">
          <Grid item xs={10}>
            <Typography variant="h2">{i18n.RevolvingLine.SummaryStep.title}</Typography>
            <Box className={classes.detailContainer}>
              <Box className={classes.detailRow}>
                <Typography variant="h4">{i18n.RevolvingLine.SummaryStep.amount}</Typography>
                <Typography variant="h4">{moneyFormatter(amount)}</Typography>
              </Box>
              <Box className={classes.detailRow}>
                <Typography>{i18n.RevolvingLine.SummaryStep.term[0]}</Typography>
                <Typography>
                  {fillMessageWith(
                    installment.installment > 1
                      ? i18n.RevolvingLine.SummaryStep.term[2]
                      : i18n.RevolvingLine.SummaryStep.term[1],
                    installment.installment,
                  )}
                </Typography>
              </Box>
              <Box className={classes.detailRow}>
                <Typography>{i18n.RevolvingLine.SummaryStep.installmentAmount}</Typography>
                <Typography>{moneyFormatter(installment.amount)}</Typography>
              </Box>
              <Box className={classes.detailRow}>
                <Typography>{i18n.RevolvingLine.SummaryStep.expirationDate}</Typography>
                <Typography>{dateFormatter(installment.expirationDate)}</Typography>
              </Box>
              <Box mt={2} mb={2}>
                <hr />
              </Box>
              <Typography>
                {parse(fillMessageWith(i18n.RevolvingLine.SummaryStep.disbursement, prepaidCard))}
              </Typography>
            </Box>
            <Box mt={3} mb={3}>
              <Typography>
                {parse(fillMessageWith(
                  i18n.RevolvingLine.SummaryStep.payment,
                  getCardType(paymentMethod.debitCardNumber).toUpperCase(),
                  getLastFourDigits(paymentMethod.debitCardNumber),
                ))}
              </Typography>
            </Box>
            <hr />
            <Box mt={3} mb={3}>
              <Typography>
                {parse(fillMessageWith(
                  i18n.RevolvingLine.SummaryStep.taxes,
                  moneyFormatter(loanDetailCost.interests),
                  moneyFormatter(loanDetailCost.outgoings),
                  moneyFormatter(loanDetailCost.taxes),
                  moneyFormatter(loanDetailCost.amountToReturn),
                ))}
              </Typography>
            </Box>
            <LegalFooter loanDetailCost={loanDetailCost} />
          </Grid>
        </Grid>
      </Box>
      <ActionFooterFixed
        ctaLabel={i18n.RevolvingLine.SummaryStep.ctaConfirm}
        onCTA={onContinue}
      >
        <Box mb={2}>
          <Typography>
            {i18n.RevolvingLine.SummaryStep.termsAndConditions[0]}
            <Button
              style={{ padding: '0px', marginBottom: '2px' }}
              onClick={showAgreement}
              disabled={loading}
            >
              {i18n.RevolvingLine.SummaryStep.termsAndConditions[1]}
            </Button>
          </Typography>
        </Box>
      </ActionFooterFixed>
    </>
  );
};

RevolvingLineSummaryStep.propTypes = {
  loanId: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  installment: PropTypes.shape().isRequired,
  loanDetailCost: PropTypes.shape().isRequired,
  prepaidCard: PropTypes.string.isRequired,
  paymentMethod: PropTypes.shape().isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default RevolvingLineSummaryStep;
