import {
  Box, Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useEffect, useState, useRef } from 'react';
import imgSuccess from '../assets/imgs/success_green.svg';
import imgError from '../assets/imgs/error.svg';
import useQueryParam from '../customHook/useQueryParam';
import i18n from '../common/i18n';
import { PAYMENT_STATUS_SUCCESS } from '../utils/PaymentConstant';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { moneyFormatter, dateTimeFormatter, amountFormatter } from '../utils/formatterUtil';
import { printDocument, fillMessageWith } from '../utils/functionsUtil';
import { useAuth } from '../contexts/AuthContext';
import TakeSurveyModal from './TakeSurveyModal';

const useStyles = makeStyles(() => ({
  receiptBoxInputName: {
    textAlign: 'left',
    margin: 'auto',
  },
  receiptBoxInputValue: {
    textAlign: 'right',
    margin: 'auto',
  },
  receiptInputValue: {
    fontSize: '1em',
    fontWeight: 'bold',
  },
  receiptSeparator: {
    border: '1px ridge',
    width: '95%',
  },
}));

const PaymentStatus = () => {
  const queryParam = useQueryParam();
  const [isSuccess, setIsSuccess] = useState();
  const [idCredit, setIdCredit] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [showSurvey, setShowSurvey] = useState(false);
  const [showPaymentReceipt, setShowPaymentReceipt] = useState(false);

  const { currentUser: { customerFirstName = '' } = {} } = useAuth() || {};
  const [orderId, setOrderId] = useState();
  const [amount, setAmount] = useState();
  const printRef = useRef();
  const history = useHistory();
  const classes = useStyles();
  const goToPaymentHome = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const initMessageResponse = async () => {
    const status = queryParam.get('status');
    if (status === PAYMENT_STATUS_SUCCESS) {
      let loanId = queryParam.get('id');
      if (loanId === undefined || loanId === null) {
        loanId = JSON.parse(queryParam.get('external_reference')).idcredito;
      } else {
        setOrderId(queryParam.get('transactionId'));
        setAmount(amountFormatter(queryParam.get('amount')));
        setCurrentDate(new Date());
      }
      setIsSuccess(true);
      setIdCredit(loanId);
      setShowSurvey(true);
    } else if (status === undefined || status === null || status === 'null') {
      goToPaymentHome();
    } else {
      setIsSuccess(false);
    }
  };

  const downloadPaymentVoucher = async () => {
    await setShowPaymentReceipt(true);
    printDocument(printRef, `comprobante_${idCredit}`);
    setShowPaymentReceipt(false);
  };

  const goHome = useHomeNavigation();
  const goToHome = () => {
    goHome();
  };
  useEffect(() => initMessageResponse(), []);

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={10} md={10}>
          <Box m={3} />
          {!isSuccess && (
            <>
              <img src={imgError} className="error" width="130px" alt="Error" />
              <Typography variant="h2">
                {i18n.PaymentStatusErrorTitle}
              </Typography>
              <Typography variant="h5">
                {i18n.PaymentStatusErrorMessage}
              </Typography>
            </>
          )}
          {isSuccess && (
            <>
              <img src={imgSuccess} className="success" width="126px" alt="Importante" />
              <Box m={3} />
              <Typography variant="h2">
                {fillMessageWith(i18n.PaymentStatusSuccessTitle, moneyFormatter(amount))}
              </Typography>
              <Box m={3} />
              <Typography variant="h5">
                {i18n.PaymentStatusSuccessMessage}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Box m={6} />

      {customerFirstName && orderId && (
        <>
          <div hidden={!showPaymentReceipt}>
            <Grid ref={printRef} container justify="center">
              <Grid style={{ border: '1px solid', margin: '10px' }} item xs={12} sm={11} md={9} lg={6}>
                <Grid container style={{ margin: '6px' }}>
                  <Grid item xs={8} sm={10} md={10} style={{ textAlign: 'left', margin: 'auto' }}>
                    <Typography variant="h4">
                      {i18n.PaymentReceiptTitle}
                    </Typography>
                    <Typography variant="subtitle2">
                      {i18n.PaymentReceiptSubtitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2}>
                    <img src="logo192.png" alt="Importante" width="40rem" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className={classes.receiptBoxInputName} item xs={5} sm={5} md={5}>
                    <Typography>
                      {i18n.PaymentReceiptInputName}
                    </Typography>
                    <Typography>
                      {i18n.PaymentReceiptInputCreditId}
                    </Typography>
                    <Typography>
                      {i18n.PaymentReceiptInputTransactionId}
                    </Typography>
                  </Grid>
                  <Grid className={classes.receiptBoxInputValue} item xs={6} sm={6} md={6}>
                    <Typography className={classes.receiptInputValue}>
                      {customerFirstName}
                    </Typography>
                    <Typography className={classes.receiptInputValue}>
                      {idCredit}
                    </Typography>
                    <Typography className={classes.receiptInputValue}>
                      {orderId}
                    </Typography>
                  </Grid>
                </Grid>
                <hr className={classes.receiptSeparator} />
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body2">
                    {i18n.PaymentReceiptInputDate}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {currentDate ? dateTimeFormatter(currentDate) : '-' }
                  </Typography>
                </Grid>
                <hr className={classes.receiptSeparator} />
                <Grid container>
                  <Grid className={classes.receiptBoxInputName} item xs={5} sm={5} md={5} style={{ margin: '6px' }}>
                    <Typography>
                      {i18n.PaymentReceiptAmount}
                    </Typography>
                  </Grid>
                  <Grid className={classes.receiptBoxInputValue} item xs={6} sm={6} md={6} style={{ margin: '6px' }}>
                    <Typography className={classes.receiptInputValue}>
                      {moneyFormatter(amount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ margin: '6px' }}>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {i18n.PaymentReceiptReminder}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box m={1} />
          </div>
          <Grid container justify="center">
            <Grid item xs={5} sm={5} md={4} lg={3}>
              <Button variant="contained" color="primary" disableRipple onClick={downloadPaymentVoucher}>
                {i18n.PaymentReceiptDownload}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Box m={4} />

      <Grid container justify="center">
        <Grid item xs={5} sm={5} md={3} lg={2}>
          <Button variant="outlined" color="primary" disableRipple onClick={goToHome}>
            {i18n.backToTop}
          </Button>
        </Grid>
      </Grid>

      <Box m={3} />

      {showSurvey && (
        <TakeSurveyModal delay="5500" />
      )}
    </>
  );
};

export default PaymentStatus;
