import * as yup from 'yup';
import i18n from '../../common/i18n';

const sequenceValidator = (value, maxLength, isAscending) => {
  if (value.length === maxLength) {
    for (let index = 1; index < maxLength; index += 1) { // skip 0
      const valueCode = value.charCodeAt(index);
      const lastValueCode = value.charCodeAt(index - 1);
      if (
        (isAscending && (valueCode - 1) !== lastValueCode)
        || (isAscending === false && (valueCode + 1) !== lastValueCode)
      ) {
        break;
      }
      if ((index + 1) === maxLength) {
        return false;
      }
    }
  }
  return true;
};

export const loginSchema = yup.object().shape({
  user: yup.string().required(i18n.loginInputUserRequired),
  password: yup.string().required(i18n.loginInputPassRequired),
});

export const userLocationDataSchema = yup.object().shape({
  state: yup.string().required().typeError(i18n.userLocationDataInputProvinceRequired),
  district: yup.number().required().typeError(i18n.userLocationDataInputDistrictRequired),
  city: yup.number().required().typeError(i18n.userLocationDataInputLocationRequired),
  street: yup.string().required(i18n.userLocationDataInputStreetRequired),
  number: yup.string().required(i18n.userLocationDataInputNumberRequired),
  zipCode: yup.string().required(i18n.userLocationDataInputPostalCodeRequired),
});
export const avatarToolBoxSchema = yup.object().shape({
  uploadFile: yup.string().required(i18n.avatarToolBoxFileRequired),

});
export const uploadPaymentReceiptSchema = yup.object().shape({
  typeReceiptSelect: yup.string().required(i18n.paymentReceiptTypeReceiptRequired),
  uploadFile: yup.string().required(i18n.paymentReceiptFileRequired),
});
export const userClaimSchema = yup.object().shape({
  typeClaimSelect: yup.string().required(i18n.claimsTypeClaimRequired),
  email: yup.string().email(i18n.claimsEmailInvalidFormat).required(i18n.claimsEmailRequired),
});
export const loginDNIValidationSchema = yup.object().shape({
  dni: yup.string().matches(/^[0-9]{6,8}$/,
    { excludeEmptyString: true, message: i18n.loginDNIValidationStepDNINumber6to8Digits })
    .required(i18n.loginDNIValidationStepDNIRequired),
  remember: yup.bool(),
});

export const surveySchema = yup.object().shape({
  p1: yup.number().required(i18n.surveySelectHint).moreThan(0, i18n.surveySelectHint),
  p2: yup.string().required(i18n.surveyInputRequired),
  p3: yup.string().required(i18n.surveyInputRequired),
  p4: yup.string().when(['p3'], {
    is: (p3) =>
      p3 === 'r2' || p3 === 'r3',
    then: yup.string().required(i18n.surveyInputRequired),
  }),
});

export const loginVerificationCodeSchema = yup.object().shape({
  smsCode: yup.string().matches(/^[0-9]{4,4}$/,
    { excludeEmptyString: true, message: i18n.loginVerificationCodeStepCodeNumber4Digits })
    .required(i18n.loginVerificationCodeStepCodeRequired),
});

export const loginChangeCellphoneSchema = yup.object().shape({
  cellphone: yup.string().matches(/^[0-9]+$/, i18n.loginChangeCellphoneNumberInputHelper)
    .required(i18n.loginChangeCellphoneNumberInputHelper),
});

export const prepaidCardAddressSchema = yup.object().shape({
  region: yup.string().required(i18n.prePaidCardReprintInputProvinceRequired)
    .matches(/^[A-Za-zÀ-ÿ0-9 -.]+$/, i18n.prePaidCardReprintBasicInputValidation),
  city: yup.string().required(i18n.prePaidCardReprintInputCityRequired)
    .matches(/^[A-Za-zÀ-ÿ0-9 -.]+$/, i18n.prePaidCardReprintBasicInputValidation),
  neighborhood: yup.string().required(i18n.prePaidCardReprintInputNeighborhoodRequired)
    .matches(/^[A-Za-zÀ-ÿ0-9 -.]+$/, i18n.prePaidCardReprintBasicInputValidation),
  street_name: yup.string().required(i18n.prePaidCardReprintInputStreetNameRequired)
    .matches(/^[A-Za-zÀ-ÿ0-9 -.:ªº]+$/, i18n.prePaidCardReprintStreetNameValidation),
  street_number: yup.string().required(i18n.prePaidCardReprintInputStreetNumberRequired)
    .matches(/^[A-Za-zÀ-ÿ0-9 -.]+$/, i18n.prePaidCardReprintBasicInputValidation),
});

export const prepaidCardChangePinSchema = yup.object().shape({
  pinCode: yup.string()
    .required(i18n.prePaidCardChangePinFormHint1)
    .matches(/^[0-9]+$/, i18n.prePaidCardChangePinFormHint2)
    .matches(/^(?!.*?([0-9])\1\1\1).+/, i18n.prePaidCardChangePinFormHint5)
    .test('ascendingSequence', i18n.prePaidCardChangePinFormHint6, (value) => sequenceValidator(value, 4, true))
    .test('descendingSequence', i18n.prePaidCardChangePinFormHint7, (value) => sequenceValidator(value, 4, false))
    .length(4, i18n.prePaidCardChangePinFormHint3),
  pinCode2: yup.string()
    .required(i18n.prePaidCardChangePinFormHint1)
    .equals([yup.ref('pinCode')], i18n.prePaidCardChangePinFormHint4),
});

export const getPayPartialAmountSchema = (maxAmount) => {
  const payPartialAmountSchema = {
    partialAmount: yup.number().typeError(i18n.payInstallmentPartialAmountInvalidFormat)
      .min(0.01, i18n.payInstallmentPartialAmountInvalidFormat)
      .max(maxAmount, i18n.payInstallmentPartialAmountInvalidMax),
  };
  return yup.object().shape(payPartialAmountSchema);
};

export const getCustomerContactDataSchema = (formData) => {
  let userContactDataSchemaDynamic = {};

  if (formData.email !== '') {
    userContactDataSchemaDynamic = {
      email: yup.string().email(i18n.userDataContactInputEmailInvalidFormat)
        .required(i18n.userDataContactInputEmailRequired),
    };
  } else {
    userContactDataSchemaDynamic = { email: yup.string().email(i18n.userDataContactInputEmailRequired) };
  }

  if (formData.phone !== '') {
    userContactDataSchemaDynamic = {
      ...userContactDataSchemaDynamic,
      phone: yup.string().required(i18n.userContactDataInputPhoneRequired)
        .matches(/^[0-9]+$/, i18n.userContactDataInputPhoneFormat),
    };
  } else {
    userContactDataSchemaDynamic = {
      ...userContactDataSchemaDynamic,
      phone: yup.string().matches(/^[0-9]+$/, {
        excludeEmptyString: true,
        message: i18n.userContactDataInputPhoneFormat,
      }),
    };
  }

  if (formData.alternativePhone !== '') {
    userContactDataSchemaDynamic = {
      ...userContactDataSchemaDynamic,
      alternativePhone: yup.string().required(i18n.userContactDataInputPhoneRequired)
        .matches(/^[0-9]+$/, i18n.userContactDataInputPhoneFormat),
    };
  } else {
    userContactDataSchemaDynamic = {
      ...userContactDataSchemaDynamic,
      alternativePhone: yup.string().matches(/^[0-9]+$/,
        { excludeEmptyString: true, message: i18n.userContactDataInputPhoneFormat }),
    };
  }

  return yup.object().shape(userContactDataSchemaDynamic);
};

export const generatePasswordSchemaValidation = yup.object().shape({
  newPass: yup.string()
    .required(i18n.schemaValidation.newPass.required)
    .min(6, i18n.schemaValidation.newPass.minLength)
    .matches(/^[A-Za-z0-9]+$/, i18n.schemaValidation.newPass.alphanumeric),
  repeatNewPass: yup.string()
    .required(i18n.schemaValidation.repeatNewPass.required)
    .oneOf([yup.ref('newPass'), null], i18n.schemaValidation.repeatNewPass.match),
});

export const loginPasswordSchemaValidation = yup.object().shape({
  pass: yup.string()
    .required(i18n.schemaValidation.newPass.required),
});
