import ReactGA from 'react-ga4';
import {
  findActiveLoansByCustomerTaxId, getCustomBanner, getLoanRequest,
  getCustomerAccountStatusByTaxId, getPrepaidBenefit, getPrepaidCardStatus, getLastPendingLoan,
  findRevolvingLineByCustomerTaxId,
  resolveNewLoanOfferAutomaticIfApplies,
} from '../api/selfieWebService';
import {
  PREPAID_CARD_STATUS_ACTIVE, PREPAID_CARD_STATUS_DISABLED, PREPAID_CARD_STATUS_BLOCKED,
} from './PrepaidCardStatusConstants';

const hasAnyActiveLoanWithDebitCardPayment = (loans) => {
  const loansPaymentMethodCardList = loans.filter((loan) =>
    (loan.paymentMethod === 'DIRECT_DEBIT_CARD' || loan.paymentMethod === 'DIRECT_DEBIT'));
  if (loansPaymentMethodCardList.length > 0) {
    return true;
  }
  return false;
};

export const processLogin = (authenticateCustomerResponse, gender) => {
  const userAuth = authenticateCustomerResponse.data.customerLoggedDTO;
  userAuth.accessToken = authenticateCustomerResponse.data.tokenResponseDTO.accessToken;
  userAuth.refreshToken = authenticateCustomerResponse.data.tokenResponseDTO.refreshToken;
  userAuth.gender = gender;
  ReactGA.set({ userId: userAuth.customerId });
  return userAuth;
};

export const hasCustomerActiveLoanWithDebitCardPayment = async (userAuth) => {
  let hasActiveLoanWithDebitCardPayment = false;
  const activeLoansByCustomerTaxIdResponse = await findActiveLoansByCustomerTaxId(
    { customerTaxId: userAuth.customerTaxId },
  );
  if (activeLoansByCustomerTaxIdResponse.data.length > 0) {
    hasActiveLoanWithDebitCardPayment = hasAnyActiveLoanWithDebitCardPayment(
      activeLoansByCustomerTaxIdResponse.data,
    );
  }
  return hasActiveLoanWithDebitCardPayment;
};

export const getCustomerRevolvingLine = async (customerTaxId) => {
  let customerRevolvingLine;
  try {
    const res = await findRevolvingLineByCustomerTaxId(customerTaxId);
    if (res.data) {
      customerRevolvingLine = res.data;
    }
  } catch (error) {
    console.log(error);
  }
  return customerRevolvingLine;
};

export const resolveLastPendingLoan = async (userAuth) => {
  let lastPendingLoan;
  try {
    const res = await getLastPendingLoan(userAuth.customerTaxId);
    if (res.data) {
      lastPendingLoan = res.data;
      const loanData = await getLoanRequest(res.data.hash);
      lastPendingLoan.installmentAmountSelected = loanData.data.installmentAmountSelected;
      lastPendingLoan.installmentsNumberSelected = loanData.data.installmentsNumberSelected;
    }
  } catch (error) {
    console.log(error);
  }
  return lastPendingLoan;
};

export const resolveNewLoanOfferIfApplies = async (userAuth) => {
  let loanOffer;
  try {
    const resNewOffer = await resolveNewLoanOfferAutomaticIfApplies(userAuth.customerId, 19, 38);
    if (resNewOffer && resNewOffer.status === 200 && resNewOffer.data && !resNewOffer.data.rejectedReason) {
      const resLoanData = await getLoanRequest(resNewOffer.data.hashKey);
      const loanDatetime = new Date(resLoanData.data.date);
      const currentDatetimeMinus10 = new Date();
      currentDatetimeMinus10.setMinutes(currentDatetimeMinus10.getMinutes() - 10);
      if (loanDatetime > currentDatetimeMinus10) {
        loanOffer = resNewOffer.data;
      }
    }
  } catch (error) {
    console.log(error);
  }
  return loanOffer;
};

export const getCustomerPrepaidCardStatus = async (userAuth) => {
  const customerPrepaidCardStatus = {
    hasPrepaidCardCustomerData: false,
    hasShipment: false,
    cardStatus: undefined,
  };
  try {
    const res = await getPrepaidCardStatus(userAuth.customerTaxId);
    if (res.data) {
      customerPrepaidCardStatus.hasPrepaidCardCustomerData = res.data.hasPrepaidCardCustomerData;
      customerPrepaidCardStatus.hasShipment = res.data.hasShipment;
      customerPrepaidCardStatus.cardStatus = res.data.cardStatus;
    }
  } catch (error) {
    console.log(error);
  }
  return customerPrepaidCardStatus;
};

export const resolvePrepaidCardStatus = async (userAuth) => {
  const customerPrepaidCardStatus = await getCustomerPrepaidCardStatus(userAuth);

  // chequea si tiene tarjeta prepaga
  customerPrepaidCardStatus.customerHasPrepaidCard = customerPrepaidCardStatus.hasPrepaidCardCustomerData === true;

  // chequea si tiene tarjeta para activar
  customerPrepaidCardStatus.customerHasPrepaidCardToActivate = (
    customerPrepaidCardStatus.hasPrepaidCardCustomerData === true
    && customerPrepaidCardStatus.cardStatus !== PREPAID_CARD_STATUS_ACTIVE
    && customerPrepaidCardStatus.cardStatus !== PREPAID_CARD_STATUS_BLOCKED
    && customerPrepaidCardStatus.cardStatus !== PREPAID_CARD_STATUS_DISABLED
  ) || customerPrepaidCardStatus.hasShipment === true;

  return customerPrepaidCardStatus;
};

export const resolveCustomerAccountStatus = async (userAuth) => {
  let customerAccountStatusData;
  const customerAccountStatus = await getCustomerAccountStatusByTaxId(userAuth.customerTaxId);
  if (customerAccountStatus !== undefined) {
    const [
      hasActiveLoanWithDebitCardPayment, customerRevolvingLine, customerPrepaidCardStatus,
    ] = await Promise.all([
      // chequea si tiene prestamos activos con metodo de pago TD
      hasCustomerActiveLoanWithDebitCardPayment(userAuth),
      // chequea si tiene linea de credito
      getCustomerRevolvingLine(userAuth.customerTaxId),
      // chequea si tiene tarjeta prepaga
      resolvePrepaidCardStatus(userAuth),
    ]);

    // si tiene tarjeta y si tiene tarjeta para activar
    const { customerHasPrepaidCard, customerHasPrepaidCardToActivate } = customerPrepaidCardStatus;

    customerAccountStatusData = {
      ...customerAccountStatus.data,
      hasActiveLoanWithDebitCardPayment,
      customerHasPrepaidCard,
      customerHasPrepaidCardToActivate,
      customerRevolvingLine,
    };
  }
  return customerAccountStatusData;
};

export const resolvePrepaidBenefit = async () => {
  let prepaidBenefit;
  try {
    const res = await getPrepaidBenefit();
    prepaidBenefit = res.data;
  } catch (error) {
    console.log(error);
  }
  return prepaidBenefit;
};

export const resolveCustomBannerList = async () => {
  let customBannerList;
  try {
    const res = await getCustomBanner();
    customBannerList = res.data;
  } catch (error) {
    console.log(error);
  }
  return customBannerList;
};
