import {
  Box, Grid, Typography, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import i18n from '../common/i18n';
import {
  capitalizeSentence, fillMessageWith, mapCodeToDescription, translatePaymentMethod,
} from '../utils/functionsUtil';
import { dateFormatter, moneyFormatter } from '../utils/formatterUtil';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px',
  },
  pending: {
    color: '#eaae53',
  },
  paid: {
    color: '#00875a',
  },
}));

const CardPayment = ({ payment }) => {
  const classes = useStyles();
  const [status, setStatus] = useState();
  const [statusClass, setStatusClass] = useState();

  const translateStatus = () => {
    if (payment.accused) {
      setStatus(i18n.CardPayment.status.paid);
      setStatusClass(`${classes.styleStatus} ${classes.paid}`);
    } else {
      setStatus(i18n.CardPayment.status.pending);
      setStatusClass(`${classes.styleStatus} ${classes.pending}`);
    }
  };

  const isPending = () => !payment.accused;

  useEffect(() => translateStatus(), []);

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8}>
        <Box className={classes.container}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5" component="h2" style={{ fontSize: '18px', textAlign: 'left' }}>
                <strong>
                  {fillMessageWith(i18n.CardPayment.amount, moneyFormatter(payment.amount))}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                component="h2"
                style={{ fontSize: '18px', textAlign: 'right' }}
                className={statusClass}
              >
                <strong>
                  {status}
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" component="h2" style={{ fontSize: '14px' }}>
            <strong>
              {fillMessageWith(
                mapCodeToDescription(payment.subproductId, i18n.subproductsGroup),
                capitalizeSentence(payment.commerceName),
              )}
            </strong>
          </Typography>
          <Typography style={{ fontSize: '14px' }}>
            {translatePaymentMethod(payment.paymentMethod)}
          </Typography>
          <Typography style={{ fontSize: '14px' }}>
            {fillMessageWith(i18n.CardPayment.date, dateFormatter(payment.paymentDate))}
          </Typography>
          {isPending() && (
            <Alert severity="warning" icon={false} style={{ textAlign: 'left', marginTop: '10px' }}>
              {i18n.CardPayment.pendingStatusMessage}
            </Alert>
          )}
        </Box>
        <hr />
      </Grid>
    </Grid>
  );
};

CardPayment.propTypes = {
  payment: PropTypes.shape().isRequired,
};

export default CardPayment;
