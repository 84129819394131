import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import { Button, Hidden } from '@material-ui/core';
import { moneyFormatter } from '../utils/formatterUtil';
import i18n from '../common/i18n';

const useStyles = makeStyles({
  paddingHead: {
    padding: '5px',
  },
  paddingData: {
    padding: '5px',
    fontSize: '0.8rem',
  },
  paddingIcon: {
    paddingRight: '0px',
  },
  downloadButton: {
    display: 'inline-block',
    padding: '6px 3px 6px 3px',
    minHeight: 0,
    minWidth: 0,
    '@media (max-width:600px)': {
      display: 'inline-block',
      padding: '3px',
      minHeight: 0,
      minWidth: 0,
    },
  },
});

function InvoicesTable({ invoices, onClickDownload }) {
  const classes = useStyles();

  const handleDownloadInvoice = (row) => {
    onClickDownload(row.paymentNumber);
  };

  return (
    <>

      <TableContainer component="div" style={{ backgroundColor: 'white' }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.paddingHead}>
                <b>{i18n.invoicesPaymentNumberColumnName}</b>
              </TableCell>
              <TableCell
                align="center"
                className={classes.paddingHead}
              >
                <b>{i18n.invoicesDateColumnName}</b>

              </TableCell>
              <TableCell
                align="center"
                className={classes.paddingHead}
              >
                <b>{i18n.invoicesTotalColumnName}</b>

              </TableCell>
              <Hidden xsDown>
                <TableCell
                  align="center"
                  className={classes.paddingHead}
                >
                  <b>{i18n.invoicesDownloadColumnName}</b>

                </TableCell>
              </Hidden>
              <Hidden mdUp>
                <TableCell />
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((row) => (
              <TableRow key={row.paymentNumber}>
                <TableCell align="center" scomponent="th" scope="row" className={classes.paddingData}>
                  {row.paymentNumber}
                </TableCell>
                <TableCell align="center" className={classes.paddingData}>
                  {' '}
                  {row.date}
                </TableCell>
                <TableCell align="center" className={classes.paddingData}>
                  {' '}
                  {moneyFormatter(row.total)}
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => handleDownloadInvoice(row)}
                    className={classes.downloadButton}
                  >
                    <GetAppIcon color="secondary" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickDownload: PropTypes.func.isRequired,
};

export default InvoicesTable;
