import {
  Box, Button,
  Grid, TextField, Typography, makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';
import useQueryParam from '../customHook/useQueryParam';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import CardCustomerDebtorAccordion from './CardCustomerDebtorAccordion';
import i18n from '../common/i18n';
import Header from './common/Header';
import LoadingButton from './common/LoadingButton';
import LogoMp from '../assets/imgs/logo_mp.svg';
import { getPaymentInformation, mercadoPagoPayment } from '../api/selfieWebService';
import Loading from './common/Loading';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { errorCodeList, PAYMENT_STATUS_ERROR } from '../utils/PaymentConstant';

const PaymentMPForm = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  const history = useHistory();

  const useStyles = makeStyles(() => ({
    margin10: {
      marginBottom: '10px',
    },
    margin30: {
      marginBottom: '30px',
    },
  }));

  const queryParam = useQueryParam();

  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showInputOtherAmount, setShowInputOtherAmount] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState(undefined);
  const [selectedLoanId, setSelectedLoanId] = useState(undefined);
  const [otherAmount, setOtherAmount] = useState(undefined);
  const [messageError, setMessageError] = useState(undefined);

  const goBack = () => {
    history.push({
      pathname: getPathRouteFromKey(i18n.payInstallmentKey),
      search: `m=${i18n.PaymentMPFormKey}`,
    });
  };

  const initLoadPaymentInformation = async () => {
    const loanId = queryParam.get('id');
    setInitLoading(true);
    if (loanId) {
      setSelectedLoanId(loanId);

      try {
        const res = await getPaymentInformation(loanId);
        setPaymentInformation(res.data);
      } catch (error) {
        console.log('Error getting payment information');
      }
    } else {
      history.goBack();
    }
    setInitLoading(false);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOtherAmount(value.replace(/\D+/g, ''));
  };

  const goToPaymentResult = (paymentStatus) => {
    history.push({
      pathname: getPathRouteFromKey(i18n.PaymentStatus),
      search: `status=${paymentStatus}&id=${selectedLoanId}`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      let amountToPay = paymentInformation.finalAmount;
      if (otherAmount) {
        amountToPay = otherAmount;
      }

      const res = await mercadoPagoPayment(selectedLoanId, amountToPay);

      if (res && res.data) {
        if (res.data.urlToRedirect) {
          // init checkout in MP
          window.open(res.data.urlToRedirect, '_SELF');
        } else if (errorCodeList.includes(res.data.errorCode)) {
          // errors to show in the same screen
          setMessageError(res.data.errorCode);
        } else {
          // go to error page
          goToPaymentResult(PAYMENT_STATUS_ERROR);
        }
      }
    } catch (error) {
      goToPaymentResult(PAYMENT_STATUS_ERROR);
    }
    setLoading(false);
  };

  useEffect(() => initLoadPaymentInformation(), []);

  const classes = useStyles();
  return (
    <>
      <Header title={i18n.payInstallmentHeaderTitle} />
      {customerIsUpToDate === false && (
        <CardCustomerDebtorAccordion
          totalInstallmentDebt={totalInstallmentDebt}
          totalPunitiveDebt={totalPunitiveDebt}
          totalDebt={totalDebt}
        />
      )}
      {initLoading && (
        <Loading />
      )}
      {!initLoading && (
        <Grid container justify="center" alignContent="center">
          <Grid item container xs={12} spacing={1} justify="center">
            <Grid item xs={12}>
              <Box m={isMobile ? 3 : 6} />
              <Typography variant="h2" align="center">
                {i18n.paymentCreditCardMpFormTitle}
              </Typography>
              <Box m={2} />
              <Typography variant="h5">
                {i18n.paymentCreditCardMpFormSubtitle}
              </Typography>
              <Box m={2} />
              <img
                src={LogoMp}
                width="180 px"
                alt="Logo de Mercado Pago"
                className={classes.margin30}
              />
            </Grid>
            <form onSubmit={handleSubmit}>
              <Grid container justify="center" alignContent="center">
                <Grid item container xs={11} md={6} spacing={1}>
                  <Grid item xs={12} className={classes.margin10}>
                    <TextField
                      disabled
                      variant="outlined"
                      name="amount"
                      value={paymentInformation.finalAmount}
                      label={i18n.paymentdebitCardFormCardAmount}
                      inputProps={{ maxLength: 8 }}
                      fullWidth
                    />
                  </Grid>
                  {!showInputOtherAmount && (
                    <Grid item container justify="center">
                      <Grid item xs={12}>
                        <Button
                          className={classes.margin10}
                          onClick={() => setShowInputOtherAmount(true)}
                        >
                          {i18n.paymentDebitCardAmountCTA}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {// Info que se descubre cuando ingresa otro importe. Desaparece el btn "quiero ingresar otro importe"
                    }
                  {showInputOtherAmount && (
                    <Grid item xs={12} className={classes.margin10}>
                      <Typography variant="h5">
                        {i18n.paymentDebitCardPartialAmountSubtitle}
                      </Typography>
                      <Box m={2} />
                      <TextField
                        xs={12}
                        variant="outlined"
                        name="otherAmount"
                        value={otherAmount}
                        onChange={handleInputChange}
                        label={i18n.paymentCreditCardFormOtherAmount}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid>
                    <Typography variant="h5" className={classes.margin30}>
                      {i18n.paymentCreditCardFormText}
                    </Typography>
                  </Grid>
                  {messageError && (
                    <>
                      <Box m={2} />
                      <Grid item container xs={12} justify="center">
                        <Grid item xs={12}>
                          <Alert severity="error">
                            <Typography>
                              {i18n[messageError]}
                            </Typography>
                          </Alert>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Box m={6} />
                  <Grid item container xs={12} justify="center">
                    <Grid item xs={12}>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        style={{ width: '100%' }}
                        type="submit"
                        loading={loading}
                      >
                        {i18n.paymentCreditCardFormCTA}
                      </LoadingButton>
                      <Button
                        startIcon={<ArrowBack />}
                        onClick={goBack}
                      >
                        {i18n.paymentDebitCardBackCTA}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PaymentMPForm;
