import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { fillMessageWith } from '../utils/functionsUtil';

const useStyles = makeStyles(() => ({
  countDown: {
    textAlign: 'left',
    marginTop: '16px',
    marginBottom: '24px',
  },
}));

const CountDown = ({ timeLimit, onTimeOver, label }) => {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = useState(timeLimit > 0 ? timeLimit : 0);

  const getFormattedTime = () => {
    const total = timeLeft;
    const seconds = Math.floor((total) % 60);
    const minutes = Math.floor((total / 60) % 60);
    const hours = Math.floor((total / 60 / 60) % 24);

    return `
      ${(hours > 9 ? hours : `0${hours}`)}h
      ${(minutes > 9 ? minutes : `0${minutes}`)}m
      ${(seconds > 9 ? seconds : `0${seconds}`)}s
    `;
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        const time = timeLeft - 1;
        if (time <= 0) {
          mounted = false;
          onTimeOver();
        } else {
          setTimeLeft(timeLeft - 1);
        }
      }
    }, 1000);
    return () => {
      mounted = false;
    };
  }, [timeLeft]);

  return (
    <>
      <Alert severity="info" icon={<AccessTimeIcon />} className={classes.countDown}>
        {label ? (
          fillMessageWith(label, getFormattedTime())
        ) : (
          getFormattedTime()
        )}
      </Alert>
    </>
  );
};

CountDown.propTypes = {
  timeLimit: PropTypes.number.isRequired,
  onTimeOver: PropTypes.func.isRequired,
  label: PropTypes.string,
};

CountDown.defaultProps = {
  label: undefined,
};

export default CountDown;
