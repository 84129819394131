import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import i18n from '../../common/i18n';
import Header from '../../components/header/Header';
import imgExpired from '../../assets/imgs/expired.svg';

function LoginExpired() {
  const history = useHistory();
  const location = useLocation();

  const goToIndex = () => {
    history.push({
      pathname: '/',
      search: location.search,
    });
  };

  return (
    <>
      <Header />
      <Box m={6} mt={16} />
      <Grid container justify="center">
        <Grid item xs={10} md={10}>
          <img src={imgExpired} className="error" width="130px" alt="" />
          <Box m={3} />
          <Typography variant="h2">
            {i18n.loginExpiredTitle}
          </Typography>
          <Box m={3} />
          <Typography variant="h5">
            {i18n.loginExpiredSubtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={10} sm={4} md={3}>
          <Box m={6} />
          <Button onClick={goToIndex} variant="contained" color="primary" fullWidth>
            {i18n.loginExpiredCTA}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default LoginExpired;
