import { Box, Grid, Typography } from '@material-ui/core';
import CopyToClipboardButton from '../../../components/common/CopyToClipboardButton';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCopyToClipboardButton = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <CopyToClipboardButton />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Mensaje: Me copie al portapapeles
          </Typography>
          <Box m={3} />
          <CopyToClipboardButton value="Me copie al portapapeles" />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCopyToClipboardButton;
