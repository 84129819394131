import {
  Box, Button, Grid, Typography, useMediaQuery,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from '../common/i18n';
import CardPayment from '../components/CardPayment';
import Header from '../components/common/Header';
import Loading from '../components/common/Loading';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { getError } from '../utils/functionsUtil';
import MessageResponse from '../components/common/MessageResponse';
import { MY_PAYMENT_INIT_ERROR } from '../utils/referenceConstant';
import { getMyPaymentList } from '../api/selfieWebService';
import { useAuth } from '../contexts/AuthContext';

const MyPayments = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  const goHome = useHomeNavigation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(true);
  const [allPaymentList, setAllPaymentList] = useState();
  const [paymentListToShow, setPaymentListToShow] = useState();
  const [response, setResponse] = useState(undefined);

  const QTY_TO_SHOW = 20; // cantidad de pagos a mostrar inicialmente
  const PAGE_SIZE = 50; // cantidad que agrega al clickear "ver mas"

  const init = async () => {
    try {
      setLoading(true);
      const res = await getMyPaymentList(currentUser.customerTaxId);
      setAllPaymentList(res.data);
      setPaymentListToShow(res.data.slice(0, QTY_TO_SHOW));
    } catch (error) {
      setResponse(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const showMore = () => {
    const result = allPaymentList.slice(0, paymentListToShow.length + PAGE_SIZE);
    setPaymentListToShow(result);
  };

  const hasMore = () => paymentListToShow.length < allPaymentList.length;

  const goToNewPayment = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const reload = () => {
    setResponse(undefined);
    init();
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Header title={i18n.MyPayments.header} />
      {loading && <Loading />}
      {!loading && !response && (
        <Box mt={6} mb={6}>
          <Grid container justify="center">
            <Grid item xs={11}>
              <Typography
                variant="h3"
                style={{ textAlign: isMobile ? 'left' : 'center' }}
              >
                {i18n.MyPayments.title}
              </Typography>
              <Box mt={3} />
              {paymentListToShow.map((payment) => (
                <CardPayment payment={payment} />
              ))}
            </Grid>
            <Grid item xs={11}>
              <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                  <Box mt={2} style={{ textAlign: 'left' }}>
                    <Button
                      disabled={!hasMore()}
                      onClick={showMore}
                      style={{ paddingLeft: '0px' }}
                    >
                      {i18n.MyPayments.seeMore}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box mt={3}>
                    <Button
                      onClick={goToNewPayment}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {i18n.MyPayments.newPayment}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box mt={2}>
                    <Button
                      onClick={goHome}
                      variant="outlined"
                      color="primary"
                      fullWidth
                    >
                      {i18n.MyPayments.goHome}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {!loading && response && (
        <MessageResponse
          response={response}
          onErrorPrimary={reload}
          referenceLabels={MY_PAYMENT_INIT_ERROR}
        />
      )}
    </>
  );
};

export default MyPayments;
