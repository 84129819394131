import {
  Box, Grid, Container, makeStyles, Typography, Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowBack } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getPrepaidCardSecuredActivationUrl, proccessResultActivation } from '../api/selfieWebService';
import { getCustomerAccountStatus, saveCustomerAccountStatus } from '../api/userStorage';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import Loading from '../components/common/Loading';
import { useAuth } from '../contexts/AuthContext';
import { getError, fillMessageWith, replaceNullValuesByUndefined } from '../utils/functionsUtil';
import { PRE_PAID_CARD } from '../utils/referenceConstant';
import {
  getEnv, PREPAID_CARD_STYLE_URL, PREPAID_CARD_SUCCESS_URL,
} from '../api/env';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { resolvePrepaidCardStatus } from '../utils/loginUtil';
import useQueryParam from '../customHook/useQueryParam';

const useStyles = makeStyles({
  iframeWrapper: {
    overflow: 'hidden',
    border: 0,
    position: 'relative',
    width: '100%',
    height: '229px',
    top: 0,
    left: 0,
  },
  activationTitle: {
    fontFamily: 'Poppins',
    fontSize: '1.5rem',
    fontWeight: 700,
    textAlign: 'left',
  },
  activationMessage: {
    fontFamily: 'Open sans',
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'start',
  },
  activateWarningMessage: {
    textAlign: 'start',
  },
});

const PrePaidCardActivate = () => {
  // Context
  const classes = useStyles();
  const { currentUser } = useAuth();
  const customerAccountStatus = getCustomerAccountStatus();// recupera el customerAccountStatus del localeStorage
  const { setCustomerAccountStatus } = useCustomerAccountStatus();
  const queryParam = useQueryParam();
  const history = useHistory();

  const stylesUrlParam = `&styles=${getEnv(PREPAID_CARD_STYLE_URL)}`;
  const succesLinkParam = `&success_link=${getEnv(PREPAID_CARD_SUCCESS_URL)}?success=true`;
  const localeParam = '&locale=es';
  const countryParam = '&country=ARG';

  const [activationUrl, setActivationUrl] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [amountToDisburse, setAmountToDisburse] = useState(undefined);
  const [loanId, setLoanId] = useState(undefined);
  const [installments, setInstallments] = useState(undefined);
  const [installmentAmount, setInstallmentAmount] = useState(undefined);

  const goBack = () => {
    history.goBack();
  };

  const goToMyCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardKey));
  };

  const raiseResponse = (res, isInitError) => {
    setInitError(isInitError);
    setResponse(res);
  };

  const refreshCustomerPrepaidCardStatus = async (userAuth) => {
    const customerPrepaidCardStatus = await resolvePrepaidCardStatus(userAuth);
    if (customerPrepaidCardStatus !== undefined) {
      // si tiene tarjeta y si tiene tarjeta para activar
      const { customerHasPrepaidCard, customerHasPrepaidCardToActivate } = customerPrepaidCardStatus;
      customerAccountStatus.customerHasPrepaidCard = customerHasPrepaidCard;
      customerAccountStatus.customerHasPrepaidCardToActivate = customerHasPrepaidCardToActivate;
      // guarda toda la info en el localstorage y contexto
      saveCustomerAccountStatus(customerAccountStatus);
      setCustomerAccountStatus(customerAccountStatus);
    }
  };

  const initPrePaidCard = async () => {
    setInitLoading(true);
    try {
      if (response === undefined && queryParam.get('success') === 'true') {
        const resAct = await proccessResultActivation(currentUser.customerTaxId);
        if (resAct.data) {
          const resBody = replaceNullValuesByUndefined(resAct.data);
          setAmountToDisburse(resBody.amountToDisburse);
          setLoanId(resBody.loanId);
          setInstallments(resBody.installments);
          setInstallmentAmount(resBody.installmentAmount);
          refreshCustomerPrepaidCardStatus(currentUser);
        }
        raiseResponse({ status: 200 }, false);
      } else if (currentUser.customerTaxId !== undefined) {
        const res = await getPrepaidCardSecuredActivationUrl(currentUser.customerTaxId);
        if (res.data.length > 0) {
          setActivationUrl(`${res.data}${stylesUrlParam}${succesLinkParam}${localeParam}${countryParam}`);
        } else {
          raiseResponse(getError(undefined), true);
        }
      }
    } catch (error) {
      raiseResponse(getError(error), false);
    }
    setInitLoading(false);
  };

  const subtitleMessageResponse = () => (
    <>
      <Typography>
        <strong>
          {fillMessageWith(i18n.prePaidCardActivateAndDisburseSubtitleSuccess,
            amountToDisburse, loanId, installments, installmentAmount)}
        </strong>
      </Typography>
      <Box m={1} />
      <Typography><strong>{i18n.prePaidCardActivateSubtitleSuccess}</strong></Typography>
    </>
  );

  useEffect(() => initPrePaidCard(), []);

  return (
    <>
      <Header title={i18n.PrepaidCardHeaderTitle} />
      <Box m={1} />
      <Container>

        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={PRE_PAID_CARD}
            subtitleMessageResponse={(amountToDisburse && loanId && installmentAmount && installmentAmount)
              ? subtitleMessageResponse : undefined}
            isInitError={initError}
            onSuccessPrimary={goToMyCard}
            successGreen={!initError}
            canGoHome={!initError}
          />
        )}

        {response === undefined && initLoading === true && (
          <Loading />
        )}

        {response === undefined && initLoading === false && (
          <>
            <Grid container justify="left">
              <Grid item>
                <Button
                  startIcon={<ArrowBack />}
                  onClick={goBack}
                  style={{ color: 'black' }}
                >
                  {i18n.prePaidCardActivateArrowButtonGoBack}
                </Button>
              </Grid>
            </Grid>
            <Box m={1} />
            <Grid container justify="center">
              <Grid item xs={10} sm={6} md={4}>
                <Typography className={classes.activationTitle}>
                  {i18n.prePaidCardActivateTitle}
                </Typography>
                <Box m={1} />
                <Typography className={classes.activationMessage}>
                  <b>{i18n.prePaidCardActivateHintMessage1}</b>
                  {i18n.prePaidCardActivateHintMessage2}
                </Typography>
                <Box m={1} />
                <Typography className={classes.activationMessage}>
                  <b>{i18n.prePaidCardActivateHintMessage3}</b>
                  {i18n.prePaidCardActivateHintMessage4}
                </Typography>
                <Box m={3} />
                <Alert severity="info" color="warning" className={classes.activateWarningMessage}>
                  {i18n.prePaidCardActivateWarningMessage}
                </Alert>
                <Grid item xs={12}>
                  <Box m={1} />
                </Grid>
                {/* NO CONTIENE EL BOTON CANCELAR PORQUE NO SE PUDO MANEJAR EL ALTO DINAMICO QUE CONTIENE
                ESTE IFRAME AL PRESIONAR EL BOTON AL TENER UN ERROR EN EL FORMULARIO */}
                <Grid item>
                  <iframe
                    src={activationUrl}
                    title="activation"
                    className={classes.iframeWrapper}
                    allow="clipboard-write"
                  />
                </Grid>
                <Box m={1} />
              </Grid>
            </Grid>
          </>
        )}

      </Container>
    </>
  );
};

export default PrePaidCardActivate;
