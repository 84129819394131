import { useEffect } from 'react';
import {
  useMediaQuery, Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import SideBarMenu from './SideBarMenu';
import logoCredicuotas from '../../assets/imgs/logo.svg';
import i18n from '../../common/i18n';
import HeaderNavBar from './HeaderNavBar';
import '../../assets/css/headerStyle.css';
import {
  getEnv, GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID,
} from '../../api/env';

const Header = ({ nomenu }) => {
  const matchesMobile = useMediaQuery('(max-width: 1264px)');
  const hjid = 335396;
  const hjsv = 6;

  useEffect(() => {
    hotjar.initialize(hjid, hjsv);
    ReactGA.initialize(getEnv(GOOGLE_ANALYTICS_ID));
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
  }, []);

  return (
    <>
      <div className="header">
        <header className="headerNew" style={{ backgroundColor: 'white', height: '80px' }}>
          <Grid container>
            <Grid item xs={10} md={2} className="gridLogoHeader">
              <a
                href="/"
              >
                <img
                  src={logoCredicuotas}
                  alt={i18n.altBrand}
                  className="logoHeader"
                />
              </a>
            </Grid>
            {
              nomenu
                ? ''
                : (
                  matchesMobile ? (
                    <>
                      <Grid item md={8} />
                      <Grid item xs={2} md={2}>
                        <nav className="nav-area menuHamburguesa">
                          <SideBarMenu />
                        </nav>
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={10}>
                      <nav className="nav-area">
                        <HeaderNavBar />
                      </nav>
                    </Grid>
                  )
                )
            }
          </Grid>
        </header>
      </div>
    </>
  );
};

Header.propTypes = {
  nomenu: PropTypes.bool,
};
Header.defaultProps = {
  nomenu: false,
};

export default Header;
