import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Grid, FormControl, FormHelperText, Typography,
} from '@material-ui/core';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import i18n from '../../common/i18n';
import imgDownload from '../../assets/imgs/downloadDocument.svg';

const useStyles = makeStyles({
  image: {
    maxWidth: '200px',
    '@media (max-width:600px)': {
      maxWidth: '300px',
    },
    maxHeight: '500px',
    marginBottom: '15px',
  },
  uploadButton: {
    border: 'solid',
    borderRadius: '3px',
    borderWidth: 'thin',
    marginTop: '20px',
    marginBottom: '20px',
    height: '110px',
    color: '#cdcdcd',
    width: '100%',
  },
  uploadButtonLabel: {
    color: '#e72064',
    fontSize: '0.875rem',
  },
});

const UploadFile = ({
  name, label, uploadChange, id, showPreview, uploadFileLabelButton, ...props
}) => {
  const classes = useStyles();
  const [image, setImage] = useState();
  const [pdf, setPdf] = useState(null);
  const { control, formState: { errors } } = useFormContext();

  const processImage = (path, file) => {
    const reader = new FileReader();
    reader.onload = () => {
      uploadChange(path, file, reader.result);
      if (showPreview) {
        setImage(reader.result);
        if (file.type === 'application/pdf') {
          setPdf(file.name);
        } else {
          setPdf(null);
        }
      }
    };
    reader.readAsDataURL(file); // convert to base64 string
  };
  const upload = (event) => {
    const { files } = event.currentTarget;
    if (files && files[0]) {
      processImage(event.target.value, files[0]);
    }
  };

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          <FormControl
            error={!!errors[name]}
            {...props}
            {...field}
          >
            {label && <Typography variant="h7">{label}</Typography>}
            <Box m={1} />

            {image && (
            <>
              <Grid container direction="row" justify="center">
                <Grid item>
                  {pdf === null && (<img alt="" className={classes.image} src={image} />)}
                  {pdf && (
                  <>
                    <img src={imgDownload} className="Descargar" width="100px" alt="" />
                    <br />
                    {pdf}
                  </>
                  )}
                  <Typography variant="h5" component="h2">
                    <Button component="label">
                      {i18n.paymentReceiptUploadOtherFileLabelButton}
                      <input
                        onChange={upload}
                        accept="image/png, image/jpeg, application/pdf"
                        type="file"
                        id={`upload-file-${id}`}
                        hidden
                      />
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </>
            )}
            {!image && (
              <Button
                component="label"
                fullWidth
                className={classes.uploadButton}
              >
                <Typography className={classes.uploadButtonLabel}>
                  {uploadFileLabelButton}
                </Typography>
                <input
                  onChange={upload}
                  accept="image/png, image/jpeg, application/pdf"
                  type="file"
                  id={`upload-file-${id}`}
                  hidden
                />
              </Button>
            )}
            <Box m={1} />
            <FormHelperText>{errors[name]?.message}</FormHelperText>
          </FormControl>
        </div>
      )}
    />
  );
};

UploadFile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  uploadChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  showPreview: PropTypes.bool,
  uploadFileLabelButton: PropTypes.string.isRequired,

};
UploadFile.defaultProps = {
  label: '',
  showPreview: true,
};
export default UploadFile;
