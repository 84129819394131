import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from '../../common/i18n';

const useStyles = makeStyles({
  title: {
    textAlign: 'left',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  details: {
    textAlign: 'left',
    fontSize: '0.8rem',
  },
  padding: {
    padding: '25px',
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid #D5D5D5',
  },
});

function PaymentMethodBank() {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: '0.8rem', textAlign: 'center' }}>
          {i18n.paymentMethodBankTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="center">
          <Grid item container justify="center">
            <Grid item xs={12} md={12}>
              <Typography className={classes.title}>
                {i18n.paymentMethodBankDescription}
              </Typography>
              <Typography className={classes.title} style={{ marginTop: '25px', marginBottom: '10px' }}>
                <u>{i18n.paymentMethodBankInternet}</u>
              </Typography>
              <Typography className={classes.details}>
                {i18n.paymentMethodBankInternetStep1}
              </Typography>
              <Typography className={classes.details}>
                {i18n.paymentMethodBankInternetStep2}
              </Typography>
              <Typography className={classes.details}>
                {i18n.paymentMethodBankInternetStep3}
              </Typography>

              <Typography className={classes.title} style={{ marginTop: '25px', marginBottom: '10px' }}>
                <u>{i18n.paymentMethodBankATM}</u>
              </Typography>
              <Typography className={classes.details}>
                {i18n.paymentMethodBankATMStep1}
                {' '}
                <b><i>{i18n.paymentMethodBankATMStep1Method}</i></b>
                {' '}
                {i18n.paymentMethodBankATMStep1Option}
              </Typography>
              <Typography className={classes.details}>
                {i18n.paymentMethodBankATMStep2}
              </Typography>
              <Typography className={classes.details}>
                {i18n.paymentMethodBankATMStep3}
              </Typography>
            </Grid>
            <Grid xs={12} style={{ marginTop: '25px' }}>
              <Typography className={classes.title}>
                {i18n.paymentMethodBankDisclaimer}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PaymentMethodBank;
