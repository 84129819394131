import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Container, Typography, Grid,
} from '@material-ui/core';
import '../index.css';
import { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import PropTypes from 'prop-types';
import CardButton from './CardButton';
import i18n from '../common/i18n';
import imgDc from '../assets/imgs/img_td.svg';
import imgMp from '../assets/imgs/pago-mp.svg';
import imgCash from '../assets/imgs/pago-efectivo.svg';
import imgBankTrx from '../assets/imgs/pago-transferencia.svg';
import imgBank from '../assets/imgs/pago-mis-cuentas.svg';
import imgQR from '../assets/imgs/pago-qr.svg';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import CardCustomerDebtorAccordion from './CardCustomerDebtorAccordion';

const useRowStyles = makeStyles({
  gap: {
    gap: '15px',
  },
});
const PaymentMethodSelection = ({ onClickMethod, loans }) => {
  const classes = useRowStyles();
  const location = useLocation();
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};
  const isMobile = useMediaQuery('(max-width:600px)');
  const [showAllOptions, setShowAllOptions] = useState(!isMobile);

  const paymentMethodForRevolvingLine = [
    {
      key: i18n.payInstallmentBankTrxKey,
      title: i18n.payInstallmentBankTrxTitle,
      subtitle: i18n.payInstallmentBankTrxSubtitle,
      urlImage: imgBankTrx,
    },
    {
      key: i18n.payInstallmentQRKey,
      title: i18n.payInstallmentQRTitle,
      subtitle: i18n.payInstallmentQRSubtitle,
      urlImage: imgQR,
    },
  ];

  const paymentMethodCards = [
    {
      key: i18n.payInstallmentBankTrxKey,
      title: i18n.payInstallmentBankTrxTitle,
      subtitle: i18n.payInstallmentBankTrxSubtitle,
      urlImage: imgBankTrx,
    },
    {
      key: i18n.payInstallmentQRKey,
      title: i18n.payInstallmentQRTitle,
      subtitle: i18n.payInstallmentQRSubtitle,
      urlImage: imgQR,
    },
    {
      key: i18n.PaymentDebitCardFormKey,
      title: i18n.payInstallmentDebitCardTitle,
      subtitle: i18n.payInstallmentDebitCardSubtitle,
      urlImage: imgDc,
    },
    {
      key: i18n.payInstallmentCashKey,
      title: i18n.payInstallmentCashTitle,
      subtitle: i18n.payInstallmentCashSubtitle,
      urlImage: imgCash,
    },
    {
      key: i18n.PaymentMPFormKey,
      title: i18n.payInstallmentMercadoPagoTitle,
      subtitle: i18n.payInstallmentMercadoPagoSubtitle,
      urlImage: imgMp,
    },
    {
      key: i18n.payInstallmentEPaymentKey,
      title: i18n.payInstallmentEPaymentTitle,
      subtitle: i18n.payInstallmentEPaymentSubtitle,
      urlImage: imgBank,
    },
  ];

  const hasOnlyRevolvingLine = loans.filter((loan) => loan.productId === 46).length === loans.length;

  const paymentMethodAvailable = location?.state?.isRevolvingLine || hasOnlyRevolvingLine
    ? paymentMethodForRevolvingLine : paymentMethodCards;

  const handleOnClickMethod = (idMethod) => {
    onClickMethod(idMethod);
  };

  return (
    <>
      {customerIsUpToDate === false && (
        <CardCustomerDebtorAccordion
          totalInstallmentDebt={totalInstallmentDebt}
          totalPunitiveDebt={totalPunitiveDebt}
          totalDebt={totalDebt}
        />
      )}
      <Container maxWidth="lg">
        <Box m={6} />
        <Typography variant="h2">{i18n.payInstallmentTitle}</Typography>
        <Box m={6} />

        <Grid container justify="center" className={classes.gap}>
          {paymentMethodAvailable.slice(0, showAllOptions ? 6 : 3).map((method) => (
            <Grid
              item
              key={method.key}
              md={5}
              sm={6}
              xs={12}
            >
              <CardButton
                title={method.title}
                subtitle={method.subtitle}
                urlImage={method.urlImage}
                onClick={() => handleOnClickMethod(method.key)}
              />
            </Grid>
          ))}
          {!showAllOptions && (
            <Grid
              item
              md={5}
              sm={6}
              xs={12}
            >
              <Button
                onClick={() => setShowAllOptions(true)}
                variant="outlined"
                color="primary"
                fullWidth
                style={{
                  fontFamily: 'Open sans',
                  fontWeight: 600,
                  fontSize: '1.1rem',
                }}
              >
                {i18n.payInstallmentMoreOptions}
              </Button>
            </Grid>
          )}
        </Grid>
        <Box m={3} />
      </Container>

    </>

  );
};
PaymentMethodSelection.propTypes = {
  onClickMethod: PropTypes.func.isRequired,
  loans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default PaymentMethodSelection;
