import {
  Box, Grid, Container, makeStyles, Typography, Button, MenuItem, Select, InputLabel, FormControl,
} from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { disablePrePaidCard } from '../api/selfieWebService';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import Loading from '../components/common/Loading';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { PRE_PAID_CARD_DISABLE } from '../utils/referenceConstant';

const useStyles = makeStyles({
  blockTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 800,
    textAlign: 'left',
    lineHeight: '33px',
  },
  blockMessage: {
    fontFamily: 'Open sans',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'justify',
    paddingTop: '6px',
    lineHeight: '20px',
  },
});

const PrePaidCardDisable = () => {
  // Context
  const classes = useStyles();
  const { currentUser } = useAuth();
  const history = useHistory();

  const [response, setResponse] = useState(undefined);
  const [initLoading, setInitLoading] = useState(false);

  const [disableReason, setDisableReason] = useState('STOLEN');

  const goBack = () => {
    history.goBack();
  };

  const goToMyCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardKey));
  };

  const goToReprintCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardReprintKey));
  };

  const handleChange = (event) => {
    setDisableReason(event.target.value);
  };

  const disableCard = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await disablePrePaidCard(currentUser.customerTaxId, disableReason);
        if (res.status === 200) {
          setResponse({ status: 200 });
        } else {
          setResponse(getError(undefined));
        }
      }
      setInitLoading(false);
    } catch (error) {
      setResponse(getError(error));
    }
  };

  const reload = () => {
    setResponse(undefined);
    setInitLoading(false);
  };

  return (
    <>
      <Header title={i18n.PrepaidCardHeaderTitle} />
      <Box m={1} />
      <Container>

        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={PRE_PAID_CARD_DISABLE}
            onSuccessPrimary={goToReprintCard}
            onErrorPrimary={reload}
            canGoHome
            successGreen
            customCTA={{ action: goToMyCard, label: i18n.prePaidCardBlockButtonBack, notShowOnError: true }}
          />
        )}

        {response === undefined && initLoading === true && (
          <Loading />
        )}

        {response === undefined && initLoading === false && (
          <>
            <Box m={3} />
            <Grid container justify="center">
              <Grid item xs={10} md={6} lg={5}>
                <Grid item>
                  <Typography className={classes.blockTitle}>
                    {i18n.prePaidCardBlockTitle}
                  </Typography>
                  <Typography className={classes.blockMessage}>
                    {i18n.prePaidCardBlockMessage1}
                  </Typography>
                  <Typography className={classes.blockMessage}>
                    {i18n.prePaidCardBlockMessage2}
                  </Typography>
                </Grid>
                <Box m={6} />
                <Grid item>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="form-select-label">{i18n.prePaidCardBlockReasonMessage}</InputLabel>
                    <Select
                      style={{ textAlign: 'start' }}
                      name="reasonTypeSelect"
                      value={disableReason}
                      onChange={handleChange}
                      labelId="form-select-label"
                      id="form-select"
                      label={i18n.prePaidCardBlockReasonMessage}
                      fullWidth
                    >
                      <MenuItem value="STOLEN">
                        {i18n.prePaidCardBlockReasonStolen}
                      </MenuItem>
                      <MenuItem value="LOST">
                        {i18n.prePaidCardBlockReasonLost}
                      </MenuItem>
                      <MenuItem value="BROKEN">
                        {i18n.prePaidCardBlockReasonBroken}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Box m={9} />
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12} sm={8} md={8} lg={7}>
                    <Button
                      onClick={disableCard}
                      variant="containedPrimary"
                      fullWidth
                    >
                      {i18n.prePaidCardBlockButtonConfirm}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={7}>
                    <Button
                      onClick={goBack}
                      variant="outlinedPrimary"
                      fullWidth
                    >
                      {i18n.prePaidCardBlockButtonCancel}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

      </Container>
    </>
  );
};

export default PrePaidCardDisable;
