import {
  Box, Button,
  Grid, TextField, Typography, makeStyles,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Cards from 'react-credit-cards';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import LoadingButton from './common/LoadingButton';
import Header from './common/Header';
import {
  formatExpirationDate, formatCVC, formatCreditCardNumber, formatDNI, formatName,
} from '../utils/cardUtils';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import {
  errorCodeList,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_ERROR,
} from '../utils/PaymentConstant';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import CardCustomerDebtorAccordion from './CardCustomerDebtorAccordion';
import useQueryParam from '../customHook/useQueryParam';
import { getPaymentInformation, debitCardPayment } from '../api/selfieWebService';
import Loading from './common/Loading';
import MessageResponse from './common/MessageResponse';
import warningImg from '../assets/imgs/warning-red.svg';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { ERROR_CODE_DEBIT_CARD_PAYMENT } from '../utils/errorCodeConstant';
import {
  DEBIT_CARD_PAYMENT_CARD_BLOCKED,
  DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED,
  DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS,
  DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER,
  DEBIT_CARD_PAYMENT_ERROR_PAYMENT,
  DEBIT_CARD_PAYMENT_EXCEED_LIMITS,
  DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE,
} from '../utils/referenceConstant';

const useStyles = makeStyles(() => ({
  margin10: {
    marginBottom: '10px',
  },
}));
const PaymentDebitCardForm = () => {
  const goHome = useHomeNavigation();
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  const history = useHistory();

  const queryParam = useQueryParam();

  const isMobile = useMediaQuery('(max-width:600px)');
  const stateInit = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    dni: '',
    otherAmount: '',
  };

  const [state, setState] = useState(stateInit);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showInputOtherAmount, setShowInputOtherAmount] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState(undefined);
  const [selectedLoanId, setSelectedLoanId] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [messageError, setMessageError] = useState(undefined);

  const [errors, setErrors] = useState({
    dni: false,
    number: false,
    name: false,
    expiry: false,
    cvc: false,
  });
  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.name });
  };

  const applyMask = (field, value) => {
    switch (field) {
      case 'number': return formatCreditCardNumber(value);
      case 'expiry': return formatExpirationDate(value);
      case 'cvc': return formatCVC(value);
      case 'dni': return formatDNI(value);
      case 'name': return formatName(value);
      case 'otherAmount': return value.replace(/\D+/g, '');
      default: return value;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maskedValue = applyMask(name, value);
    setState({ ...state, [name]: maskedValue });
  };

  const isExpiryDateError = () => {
    let hasError = false;
    if (state.expiry.trim().length < 5) {
      hasError = true;
    } else {
      const month = state.expiry.trim().split('/')[0];
      if (month < 1 || month > 12) {
        hasError = true;
      }

      const year = state.expiry.trim().split('/')[1];
      const currentYear = new Date().getFullYear().toString().substr(-2);

      if (year < currentYear) {
        hasError = true;
      }

      const currentMonth = new Date().getMonth() + 1;
      if (year === currentYear && month <= currentMonth) {
        hasError = true;
      }
    }
    return hasError;
  };
  const isValidForm = () => {
    const formErrors = { ...errors };

    formErrors.dni = (state.dni.trim().length < 6);
    formErrors.number = (state.number.trim().length < 12);
    formErrors.name = (state.name.trim().length === 0);
    formErrors.expiry = isExpiryDateError();
    formErrors.cvc = (state.cvc.trim().length !== 3);

    setErrors(formErrors);

    return !(formErrors.dni || formErrors.number || formErrors.name || formErrors.expiry || formErrors.cvc);
  };

  const goToPaymentResult = (paymentStatus, transactionId, amount) => {
    const queryParams = transactionId && amount
      ? `status=${paymentStatus}&id=${selectedLoanId}&transactionId=${transactionId}&amount=${amount}`
      : `status=${paymentStatus}&id=${selectedLoanId}`;
    history.push({
      pathname: getPathRouteFromKey(i18n.PaymentStatus),
      search: queryParams,
    });
  };

  const getReferenceErrorLabel = (errorCode) => {
    switch (errorCode) {
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_CARD_BLOCKED:
        return DEBIT_CARD_PAYMENT_CARD_BLOCKED;
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INSUFFICIENT_FUNDS:
        return DEBIT_CARD_PAYMENT_CARD_INSUFFICIENT_FUNDS;
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_TRANSACTION_NOT_ALLOWED:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_DENIED:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRED_ERROR:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRATION_MONTH:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRATION_YEAR:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_AUTH_REQUIRED:
        return DEBIT_CARD_PAYMENT_CARD_DENIED_OR_DISABLED;
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_CARD_NUMBER:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_CARD_BIN_ERROR:
        return DEBIT_CARD_PAYMENT_CARD_WRONG_NUMBER;
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_REJECTED:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_OTHER_ERROR:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_NOT_BE_CREDITED:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_MISSING_IDCREDIT:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_USER_OR_PASSWORD:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_HASH:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_PAYMENT_METHOD_ID:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INPUT_DATA_ERROR:
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_INVALID_AMOUNT:
        return DEBIT_CARD_PAYMENT_ERROR_PAYMENT;
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_EXCEED_LIMITS:
        return DEBIT_CARD_PAYMENT_EXCEED_LIMITS;
      case ERROR_CODE_DEBIT_CARD_PAYMENT.DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_ERROR:
        return DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_MESSAGE;
      default:
        return '';
    }
  };

  const cardPayment = async () => {
    let amountToPay = paymentInformation.finalAmount;
    if (state.otherAmount && state.otherAmount.trim() !== '') {
      amountToPay = state.otherAmount;
    }

    const paymentInformationRequest = {
      idCredit: selectedLoanId,
      amount: amountToPay,
      dni: state.dni,
      fullname: state.name,
      debitCardNumber: state.number.replace(/\s/g, ''),
      month: state.expiry.split('/')[0],
      year: state.expiry.split('/')[1],
      ccv: state.cvc,
    };

    try {
      const res = await debitCardPayment(selectedLoanId, paymentInformationRequest);

      if (res && res.data) {
        if (errorCodeList.includes(res.data.errorCode)) {
          // fuerzo error para que messageResponse lo interprete como error
          res.status = 409;
          setResponse(res);
          setMessageError(getReferenceErrorLabel(res.data.errorCode));
        } else {
          // go to success/error page
          const paymentStatus = res.data.paymentSuccess ? PAYMENT_STATUS_SUCCESS : PAYMENT_STATUS_ERROR;
          goToPaymentResult(paymentStatus, res.data.transactionId, res.data.amount);
        }
      }
    } catch (error) {
      goToPaymentResult(PAYMENT_STATUS_ERROR);
    }

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidForm()) {
      setLoading(true);
      cardPayment();
    }
  };

  const goBack = () => {
    history.push({
      pathname: getPathRouteFromKey(i18n.payInstallmentKey),
      search: `m=${i18n.PaymentDebitCardFormKey}`,
    });
  };

  const classes = useStyles();

  const initLoadPaymentInformation = async () => {
    const loanId = queryParam.get('id');
    setInitLoading(true);
    if (loanId) {
      setSelectedLoanId(loanId);
      try {
        const res = await getPaymentInformation(loanId);
        setPaymentInformation(res.data);
      } catch (error) {
        console.log('Error getting payment information');
      }
    } else {
      history.goBack();
    }
    setInitLoading(false);
  };

  const retry = () => {
    setResponse(undefined);
    setMessageError(undefined);
  };

  useEffect(() => initLoadPaymentInformation(), []);

  return (
    <>
      <Header title={i18n.payInstallmentHeaderTitle} />
      {customerIsUpToDate === false && (
        <CardCustomerDebtorAccordion
          totalInstallmentDebt={totalInstallmentDebt}
          totalPunitiveDebt={totalPunitiveDebt}
          totalDebt={totalDebt}
        />
      )}
      {initLoading && (
        <Loading />
      )}
      {!initLoading && !response && (
        <Grid container justify="center" alignContent="center">
          <Grid item container xs={11} spacing={1} justify="center">
            <Grid item xs={12} md={4}>
              <Box m={isMobile ? 3 : 6} />
              <Typography variant="h2" align="center">
                {i18n.paymentDebitCardFormTitle}
              </Typography>
              <Box m={2} />
              <Typography variant="h5">
                {i18n.paymentDebitCardFormSubtitle}
              </Typography>
              <Box m={2} />
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center">
              <Grid item xs={11} sm={6} ms={4} id="PaymentForm">
                <Cards
                  cvc={state.cvc}
                  expiry={state.expiry}
                  focused={state.focus}
                  name={state.name}
                  number={state.number}
                />
                <Box m={4} />
              </Grid>
            </Grid>
            <Grid container justify="center" alignContent="center">
              <Grid item container xs={11} md={6} spacing={1}>
                <Grid item xs={12} className={classes.margin10}>
                  <TextField
                    variant="outlined"
                    name="name"
                    label={i18n.debitCardFormName}
                    value={state.name}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.name}
                    helperText={errors.name ? i18n.debitCardFormValidationName : i18n.debitCardFormHintName}
                  />
                </Grid>
                <Grid item xs={6} className={classes.margin10}>
                  <TextField
                    variant="outlined"
                    name="dni"
                    label={i18n.debitCardFormCardIdNumber}
                    value={state.dni}
                    inputProps={{ maxLength: 8 }}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.dni}
                    helperText={errors.dni ? i18n.debitCardFormValidationDni : ''}
                  />
                </Grid>
                {// Este input debería traer el importe a abonar y no debería ser editable
                }
                <Grid item xs={6} className={classes.margin10}>
                  <TextField
                    disabled
                    variant="outlined"
                    name="amount"
                    label={i18n.paymentdebitCardFormCardAmount}
                    value={paymentInformation.finalAmount}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item xs={12} className={classes.margin10}>
                  <TextField
                    variant="outlined"
                    name="number"
                    label={i18n.debitCardFormCardNumber}
                    value={state.number}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.number}
                    helperText={errors.number ? i18n.debitCardFormValidationNumber : ''}
                  />
                </Grid>
                <Grid item xs={6} className={classes.margin10}>
                  <TextField
                    variant="outlined"
                    name="expiry"
                    label={i18n.debitCardFormDueDate}
                    fullWidth
                    value={state.expiry}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.expiry}
                    helperText={errors.expiry ? i18n.debitCardFormValidationExpiry : ''}
                  />
                </Grid>
                <Grid item xs={6} className={classes.margin10}>
                  <TextField
                    variant="outlined"
                    name="cvc"
                    label={i18n.debitCardFormSecurityCode}
                    value={state.cvc}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.cvc}
                    helperText={errors.cvc ? i18n.debitCardFormValidationCvc : ''}
                  />
                </Grid>
                {!showInputOtherAmount && (
                  <Grid item container xs={12} justify="center">
                    <Grid item xs={12}>
                      <Button
                        onClick={() => setShowInputOtherAmount(true)}
                      >
                        {i18n.paymentDebitCardAmountCTA}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {/* Info que se descubre cuando ingresa otro importe.
                Desaparece el btn "quiero ingresar otro importe" */}
                {showInputOtherAmount && (
                  <Grid item xs={12} className={classes.margin10}>
                    <Typography variant="h5">
                      {i18n.paymentDebitCardPartialAmountSubtitle}
                    </Typography>
                    <Box m={2} />
                    <TextField
                      variant="outlined"
                      name="otherAmount"
                      label={i18n.paymentdebitCardFormCardAmount}
                      value={state.otherAmount}
                      fullWidth
                      onChange={handleInputChange}
                      error={errors.number}
                      helperText={errors.number ? i18n.debitCardFormValidationNumber : ''}
                    />
                  </Grid>
                )}
                <Box m={2} />
                <Grid item container xs={12} justify="center">
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      style={{ width: '100%' }}
                      type="submit"
                      loading={loading}
                    >
                      {i18n.paymentDebitCardFormCTA}
                    </LoadingButton>
                    <Box m={3} />
                    <Button
                      startIcon={<ArrowBack />}
                      onClick={goBack}
                    >
                      {i18n.paymentDebitCardBackCTA}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
      {response && messageError && (
        <Grid container justify="center" alignContent="center">
          <Grid item xs={12}>
            <MessageResponse
              response={response}
              onErrorPrimary={retry}
              customImgError={warningImg}
              referenceLabels={messageError}
              canGoHome={false}
              customCTA={{
                action: goHome,
                label: i18n.backToTop,
                notShowOnSuccess: true,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PaymentDebitCardForm;
