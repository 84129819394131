import './App.css';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';
import { ToasterProvider } from './contexts/ToasterContext';

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={Theme}>
        <ToasterProvider>
          <CssBaseline />
          <AppRouter />
        </ToasterProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
