import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core';
import ArrowRight from '../assets/imgs/arrow-right.svg';
import Mastercard from '../assets/imgs/mastercard.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    textTransform: 'none',
    borderColor: theme.palette.secondary.main,
    height: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontFamily: 'Open sans',
    fontWeight: 400,
    textAlign: 'left',
    fontSize: '14px',
  },
  cardSubtitle: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    textAlign: 'left',
    fontSize: '16px',
  },
  alignCenter: {
    margin: 'auto',
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#ED8CB2',
      color: 'white',
    },
  },
  containerCenterImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function PrepaidCardData({
  title, lastFourDigits, onClick,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Grid item container direction="row" spacing={3}>
            <Grid item xs={3} sm={2} className={classes.containerCenterImg}>
              <img src={Mastercard} height="28px" alt="" />
            </Grid>
            <Grid item xs={7} sm={8} className={classes.alignCenter}>
              <Typography variant="h5" component="h2" className={classes.cardTitle}>
                {title}
              </Typography>
              <Typography variant="h5" component="h2" className={classes.cardSubtitle}>
                {`•••• ${lastFourDigits}`}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.containerCenterImg}>
              <img src={ArrowRight} height="16px" alt="" />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
PrepaidCardData.propTypes = {
  title: PropTypes.string,
  lastFourDigits: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
PrepaidCardData.defaultProps = {
  title: '',
  lastFourDigits: '',
};
export default PrepaidCardData;
