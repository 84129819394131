import {
  Box, Container, Typography, Button, Grid,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import i18n from '../common/i18n';
import { findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine } from '../api/selfieWebService';
import { LOAN_INSTALLMENTS } from '../utils/referenceConstant';
import { getError } from '../utils/functionsUtil';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/common/Header';
import useHomeNavigation from '../customHook/useHomeNavigation';
import LoanSelectionInstallments from '../components/LoanSelectionInstallments';
import MessageResponse from '../components/common/MessageResponse';
import ActionNotAvailable from '../components/common/ActionNotAvailable';
import CardOfferWrapper from '../components/CardOfferWrapper';
import Loading from '../components/common/Loading';

function LoanInstallments() {
  const { currentUser } = useAuth();
  const [loans, setLoans] = useState();
  const [noActiveLoans, setNoActiveLoans] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const goHome = useHomeNavigation();

  const goToHome = () => {
    goHome();
  };

  const initLoanInstallemts = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine(
          { customerTaxId: currentUser.customerTaxId },
        );
        if (res.data.length > 0) {
          setLoans(res.data);
        } else {
          setNoActiveLoans(true);
        }
      }
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };
  useEffect(() => initLoanInstallemts(), []);

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initLoanInstallemts();
  };

  return (
    <>
      <Header title={i18n.installmentsHeaderTitle} />
      <Box m={3} />
      <Container>
        {response === undefined && initLoading === true && (
          <Loading />
        )}
        {response === undefined && initLoading === false && (
          <>
            {!noActiveLoans && loans !== undefined
              && (
                <>
                  <Box m={6} />
                  <Typography variant="h5" style={{ fontSize: '18px' }}>{i18n.installmentsHeaderSubtitle}</Typography>
                  <Box mt={6} />
                  <LoanSelectionInstallments loans={loans} />
                  <Grid container justify="center">
                    <CardOfferWrapper useSmallBackgroundImage />
                  </Grid>
                  <Box mt={3} />
                </>
              )}
            {noActiveLoans && (
              <>
                <ActionNotAvailable
                  title={i18n.loanInstallmentActionNotAvailableTitle}
                  subTitle={i18n.loanInstallmentActionNotAvailableSubTitle}
                  goHomeButton={false}
                />
                <Grid container justify="center">
                  <CardOfferWrapper useSmallBackgroundImage />
                </Grid>
                <Box m={2} />
                <Grid container justify="center">
                  <Grid item xs={10} md={4}>
                    <Button onClick={goToHome} style={{ marginTop: '10px' }}>
                      {i18n.backToTop}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={LOAN_INSTALLMENTS}
            isInitError={initError}
            onErrorPrimary={reload}
          />
        )}

      </Container>
    </>
  );
}

export default LoanInstallments;
