import { PropTypes } from 'prop-types';
import {
  Box, IconButton, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'left',
    borderBottom: '1px solid #E0E0E0',
    paddingTop: '5px',
    paddingBottom: '10px',
    cursor: 'pointer',
  },
  button: {
    padding: '0px',
  },
  child: {
    paddingLeft: '10px',
  },
}));

const CustomDetailAccordion = ({ title, children }) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <Box className={classes.container} onClick={handleCollapse}>
        <IconButton onClick={handleCollapse} className={classes.button}>
          {collapse ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </IconButton>
        {title}
        {collapse && (
          <Box className={classes.child}>
            {children}
          </Box>
        )}
      </Box>
    </>
  );
};

CustomDetailAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomDetailAccordion;
