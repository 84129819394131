import {
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import HeaderNav from '../components/header/Header';
import { getDebitCardStatus, updateDebitCardData } from '../api/selfieWebService';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import Loading from '../components/common/Loading';
import MessageResponse from '../components/common/MessageResponse';
import DebitCardFormNew from '../components/DebitCardFormNew';
import DebitCardPreForm from '../components/DebitCardPreForm';
import { fillMessageWith, getError, capitalizeSentence } from '../utils/functionsUtil';
import { UPDATED_DEBIT_CARD, UPDATE_DEBIT_CARD } from '../utils/referenceConstant';

const useStyles = makeStyles({

  header: {
    marginTop: '80px',
  },
});

const UpdateDebitCard = () => {
  // Context
  const [debitCardData, setDebitCardData] = useState();
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [hasLoans, setHasLoans] = useState(true);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const classes = useStyles();

  const debitCardToken = new URLSearchParams(window.location.search).get('data').toString();

  const initDebitCardStatus = async () => {
    try {
      setInitLoading(true);
      const res = await getDebitCardStatus(debitCardToken);
      if (res.data !== '') {
        setDebitCardData(res.data);
      } else {
        setHasLoans(false);
        setResponse({ status: 200 });
      }
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };

  const updateDebitCard = async (newDebitCardData) => {
    try {
      const data = {
        dni: newDebitCardData.dni,
        debitCardNumber: newDebitCardData.number.replace(/\s/g, ''),
        month: newDebitCardData.expiry.split('/')[0],
        year: newDebitCardData.expiry.split('/')[1],
        ccv: newDebitCardData.cvc,
      };
      const res = await updateDebitCardData(debitCardToken, data);
      setResponse(res);
    } catch (error) {
      setInitError(false);
      setResponse(getError(error));
    }
  };

  useEffect(() => initDebitCardStatus(), []);

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initDebitCardStatus();
  };

  return (
    <>
      <HeaderNav nomenu="true" />
      <Box className={classes.header}>
        <Header
          title={fillMessageWith(
            i18n.updateDebitCardHeaderTitle,
            debitCardData ? capitalizeSentence(` ${debitCardData?.names} ${debitCardData?.lastName}`) : '',
          )}
        />
      </Box>
      {response === undefined && initLoading === true && (
        <Loading />
      )}
      {response === undefined && initLoading === false && (
        <>
          {debitCardData !== undefined
            && (
              <>
                <Box m={3} />
                {!showUpdateForm && (
                  <DebitCardPreForm debitCardData={debitCardData} onCTA={() => setShowUpdateForm(true)} />
                )}
                {showUpdateForm && (<DebitCardFormNew onCTA={updateDebitCard} />)}
              </>
            )}
        </>
      )}
      {response !== undefined && (
        <MessageResponse
          response={response}
          referenceLabels={hasLoans ? UPDATE_DEBIT_CARD : UPDATED_DEBIT_CARD}
          onErrorPrimary={hasLoans ? reload : undefined}
          canGoHome={false}
          isInitError={initError}
        />
      )}
    </>
  );
};

export default UpdateDebitCard;
