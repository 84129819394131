import '../../assets/css/headerStyle.css';
import MenuItems from './MenuItems';
import { MenuNavBarItems } from '../../utils/menuAndRoutesUtil';

const HeaderNavBar = () => (
  <nav>
    <ul className="menus">
      {MenuNavBarItems.map((item) => (
        <MenuItems items={item} key={item.text} />
      ))}
    </ul>
  </nav>
);

export default HeaderNavBar;
