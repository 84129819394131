/* eslint-disable indent */
import PropTypes from 'prop-types';
import {
  Box,
  Button, Grid, Hidden, makeStyles, Typography,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import i18n from '../common/i18n';
import Warning from '../assets/imgs/warning.svg';
import { fillMessageWith } from '../utils/functionsUtil';
import { getEnv, URL_WHATSAPP } from '../api/env';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.lightpink.main,
    padding: '15px',
    height: '100vh',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.8rem',
    lineHeight: '0.8',
    '@media (max-width:600px)': {
      fontSize: '1.3rem',
    },
  },
  whatsapp: {
    backgroundColor: theme.palette.whatsapp.main,
    '&:hover': {
      backgroundColor: '#009620',
    },
  },
  hr: {
    border: '1px solid #e72064',
  },
}));

const CardCustomerLegalStudy = ({ managmentLegalStudyStatus, legalStudyContact }) => {
  const classes = useStyles();

  const goWhatsappLegalStudy = () => {
    window.open(legalStudyContact.whatsappApiUrl);
  };

  const goWhatsappCac = () => {
    window.open(getEnv(URL_WHATSAPP) + i18n.whatsappCACLegalStudyPendingToAssign);
  };

  return (
    <>
      <Grid container className={classes.card} justify="center">
        <Grid item xs={11} md={6}>
          <Hidden smDown>
            <Box m={6} />
          </Hidden>
          <Hidden smUp>
            <Box m={3} />
          </Hidden>
          <img src={Warning} alt="Importante" width="90px" />
          <Box m={3} />
          {managmentLegalStudyStatus !== 'ASSIGNED' ? (
            <>
              <Box m={4} />
              <hr className={classes.hr} />
              <Box m={4} />
              <Typography variant="body1">
                {i18n.customerLegalStudyPendingToAssign}
              </Typography>
              <Box m={6} />
              <Button
                variant="contained"
                onClick={goWhatsappCac}
                className={classes.whatsapp}
                startIcon={<WhatsAppIcon />}
              >
                {i18n.customerLegalStudyWhatsappCTA}
              </Button>
            </>
          ) : (
            <>
              <br />
              <Typography variant="h1" className={classes.title}>
                {i18n.customerLegalStudyAssigned}
              </Typography>
              <Box m={4} />
              <hr className={classes.hr} />
              <Box m={4} />
              {legalStudyContact !== null && (
                <Typography variant="body1">
                  {fillMessageWith(i18n.customerLegalStudyMessage,
                    legalStudyContact.description || '-',
                    legalStudyContact.phoneNumber || '-',
                    legalStudyContact.startTimeAttention || '-',
                    legalStudyContact.endTimeAttention || '-', legalStudyContact.contact || '-')}
                  <br />
                </Typography>
              )}
              {legalStudyContact?.whatsappNumber !== undefined && (
                <>
                  {managmentLegalStudyStatus === 'ASSIGNED' && (
                    <Typography variant="body1">
                      {i18n.customerLegalStudyWhatsappMessage}
                    </Typography>
                  )}
                  <Box m={6} />
                  <Button
                    variant="contained"
                    className={classes.whatsapp}
                    onClick={goWhatsappLegalStudy}
                    startIcon={<WhatsAppIcon />}
                  >
                    {i18n.customerLegalStudyWhatsappCTA}
                  </Button>
                </>

              )}

            </>
          )}

        </Grid>
      </Grid>
    </>
  );
};

CardCustomerLegalStudy.propTypes = {
  managmentLegalStudyStatus: PropTypes.string,
  legalStudyContact: PropTypes.shape({
    description: PropTypes.string,
    phoneNumber: PropTypes.string,
    startTimeAttention: PropTypes.string,
    endTimeAttention: PropTypes.string,
    contact: PropTypes.string,
    whatsappNumber: PropTypes.string,
    whatsappApiUrl: PropTypes.string,
  }),
};

CardCustomerLegalStudy.defaultProps = {
  managmentLegalStudyStatus: null,
  legalStudyContact: null,
};

export default CardCustomerLegalStudy;
