/* eslint "import/prefer-default-export": "off" */
export const ERROR_UPDATE_CUSTOMER_CELLPHONE = 'ERROR_UPDATE_CUSTOMER_CELLPHONE';

export const ERROR_CODE_DEBIT_CARD_PAYMENT = {
  DEBIT_CARD_PAYMENT_INSUFFICIENT_FUNDS: 'DEBIT_CARD_PAYMENT_INSUFFICIENT_FUNDS',
  DEBIT_CARD_PAYMENT_CARD_BLOCKED: 'DEBIT_CARD_PAYMENT_CARD_BLOCKED',
  DEBIT_CARD_PAYMENT_DENIED: 'DEBIT_CARD_PAYMENT_DENIED',
  DEBIT_CARD_PAYMENT_TRANSACTION_NOT_ALLOWED: 'DEBIT_CARD_PAYMENT_TRANSACTION_NOT_ALLOWED',
  DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRATION_MONTH: 'DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRATION_MONTH',
  DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRED_ERROR: 'DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRED_ERROR',
  DEBIT_CARD_PAYMENT_AUTH_REQUIRED: 'DEBIT_CARD_PAYMENT_AUTH_REQUIRED',
  DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRATION_YEAR: 'DEBIT_CARD_PAYMENT_INVALID_CARD_EXPIRATION_YEAR',
  DEBIT_CARD_PAYMENT_INVALID_CARD_NUMBER: 'DEBIT_CARD_PAYMENT_INVALID_CARD_NUMBER',
  DEBIT_CARD_PAYMENT_EXCEED_LIMITS: 'DEBIT_CARD_PAYMENT_EXCEED_LIMITS',
  DEBIT_CARD_PAYMENT_REJECTED: 'DEBIT_CARD_PAYMENT_REJECTED',
  DEBIT_CARD_PAYMENT_CARD_BIN_ERROR: 'DEBIT_CARD_PAYMENT_CARD_BIN_ERROR',
  DEBIT_CARD_PAYMENT_NOT_BE_CREDITED: 'DEBIT_CARD_PAYMENT_NOT_BE_CREDITED',
  DEBIT_CARD_PAYMENT_OTHER_ERROR: 'DEBIT_CARD_PAYMENT_OTHER_ERROR',
  DEBIT_CARD_PAYMENT_INVALID_USER_OR_PASSWORD: 'DEBIT_CARD_PAYMENT_INVALID_USER_OR_PASSWORD',
  DEBIT_CARD_PAYMENT_MISSING_IDCREDIT: 'DEBIT_CARD_PAYMENT_MISSING_IDCREDIT',
  DEBIT_CARD_PAYMENT_INVALID_PAYMENT_METHOD_ID: 'DEBIT_CARD_PAYMENT_INVALID_PAYMENT_METHOD_ID',
  DEBIT_CARD_PAYMENT_INVALID_HASH: 'DEBIT_CARD_PAYMENT_INVALID_HASH',
  DEBIT_CARD_PAYMENT_INVALID_AMOUNT: 'DEBIT_CARD_PAYMENT_INVALID_AMOUNT',
  DEBIT_CARD_PAYMENT_INPUT_DATA_ERROR: 'DEBIT_CARD_PAYMENT_INPUT_DATA_ERROR',
  DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_ERROR: 'DEBIT_CARD_PAYMENT_MAX_ATTEMPTS_ERROR',
};
