import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import {
  getPaymentInfoQR, getInstallmentPlan, revolvineLineCreateLoanRequest, paymentQRAndActivateLoan,
  getPaymentQRAgreementDocument, getPaymentQROperation,
} from '../api/selfieWebService';
import { usePaymentQRContext } from '../contexts/PaymentQRContext';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { useAuth } from '../contexts/AuthContext';
import useQueryParam from '../customHook/useQueryParam';
import {
  getError, sleep, capitalizeSentence, generateLinkWithFileInResponse,
} from '../utils/functionsUtil';
import { PaymentQRRoutesList, getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { moneyFormatterWithoutDecimals, moneyFormatterSplitDecimals } from '../utils/formatterUtil';
import {
  PAYMENT_QR_GET_INFO_ERROR,
  PAYMENT_QR_CUSTOMER_DEBT_ERROR,
  PAYMENT_QR_REVOLING_LINE_PENDING_TO_ACTIVE,
  PAYMENT_QR_SELECT_AMOUNT_ERROR,
  PAYMENT_QR_GET_OPERATION_ERROR,
  PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR,
  PAYMENT_QR_INSUFICIENT_FUNDS_ERROR,
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS,
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR,
} from '../utils/referenceConstant';

/* ********************************** */
const OPEN_AMOUNT = 'open_amount';

const clearValueOnlyNumbers = (value) => Number(value.replace(/\D+/g, ''));

export const usePaymentQRNavigation = () => {
  const history = useHistory();

  const goPaymentQRHome = () => {
    history.push({ pathname: PaymentQRRoutesList.home });
  };

  const goPaymentQRSelectAmount = () => {
    history.push({ pathname: PaymentQRRoutesList.selectAmount });
  };

  const goPaymentQRInstallmentPlan = () => {
    history.push({ pathname: PaymentQRRoutesList.installmentPlan });
  };

  const goPaymentQRVoucher = (operationId) => {
    const queryParams = `operationId=${operationId}`;
    history.push({
      pathname: PaymentQRRoutesList.voucher,
      search: queryParams,
    });
  };

  const goRevolvingLineDetail = () => {
    history.push({ pathname: getPathRouteFromKey(i18n.revolvingLineDetail) });
  };

  return {
    goPaymentQRHome,
    goPaymentQRSelectAmount,
    goPaymentQRInstallmentPlan,
    goPaymentQRVoucher,
    goRevolvingLineDetail,
  };
};

/* ************ OBTENER QR INFO ************ */
export const usePaymentQRProcess = () => {
  const { goPaymentQRSelectAmount, goPaymentQRInstallmentPlan } = usePaymentQRNavigation();
  const { customerAccountStatus } = useCustomerAccountStatus() || {};
  const { setPaymentQRContext } = usePaymentQRContext();

  const getAndProcessInfoQR = async (qrText) => {
    const response = await getPaymentInfoQR(qrText);
    const qrInfo = response.data;
    if (qrInfo.amountType === OPEN_AMOUNT) {
      console.log('open_amount: $0.00');
      setPaymentQRContext((prev) => ({
        ...prev,
        sellerName: qrInfo.sellerName,
        sellerCbuCvu: qrInfo.sellerCbuCvu,
        sellerCuit: qrInfo.sellerCuit,
        transaccionId: qrInfo.transaccionId,
        amountType: qrInfo.amountType,
        selectedAmount: '0',
        selectedAmountDecimals: '00',
        textQR: qrText,
      }));
      goPaymentQRSelectAmount();
    } else {
      if (qrInfo.amount > customerAccountStatus.customerRevolvingLine.remainingAmount) {
        throw new Error(PAYMENT_QR_INSUFICIENT_FUNDS_ERROR);
      }
      const [amount, decimal] = moneyFormatterSplitDecimals(qrInfo.amount);
      console.log(`closed_amount: qrInfo.amount: ${qrInfo.amount}`);
      console.log(`closed_amount: amount: ${amount} | decimal: ${decimal}`);
      setPaymentQRContext((prev) => ({
        ...prev,
        sellerName: qrInfo.sellerName,
        sellerCbuCvu: qrInfo.sellerCbuCvu,
        sellerCuit: qrInfo.sellerCuit,
        transaccionId: qrInfo.transaccionId,
        amountType: qrInfo.amountType,
        selectedAmount: clearValueOnlyNumbers(amount),
        selectedAmountDecimals: clearValueOnlyNumbers(decimal),
        textQR: qrText,
      }));
      goPaymentQRInstallmentPlan();
    }
  };

  return { getAndProcessInfoQR };
};

/* ************ ESCANEAR ************ */
export const usePaymentQRScanner = () => {
  const { goRevolvingLineDetail } = usePaymentQRNavigation();
  const { customerAccountStatus } = useCustomerAccountStatus() || {};
  const { setPaymentQRContext } = usePaymentQRContext();
  const { getAndProcessInfoQR } = usePaymentQRProcess();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const scanner = useRef();
  const videoEl = useRef();
  const qrBoxEl = useRef();
  const [qrOn, setQrOn] = useState(true);

  const scannerStop = () => {
    console.log('Scanner STOP');
    scanner.current.stop();
  };

  const onScanSuccess = async (result) => {
    try {
      setLoading(true);
      const qrText = result.data;
      await getAndProcessInfoQR(qrText);
    } catch (error) {
      console.log(error);
      if (error?.message === PAYMENT_QR_INSUFICIENT_FUNDS_ERROR) {
        setErrorMessage(PAYMENT_QR_INSUFICIENT_FUNDS_ERROR);
      } else {
        setErrorMessage(PAYMENT_QR_GET_INFO_ERROR);
      }
      setResponseError(getError(undefined));
      setLoading(false);
    } finally {
      scannerStop();
    }
  };

  const onScanFail = () => {
    console.log('Error reading QR');
  };

  const scannerInit = () => {
    console.log('INIT SCANNER');
    // Instantiate the QR Scanner
    console.log('Instantiate the QR Scanner');
    if (!scanner.current) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // This is the camera facing mode. In mobile devices, "environment"
        // means back camera and "user" means front camera.
        preferredCamera: 'environment',
        // This will help us position our "QrFrame.svg" so that user can only scan
        // when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // This will produce a yellow (default color) outline around the qr code that we scan,
        // Showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // A custom div which will pair with "highlightScanRegion" option above.
        // This gives us full control over our scan region.
        overlay: qrBoxEl.current || undefined,
      });
    }
    scanner.current.start()
      .then(() => {
        console.log('Scanner START');
        setQrOn(true);
      })
      .catch((err) => {
        if (err) setQrOn(false);
      });
  };

  const handleBack = () => {
    goRevolvingLineDetail();
    scannerStop();
  };

  const handleRetry = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (customerAccountStatus.customerIsUpToDate === false) {
      setErrorMessage(PAYMENT_QR_CUSTOMER_DEBT_ERROR);
      setResponseError(getError(undefined));
    } else if (customerAccountStatus.customerRevolvingLine.campaignOffer) {
      setErrorMessage(PAYMENT_QR_REVOLING_LINE_PENDING_TO_ACTIVE);
      setResponseError(getError(undefined));
    } else if (!customerAccountStatus.customerRevolvingLine.remainingAmount
      || customerAccountStatus.customerRevolvingLine.remainingAmount <= 0) {
      setErrorMessage(PAYMENT_QR_INSUFICIENT_FUNDS_ERROR);
      setResponseError(getError(undefined));
    } else {
      setPaymentQRContext((prev) => ({
        ...prev,
        maxAmountAvailable: moneyFormatterWithoutDecimals(customerAccountStatus.customerRevolvingLine.remainingAmount),
      }));
      scannerInit();
    }
  }, []);

  return {
    loading, responseError, errorMessage, videoEl, qrBoxEl, qrOn, handleBack, handleRetry,
  };
};

/* ************ SELECCIONAR MONTO ************ */
export const usePaymentQRSelectAmount = () => {
  const { goPaymentQRHome, goPaymentQRInstallmentPlan } = usePaymentQRNavigation();
  const { paymentQRContext, setPaymentQRContext } = usePaymentQRContext();
  const amountEl = useRef();
  const decimalEl = useRef();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [hasFirstDigitDecimal, setHasFirstDigitDecimal] = useState(false);
  const [hasSecondDigitDecimal, setHasSecondDigitDecimal] = useState(false);
  const [amountNotAvailableError, setAmountNotAvailableError] = useState(false);

  const adjustAmountWidth = (newValue) => {
    document.getElementById('ghost').innerText = `${moneyFormatterWithoutDecimals(newValue)}`;
  };

  const amountFocus = async () => {
    amountEl.current.focus();
    document.getElementById('ghost').style.color = 'transparent';
    await sleep(100);
    document.getElementById('ghost').style.color = 'black';
  };

  const decimalFocus = async () => {
    decimalEl.current.focus();
    decimalEl.current.style.color = 'transparent';
    await sleep(100);
    decimalEl.current.style.color = 'black';
  };

  const assertAmount = (amount, decimals) => {
    const amountWithoutDecimals = clearValueOnlyNumbers(moneyFormatterWithoutDecimals(`${amount}.${decimals}`));
    const requestedAmountIsNotAvailable = amountWithoutDecimals > clearValueOnlyNumbers(paymentQRContext.maxAmountAvailable);
    setAmountNotAvailableError(requestedAmountIsNotAvailable);
    if (requestedAmountIsNotAvailable) {
      console.log('error amount not available');
      setIsValidAmount(false);
    } else if (amountWithoutDecimals === '0' || amountWithoutDecimals === '00'
        || amountWithoutDecimals === '' || amountWithoutDecimals === undefined) {
      console.log('error amount is invalid');
      setIsValidAmount(false);
    } else {
      console.log('amount is valid');
      setIsValidAmount(true);
    }
  };

  const handleChangeInputAmount = (e) => {
    console.log('===================');
    console.log(`guardado: ${paymentQRContext.selectedAmount}`);
    console.log(`tipea: ${e.key}`);

    if (!Number.isNaN(Number(e.key))) {
      console.log('amount isNumeric');
      const number = e.key;
      const newValue = String(clearValueOnlyNumbers(`${paymentQRContext.selectedAmount}${number}`));
      console.log(`queda: ${newValue}`);
      setPaymentQRContext((prev) => ({
        ...prev,
        selectedAmount: newValue,
      }));
      assertAmount(newValue, paymentQRContext.selectedAmountDecimals);
      adjustAmountWidth(newValue);
      assertAmount(newValue, paymentQRContext.selectedAmountDecimals);
    } else if (e.key === 'Backspace') {
      console.log('isBackspace');
      if (paymentQRContext.selectedAmount !== '0') {
        let newValue;
        if (paymentQRContext.selectedAmount.length === 1) {
          console.log('delete last digit');
          newValue = '0';
        } else {
          console.log('delete 1 digit');
          newValue = String(paymentQRContext.selectedAmount).slice(0, -1);
        }
        console.log(`queda: ${newValue}`);
        setPaymentQRContext((prev) => ({
          ...prev,
          selectedAmount: newValue,
        }));
        assertAmount(paymentQRContext.selectedAmount, newValue);
        adjustAmountWidth(newValue);
        assertAmount(newValue, paymentQRContext.selectedAmountDecimals);
      }
    } else {
      console.log('handleFocus selectedAmountDecimalsInput');
      // no changes
      e.preventDefault();
      decimalFocus();
    }
  };

  const handleChangeInputDecimals = (e) => {
    console.log('===================');
    console.log(`guardado: ${paymentQRContext.selectedAmountDecimals}`);
    console.log(`tipea: ${e.key}`);
    if (!Number.isNaN(Number(e.key))) {
      console.log('decimals isNumeric');
      const number = e.key;
      // agrega un digito
      if (!hasFirstDigitDecimal) {
        const newValue = `${number}0`;
        console.log(`queda: ${newValue}`);
        setPaymentQRContext((prev) => ({
          ...prev,
          selectedAmountDecimals: newValue,
        }));
        assertAmount(paymentQRContext.selectedAmount, newValue);
        setHasFirstDigitDecimal(true);
      } else if (!hasSecondDigitDecimal) {
        const newValue = `${paymentQRContext.selectedAmountDecimals[0]}${number}`;
        console.log(`queda: ${newValue}`);
        setPaymentQRContext((prev) => ({
          ...prev,
          selectedAmountDecimals: newValue,
        }));
        assertAmount(paymentQRContext.selectedAmount, newValue);
        setHasSecondDigitDecimal(true);
      } else {
        // se podria resaltar el input decimales cuando ya esta completo
        // para que se entienda porque no se esta agregando lo que se tipea
      }
    } else if (e.key === 'Backspace') {
      // borrar el ultimo digito
      if (hasSecondDigitDecimal) {
        const newValue = `${paymentQRContext.selectedAmountDecimals[0]}0`;
        console.log(`queda: ${newValue}`);
        setPaymentQRContext((prev) => ({
          ...prev,
          selectedAmountDecimals: newValue,
        }));
        assertAmount(paymentQRContext.selectedAmount, newValue);
        setHasSecondDigitDecimal(false);
      } else if (hasFirstDigitDecimal) {
        const newValue = '00';
        console.log(`queda: ${newValue}`);
        setPaymentQRContext((prev) => ({
          ...prev,
          selectedAmountDecimals: newValue,
        }));
        assertAmount(paymentQRContext.selectedAmount, newValue);
        setHasFirstDigitDecimal(false);
      } else {
        console.log('handleFocus selectedAmountInput');
        amountFocus();
      }
    } else {
      // no changes
      e.preventDefault();
    }
  };

  const handleBack = () => {
    goPaymentQRHome();
  };

  const handleContinue = () => {
    goPaymentQRInstallmentPlan();
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      if (paymentQRContext.amountType !== OPEN_AMOUNT) {
        throw new Error();
      }
      assertAmount(paymentQRContext.selectedAmount, paymentQRContext.selectedAmountDecimals);
      adjustAmountWidth(paymentQRContext.selectedAmount);
      amountFocus();
    } catch (error) {
      setErrorMessage(PAYMENT_QR_SELECT_AMOUNT_ERROR);
      setResponseError(getError(undefined));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    responseError,
    errorMessage,
    handleContinue,
    handleBack,
    handleChangeInputAmount,
    handleChangeInputDecimals,
    isValidAmount,
    amountNotAvailableError,
    amountEl,
    decimalEl,
  };
};

/* ************ PLAN DE CUOTAS ************ */
export const usePaymentQRInstallmentPlan = () => {
  const { goPaymentQRSelectAmount, goPaymentQRHome } = usePaymentQRNavigation();
  const { paymentQRContext, setPaymentQRContext } = usePaymentQRContext();
  const { getAndProcessInfoQR } = usePaymentQRProcess();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [installmentPlan, setInstallmentPlan] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [processingProgress, setProcessingProgress] = useState(null);
  const [progressSpeedUp, setProgressSpeedUp] = useState(false);
  const { currentUser } = useAuth();

  const showPaymentQRAgreement = async () => {
    try {
      const paymentQRAgreementRequest = {
        customerTaxId: currentUser.customerTaxId,
        fullName: capitalizeSentence(`${currentUser.customerFirstName} ${currentUser.customerSurname}`),
        requestedAmount: `${paymentQRContext.selectedAmount}.${paymentQRContext.selectedAmountDecimals}`,
        selectedInstallment,
      };
      const res = await getPaymentQRAgreementDocument(paymentQRAgreementRequest);
      generateLinkWithFileInResponse(res);
    } catch (error) {
      console.log(error);
    }
  };

  const selectInstallment = (installment) => {
    setSelectedInstallment(installment);
  };

  const isSelected = (installment) =>
    (selectedInstallment && selectedInstallment.installment === installment.installment) || false;

  const handleBack = () => {
    if (paymentQRContext?.amountType === OPEN_AMOUNT) {
      goPaymentQRSelectAmount();
    } else {
      goPaymentQRHome();
    }
  };

  const handleRetry = async () => {
    await getAndProcessInfoQR(paymentQRContext.textQR);
    setErrorMessage(undefined);
    setResponseError(false);
    setIsProcessingPayment(false);
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        const step = progressSpeedUp ? 2 : 0.1;
        const percentage = processingProgress + step;
        if (percentage > 100) {
          setProcessingProgress(100);
          mounted = false;
        } else if (percentage > 96 && !progressSpeedUp) {
          setProcessingProgress(95.8);
        } else {
          setProcessingProgress(percentage);
        }
      }
    }, progressSpeedUp ? 1 : 100);
    return () => {
      mounted = false;
    };
  }, [processingProgress]);

  const handleContinue = async () => {
    try {
      console.log('PAYMENT QR -->');
      setIsProcessingPayment(true);
      setProcessingProgress(10);

      const requestedAmount = `${paymentQRContext.selectedAmount}.${paymentQRContext.selectedAmountDecimals}`;

      const createLoanRequest = {
        requestedAmount,
        installmentAmount: selectedInstallment.amount,
        installments: selectedInstallment.installment,
        lineId: selectedInstallment.lineId,
        disbursementMethod: 'PAYMENT_QR',
      };
      const loanRequestResponse = await revolvineLineCreateLoanRequest(currentUser.customerTaxId, createLoanRequest);

      const paymentQRRequest = {
        amount: requestedAmount,
        sellerName: paymentQRContext.sellerName,
        sellerCbuCvu: paymentQRContext.sellerCbuCvu,
        sellerCuit: paymentQRContext.sellerCuit,
        transaccionId: paymentQRContext.transaccionId,
        textQR: paymentQRContext.textQR,
        paymentQRAgreementRequest: {
          customerTaxId: currentUser.customerTaxId,
          fullName: capitalizeSentence(`${currentUser.customerFirstName} ${currentUser.customerSurname}`),
          requestedAmount,
          selectedInstallment,
        },
      };
      const res = await paymentQRAndActivateLoan(loanRequestResponse.data.loanId, paymentQRRequest);

      // speed up progress bar
      setProgressSpeedUp(true);
      // wait for the progress bar end
      await sleep(2000);
      // show success
      setPaymentQRContext((prev) => ({
        ...prev,
        operationId: res.data.operationId,
      }));
      setErrorMessage(PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS);
      setResponseError({
        status: 200,
        data: {
          selectedAmount: requestedAmount,
          sellerName: paymentQRContext.sellerName,
        },
      });
    } catch (error) {
      setErrorMessage(PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR);
      setResponseError(getError(undefined));
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      if (!paymentQRContext.selectedAmount) {
        throw new Error();
      }
      const requestedAmount = `${paymentQRContext.selectedAmount}.${paymentQRContext.selectedAmountDecimals}`;
      const installmentListResp = await getInstallmentPlan(currentUser.customerTaxId, requestedAmount);
      setInstallmentPlan(installmentListResp.data);
    } catch (error) {
      setErrorMessage(PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR);
      setResponseError(getError(undefined));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    responseError,
    errorMessage,
    handleContinue,
    handleBack,
    handleRetry,
    showPaymentQRAgreement,
    installmentPlan,
    selectedInstallment,
    selectInstallment,
    isSelected,
    isProcessingPayment,
    processingProgress,
  };
};

/* ************ COMPROBANTE ************ */
export const usePaymentQRVoucher = () => {
  const queryParam = useQueryParam();
  const exportVoucher = useRef();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [voucherData, setVoucherData] = useState({});

  const DAYS_OF_WEEK = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  const onShare = async () => {
    const canvas = await html2canvas(exportVoucher.current);
    const dataUrl = canvas.toDataURL('image/png');
    const blob = await (await fetch(dataUrl)).blob();
    const operationId = queryParam.get('operationId');
    const filesArray = [new File([blob], `comprobante_${operationId || 'pago'}.png`,
      { type: blob.type, lastModified: new Date().getTime() })];
    const shareData = {
      files: filesArray,
    };
    navigator.share(shareData);
  };

  const init = async () => {
    try {
      setLoading(true);
      const operationId = queryParam.get('operationId');
      const { data: { reference, amount, createdDate } } = await getPaymentQROperation(operationId);
      const operationDate = new Date(createdDate);
      setVoucherData({
        operationId,
        reference,
        amount,
        operationDate: {
          dayOfWeek: DAYS_OF_WEEK[operationDate.getDay()],
          day: operationDate.getDate(),
          month: MONTHS[operationDate.getMonth()],
          year: operationDate.getFullYear(),
          hours: operationDate.getHours().toString().padStart(2, '0'),
          minutes: operationDate.getMinutes().toString().padStart(2, '0'),
        },
      });
    } catch (error) {
      setErrorMessage(PAYMENT_QR_GET_OPERATION_ERROR);
      setResponseError(getError(undefined));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    responseError,
    errorMessage,
    onShare,
    exportVoucher,
    voucherData,
  };
};
