import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import i18n from '../common/i18n';

import { dateFormatter } from '../utils/formatterUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'left',
    marginBottom: '10px',
  },
  cuota: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#212121',
  },
  styleStatus: {
    textAlign: 'right',
    fontSize: '16px',
    marginTop: '3px',
    fontWeight: '700',
  },
  abonada: {
    color: theme.palette.success.main,
  },
  parcial: {
    color: theme.palette.partial.main,
  },
  impaga: {
    color: theme.palette.error.main,
  },
  aplazada: {
    color: theme.palette.defer.main,
  },
  actual: {
    color: '#0052CC',
  },
  separator: {
    border: '1px ridge',
    width: '100%',
  },
}));

function CardInstallments({
  number, status, dueDate, isCurrentInstallment,
}) {
  const classes = useStyles();
  const [loanStatus, setLoanStatus] = useState();
  const [customClass, setCustomClass] = useState();

  const translateStatus = () => {
    switch (status) {
      case 'PARTIAL':
        setLoanStatus(i18n.cardInstallmentStatusPARTIAL);
        setCustomClass(`${classes.styleStatus} ${classes.parcial}`);
        break;
      case 'PAID':
        setLoanStatus(i18n.cardInstallmentStatusPAID);
        setCustomClass(`${classes.styleStatus} ${classes.abonada}`);
        break;
      case 'PENDING':
        if (isCurrentInstallment) {
          setLoanStatus(i18n.cardInstallmentStatusActual);
          setCustomClass(`${classes.styleStatus} ${classes.actual}`);
        }
        // para cuotas futuras queda vacio
        break;
      case 'OVERDUE':
        setLoanStatus(i18n.cardInstallmentStatusOVERDUE);
        setCustomClass(`${classes.styleStatus} ${classes.impaga}`);
        break;
      case 'DEFER':
        setLoanStatus(i18n.cardInstallmentStatusDEFER);
        setCustomClass(`${classes.styleStatus} ${classes.aplazada}`);
        break;
      default:
        console.log('invalid status');
    }
  };
  useEffect(() => translateStatus(), [status]);

  const getCardLayout = () => (
    <Grid container className={classes.card}>
      <Grid item xs={7}>
        <Typography className={classes.cuota}>
          {i18n.cardInstallmentLabelInstallment}
          {number}
        </Typography>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'right' }}>
        <Typography className={customClass}>
          {loanStatus}
        </Typography>
      </Grid>
      <Grid item md={11} xs={8}>
        <Typography style={{ fontSize: '14px', color: '#4E4E4E' }}>
          {i18n.cardInstallmentLabelDueDate}
          {dateFormatter(dueDate)}
        </Typography>
      </Grid>
      <hr className={classes.separator} />
    </Grid>
  );

  return getCardLayout();
}

CardInstallments.propTypes = {
  number: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  isCurrentInstallment: PropTypes.bool,
};
CardInstallments.defaultProps = {
  isCurrentInstallment: false,
};
export default CardInstallments;
