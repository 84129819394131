import PropTypes from 'prop-types';
import { Box, Grid, makeStyles } from '@material-ui/core';
import QRCode from 'react-qr-code';
import logoMP from '../assets/imgs/logo_mp.svg';
import logoCuentaDNI from '../assets/imgs/logo_cuenta_dni.svg';
import logoMODO from '../assets/imgs/logo_modo.svg';
import logoBNA from '../assets/imgs/logo_bna.svg';
import Loading from './common/Loading';

const useStyles = makeStyles(() => ({
  containerBackgroundQR: {
    display: 'inline-grid',
    alignContent: 'center',
    backgroundColor: '#D7E7FF',
    borderRadius: '19px',
    height: '320px',
    width: '290px',
  },
  containerElementsQR: {
    paddingInlineStart: '8%',
    paddingInlineEnd: '8%',
  },
}));

const QRCodePayment = ({ value, loading }) => {
  const classes = useStyles();
  console.log(value);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container className={classes.containerBackgroundQR}>
          <Grid container className={classes.containerElementsQR}>
            <Box m={1} />
            <Grid container justify="center">
              <Grid item>
                <QRCode
                  size={190}
                  value={value}
                  viewBox="0 0 190 190"
                />
              </Grid>
            </Grid>
            <Box m={1} />
            <Grid container justify="space-around">
              <img src={logoMP} alt="mp" width={81} height={32} />
              <img src={logoCuentaDNI} alt="cuentaDni" width={73} height={32} />
            </Grid>
            <Box m={1} />
            <Grid container justify="space-around">
              <img src={logoMODO} alt="modo" width={65} height={20} />
              <img src={logoBNA} alt="bna+" width={54} height={16} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

QRCodePayment.propTypes = {
  value: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default QRCodePayment;
