import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  containerDesktop: {
    height: '100vh',
    backgroundImage: 'url("/images/background_wave.png")',
    backgroundSize: '100vh',
    backgroundRepeat: 'no-repeat',

    '& img': {
      width: '80%',
      height: 'auto',
    },

    '& h1': {
      color: '#212121',
      marginBottom: '8px',
      textAlign: 'left',
    },

    '& p': {
      textAlign: 'left',
    },
  },

  containerMobile: {
    '& h1': {
      color: '#212121',
      marginBottom: '8px',
      textAlign: 'left',
    },

    '& p': {
      textAlign: 'left',
    },
  },

  banner: {
    width: '100%',
    marginBottom: '32px',
  },

  progress: {
    width: '90%',
    marginTop: '16px',
    marginBottom: '16px',
  },

  ctaMobile: {
    width: '100%',
    position: 'fixed',
    bottom: '0',
    left: '0',
    padding: '20px',
    background: 'white',
  },
}));

export default useStyles;
