import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, MenuItem, Button, FormControl,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import TextInput from '../components/common/TextInput';
import SimpleSelect from '../components/common/SimpleSelect';
import imgHappyFace from '../assets/imgs/face-happy.svg';
import imgSadFace from '../assets/imgs/face-sad.svg';
import i18n from '../common/i18n';
import { getSurvey, saveSurveyAnswer } from '../api/selfieWebService';
import { getError } from '../utils/functionsUtil';
import Loading from '../components/common/Loading';
import { SURVEY } from '../utils/referenceConstant';
import useHomeNavigation from '../customHook/useHomeNavigation';
import RatingInput from '../components/common/RatingInput';
import { surveySchema } from '../forms/schemaValidations/schemaValidations';

function Survey() {
  const [initLoading, setInitLoading] = useState(false);
  const [surveyData, setSurveyData] = useState(undefined);
  const typeSelectorAnswer = 'SINGLE_SELECTION_SCORE';
  const typeTextAnswer = 'FREE_TEXT';
  const [questionResults, setQuestionResults] = useState();
  const [saveSurveyResponse, setSaveSurveyResponse] = useState();
  const initSettings = async () => {
    try {
      setInitLoading(true);
      const res = await getSurvey();
      await setSurveyData(res.data);
      setQuestionResults();
    } catch (error) {
      console.log(getError(error));
    } finally {
      setInitLoading(false);
    }
  };
  useEffect(() => initSettings(), []);

  const setQueryResponse = (tag, value) => {
    if (questionResults) {
      setQuestionResults({ ...questionResults, [tag]: value });
    } else {
      setQuestionResults({ [tag]: value });
    }
  };

  const methods = useForm({
    resolver: yupResolver(surveySchema),
  });

  const saveSurvey = async (values) => {
    const userResults = [{
      npsRunId: surveyData.npsRunId,
      questionResults: [],
    }];

    (surveyData.questions).forEach((question) => {
      if (question.type === typeSelectorAnswer) {
        userResults[0].questionResults.push({ code: question.code, answerResults: [{ code: values[question.code] }] });
      } else if (question.type === typeTextAnswer) {
        userResults[0].questionResults.push(
          { code: question.code, answerResults: [{ freeText: values[question.code] }] },
        );
      }
    });

    const npsResult = {
      npsRunId: surveyData.npsRunId,
      userResults,
    };

    const res = await saveSurveyAnswer(npsResult);
    setSaveSurveyResponse(res);
  };

  const retry = () => {
    setSaveSurveyResponse(undefined);
  };

  const goHome = useHomeNavigation();

  return (
    <>
      <Header title={i18n.surveyHeaderTitle} />
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={11} md={4}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(saveSurvey)}>
              {surveyData === undefined && initLoading && (
                <Loading />
              )}
              {surveyData !== undefined && !initLoading && (
                <>
                  {saveSurveyResponse !== undefined && (
                    <MessageResponse
                      response={saveSurveyResponse}
                      referenceLabels={SURVEY}
                      onSuccessPrimary={goHome}
                      onErrorPrimary={retry}
                      canGoHome={false}
                      customImgSuccess={imgHappyFace}
                      customImgError={imgSadFace}
                      customCTA={{
                        action: goHome,
                        label: i18n.surveyResultGoBack,
                        notShowOnSuccess: true,
                      }}
                    />
                  )}
                  {saveSurveyResponse === undefined && (
                    <>
                      {surveyData.questions.map((question, index) => (
                        (question.order !== 4 || questionResults?.p3 === 'r2' || questionResults?.p3 === 'r3') && (
                          <div key={question.code}>
                            <Typography align="left">
                              {question.order}
                              .
                              {i18n[question.label]}
                            </Typography>
                            <Box m={2} />
                            {question.type === typeSelectorAnswer && index === 0 && (
                              <RatingInput name={question.code} />
                            )}
                            {question.type === typeSelectorAnswer && index > 0 && (
                              <FormControl fullWidth variant="outlined">
                                <SimpleSelect
                                  style={{ textAlign: 'left' }}
                                  name={question.code}
                                  label={i18n.surveySelectHint}
                                  fullWidth
                                  onClick={(ev) => setQueryResponse(question.code, ev.target.value)}
                                >
                                  {question.answers.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                      {i18n[option.label]}
                                    </MenuItem>
                                  ))}
                                </SimpleSelect>
                              </FormControl>
                            )}
                            {question.type === typeTextAnswer && (
                              <TextInput
                                name={question.code}
                                onChange={(ev) => setQueryResponse(question.code, ev.target.value)}
                                label={i18n[question.answers[0].label]}
                                fullWidth
                                rows={3}
                                maxrows={10}
                                errors=""
                              />
                            )}
                            <Box m={3} />
                          </div>
                        )
                      ))}
                      <Box m={7} />
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        {i18n.surveyButtonPrimary}
                      </Button>

                    </>
                  )}
                </>
              )}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}

export default Survey;
