import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';

const SimpleSelect = ({
  label, name, mandatory, children, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          select
          variant="outlined"
          label={mandatory ? `${label} *` : label}
          fullWidth
          error={!!errors[name]}
          helperText={errors[name]?.message}
          {...props}
          {...field}
        >
          {children}
        </TextField>
      )}
    />
  );
};

SimpleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
SimpleSelect.defaultProps = {
  label: '',
  mandatory: false,

};

export default SimpleSelect;
