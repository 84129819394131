import PropTypes from 'prop-types';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const CustomArrowBack = ({ ctaLabel, handleBack }) => (
  <>
    <Grid container>
      <Grid item xs={1}>
        <Button
          onClick={handleBack}
          style={{ padding: '6px 0px', color: 'black' }}
          startIcon={<ArrowBack />}
        >
          {ctaLabel}
        </Button>
      </Grid>
    </Grid>
  </>
);

CustomArrowBack.propTypes = {
  ctaLabel: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default CustomArrowBack;
