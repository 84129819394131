import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import parse from 'html-react-parser';
import {
  Box,
  Button, IconButton,
  Dialog, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import i18n from '../common/i18n';
import { fillMessageWith, getCurrentDayOfMonth, getCurrentMonthName } from '../utils/functionsUtil';
import { moneyFormatter } from '../utils/formatterUtil';
import prepaidImg from '../assets/imgs/pago-cancelacion-anticipada.svg';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { saveCurrentUser } from '../api/userStorage';

const useStyles = makeStyles({
  mainContainer: {
    padding: '30px',
    textAlign: 'center',
  },
  description: {
    textAlign: 'left',
  },
  moreInfo: {
    padding: '0px',
    marginTop: '5px',
  },
});

const PrepaidBenefitDialog = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser, setCurrentUser } = useAuth();
  const [open, setOpen] = useState(false);

  const markAsShown = () => {
    const currentUserCtx = currentUser;
    currentUserCtx.prepaidBenefit.alreadyShown = true;
    setCurrentUser(currentUserCtx);
    saveCurrentUser(currentUserCtx);
  };

  const goToPrepaid = () => {
    markAsShown();
    history.push(getPathRouteFromKey(i18n.prePaidLoanKey));
  };

  const handleClose = () => {
    markAsShown();
    setOpen(false);
  };

  const calculateDiscount = () =>
    Math.round((
      (currentUser.prepaidBenefit.totalInstallmentAmount - currentUser.prepaidBenefit.amount)
      / currentUser.prepaidBenefit.totalInstallmentAmount)
      * 100);

  const init = () => {
    // Muestra el popUp solo si tiene descuento disponible y solo una vez por sesion
    if (currentUser && currentUser.prepaidBenefit && !currentUser.prepaidBenefit.alreadyShown) {
      setOpen(true);
    }
  };

  useEffect(() => { init(); }, []);

  const getModalBody = () => (
    <>
      <Grid container style={{ padding: '0px' }}>
        <Grid item xs={12} style={{ textAlign: 'end' }}>
          <IconButton>
            <Close onClick={handleClose} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container className={classes.mainContainer} style={{ paddingTop: '0px' }}>
        <Grid item xs={12}>
          <img src={prepaidImg} alt="Cancelacion Anticipada" />
          <Box mt={3} />
          {calculateDiscount() >= 10 ? (
            <Typography variant="h2">
              {
                parse(fillMessageWith(
                  i18n.PrepaidBenefitDialog.title,
                  calculateDiscount(),
                ))
              }
            </Typography>
          ) : (
            // Si el descuento es menor al 10% no mostramos el porcentaje de descuento.
            <Typography variant="h2">
              {i18n.PrepaidBenefitDialog.title2}
            </Typography>
          )}
          <Box mt={2} className={classes.description}>
            <Typography>
              {
                parse(fillMessageWith(
                  (getCurrentDayOfMonth() > 10 ? i18n.PrepaidBenefitDialog.description[0]
                    : i18n.PrepaidBenefitDialog.description[1]),
                  moneyFormatter(currentUser.prepaidBenefit.amount),
                  moneyFormatter(currentUser.prepaidBenefit.totalInstallmentAmount),
                  getCurrentMonthName(),
                ))
              }
            </Typography>
          </Box>
          <Box mt={3} />
          <Box className={classes.description}>
            <Typography><b>{i18n.PrepaidBenefitDialog.stepsTitle}</b></Typography>
            <Box mt={1} />
            <Typography>{i18n.PrepaidBenefitDialog.step1}</Typography>
            <Typography>{i18n.PrepaidBenefitDialog.step2}</Typography>
            <Typography>{i18n.PrepaidBenefitDialog.step3}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3} />
          <Button variant="contained" color="primary" onClick={goToPrepaid}>
            {i18n.PrepaidBenefitDialog.cta}
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {(currentUser && currentUser.prepaidBenefit) && (
        <Dialog
          open={open}
          onClose={handleClose}
          setOpen={setOpen}
          fullWidth
          maxWidth="xs"
        >
          {getModalBody()}
        </Dialog>
      )}
    </>
  );
};

export default PrepaidBenefitDialog;
