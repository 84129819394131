import parse from 'html-react-parser';
import {
  Box, Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import HeaderNav from '../../components/header/Header';
import { fillMessageWith, isMobileOperatingSystem } from '../../utils/functionsUtil';
import i18n from '../../common/i18n';
import { dateFormatter } from '../../utils/formatterUtil';
import { useRefinancingCommonStyles } from './refinancingHome';
import { useRefinancingContext } from '../../contexts/RefinancingContext';
import { useRefinancingFinal } from '../../services/refinancingService';

const useStyles = makeStyles(() => ({
  result: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .message': {
      textAlign: 'left',
      marginLeft: '8px',
      fontSize: '16px',
      color: '#F2025D',
      fontWeight: '700',
    },
  },
}));

const RefinancingFinal = () => {
  const commonClasses = useRefinancingCommonStyles();
  const classes = useStyles();

  const { handleBack, handleContinue } = useRefinancingFinal();
  const { refinancingContext } = useRefinancingContext();

  return (
    <>
      <HeaderNav nomenu />

      <Grid container className={commonClasses.body}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" align="left">
            {i18n.RefinancingFinal.title}
          </Typography>
          <Box mt={4} />
          <Box className={classes.result}>
            <img src="/images/refinanciacion/ok.svg" alt="OK" />
            <Typography className="message">
              {i18n.RefinancingFinal.result}
            </Typography>
          </Box>
          <Box mt={4} />
          <Typography align="left">
            <b>
              {parse(fillMessageWith(
                i18n.RefinancingFinal.description,
                dateFormatter(refinancingContext.selectedInstallment.expirationDate),
              ))}
            </b>
          </Typography>
          <Box mt={4} />
          <Alert severity="info">
            <Typography align="left">
              {i18n.RefinancingFinal.info}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid
            container
            className={commonClasses.ctaBox}
            spacing={isMobileOperatingSystem() ? 2 : 0}
            direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
          >
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                fullWidth
              >
                {i18n.RefinancingFinal.ctaContinue}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="text"
                color="primary"
                onClick={handleBack}
                fullWidth
              >
                {i18n.RefinancingFinal.ctaBack}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RefinancingFinal;
