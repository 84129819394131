import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const LoginDataContext = createContext(undefined);

// wrapper for the provider
export const LoginDataProvider = ({ loginData, children }) => {
  const [currentLoginData, setCurrentLoginData] = useState(loginData);
  return (
    <LoginDataContext.Provider value={{ currentLoginData, setCurrentLoginData }}>
      {children}
    </LoginDataContext.Provider>
  );
};

LoginDataProvider.propTypes = {
  loginData: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
LoginDataProvider.defaultProps = {
  loginData: undefined,
};

export const useLoginData = () => useContext(LoginDataContext);
