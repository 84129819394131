import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useLoginData } from '../contexts/LoginDataContext';
import {
  getRememberLoginData, removeCurrentLoginData, removeCurrentUser, removeCustomerAccountStatus,
  removeRememberLoginData, saveCurrentLoginData, saveCurrentUser, saveCustomerAccountStatus, saveRememberLoginData,
} from '../api/userStorage';
import { getError, getMaskedEmail } from '../utils/functionsUtil';
import {
  authenticateCustomer, getLastLoan, getNotifications, getUsers,
  performLoginUsingPassword,
  sendVerificationCode,
} from '../api/selfieWebService';
import { getPathRouteFromKey, PublicRoutesList } from '../utils/menuAndRoutesUtil';
import useQueryParam from '../customHook/useQueryParam';
import { useAuth } from '../contexts/AuthContext';
import { useNotification } from '../contexts/NotificationContext';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import useHomeNavigation from '../customHook/useHomeNavigation';
import {
  processLogin, resolveCustomBannerList, resolveCustomerAccountStatus, resolveLastPendingLoan,
  resolveNewLoanOfferIfApplies, resolvePrepaidBenefit,
} from '../utils/loginUtil';
import i18n from '../common/i18n';
import { hasCredentials } from '../api/authenticatePasswordApiService';

/* *********************** INGRESO DNI *********************** */
export const useLoginDNIValidation = () => {
  const location = useLocation();
  const history = useHistory();
  const { setCurrentLoginData } = useLoginData();

  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);
  const [messageError, setMessageError] = useState();

  const goToBack = () => {
    setResponse(undefined);
  };

  const onSubmitDNIValidation = async (data) => {
    const loginData = { dni: data.dni };

    if (data.remember) {
      saveRememberLoginData(data);
    } else {
      removeRememberLoginData();
    }

    setCurrentLoginData(loginData);
    setLoading(true);
    try {
      const result = await getUsers(loginData.dni);
      if (result.data.length === 0) {
        throw Error;
      }
      loginData.users = result.data;
      setCurrentLoginData(loginData);
      saveCurrentLoginData(loginData);
      history.push({
        pathname: PublicRoutesList.loginIdentityResolverStep,
        search: location.search,
      });
    } catch (error) {
      setResponse(getError(error));
      setLoading(false);
      setInitLoading(false);
    }
  };

  const init = () => {
    const rememberLoginData = getRememberLoginData();
    if (!location?.state?.askCustomerId && rememberLoginData?.dni) {
      // Si existe el dni guardado en localStorage y no fuerza a pedir el ingreso, avanza a la proxima pantalla
      // con el listado de personas para ese dni
      setInitLoading(true);
      onSubmitDNIValidation(rememberLoginData);
    } else if (location?.state?.message) {
      // Error recibido desde autologin
      setMessageError(location.state.message);
      setOpenToast(true);
      // Una vez mostrado el toast limpio la variable de mensaje de error
      history.replace();
      setInitLoading(false);
    } else {
      setInitLoading(false);
    }
  };

  useEffect(init, []);

  return {
    initLoading, loading, response, openToast, messageError, goToBack, setOpenToast, onSubmitDNIValidation,
  };
};

/* *********************** SELECCION DE CUIT *********************** */
export const useLoginIdentityResolver = () => {
  const history = useHistory();
  const location = useLocation();
  const { currentLoginData, setCurrentLoginData } = useLoginData();
  const [initLoading, setInitLoading] = useState(true);
  const [response, setResponse] = useState(undefined);

  const friendsAndFamilyCUITList = [
    '27321882833', '27355441364', '27272156684', '27254300026',
    '27417154464', '20379922105', '20332939875', '27285066013',
    '20316165223', '20312271126', '27376837063', '20308408885',
    '20256694884', '20272862908', '20273123327', '20228260194',
    '27339439481', '20370399108', '20954972284', '20294130625',
    '20319201565', '27411822228', '27304982255', '27349103449',
    '20331398498', '20347196755', '27431620435', '27232387993',
    '27413463470', '20286811982', '20220737692', '20321738088',
  ];

  const goToDniValidationStep = () => {
    history.push({
      pathname: PublicRoutesList.loginDNIValidationStep,
      search: location.search,
      state: {
        askCustomerId: true, // fuerza a pedir ingreso de dni, aunque este en localStorage
      },
    });
  };

  const goToVerificationCodeStep = () => {
    history.push({
      pathname: PublicRoutesList.loginVerificationCodeStep,
      search: location.search,
    });
  };

  const goToUserNotClient = () => {
    history.push({
      pathname: PublicRoutesList.loginUserNotClient,
      search: location.search,
    });
  };

  const goToNext = async (user) => {
    try {
      const loginData = { ...currentLoginData };
      loginData.fullname = user.user.fullname;
      loginData.customerTaxId = user.user.customerTaxId;
      loginData.gender = user.user.gender;

      const rememberLoginData = getRememberLoginData();
      if (rememberLoginData?.remember) {
        saveRememberLoginData({ ...rememberLoginData, user });
      } else {
        removeRememberLoginData();
      }

      setCurrentLoginData(loginData);
      saveCurrentLoginData(loginData);

      const resultLastLoan = await getLastLoan(loginData.customerTaxId);
      if (friendsAndFamilyCUITList.includes(loginData.customerTaxId)
        || (resultLastLoan !== undefined && resultLastLoan.data !== undefined
        && Object.keys(resultLastLoan.data).length > 0)) {
        const res = await hasCredentials(loginData.customerTaxId);
        if (res.data) {
          // Si tiene clave creada, debe ingresar con clave
          history.push(PublicRoutesList.loginPassword);
        } else {
          // Si no tiene clave creada, ingresa por sms
          const result = await sendVerificationCode(loginData.customerTaxId);
          const { phoneNumber, smsCode, customerEmail } = result.data;
          loginData.smsCode = smsCode;
          loginData.phoneNumber = `${phoneNumber.substr(0, 4)} **** ${phoneNumber.substr(9, 10)}`;
          if (customerEmail) {
            loginData.customerEmail = getMaskedEmail(customerEmail);
          }
          setCurrentLoginData(loginData);
          saveCurrentLoginData(loginData);

          goToVerificationCodeStep();
        }
      } else {
        removeRememberLoginData();
        goToUserNotClient();
      }
    } catch (error) {
      setResponse(getError(error));
    }
  };

  const init = () => {
    setInitLoading(true);
    const rememberLoginData = getRememberLoginData();
    if (rememberLoginData?.user) {
      goToNext(rememberLoginData.user);
    } else {
      setInitLoading(false);
    }
  };

  useEffect(init, []);

  return {
    initLoading, response, goToNext, goToDniValidationStep,
  };
};

/* *********************** VERIFICACION CODIGO INGRESO *********************** */
export const useLoginVerificationCode = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParam = useQueryParam();
  const { currentLoginData, setCurrentLoginData } = useLoginData();
  const { setCurrentUser } = useAuth();
  const { setContextNotification } = useNotification();
  const { setCustomerAccountStatus } = useCustomerAccountStatus();

  const [loading, setLoading] = useState(false);
  const [invalidCodeAlert, setInvalidCodeAlert] = useState(false);
  const [response, setResponse] = useState(undefined);

  const goHome = useHomeNavigation();
  const goToHome = () => {
    goHome();
  };

  const goToChangeIdentity = () => {
    history.push({
      pathname: PublicRoutesList.loginDNIValidationStep,
      search: location.search,
      state: {
        askCustomerId: true, // fuerza a pedir ingreso de dni, aunque este en localStorage
      },
    });
  };

  const goToCodeNoReception = () => {
    history.push({
      pathname: PublicRoutesList.loginCodeNoReception,
      search: location.search,
    });
  };

  const goToBack = () => {
    setResponse(undefined);
  };

  const goToChangeNumber = () => {
    history.push({
      pathname: PublicRoutesList.loginChangeCellphoneNumber,
      search: location.search,
    });
  };

  const resolveNotifications = async () => {
    let notifications;
    try {
      const res = await getNotifications();
      notifications = res.data;
    } catch (error) {
      console.log(error);
    }
    return notifications;
  };

  const processLoginResult = async (authenticateCustomerResponse, resetPassword, gender) => {
    // procesa la info
    const customerGender = currentLoginData?.gender || gender;
    let userAuth = processLogin(authenticateCustomerResponse, customerGender);
    userAuth.resetPassword = resetPassword;
    if (resetPassword) {
      // Si es un reset password, me guardo la info de login
      // para que luego que cargue la pass,
      // poder enviarlo a la pantalla de login con la info sin problema
      userAuth.loginData = currentLoginData;
    }

    // guarda la info en localstorage
    saveCurrentUser(userAuth);

    const [hasCredentialsRes, prepaidBenefit, customBannerList, lastPendingLoan, customerAccountStatusData, notifications] = await Promise.all([
      hasCredentials(authenticateCustomerResponse.data.customerLoggedDTO.customerTaxId),
      resolvePrepaidBenefit(userAuth),
      resolveCustomBannerList(),
      resolveLastPendingLoan(userAuth),
      // resuelve el estado de cuenta
      resolveCustomerAccountStatus(userAuth),
      resolveNotifications(),
    ]);
    userAuth.hasCredentials = hasCredentialsRes.data;
    userAuth = {
      ...userAuth, prepaidBenefit, customBannerList, lastPendingLoan,
    };

    setContextNotification(notifications);

    // guarda la info en localstorage
    saveCurrentUser(userAuth);

    if (customerAccountStatusData !== undefined) {
      // guarda toda la info en el localstorage y contexto
      saveCustomerAccountStatus(customerAccountStatusData);
      setCustomerAccountStatus(customerAccountStatusData);
      removeCurrentLoginData();
      setCurrentUser(userAuth); // cambia el estado del contexto y la ruta publica redirige a la HOME
      const redirectUrl = queryParam.get('redirectUrl');
      if (redirectUrl) {
        window.location = queryParam.get('redirectUrl');
      }

      // buscar oferta nueva
      const loanOffer = await resolveNewLoanOfferIfApplies(userAuth);
      if (loanOffer) {
        customerAccountStatusData.loanOffer = loanOffer;
        // actualiza la info en el localstorage y contexto
        saveCustomerAccountStatus(customerAccountStatusData);
        setCustomerAccountStatus(customerAccountStatusData);
        // si estoy en el home, hace refresh
        if (window.location.pathname === getPathRouteFromKey(i18n.homeKey)) {
          goToHome();
        }
      }
    } else {
      throw Error;
    }
  };

  const onSubmitVerificationCode = async (data) => {
    setLoading(true);
    setInvalidCodeAlert(false);
    try {
      // Realiza login
      const resetPassword = location.state?.resetPassword || false;
      const authenticateCustomerResponse = await authenticateCustomer(
        currentLoginData.customerTaxId, data.smsCode, resetPassword,
      );
      await processLoginResult(authenticateCustomerResponse, resetPassword);
    } catch (error) {
      removeCustomerAccountStatus();
      removeCurrentUser();
      setLoading(false);
      if (error.data.message === 'INVALID_SMS_CODE_ERROR') {
        setInvalidCodeAlert(true);
      } else {
        setResponse(getError(error));
      }
    }
  };

  const onReSendVerificationCode = async () => {
    setInvalidCodeAlert(false);
    try {
      const loginData = { ...currentLoginData };

      let sendByEmail = false;
      if (loginData.customerEmail) {
        sendByEmail = true;
      }
      const res = await sendVerificationCode(currentLoginData.customerTaxId, sendByEmail);
      const { phoneNumber, smsCode, customerEmail } = res.data;
      // const loginData = { ...currentLoginData };
      loginData.phoneNumber = `${phoneNumber.substr(0, 4)} **** ${phoneNumber.substr(9, 10)}`;
      loginData.smsCode = smsCode;
      if (customerEmail) {
        loginData.customerEmail = getMaskedEmail(customerEmail);
      }
      setCurrentLoginData(loginData);
    } catch (error) {
      setResponse(getError(error));
    }
  };

  const handlePasswordSubmit = async (password) => {
    try {
      setLoading(true);
      const authenticateCustomerResponse = await performLoginUsingPassword(currentLoginData.customerTaxId, password);
      await processLoginResult(authenticateCustomerResponse);
    } catch (error) {
      removeCustomerAccountStatus();
      removeCurrentUser();
      setResponse(getError(error));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    invalidCodeAlert,
    response,
    goToChangeIdentity,
    goToCodeNoReception,
    goToBack,
    goToChangeNumber,
    onSubmitVerificationCode,
    onReSendVerificationCode,
    handlePasswordSubmit,
    processLoginResult,
  };
};
