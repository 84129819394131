import {
  Box, Grid, MenuItem, Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import SimpleSelect from '../../../components/common/SimpleSelect';

const WikiSimpleSelect = () => {
  const options = [
    { key: 'key1', label: 'label 1' },
    { key: 'key2', label: 'label 2' },
    { key: 'key3', label: 'label 3' },
  ];

  const methods = useForm();

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <Typography variant="h5" noWrap>
                SimpleSelect:
              </Typography>
              <Box m={3} />
              <SimpleSelect name="simpleSelect1" label="Simple select">
                {options.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </SimpleSelect>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default WikiSimpleSelect;
