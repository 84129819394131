import {
  Grid, Typography,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import Header from '../../components/header/Header';
import LoginDNIValidationStep from '../../components/LoginDNIValidationStep';
import MessageResponse from '../../components/common/MessageResponse';
import { LOGIN_DNI_RESOLVER } from '../../utils/referenceConstant';
import Toast from '../../components/common/Toast';
import Loading from '../../components/common/Loading';
import { useLoginDNIValidation } from '../../services/loginService';
import useStyle from './loginStyle';

function LoginDNIValidation() {
  window.scrollTo(0, 0);
  const classes = useStyle();
  const {
    initLoading, loading, response, openToast, messageError, goToBack, setOpenToast, onSubmitDNIValidation,
  } = useLoginDNIValidation();

  return (
    <>
      <Header />
      <Grid container className={classes.container}>
        <Grid item xs={1} md={1} />
        <Grid item xs={10} md={5} style={{ marginTop: '120px' }}>
          {initLoading ? (
            <Loading />
          ) : (
            <>
              {response === undefined ? (
                <>
                  <Typography
                    variant="h1"
                    align="left"
                    style={{ color: '#212121', marginBottom: '8px', fontFamily: 'Open sans' }}
                  >
                    {i18n.loginDNIValidationTitle}
                  </Typography>
                  <Typography
                    align="left"
                    style={{ marginBottom: '32px' }}
                  >
                    {i18n.loginDNIValidationSubtitle}
                  </Typography>
                  <LoginDNIValidationStep
                    onSubmit={onSubmitDNIValidation}
                    loading={loading}
                  />
                </>
              ) : (
                <MessageResponse
                  response={response}
                  referenceLabels={LOGIN_DNI_RESOLVER}
                  onErrorPrimary={goToBack}
                  canGoHome={false}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={1} md={5} />
      </Grid>
      {messageError && (
        <Toast
          messageOnError={messageError}
          open={openToast}
          onClose={() => setOpenToast(false)}
        />
      )}
    </>
  );
}
export default LoginDNIValidation;
