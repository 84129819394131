import {
  Grid,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import CustomArrowBack from '../../components/common/CustomArrowBack';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import { fillMessageWith, isMobileOperatingSystem } from '../../utils/functionsUtil';
import i18n from '../../common/i18n';
import Loading from '../../components/common/Loading';
import PaymentQRMessageResponse from './paymentQRMessageResponse';
import { usePaymentQRSelectAmount } from '../../services/paymentQRService';
import { usePaymentQRContext } from '../../contexts/PaymentQRContext';

const PaymentQRSelectAmount = () => {
  const classes = usePaymentQRStyles();
  const { paymentQRContext } = usePaymentQRContext();
  const {
    loading, responseError, errorMessage, handleContinue, handleBack, handleChangeInputAmount, handleChangeInputDecimals,
    isValidAmount, amountNotAvailableError, amountEl, decimalEl,
  } = usePaymentQRSelectAmount();

  return (
    <>
      {loading && (
        <Loading />
      )}

      {!loading && !responseError && (
        <>
          <Grid container className={classes.body} justifyContent="center">
            <Grid item xs={12}>
              <CustomArrowBack ctaLabel={i18n.PaymentQR.ctaBack} handleBack={handleBack} />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <Typography variant="h2" align="left">
                {i18n.PaymentQR.OpenAmount.selectAmountTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '50px' }}>
              <table className={classes.table}>
                <tr className={classes.tr}>
                  <td width={isMobileOperatingSystem() ? '30px' : '40px'} className={classes.td} />
                  <td className={classes.td}>
                    <div id="wrapper" className={classes.wrapper}>
                      <div id="input" className={classes.input}>
                        <TextField
                          variant="outlined"
                          type="number"
                          inputRef={amountEl}
                          name="selectedAmountInput"
                          value={paymentQRContext?.selectedAmount}
                          onKeyDown={handleChangeInputAmount}
                          InputProps={{
                            classes: {
                              input: paymentQRContext?.selectedAmount.length > 6
                                ? classes.amountInputSmall : classes.amountInput,
                              notchedOutline: classes.noBorder,
                            },
                          }}
                          fullWidth
                        />
                      </div>
                      <div
                        id="ghost"
                        className={paymentQRContext?.selectedAmount.length > 6
                          ? classes.ghostSmall : classes.ghost}
                      />
                    </div>
                  </td>
                  <td width={isMobileOperatingSystem() ? '30px' : '40px'} className={classes.td}>
                    <TextField
                      variant="outlined"
                      type="number"
                      inputRef={decimalEl}
                      name="selectedAmountDecimalsInput"
                      value={paymentQRContext?.selectedAmountDecimals}
                      onKeyDown={handleChangeInputDecimals}
                      InputProps={{
                        classes: {
                          input: paymentQRContext?.selectedAmount.length > 6
                            ? classes.decimalInputSmall : classes.decimalInput,
                          notchedOutline: classes.noBorder,
                        },
                      }}
                      fullWidth
                    />
                  </td>
                </tr>
              </table>

            </Grid>

            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              {paymentQRContext?.maxAmountAvailable && (
              <Typography
                variant="h6"
                textAlign="center"
                style={{
                  color: amountNotAvailableError ? 'red' : 'black',
                }}
              >
                {fillMessageWith(i18n.PaymentQR.OpenAmount.maxAmountAvailable, paymentQRContext.maxAmountAvailable)}
              </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.ctaBox}
            justifyContent="center"
            spacing={isMobileOperatingSystem() ? 2 : 0}
            direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
          >
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                disabled={!isValidAmount}
                fullWidth
              >
                {i18n.PaymentQR.ctaContinue}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (
        <Grid container className={classes.body} justifyContent="center">
          <Grid item xs={10} md={6}>
            <PaymentQRMessageResponse
              response={responseError}
              referenceLabels={errorMessage}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PaymentQRSelectAmount;
