import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import i18n from '../common/i18n';
import { getEnv, URL_DISMISS } from '../api/env';
import useHomeNavigation from '../customHook/useHomeNavigation';

const PrePaidLoanDismiss = () => {
  const urlDismiss = getEnv(URL_DISMISS);
  const goHome = useHomeNavigation();

  const goToDismiss = () => {
    window.open(urlDismiss);
  };

  const goToHome = () => {
    goHome();
  };

  return (
    <>
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={11} md={11}>
          <Typography variant="h5"><b>{i18n.prePaidLoanDismissTitle}</b></Typography>
          <Typography variant="h5">{i18n.prePaidLoanDismissSubTitle}</Typography>
        </Grid>
        <Grid container justify="center" spacing={3} style={{ marginTop: '3em' }}>
          <Grid item xs={11} sm={6} md={3}>
            <Button
              onClick={goToDismiss}
              variant="outlined"
            >
              {i18n.prePaidLoanDismissCTA}
            </Button>
          </Grid>
          <Grid item xs={11} sm={6} md={3}>
            <Button
              onClick={goToHome}
              variant="outlined"
            >
              {i18n.backToTop}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PrePaidLoanDismiss;
