import PropTypes from 'prop-types';
import { useState, createContext, useContext } from 'react';

const PaymentQRContext = createContext(undefined);

export const PaymentQRProvider = ({ paymentQR, children }) => {
  const [paymentQRContext, setPaymentQRContext] = useState(paymentQR);

  return (
    <PaymentQRContext.Provider value={{ paymentQRContext, setPaymentQRContext }}>
      {children}
    </PaymentQRContext.Provider>
  );
};

PaymentQRProvider.propTypes = {
  paymentQR: PropTypes.shape(),
  children: PropTypes.node.isRequired,

};

PaymentQRProvider.defaultProps = {
  paymentQR: null,
};

export const usePaymentQRContext = () => useContext(PaymentQRContext);
