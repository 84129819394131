import {
  List,
  ListItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import CardUserConfirmation from './CardUserConfirmation';
import { fillMessageWith } from '../utils/functionsUtil';

const LoginIdentityResolverStep = ({ users, goToNext }) => (
  <>
    <List>
      {users.map((user) => (
        <ListItem key={user.customerTaxId} style={{ paddingLeft: '0px' }}>
          <CardUserConfirmation
            name={user.fullname}
            cuit={fillMessageWith(i18n.loginIdentityResolverCUIT, [user.customerTaxId])}
            onClick={() => { goToNext({ user }); }}
          />
        </ListItem>
      ))}
    </List>
  </>
);

LoginIdentityResolverStep.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    fullname: PropTypes.string.isRequired,
    customerTaxId: PropTypes.string.isRequired,
  })).isRequired,
  goToNext: PropTypes.func.isRequired,
};

export default LoginIdentityResolverStep;
