import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import CardVerticalButton from './CardVerticalButton';
import i18n from '../common/i18n';
import Ban from '../assets/imgs/ban-yellow.svg';
import Change from '../assets/imgs/change-yellow.svg';
import Pause from '../assets/imgs/pause-yellow.svg';
import Unpause from '../assets/imgs/unpause-yellow.svg';
import CustomDialog from './common/CustomDialog';
import PrepaidCardPauseInfo from './PrepaidCardPauseInfo';
import { pausePrePaidCard, unpausePrePaidCard } from '../api/selfieWebService';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useAuth } from '../contexts/AuthContext';
import {
  PREPAID_CARD_STATUS_ACTIVE, PREPAID_CARD_STATUS_BLOCKED,
} from '../utils/PrepaidCardStatusConstants';

function PrepaidCardBoxActions({
  cardInfo, setCardInfo, dialog,
}) {
  const { currentUser } = useAuth();
  const history = useHistory();

  const changeStatusDialog = async (status) => {
    if (PREPAID_CARD_STATUS_BLOCKED === status) {
      await pausePrePaidCard(currentUser.customerTaxId);
    } else if (PREPAID_CARD_STATUS_ACTIVE === status) {
      await unpausePrePaidCard(currentUser.customerTaxId);
    }
    setCardInfo({ ...cardInfo, card: { ...cardInfo.card, status } });
    dialog.setOpenDialog(false);
  };

  const pauseCard = () => {
    dialog.setOpenDialog(true);
  };
  const unPauseCard = () => {
    changeStatusDialog(PREPAID_CARD_STATUS_ACTIVE);
  };

  const goToDisableCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardDisableKey));
  };

  const goToChangePinCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardChangePinKeyPreview));
  };

  return (
    <Grid container justify="center" spacing={1} style={{ paddingTop: '9px' }}>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        {cardInfo.card.status === PREPAID_CARD_STATUS_ACTIVE && (
          <CardVerticalButton
            title={i18n.PrepaidCardActivatedCardBoxActionPause}
            onClick={pauseCard}
            logo={Pause}
          />
        )}
        {cardInfo.card.status === PREPAID_CARD_STATUS_BLOCKED && (
          <CardVerticalButton
            title={i18n.PrepaidCardActivatedCardBoxActionUnpause}
            onClick={unPauseCard}
            logo={Unpause}
          />
        )}
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <CardVerticalButton
          title={i18n.PrepaidCardActivatedCardBoxActionBan}
          onClick={goToDisableCard}
          logo={Ban}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <CardVerticalButton
          title={i18n.PrepaidCardActivatedCardBoxActionChangePin}
          onClick={goToChangePinCard}
          logo={Change}
        />
      </Grid>
      <>
        <CustomDialog
          open={dialog.openDialog}
          setOpen={dialog.setOpenDialog}
          dialogData={PrepaidCardPauseInfo}
          onClick={() => changeStatusDialog(PREPAID_CARD_STATUS_BLOCKED)}
        />
      </>

    </Grid>
  );
}
PrepaidCardBoxActions.propTypes = {
  cardInfo: PropTypes.objectOf(Object).isRequired,
  setCardInfo: PropTypes.func.isRequired,
  dialog: PropTypes.shape({
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
  }).isRequired,
};
export default PrepaidCardBoxActions;
