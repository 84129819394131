import parse from 'html-react-parser';
import Cards from 'react-credit-cards';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles,
} from '@material-ui/core';
import Loading from '../../components/common/Loading';
import HeaderNav from '../../components/header/Header';
import i18n from '../../common/i18n';
import { fillMessageWith, isMobileOperatingSystem } from '../../utils/functionsUtil';
import { RefinancingRoutesList } from '../../utils/menuAndRoutesUtil';
import { moneyFormatter } from '../../utils/formatterUtil';
import { applyMask, isExpiryDateError } from '../../utils/cardUtils';
import { useRefinancingCommonStyles } from './refinancingHome';
import { useRefinancingContext } from '../../contexts/RefinancingContext';

const useStyles = makeStyles(() => ({
  amount: {
    color: '#F2025D !important',
    fontSize: '24px !important',
  },

  spacing: {
    marginTop: '8px',
  },
}));

const RefinancingPaymentMethod = () => {
  const commonClasses = useRefinancingCommonStyles();
  const classes = useStyles();

  const { refinancingContext, setRefinancingContext } = useRefinancingContext();

  const history = useHistory();

  const [loading, setLoading] = useState();
  const [state, setState] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    dni: '',
  });

  const [errors, setErrors] = useState({
    dni: false,
    number: false,
    name: false,
    expiry: false,
    cvc: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maskedValue = applyMask(name, value);
    setState({ ...state, [name]: maskedValue });
  };

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.name });
  };

  const isValidForm = () => {
    const formErrors = { ...errors };

    formErrors.dni = (state.dni.trim().length < 6);
    formErrors.number = (state.number.trim().length < 12);
    formErrors.name = (state.name.trim().length === 0);
    formErrors.expiry = isExpiryDateError(state.expiry);
    formErrors.cvc = (state.cvc.trim().length !== 3);

    setErrors(formErrors);

    return !(formErrors.dni || formErrors.number || formErrors.name || formErrors.expiry || formErrors.cvc);
  };

  const handleContinue = () => {
    if (isValidForm()) {
      setLoading(true);
      setRefinancingContext({
        ...refinancingContext,
        paymentInfo: state,
      });
      history.push({
        pathname: RefinancingRoutesList.summary,
        search: `?hash=${refinancingContext.hash}`,
      });
    }
  };

  const handleBack = () => {
    history.push({
      pathname: RefinancingRoutesList.selfie,
      search: `?hash=${refinancingContext.hash}`,
    });
  };

  const init = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <HeaderNav nomenu />
      {loading && (
        <Loading />
      )}
      {!loading && (
        <>
          <Grid container className={commonClasses.body}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" align="left">
                {i18n.RefinancingPaymentMethod.title}
              </Typography>
              <Typography align="left">
                {parse(fillMessageWith(
                  i18n.RefinancingPaymentMethod.description,
                  moneyFormatter(refinancingContext.debtAmount),
                ))}
              </Typography>
              <Box mt={2} />
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Cards
                    cvc={state.cvc}
                    expiry={state.expiry}
                    focused={state.focus}
                    name={state.name}
                    number={state.number}
                  />
                </Grid>
              </Grid>
              <Box mt={2} />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name="name"
                    label={i18n.RefinancingPaymentMethod.dcName}
                    value={state.name}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.name}
                    helperText={errors.name ? i18n.debitCardFormValidationName : ''}
                  />
                </Grid>
                <Grid item xs={6} className={classes.spacing}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="docType">{i18n.RefinancingPaymentMethod.dcDocType}</InputLabel>
                    <Select
                      labelId="docType"
                      label={i18n.RefinancingPaymentMethod.dcDocType}
                    >
                      {i18n.RefinancingPaymentMethod.dcDocTypes.map((doc) => (
                        <MenuItem value={doc} key={doc}>{doc}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={classes.spacing}>
                  <TextField
                    variant="outlined"
                    name="dni"
                    label={i18n.RefinancingPaymentMethod.dcDocNumber}
                    value={state.dni}
                    inputProps={{ maxLength: 8 }}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.dni}
                    helperText={errors.dni ? i18n.debitCardFormValidationDni : ''}
                  />
                </Grid>
                <Grid item xs={12} className={classes.spacing}>
                  <TextField
                    variant="outlined"
                    name="number"
                    label={i18n.RefinancingPaymentMethod.dcNumber}
                    value={state.number}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.number}
                    helperText={errors.number ? i18n.debitCardFormValidationNumber : ''}
                  />
                </Grid>
                <Grid item xs={6} className={classes.spacing}>
                  <TextField
                    variant="outlined"
                    name="expiry"
                    label={i18n.RefinancingPaymentMethod.dcExpirationDate}
                    fullWidth
                    value={state.expiry}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.expiry}
                    helperText={errors.expiry ? i18n.debitCardFormValidationExpiry : ''}
                  />
                </Grid>
                <Grid item xs={6} className={classes.spacing}>
                  <TextField
                    variant="outlined"
                    name="cvc"
                    label={i18n.RefinancingPaymentMethod.dcCvc}
                    value={state.cvc}
                    fullWidth
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    error={errors.cvc}
                    helperText={errors.cvc ? i18n.debitCardFormValidationCvc : ''}
                  />
                </Grid>
                <Grid item xs={12} className={classes.spacing}>
                  <TextField
                    variant="outlined"
                    name="amount"
                    label={i18n.RefinancingPaymentMethod.amount}
                    value={moneyFormatter(refinancingContext.debtAmount)}
                    fullWidth
                    disabled
                    InputProps={{
                      classes: {
                        input: classes.amount,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={20} />
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid
                container
                className={commonClasses.ctaBox}
                spacing={isMobileOperatingSystem() ? 2 : 0}
                direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    fullWidth
                  >
                    {i18n.RefinancingPaymentMethod.ctaContinue}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleBack}
                    fullWidth
                  >
                    {i18n.RefinancingPaymentMethod.ctaBack}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default RefinancingPaymentMethod;
