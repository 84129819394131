import { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import '../../assets/css/headerStyle.css';

const MenuItems = ({ items }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <li className="menu-items">
      {items.subMenu ? (
        <>
          <button
            type="button"
            className="headerTitles"
            aria-expanded={dropdown ? 'true' : 'false'}
            onMouseEnter={() => setDropdown((prev) => !prev)}
            onMouseLeave={() => setDropdown((prev) => !prev)}
          >
            {items.text}
            {' '}
          </button>
          <Dropdown
            subMenu={items.subMenu}
            dropDown={dropdown}
          />
        </>
      ) : (
        <a
          href={items.path}
          className="headerTitles"
        >
          {items.text}
        </a>
      )}
    </li>
  );
};

MenuItems.propTypes = {
  items: PropTypes.shape().isRequired,
};

export default MenuItems;
