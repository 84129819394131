import { Box, Grid, Typography } from '@material-ui/core';
import Header from '../../../components/common/Header';

/* eslint arrow-body-style: ["error", "always"] */
const WikiHeader = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <Header />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo:
          </Typography>
          <Box m={3} />
          <Header title="Titulo" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo:
          </Typography>
          <Box m={3} />
          <Header title="Titulo" subtitle="Subtitulo" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo + titulo adicional:
          </Typography>
          <Box m={3} />
          <Header title="Titulo" subtitle="Subtitulo" additionalTitle="Titulo adicional" />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiHeader;
