import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import i18n from '../common/i18n';
import { getCRMReasons, createCRMTicket } from '../api/selfieWebService';
import { CLAIMS } from '../utils/referenceConstant';
import UserClaim from '../components/UserClaim';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { getError } from '../utils/functionsUtil';

import { useAuth } from '../contexts/AuthContext';
import { saveCurrentUser } from '../api/userStorage';
import Loading from '../components/common/Loading';

const useStyles = makeStyles({
  borderBottomRow: {
    borderBottom: '2px solid #000',
  },
});

function Claims() {
  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeClaimsOptions, setTypeClaimsOptions] = useState([]);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  // Context
  const { currentUser, setCurrentUser } = useAuth();
  const [userData] = useState({ email: currentUser.customerEmail || '', typeClaimSelect: '', comments: '' });
  const goHome = useHomeNavigation();

  const initSettings = async () => {
    try {
      setInitLoading(true);
      const res = await getCRMReasons();
      setTypeClaimsOptions(res.data);
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };
  useEffect(() => initSettings(), []);

  const onSubmitClaim = async (data) => {
    try {
      setLoading(true);
      const res = await createCRMTicket(currentUser.customerTaxId, data);
      setCurrentUser({ ...currentUser, customerEmail: data.email });
      saveCurrentUser({ ...currentUser, customerEmail: data.email });
      setResponse(res);
    } catch (error) {
      setLoading(false);
      setResponse(getError(error));
    }
  };

  const goToHome = () => {
    goHome();
  };

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initSettings();
  };

  const classes = useStyles();

  return (
    <>
      <Header title={i18n.claimsHeaderTitle} />
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={11} md={7}>
          {response === undefined && initLoading === true && (
            <Loading />
          )}
          {response === undefined && initLoading === false && (

            <UserClaim
              data={userData}
              options={typeClaimsOptions}
              itemLabelKey="customDescription"
              itemKey="id"
              onSubmit={onSubmitClaim}
              loading={loading}
              className={classes.userclaim}
            />
          )}
          {response !== undefined && (
            <MessageResponse
              response={response}
              onSuccessPrimary={goToHome}
              onErrorPrimary={reload}
              referenceLabels={CLAIMS}
              isInitError={initError}
            />
          )}
        </Grid>

      </Grid>

    </>
  );
}

export default Claims;
