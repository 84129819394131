import Payment from 'payment';

export function clearValueOnlyNumbers(value = '') {
  return value.replace(/\D+/g, '');
}

function clearValueOnlyLetters(value) {
  return value.replace(/[^a-zA-Z ]/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearValueOnlyNumbers(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearValueOnlyNumbers(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearValueOnlyNumbers(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatDNI(value) {
  return clearValueOnlyNumbers(value);
}

export function formatName(value) {
  return clearValueOnlyLetters(value);
}

export const applyMask = (field, value) => {
  switch (field) {
    case 'number': return formatCreditCardNumber(value);
    case 'expiry': return formatExpirationDate(value);
    case 'cvc': return formatCVC(value);
    case 'dni': return formatDNI(value);
    case 'name': return formatName(value);
    default: return value;
  }
};

export const isExpiryDateError = (expiry) => {
  let hasError = false;
  if (expiry.trim().length < 5) {
    hasError = true;
  } else {
    const month = expiry.trim().split('/')[0];
    if (month < 1 || month > 12) {
      hasError = true;
    }

    const year = expiry.trim().split('/')[1];
    const currentYear = new Date().getFullYear().toString().substr(-2);

    if (year < currentYear) {
      hasError = true;
    }

    const currentMonth = new Date().getMonth() + 1;
    if (year === currentYear && month <= currentMonth) {
      hasError = true;
    }
  }
  return hasError;
};
