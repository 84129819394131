import PropTypes from 'prop-types';
import { useState, createContext, useContext } from 'react';

const PreLoginContext = createContext(undefined);

export const PreLoginProvider = ({ preLoginData, children }) => {
  const [preLoginContext, setPreLoginContext] = useState(preLoginData);

  return (
    <PreLoginContext.Provider value={{ preLoginContext, setPreLoginContext }}>
      {children}
    </PreLoginContext.Provider>
  );
};

PreLoginProvider.propTypes = {
  preLoginData: PropTypes.shape(),
  children: PropTypes.node.isRequired,

};

PreLoginProvider.defaultProps = {
  preLoginData: null,
};

export const usePreLoginContext = () => useContext(PreLoginContext);
