import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';
import { NAVIGATION_MENU_OPTIONS } from '../../constants/selfieConstants';

const useNavigationMenuController = (onOpenMenu) => {
  const location = useLocation();
  const history = useHistory();
  const { hasRevolvingLine, hasPrepaidCard } = useCustomerAccountStatus();
  const [options, setOptions] = useState();
  const [showMenu, setShowMenu] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const resolveOptions = () => {
    let optionsAux;
    if (hasRevolvingLine()) {
      optionsAux = [
        NAVIGATION_MENU_OPTIONS.home,
        NAVIGATION_MENU_OPTIONS.myLoans,
        NAVIGATION_MENU_OPTIONS.qr,
        NAVIGATION_MENU_OPTIONS.revolvingLine,
        NAVIGATION_MENU_OPTIONS.menu,
      ];
    } else if (hasPrepaidCard()) {
      optionsAux = [
        NAVIGATION_MENU_OPTIONS.home,
        NAVIGATION_MENU_OPTIONS.myLoans,
        NAVIGATION_MENU_OPTIONS.card,
        NAVIGATION_MENU_OPTIONS.menu,
      ];
    } else {
      optionsAux = [
        NAVIGATION_MENU_OPTIONS.home,
        NAVIGATION_MENU_OPTIONS.myLoans,
        NAVIGATION_MENU_OPTIONS.myPayments,
        NAVIGATION_MENU_OPTIONS.menu,
      ];
    }
    setOptions(optionsAux);
    return optionsAux;
  };

  const handleChange = (event, optionAux) => {
    if (optionAux === NAVIGATION_MENU_OPTIONS.menu.label) {
      onOpenMenu();
    } else {
      setSelectedOption(optionAux);
      const option = options.find((opt) => opt.label === optionAux);
      history.push(option.path);
    }
  };

  const init = () => {
    const optionsAux = resolveOptions();
    const currentOpt = optionsAux.find((opt) => opt.path === location.pathname);
    if (currentOpt) {
      setSelectedOption(currentOpt.label);
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  useEffect(init, [location]);

  return {
    options, handleChange, selectedOption, showMenu,
  };
};

export default useNavigationMenuController;
