import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import UploadFile from '../../../components/common/UploadFile';

const WikiUploadFile = () => {
  const methods = useForm();

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <UploadFile
                name="name"
                label="label"
                uploadFileLabelButton="Upload"
                uploadChange={() => { alert('Preview'); }} // eslint-disable-line no-alert
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default WikiUploadFile;
