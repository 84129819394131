import fetch from './api';
import { API_SELFIE, getEnv } from './env';

const urlBackend = getEnv(API_SELFIE);

export const getRefinancingInfo = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/refinancing/${customerTaxId}`,
    method: 'get',
  });

export const getRefinancingInstallmentPlan = (customerTaxId, installmentPlanRequest) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/refinancing/${customerTaxId}/installmentPlan`,
    method: 'post',
    data: installmentPlanRequest,
  });

export const createRefinancingLoanRequest = (customerTaxId, data) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/refinancing/${customerTaxId}/loan`,
    method: 'post',
    data,
  });

export const performRefinancingDownPaymentSignAndActivateLoan = (data) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/refinancing/${data.customerTaxId}/process`,
    method: 'post',
    data,
  });
