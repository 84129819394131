import { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import Toast from '../../../components/common/Toast';

const WikiToast = () => {
  const [openSuccess, setOpenSucess] = useState(false);
  const [openError, setOpenError] = useState(false);

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Button onClick={() => setOpenSucess(true)}>
            Toast Success
          </Button>
          <Button onClick={() => setOpenError(true)}>
            Toast Error
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <Toast
            open={openSuccess}
            onClose={() => { setOpenSucess(false); }}
            messageOnSuccess="Toast Success"
          />
          <Toast
            open={openError}
            onClose={() => { setOpenError(false); }}
            messageOnError="Toast Error"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiToast;
