import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import i18n from '../common/i18n';

const useHomeNavigation = () => {
  const history = useHistory();

  const goToHome = useCallback(() => {
    history.push(getPathRouteFromKey(i18n.homeKey));
  }, []);

  return goToHome;
};

export default useHomeNavigation;
