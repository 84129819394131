import { Button, Grid, Typography } from '@material-ui/core';
import Loading from '../components/common/Loading';
import i18n from '../common/i18n';
import usePushNotificationSubscriptionController from '../customHook/controller/usePushNotificationSubscriptionController';
import LoadingButton from '../components/common/LoadingButton';

const PushNotificationSubscription = () => {
  const {
    globalLoading, loading, subscribe, omit,
  } = usePushNotificationSubscriptionController();

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10} md={6}>
        <img src="/images/notification_icon.svg" alt="NOTIFICACIONES" style={{ marginTop: '50px' }} />
        <Typography variant="h2" component="h1" style={{ marginTop: '50px' }}>
          {i18n.PushNotificationSubscription.title}
        </Typography>
        <Typography style={{ marginTop: '8px', marginBottom: '36px' }}>
          {i18n.PushNotificationSubscription.description}
        </Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          onPress={subscribe}
          style={{ width: '100%' }}
          fullWidth
          loading={loading}
        >
          {i18n.PushNotificationSubscription.acceptCTA}
        </LoadingButton>
        <Button variant="text" onClick={omit} style={{ marginTop: '12px' }} fullWidth>
          {i18n.PushNotificationSubscription.omitCTA}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PushNotificationSubscription;
