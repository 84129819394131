/* eslint-disable no-underscore-dangle */
export const REACT_APP_LENDING_SELFIE_ENDPOINT = 'REACT_APP_LENDING_SELFIE_ENDPOINT';
export const API_SELFIE = 'API_SELFIE';
export const URL_RECHARGE = 'URL_RECHARGE';
export const URL_SERVICES = 'URL_SERVICES';
export const URL_NEW_LOAN = 'URL_NEW_LOAN';
export const URL_NEW_LOAN_FOR_NEW_CLIENT = 'URL_NEW_LOAN_FOR_NEW_CLIENT';
export const URL_DISMISS = 'URL_DISMISS';
export const URL_HELP = 'URL_HELP';
export const URL_WHATSAPP = 'URL_WHATSAPP';
export const URL_SITE_HOME = 'URL_SITE_HOME';
export const URL_SITE_COMMUNITY = 'URL_SITE_COMMUNITY';
export const URL_SITE_WC = 'URL_SITE_WC';
export const URL_SITE_US = 'URL_SITE_US';
export const URL_PAY_ONLINE = 'URL_PAY_ONLINE';
export const GOOGLE_ANALYTICS_ID = 'GOOGLE_ANALYTICS_ID';
export const GOOGLE_TAG_MANAGER_ID = 'GOOGLE_TAG_MANAGER_ID';
export const PREPAID_CARD_STYLE_URL = 'PREPAID_CARD_STYLE_URL';
export const PREPAID_CARD_SUCCESS_URL = 'PREPAID_CARD_SUCCESS_URL';
export const WIKI_AVAILABLE = 'WIKI_AVAILABLE';

export const getEnv = (key) => window.env[key];
