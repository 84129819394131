import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  Box, Grid, Radio,
} from '@material-ui/core';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';

const useStyles = makeStyles({
  subtitle: {
    fontSize: '0.8rem',
  },
});

const RadioButtonLoan = ({
  loanId, installmentCount, currentInstallment, checked, punitive, loan,
}) => {
  const classes = useStyles();

  const getAmountToPay = () =>
    (loan.productId === 46 ? loan.revolvingLineAmount : currentInstallment.totalAmount + punitive);

  const resolveLoanDescription = () =>
    (loan.productId === 46
      ? i18n.resolvingLineOfferMenuLabel : `${i18n.payInstallmentRadioButtonLoanNumber} ${loanId}`);

  return (
    <>
      <Grid container justify="left" display="flex" alignItems="center">
        <Grid item xs={2} style={{ flexBasis: '0' }}>
          <Radio
            checked={checked}
            value={loanId}
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        </Grid>
        <Grid item container xs={10}>
          <Grid item container xs={8}>
            <Grid item xs={12}>
              <Typography align="left">
                <b>
                  {resolveLoanDescription()}
                </b>
              </Typography>
            </Grid>
            {loan.productId !== 46 && (
              <Grid item xs={12}>
                <Typography align="left" className={classes.subtitle}>
                  {fillMessageWith(
                    i18n.payInstallmentRadioButtonIntallmentNumber, currentInstallment.number, installmentCount,
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography align="right">
              <b>
                {moneyFormatter(getAmountToPay())}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
    </>
  );
};

RadioButtonLoan.propTypes = {
  loanId: PropTypes.number.isRequired,
  installmentCount: PropTypes.number.isRequired,
  currentInstallment: PropTypes.shape({
    number: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  punitive: PropTypes.number,
  loan: PropTypes.shape().isRequired,
};

RadioButtonLoan.defaultProps = {
  punitive: 0,
};

export default RadioButtonLoan;
