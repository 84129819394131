import { Grid } from '@material-ui/core';
import CardCustomerDebtorAccordion from '../../../components/CardCustomerDebtorAccordion';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardCustomerDebtorAccordion = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <CardCustomerDebtorAccordion
            totalDebt="500"
            totalInstallmentDebt="3"
            totalPunitiveDebt="150"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardCustomerDebtorAccordion;
