import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import ComputerImage from '../../assets/imgs/computer.svg';
import Header from '../../components/header/Header';
import { URL_NEW_LOAN_FOR_NEW_CLIENT, getEnv } from '../../api/env';

function LoginUserNotClient() {
  const handleOnClickNewLoan = () => {
    window.open(getEnv(URL_NEW_LOAN_FOR_NEW_CLIENT));
  };

  return (
    <>
      <Header />
      <Box m={6} mt={16} />
      <Grid container justify="center">
        <Grid item xs={11} md={8}>
          <img src={ComputerImage} alt="Usuario usando celular" width="150px" />
          <Box m={6} />
          <Typography variant="h2">{i18n.loginNotClientTitle}</Typography>
          <Box m={3} />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={11} sm={3}>
          <Box m={6} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnClickNewLoan}
            fullWidth
          >
            {i18n.loginNotClientCTA}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default LoginUserNotClient;
