import {
  Box, Grid, Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import LoginChangeCellphoneNumber from './loginChangeCellphoneNumber';
import i18n from '../../common/i18n';
import Header from '../../components/header/Header';
import MobileImage from '../../assets/imgs/phone-question.svg';
import LoadingButton from '../../components/common/LoadingButton';
import { validateBiometricResult, generateBiometricOnboardingData } from '../../api/selfieWebService';
import useQueryParam from '../../customHook/useQueryParam';
import Loading from '../../components/common/Loading';
import Toast from '../../components/common/Toast';
import { PublicRoutesList } from '../../utils/menuAndRoutesUtil';
import { saveCurrentLoginData } from '../../api/userStorage';
import { useLoginData } from '../../contexts/LoginDataContext';
import BiometricValidatorPreview from '../../components/BiometricValidatorPreview';
import BiometricValidatorDefaultError from '../../components/BiometricValidatorDefaultError';
import BiometricValidatorNoHit from '../../components/BiometricValidatorNoHit';

const LoginBiometricValidator = () => {
  const queryParam = useQueryParam();
  const [loading, setLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [step, setStep] = useState('home');
  const [codeQrValue, setCodeQrValue] = useState(PublicRoutesList.welcome);
  const [openToast, setOpenToast] = useState(false);
  const history = useHistory();
  const { currentLoginData, setCurrentLoginData } = useLoginData();
  const redirectPath = PublicRoutesList.loginChangeCellphoneNumber;

  const goToVerificationCodeStep = () => {
    history.push(PublicRoutesList.loginVerificationCodeStep);
  };

  const goToHome = () => {
    setStep('home');
  };
  const goToBeforeValidate = () => {
    setStep('beforeValidate');
  };

  const goToHitSucess = () => {
    setIsValidated(true);
  };

  const goToNoHitError = () => {
    setStep('noHitError');
  };

  const goToDefaultError = () => {
    setStep('defaultError');
  };

  const goToValidateBiometricResult = async (externalId, idtx) => {
    setStep('validateBiometric');
    try {
      const res = await validateBiometricResult(idtx, externalId);
      const decision = res.data?.decision;
      switch (decision) {
        case 'HIT': goToHitSucess(); break;
        case 'NO_HIT': goToNoHitError(); break;
        default: goToDefaultError(); break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createHiddenInput = (name, value) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = name;
    i.value = value;
    return i;
  };

  const generateThirdStep = (onboardingData) => {
    const f = document.createElement('form');
    const method = 'post';
    f.setAttribute('method', method);
    f.setAttribute(
      'action',
      onboardingData.url,
    );

    f.appendChild(createHiddenInput('idapp', 'App Cliente'));
    f.appendChild(createHiddenInput('seckey', onboardingData.seckey));
    f.appendChild(createHiddenInput('country', 'ARG'));
    f.appendChild(createHiddenInput('idmaqc_service', onboardingData.idmaqcService));
    f.appendChild(createHiddenInput('profile_services', onboardingData.profileServices));
    f.appendChild(createHiddenInput('services', onboardingData.services));
    f.appendChild(createHiddenInput('externaltxid', onboardingData.externalId));
    f.appendChild(createHiddenInput('dni', onboardingData.customerId));
    f.appendChild(createHiddenInput('sexo', currentLoginData.gender));
    document.body.appendChild(f);
    f.submit();
  };
  const generateValidateBiometricResult = async () => {
    setLoading(true);
    try {
      const res = await generateBiometricOnboardingData(currentLoginData?.customerTaxId, redirectPath);
      generateThirdStep(res.data);
    } catch (error) {
      console.log(error);
      setOpenToast(true);
    }
    setLoading(false);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const init = () => {
    setLoading(false);
    setIsValidated(false);

    if (queryParam.get('customerTaxId')) {
      const auxCurrentLoginData = {
        customerTaxId: queryParam.get('customerTaxId'),
        gender: queryParam.get('gender'),
        fullname: queryParam.get('fullname'),
        hash: queryParam.get('hash') && queryParam.get('hash') !== 'null' ? queryParam.get('hash') : undefined,
      };
      setCurrentLoginData(auxCurrentLoginData);
      saveCurrentLoginData(auxCurrentLoginData);
      goToBeforeValidate();
    } else if (currentLoginData) {
      const codeQr = new URL(window.location.href);
      codeQr.searchParams.append('customerTaxId', currentLoginData.customerTaxId);
      codeQr.searchParams.append('gender', currentLoginData.gender);
      codeQr.searchParams.append('hash', currentLoginData.hash);
      codeQr.searchParams.append('fullname', currentLoginData.fullname);
      setCodeQrValue(codeQr.href);
    } else {
      history.push(PublicRoutesList.welcome);
    }
    // EMULATOR HIT (externaltxid), 4ID HIT (externaltrxId), Both err (externalId)
    if (queryParam.get('externaltxid') || queryParam.get('externalID')) {
      goToValidateBiometricResult(queryParam.get('externaltxid')
      || queryParam.get('externalID') || queryParam.get('externaltrxid'), queryParam.get('idtx'));
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      <Header />
      <Box m={6} mt={16} />
      { !isValidated && step === 'home' && (
      <Grid container justify="center">
        <Grid item xs={11} md={8}>
          <img src={MobileImage} alt="Usuario usando celular" width="100px" />
          <Box m={3} />
          <Typography variant="h2" style={{ fontSize: '2rem' }}>
            {i18n.loginBiometricValidatorTitleStep1}
          </Typography>
          <Box m={3} />
          <Typography variant="h5">{i18n.loginBiometricValidatorSubTitleStep1}</Typography>
        </Grid>
        <Grid item xs={11} md={10}>
          <Box m={3} />
          <Typography variant="h5">{i18n.loginBiometricValidatorProcessDescriptionStep1}</Typography>
        </Grid>
        <Grid item xs={11} md={4}>
          <Box m={3} />
          <LoadingButton
            variant="contained"
            color="primary"
            style={{ width: '100%' }}
            onPress={goToBeforeValidate}
            loading={loading}
          >
            {i18n.loginBiometricValidatorStart}
          </LoadingButton>
        </Grid>
      </Grid>
      )}
      { !isValidated && step === 'beforeValidate' && (
        <BiometricValidatorPreview goBack={goToHome} qrValue={codeQrValue} goNext={generateValidateBiometricResult} />
      )}
      { !isValidated && step === 'validateBiometric' && (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Loading height="30vh" />
          <Typography variant="h5" style={{ fontSize: '2rem' }}>
            {i18n.loginBiometricLoading}
          </Typography>
        </Grid>
      </Grid>
      )}
      { !isValidated && step === 'noHitError' && (
        <BiometricValidatorNoHit subtitle={i18n.loginBiometricValidatorError} goBack={goToBeforeValidate} />
      )}
      { !isValidated && step === 'defaultError' && (
        <BiometricValidatorDefaultError retry={goToBeforeValidate} goBack={goToVerificationCodeStep} />
      )}
      { isValidated && (
        <LoginChangeCellphoneNumber currentLoginData={currentLoginData} />
      )}
      <Toast
        messageOnError={i18n.loginBiometricOnboardingError}
        open={openToast}
        onClose={handleCloseToast}
      />
    </>
  );
};

export default LoginBiometricValidator;
