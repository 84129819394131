import {
  Box,
  Button, makeStyles, Typography,
} from '@material-ui/core';
import i18n from '../common/i18n';
import UserStateOffer from '../assets/imgs/userStateOffer.svg';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { getEnv, URL_NEW_LOAN } from '../api/env';
import '../assets/css/customerStatusStyle.css';

const useStyles = makeStyles((theme) => ({
  oferta: {
    color: 'black',
    background: '#FFF0CD',
    boxShadow: 'none',
  },
  imgSizeOffer: {
    position: 'absolute',
    margin: '0px',
    width: '44px',
    left: '-12%',
    '@media (max-width:600px)': {
      width: '38px',
      left: '-10%',
    },
    '@media (max-width:366px)': {
      width: '36px',
    },
  },
  fontSizeOffer: {
    fontSize: '0.9rem',
    fontWeight: '700',
    lineHeight: '1.1',
    '@media (max-width:600px)': {
      fontSize: '0.8rem',
    },
    '@media (max-width:366px)': {
      fontSize: '0.75rem',
    },
  },
  mora: {
    color: theme.palette.error.main,
  },
  aldia: {
    color: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '10px',
    '@media (max-width:600px)': {
      marginRight: '0px',
    },
  },
  img: {
    display: 'flex',
    overflow: 'visible',
  },
  imgSize: {
    display: 'flex',
    overflow: 'visible',
    width: '23px',
    margin: '5px',
  },
  fontSize: {
    fontSize: '0.8rem',
    '@media (max-width:360px)': {
      fontSize: '0.7rem',
      lineHeight: '1.2',
    },
    '@media (max-width:330px)': {
      fontSize: '0.6rem',
    },
  },
}));
const CustomerStatus = () => {
  const classes = useStyles();
  const { customerAccountStatus } = useCustomerAccountStatus();

  const redirectToNewLoanOffer = () => {
    if (customerAccountStatus?.loanOffer.hashKey) {
      window.open(getEnv(URL_NEW_LOAN) + customerAccountStatus.loanOffer.hashKey, '_SELF');
    }
  };

  return (
    <>
      { customerAccountStatus?.loanOffer && (
        <Box className="fadeInEffect">
          <Button className={classes.oferta} variant="contained" onClick={redirectToNewLoanOffer}>
            <img src={UserStateOffer} alt="Tenés una oferta" className={classes.imgSizeOffer} />
            <Typography className={classes.fontSizeOffer}>
              &nbsp;
              {i18n.customerStatusNewOffer}
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
};

export default CustomerStatus;
