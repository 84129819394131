import { useState, createContext, useContext } from 'react';

import PropTypes from 'prop-types';

const CustomerAccountStatusContext = createContext(undefined);
// wrapper for the provider
export const CustomerStatusProvider = ({ customerStatus, children }) => {
  const [customerAccountStatus, setCustomerAccountStatus] = useState(customerStatus);

  const hasDebt = () => !customerAccountStatus?.customerIsUpToDate;
  const getTotalDebt = () => customerAccountStatus?.totalDebt;
  const hasRevolvingLine = () =>
    customerAccountStatus?.customerRevolvingLine?.maxAmount || customerAccountStatus?.customerRevolvingLine?.campaignOffer;
  const getRevolvingLine = () => customerAccountStatus?.customerRevolvingLine;
  const hasPrepaidCard = () => customerAccountStatus?.customerHasPrepaidCard;
  const hasPrepaidCardToActivate = () => customerAccountStatus?.customerHasPrepaidCardToActivate;
  const getLegalStudyStatus = () => customerAccountStatus?.managmentLegalStudyStatus;
  const getLegalStudyContact = () => customerAccountStatus?.legalStudyContact;

  return (
    <CustomerAccountStatusContext.Provider value={{
      customerAccountStatus,
      setCustomerAccountStatus,
      hasDebt,
      getTotalDebt,
      hasRevolvingLine,
      getRevolvingLine,
      hasPrepaidCard,
      hasPrepaidCardToActivate,
      getLegalStudyStatus,
      getLegalStudyContact,
    }}
    >
      {children}
    </CustomerAccountStatusContext.Provider>
  );
};
CustomerStatusProvider.propTypes = {
  customerStatus: PropTypes.shape({}),
  children: PropTypes.node.isRequired,

};
CustomerStatusProvider.defaultProps = {
  customerStatus: null,
};
export const useCustomerAccountStatus = () => useContext(CustomerAccountStatusContext);
