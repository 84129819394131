import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';

const PrePaidLoanNotActive = () => {
  const goHome = useHomeNavigation();
  const history = useHistory();
  const {
    customerAccountStatus: {
      customerIsInBlacklist = false,
    } = {},
  } = useCustomerAccountStatus() || {};

  const goToNewLoan = () => {
    history.push(getPathRouteFromKey(i18n.newLoanKey));
  };

  const goToHome = () => {
    goHome();
  };

  return (
    <>
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={11} md={11}>
          <Typography variant="h2"><b>{i18n.prePaidLoanActionNotAvailableTitle}</b></Typography>
          <Box m={6} />
          {!customerIsInBlacklist && (
            <Typography variant="h5">{i18n.prePaidLoanActionNotAvailableSubTitle}</Typography>
          )}
        </Grid>
        <Grid container justify="center" spacing={3} style={{ marginTop: '3em' }}>
          {!customerIsInBlacklist && (
            <Grid item xs={11} sm={6} md={3}>
              <Button
                onClick={goToNewLoan}
                variant="outlined"
              >
                {i18n.prePaidLoanActionNotAvailableCTA}
              </Button>
            </Grid>
          )}
          <Grid item xs={11} sm={6} md={3}>
            <Button
              onClick={goToHome}
              variant="outlined"
            >
              {i18n.backToTop}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default PrePaidLoanNotActive;
