import fetch from './api';
import { API_SELFIE, getEnv } from './env';

const urlBackend = getEnv(API_SELFIE);

export const subscribe = (data) =>
  fetch({
    baseURL: urlBackend,
    url: '/push-notifications/subscribe',
    method: 'post',
    data,
  });

export default subscribe;
