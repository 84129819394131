import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import PaymentMethodBankTrx from './paymentMethodExplanations/PaymentMethodBankTrx';
import PaymentMethodBank from './paymentMethodExplanations/PaymentMethodBank';
import PaymentMethodCash from './paymentMethodExplanations/PaymentMethodCash';
import PaymentMethodOnline from './paymentMethodExplanations/PaymentMethodOnline';
import i18n from '../common/i18n';

const PaymentMethodExplanation = ({ paymentMethodKey }) => (
  <Grid container justify="center">
    <Grid item xs={12} md={8}>

      {paymentMethodKey === i18n.payInstallmentOnlineKey && (<PaymentMethodOnline />)}
      {paymentMethodKey === i18n.payInstallmentCashKey && (<PaymentMethodCash />)}
      {paymentMethodKey === i18n.payInstallmentBankTrxKey && (<PaymentMethodBankTrx />)}
      {paymentMethodKey === i18n.payInstallmentEPaymentKey && (<PaymentMethodBank />)}
    </Grid>
  </Grid>
);

PaymentMethodExplanation.propTypes = {
  paymentMethodKey: PropTypes.string.isRequired,
};

export default PaymentMethodExplanation;
