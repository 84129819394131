import { Redirect, Route, Switch } from 'react-router-dom';
import { PaymentQRProvider } from '../contexts/PaymentQRContext';
import PaymentQRInstalmentPlan from '../pages/paymentQR/paymentQRInstallmentPlan';
import PaymentQRScanner from '../pages/paymentQR/paymentQRScanner';
import PaymentQRSelectAmount from '../pages/paymentQR/paymentQRSelectAmount';
import PaymentQRVoucher from '../pages/paymentQR/PaymentQRVoucher';
import { PaymentQRRoutesList, PublicRoutesList } from '../utils/menuAndRoutesUtil';

const PaymentQRRouter = () => (
  <PaymentQRProvider>
    <Switch>
      <Route exact path={PaymentQRRoutesList.home}>
        <PaymentQRScanner />
      </Route>
      <Route exact path={PaymentQRRoutesList.selectAmount}>
        <PaymentQRSelectAmount />
      </Route>
      <Route exact path={PaymentQRRoutesList.installmentPlan}>
        <PaymentQRInstalmentPlan />
      </Route>
      <Route exact path={PaymentQRRoutesList.voucher}>
        <PaymentQRVoucher />
      </Route>
      <Route>
        <Redirect from="*" to={PublicRoutesList.welcome} />
      </Route>
    </Switch>
  </PaymentQRProvider>
);

export default PaymentQRRouter;
