import { Fragment, useEffect, useState } from 'react';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import CardOfferWrapper from './CardOfferWrapper';
import ClickeableBanner from './ClickeableBanner';
import { useAuth } from '../contexts/AuthContext';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';

const useStyles = makeStyles(() => ({
  sliderBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    cursor: 'pointer',
    marginLeft: '5px',
    marginRight: '5px',
    justifyContent: 'center',
  },
}));

const BannerSlider = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { currentUser } = useAuth();
  const { customerAccountStatus } = useCustomerAccountStatus();
  const [bannerList, setBannerList] = useState([]);

  const openCustomBannerLink = (banner) => {
    if (banner.link) {
      window.open(banner.link, '_blank');
    }
  };

  const showBannerOffer = () =>
    (currentUser.lastPendingLoan || customerAccountStatus.renewersCampaignOffer || customerAccountStatus.loanOffer)
    && customerAccountStatus.customerIsUpToDate;

  const showBannerMarketing = () =>
    currentUser.customBannerList && currentUser.customBannerList.length > 0;

  const getBannerOffer = () => (
    <Box className={classes.sliderBox}>
      <CardOfferWrapper />
    </Box>
  );

  const getBannerMarketing = (banner) => (
    <Box className={classes.sliderBox}>
      <ClickeableBanner
        onClick={() => openCustomBannerLink(banner)}
        imageUrl={isMobile ? banner.imageMobile : banner.image}
      />
    </Box>
  );

  const init = () => {
    const map = new Map();

    if (showBannerMarketing()) {
      currentUser.customBannerList.forEach((banner) => map.set(banner.index, getBannerMarketing(banner)));
    }
    if (showBannerOffer()) {
      map.set(20, getBannerOffer());
    }

    const list = [];
    const bannerKeyList = Array.from(map.keys()).sort();
    bannerKeyList.forEach((k) => list.push(map.get(k)));
    setBannerList(list);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {bannerList.length > 0 && (
        <Box mt={3}>
          <Carousel
            swipeable
            showArrows={false}
            showStatus={false}
            centerMode={bannerList.length > 1}
            centerSlidePercentage={90}
            showIndicators={!isMobile}
          >
            {bannerList.map((banner) => (
              <Fragment key={banner}>
                {banner}
              </Fragment>
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
};

export default BannerSlider;
