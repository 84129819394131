import { Box, Grid, Typography } from '@material-ui/core';
import imgHelp from '../../../assets/imgs/ayuda.svg';
import CardButtonPrepaidCard from '../../../components/CardButtonPrepaidCard';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardButtonPrepaidCard = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <CardButtonPrepaidCard />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title:
          </Typography>
          <Box m={3} />
          <CardButtonPrepaidCard title="Title" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle:
          </Typography>
          <Box m={3} />
          <CardButtonPrepaidCard title="Title" subtitle="Subtitle" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle + image:
          </Typography>
          <Box m={3} />
          <CardButtonPrepaidCard title="Title" subtitle="Subtitle" urlImage={imgHelp} />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Title + Subtitle + image + onClick:
          </Typography>
          <Box m={3} />
          <CardButtonPrepaidCard
            title="Title"
            subtitle="Subtitle"
            urlImage={imgHelp}
            onClick={() => { alert('hola'); }} // eslint-disable-line no-alert
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardButtonPrepaidCard;
