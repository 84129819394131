import { Grid } from '@material-ui/core';
import CardHome from '../../../components/CardHome';
import imgHelp from '../../../assets/imgs/ayuda.svg';

/* eslint arrow-body-style: ["error", "always"] */
const WikiCardHome = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <CardHome title="Title" urlImage={imgHelp} pathOnClick="http://www.google.com.ar" isExternalLink />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiCardHome;
