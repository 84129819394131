import parse from 'html-react-parser';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Typography, Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { setTimeout } from 'timers';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from '../common/i18n';
import { loginVerificationCodeSchema } from '../forms/schemaValidations/schemaValidations';
import LoadingButton from './common/LoadingButton';
import { fillMessageWith } from '../utils/functionsUtil';
import useStyle from '../pages/login/loginStyle';
import CodeVerificationInput from './common/CodeVerificationInput';

const LoginVerificationCodeStep = ({
  onSubmit, loading, invalidCodeAlert, onReSendVerificationCode, customerEmail, onBack,
}) => {
  const classes = useStyle();
  const methods = useForm({
    resolver: yupResolver(loginVerificationCodeSchema),
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const timeLimit = 60;
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [resendButtonClicked, setResendButtonClicked] = useState(false);

  const resendCode = () => {
    onReSendVerificationCode();
    setTimeLeft(timeLimit);
    setResendButtonClicked(true);
  };

  useEffect(() => {
    // Set to "true" when component is mounted
    let mounted = true;
    setTimeout(() => {
      // Check if the component is still mounted
      if (mounted) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    // Set to "false" when the component is unmounted
    return () => {
      mounted = false;
    };
  }, [timeLeft]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Typography align="left" style={{ marginTop: '32px', marginBottom: '16px' }}>
            <b>{i18n.loginStepVerificationCodeInputCodeLabel}</b>
          </Typography>
          <Box style={{ textAlign: 'left' }}>
            <CodeVerificationInput name="smsCode" size={4} type="number" />
          </Box>
          {invalidCodeAlert === true && (
            <>
              <Box m={1} />
              <Alert severity="error">{i18n.loginStepVerificationCodeInvalidCode}</Alert>
            </>
          )}
          {timeLeft < 1 ? (
            <Button
              onClick={resendCode}
              style={{ display: 'flex', padding: '0px', marginTop: '20px' }}
            >
              {i18n.loginStepVerificationCodeReSendCode}
            </Button>
          ) : (
            <>
              <Typography align="left" style={{ marginTop: '20px' }}>
                {parse(fillMessageWith(i18n.loginStepVerificationCodeMessage, [timeLeft]))}
              </Typography>
              <Box m={3} />
              { resendButtonClicked && customerEmail && (
                <>
                  <Alert icon={false} severity="info">
                    <div className="alert-email">
                      {fillMessageWith(i18n.loginStepVerificationCodeMessageToEmail, [customerEmail])}
                    </div>
                  </Alert>
                </>
              )}
            </>
          )}
          <Grid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            className={classes.ctaContainer}
          >
            <Grid item md={4} />
            <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Button
                onClick={onBack}
                variant={isMobile ? 'text' : 'outlined'}
                style={{ height: '45px' }}
              >
                {i18n.loginStepVerificationCodeBack}
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                type="submit"
                loading={loading}
              >
                {i18n.loginStepVerificationCodeCTA}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

LoginVerificationCodeStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  invalidCodeAlert: PropTypes.bool.isRequired,
  onReSendVerificationCode: PropTypes.func.isRequired,
  goToCodeNoReception: PropTypes.func.isRequired,
  customerEmail: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default LoginVerificationCodeStep;
