import {
  Box,
  Grid, TextField, Typography,
} from '@material-ui/core';
import { useMediaQuery } from '@react-hook/media-query';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import LoadingButton from './common/LoadingButton';

const DebitCardPreForm = ({ debitCardData, onCTA }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Grid container justify="center" alignContent="center">
        <Grid item container xs={11} spacing={1} justify="center">
          <Grid item xs={12} md={4}>
            <Box m={isMobile ? 3 : 6} />
            <Typography variant="h2" align="center" style={{ fontFamily: 'Open sans' }}>
              {i18n.debitCardPreFormTitle}
            </Typography>
            <Box m={2} />
            <TextField
              disabled
              label={debitCardData.debitCardNumber}
              name=""
              variant="outlined"
              fullWidth
            />
            <Box m={2} />
            <Typography variant="h5">
              {i18n.debitCardPreFormSubtitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={11} spacing={1} justify="center">
          <Grid item xs={12} md={4}>
            <Box m={4} />
            <LoadingButton
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
              onPress={onCTA}
            >
              {i18n.debitCardPreFormCTA}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

DebitCardPreForm.propTypes = {
  debitCardData: PropTypes.shape({
    customerTaxId: PropTypes.string.isRequired,
    debitCardNumber: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    names: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    loansQuantity: PropTypes.number.isRequired,
  }).isRequired,
  onCTA: PropTypes.func.isRequired,
};

export default DebitCardPreForm;
