import { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  Box, Card, CardActionArea, CardContent, Grid, Typography, makeStyles,
} from '@material-ui/core';
import Header from './common/Header';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';
import ActionFooterFixed from './common/ActionFooterFixed';

const useStyles = makeStyles(() => ({
  selectedCard: {
    border: '2px solid #FBB615',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    color: '#FBB615',
    fontSize: '30px',
  },
  detailContainer: {
    textAlign: 'left',
  },
  detail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const RevolvingLineInstallmentsStep = ({ amount, installments, onContinue }) => {
  const classes = useStyles();
  const [selectedInstallment, setSelectedInstallment] = useState();

  const selectInstallment = (installment) => {
    setSelectedInstallment(installment);
  };

  const isSelected = (installment) => selectedInstallment?.installment === installment.installment;

  const handleOnContinue = () => onContinue(selectedInstallment);

  return (
    <>
      <Header title={i18n.RevolvingLine.Header.title} />
      <Box mt={5} mb={10}>
        <Grid container justify="center">
          <Grid item xs={10}>
            <Typography variant="h2">
              {parse(fillMessageWith(i18n.RevolvingLine.InstallmentsStep.title, moneyFormatter(amount)))}
            </Typography>
            <Box mt={5} />
            <Typography style={{ marginBottom: '20px' }}>{i18n.RevolvingLine.InstallmentsStep.subtitle}</Typography>
            {installments.map((installment) => (
              <Box key={installment.installment} mb={2}>
                <Card
                  className={isSelected(installment) ? classes.selectedCard : classes.card}
                  variant="outlined"
                >
                  <CardActionArea onClick={() => selectInstallment(installment)}>
                    <CardContent>
                      <Grid container justify="center">
                        <Grid item xs={12}>
                          <Box className={classes.cardContent}>
                            <Typography variant="h5" component="h2" className={classes.cardTitle}>
                              {parse(fillMessageWith(
                                installment.installment > 1
                                  ? i18n.RevolvingLine.InstallmentsStep.installments[1]
                                  : i18n.RevolvingLine.InstallmentsStep.installments[0],
                                installment.installment,
                                moneyFormatter(installment.amount),
                              ))}
                            </Typography>
                            {isSelected(installment) && <CheckCircleOutlineIcon className={classes.icon} />}
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            ))}
          </Grid>
          {selectedInstallment && (
            <Grid item xs={10}>
              <Box className={classes.detailContainer}>
                <Typography>
                  <b>
                    {selectedInstallment.installment > 0
                      ? i18n.RevolvingLine.InstallmentsStep.details[1]
                      : i18n.RevolvingLine.InstallmentsStep.details[0]}
                  </b>
                </Typography>
                <hr />
                <Box className={classes.detail}>
                  <Typography>{i18n.RevolvingLine.InstallmentsStep.interest}</Typography>
                  <Typography>{moneyFormatter(selectedInstallment.interests)}</Typography>
                </Box>
                <Box className={classes.detail}>
                  <Typography>{i18n.RevolvingLine.InstallmentsStep.tax}</Typography>
                  <Typography>{moneyFormatter(selectedInstallment.taxes)}</Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <ActionFooterFixed
        ctaLabel={i18n.RevolvingLine.InstallmentsStep.ctaContinue}
        onCTA={handleOnContinue}
        disabled={!selectedInstallment}
      />
    </>
  );
};

RevolvingLineInstallmentsStep.propTypes = {
  amount: PropTypes.number.isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default RevolvingLineInstallmentsStep;
