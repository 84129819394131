/* eslint-disable indent */
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import {
  Redirect, Route,
} from 'react-router-dom';
import i18n from '../common/i18n';
import { useAuth } from '../contexts/AuthContext';
import { useLoginData } from '../contexts/LoginDataContext';
import { getPathRouteFromKey, getPathRouteIfRequireRedirect, isDirectPathRoute } from '../utils/menuAndRoutesUtil';
import useQueryParam from '../customHook/useQueryParam';

const PublicRoute = ({ children, path, ...props }) => {
  const { currentUser } = useAuth();
  const { currentLoginData } = useLoginData();
  const queryParam = useQueryParam();
  const redirectUrl = queryParam.get('redirectUrl');

  const isUserLogged = () => currentUser !== undefined && currentUser !== null;

  const resolveComponentToGo = (location) => {
    // user logged and is not direct link
    if (isUserLogged() && !isDirectPathRoute(location.pathname)) {
      return (
        <Redirect
          to={{
            pathname: redirectUrl || getPathRouteFromKey(i18n.homeKey),
            status: 302,
          }}
        />
      );
    }
    // when route need redirect
    const pathToRedirect = getPathRouteIfRequireRedirect(location.pathname, { ...currentLoginData });
    if (location.pathname !== pathToRedirect) {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: { from: location },
          }}
        />
      );
    }
    // original route
    ReactGA.send({ hitType: 'pageview', page: path });
    return children;
  };

  return (
    <Route
      {...props}
      render={({ location }) => resolveComponentToGo(location)}
    />
  );
};

PublicRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};
PublicRoute.defaultProps = {
  children: undefined,
  path: undefined,
};
export default PublicRoute;
