import PropTypes from 'prop-types';
import {
  Card, CardActionArea, Grid, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderColor: theme.palette.secondary.main,
    textTransform: 'none',
    height: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
  },
  cardTitle: {
    fontFamily: 'Open sans',
    fontWeight: 700,
    textAlign: 'left',
    fontSize: '14px',
  },
  cardSubtitle: {
    fontFamily: 'Open sans',
    fontWeight: 400,
    textAlign: 'left',
    fontSize: '14px',
  },
  alignCenter: {
    margin: 'auto',
  },
  containerCenterImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundOrange: {
    backgroundColor: '#FFF9E5',
    height: '56px',
  },
}));

function CardButtonPrepaidCard({
  title, subtitle, urlImage, onClick, fullSizeBackgroundColor,
}) {
  const classes = useStyles();
  const backgroundOrange = { backgroundColor: '#FFF9E5' };

  return (
    <Card
      className={classes.card}
      variant="outlined"
      style={fullSizeBackgroundColor ? backgroundOrange : {}}
    >
      <CardActionArea
        onClick={onClick}
        className={classes.backgroundOrange}
        style={fullSizeBackgroundColor ? {} : backgroundOrange}
      >
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={2} sm={1} className={classes.containerCenterImg}>
            <img src={urlImage} className="img-celular" height="20px" alt="" />
          </Grid>
          <Grid item xs={10} sm={11} className={classes.alignCenter}>
            <Typography variant="h5" component="h2" className={classes.cardTitle}>
              {title}
            </Typography>
            <Typography variant="h5" component="h2" className={classes.cardSubtitle}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
CardButtonPrepaidCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  urlImage: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fullSizeBackgroundColor: PropTypes.bool,
};
CardButtonPrepaidCard.defaultProps = {
  title: '',
  subtitle: '',
  fullSizeBackgroundColor: false,
};
export default CardButtonPrepaidCard;
