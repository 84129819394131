import {
  Box, Grid, Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from '../../../components/common/TextInput';

const WikiTextInput = () => {
  const methods = useForm();

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <Typography variant="h5" noWrap>
                TextInput:
              </Typography>
              <Box m={3} />
              <TextInput name="textInput" label="textInput" />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default WikiTextInput;
