import {
  Box, makeStyles, Typography, Button, Grid,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@react-hook/media-query';
import { Alert } from '@material-ui/lab';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { getPrepaidCardInfo } from '../api/selfieWebService';
import PrepaidCardData from '../components/PrepaidCardData';
import PrepaidCardBoxActions from '../components/PrepaidCardBoxActions';
import PrepaidCardMovements from '../components/PrepaidCardMovements';
import Toast from '../components/common/Toast';
import Header from '../components/common/Header';
import { useAuth } from '../contexts/AuthContext';
import Loading from '../components/common/Loading';
import Exclamation from '../assets/imgs/exclamation-yellow.svg';
import CardButtonPrepaidCard from '../components/CardButtonPrepaidCard';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import {
  PREPAID_CARD_STATUS_ACTIVE, PREPAID_CARD_STATUS_DISABLED, PREPAID_CARD_STATUS_BLOCKED,
} from '../utils/PrepaidCardStatusConstants';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
    marginBottom: '20px',
    color: 'white',
  },
  principalColor: {
    color: theme.palette.primary.main,
  },
  backgroundGray: {
    backgroundColor: '#F8F8F8',
    height: '100%',
  },
  fontW800: {
    fontWeight: '800',
  },
  fontW700: {
    fontWeight: '700',
  },
  fontW600: {
    fontWeight: '600',
  },
}));

function PrepaidCard() {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { currentUser } = useAuth();
  const [prepaidCardInfo, setPrepaidCardInfo] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [viewCardInfo, setViewCardInfo] = useState(true);
  const [initLoading, setInitLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const goToCardDetails = () => {
    history.push(getPathRouteFromKey(i18n.myCardDetailsKey));
  };

  const goToActivateCard = () => {
    setPrepaidCardInfo({ ...prepaidCardInfo, card: { ...prepaidCardInfo.card, status: PREPAID_CARD_STATUS_ACTIVE } });
    history.push(getPathRouteFromKey(i18n.myCardActivateKey));
  };

  const goToReprintCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardReprintKey));
  };

  const initPrepaidCard = async () => {
    try {
      setInitLoading(true);
      const res = await getPrepaidCardInfo(currentUser.customerTaxId);
      if (res.data) {
        await setPrepaidCardInfo(res.data);
      }
      setInitLoading(false);
    } catch (error) {
      setToastMessage(i18n.PrepaidCardGetCardInfoErrorMessage);
      setOpenToast(true);
    }
  };

  useEffect(() => initPrepaidCard(), []);

  return (
    <div
      className={isMobile && prepaidCardInfo !== undefined ? classes.backgroundGray : ''}
    >
      {(prepaidCardInfo !== undefined) && (
        <Header
          title={i18n.PrepaidCardHeaderTitle}
          subtitle={viewCardInfo ? i18n.PrepaidCardHeaderSubtitle : undefined}
          additionalTitle={viewCardInfo
            ? moneyFormatterWithoutDecimals(prepaidCardInfo.account?.balance) : undefined}
        />
      )}

      {(prepaidCardInfo === undefined || initLoading === true) ? (<Loading />)
        : (
          <>
            <Box m={3} />
            <Container maxWidth="lg">
              {/* tiene usuario y cuenta pero todavia ninguna tarjeta */}
              {(prepaidCardInfo.card?.status !== PREPAID_CARD_STATUS_ACTIVE
                && prepaidCardInfo.card?.status !== PREPAID_CARD_STATUS_BLOCKED
                && prepaidCardInfo.card?.status !== PREPAID_CARD_STATUS_DISABLED) && (
                <>
                  <Box m={3} />
                  <Grid container justify="center">
                    <Grid item xs={8} sm={6} md={5} lg={4}>
                      <Typography>{i18n.PrepaidCardActivateCardText}</Typography>
                      <Box m={3} />
                      <Button onClick={goToActivateCard} variant="contained" color="primary" fullWidth>
                        {i18n.PrepaidCardActivateCardButton}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Container>
            <Container maxWidth="lg" className={isMobile ? classes.backgroundGray : ''}>
              {/* tiene tarjeta activa */}
              {prepaidCardInfo.card?.status === PREPAID_CARD_STATUS_ACTIVE && viewCardInfo && (
                <>
                  <PrepaidCardData
                    title={i18n.PrepaidCardActivatedCardBoxTitle}
                    lastFourDigits={prepaidCardInfo.card.lastFour}
                    onClick={goToCardDetails}
                  />
                  <PrepaidCardBoxActions
                    cardInfo={prepaidCardInfo}
                    setCardInfo={setPrepaidCardInfo}
                    setLoading={setInitLoading}
                    dialog={{ openDialog, setOpenDialog }}
                  />
                  {prepaidCardInfo.card.associated !== true && (
                  <>
                    <Box m={3} />
                    <Grid container justify="center">
                      <Alert severity="error">
                        {i18n.PrepaidCardNotAssociated}
                      </Alert>
                    </Grid>
                    <Box m={3} />
                  </>
                  )}
                  <Grid container justify="center">
                    <Grid item xs={12} sm={6} md={5} lg={4}>
                      <>
                        {/*  si tiene un envio en curso, le ofrece la activación */}
                        {prepaidCardInfo.activeShipmentRequest && (
                          <>
                            <Box m={3} />
                            <Grid container justify="center">
                              <Typography>
                                {i18n.PrepaidCardBannedCardBoxTrackingUrlMsg}
                              </Typography>
                              {/* si tiene la url de seguimiento de envio, se muestra */}
                              {prepaidCardInfo.trackingUrl && (
                                <>
                                  <Typography>
                                    {i18n.PrepaidCardBannedCardBoxTrackingUrlMsgLink}
                                    <a href={prepaidCardInfo.trackingUrl} className={classes.principalColor}>
                                      {i18n.PrepaidCardBannedCardBoxTrackingUrlMsgLinkToClick}
                                    </a>
                                  </Typography>
                                  <Box m={2} />
                                </>
                              )}
                              <Typography>
                                {i18n.PrepaidCardBannedCardBoxActivateText}
                              </Typography>
                            </Grid>
                            <Box m={3} />
                            <Button onClick={goToActivateCard} variant="contained" color="primary" fullWidth>
                              {i18n.PrepaidCardActivateNewCardButton}
                            </Button>
                            <Box m={3} />
                          </>
                        )}
                      </>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* tiene tarjeta pausada (bloqued) */}
              {prepaidCardInfo.card?.status === PREPAID_CARD_STATUS_BLOCKED && viewCardInfo && (
                <>
                  <CardButtonPrepaidCard
                    subtitle={i18n.PrepaidCardPausedCardBoxTitle}
                    onClick={() => console.log('Detalles del pausado')}
                    urlImage={Exclamation}
                  />
                  <PrepaidCardBoxActions
                    cardInfo={prepaidCardInfo}
                    setCardInfo={setPrepaidCardInfo}
                    setLoading={setInitLoading}
                    dialog={{ openDialog, setOpenDialog }}
                  />
                </>
              )}
              {/* tiene tarjeta bloqueada/baneada (disabled) */}
              {prepaidCardInfo.card?.status === PREPAID_CARD_STATUS_DISABLED && viewCardInfo && (
                <>
                  <CardButtonPrepaidCard
                    title={i18n.PrepaidCardBannedCardBoxTitle}
                    subtitle={i18n.PrepaidCardBannedCardBoxSubtitle}
                    onClick={() => console.log('Detalles del bloque')}
                    urlImage={Exclamation}
                    fullSizeBackgroundColor
                  />
                  <Box m={3} />
                  <Grid container justify="center">
                    <Grid item xs={12} sm={6} md={5} lg={4}>
                      <>
                        {/*  si ya pidio reimpresion, le ofrece la activación */}
                        { prepaidCardInfo.activeShipmentRequest ? (
                          <>
                            <Grid container justify="center">
                              <Typography>
                                {i18n.PrepaidCardBannedCardBoxTrackingUrlMsg}
                              </Typography>
                              {/* si tiene la url de seguimiento de envio, se muestra */}
                              {prepaidCardInfo.trackingUrl && (
                                <>
                                  <Typography>
                                    {i18n.PrepaidCardBannedCardBoxTrackingUrlMsgLink}
                                    <a href={prepaidCardInfo.trackingUrl} className={classes.principalColor}>
                                      {i18n.PrepaidCardBannedCardBoxTrackingUrlMsgLinkToClick}
                                    </a>
                                  </Typography>
                                  <Box m={2} />
                                </>
                              )}
                              <Typography>
                                {i18n.PrepaidCardBannedCardBoxActivateText}
                              </Typography>
                            </Grid>
                            <Box m={3} />
                            <Button onClick={goToActivateCard} variant="contained" color="primary" fullWidth>
                              {i18n.PrepaidCardActivateNewCardButton}
                            </Button>
                          </>
                        ) : (
                          <>
                            {/* si no pidio la reimpresion, le ofrece pedirla */}
                            <Button
                              onClick={goToReprintCard}
                              variant="outlined"
                              color="primary"
                              fullWidth
                              className={classes.fontW700}
                            >
                              {i18n.PrepaidCardBlockedRequestReprintButton}
                            </Button>
                          </>
                        )}
                      </>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* se muestran los movimientos */}
              {prepaidCardInfo?.movements?.length > 0 && (
              <>
                <PrepaidCardMovements
                  paymentHistory={prepaidCardInfo.movements}
                  paymentHistoryLast={prepaidCardInfo.movements.filter(((m) => m.result === 'APPROVED')).slice(0, 5)}
                  viewAllHistory={!viewCardInfo}
                  onCTABack={() => { setViewCardInfo(true); }}
                  onCTAViewAll={() => { setViewCardInfo(false); }}
                />
              </>
              )}
            </Container>

            {openToast && (
              <Toast
                messageOnError={toastMessage}
                open={openToast}
                onClose={handleCloseToast}
              />
            )}
          </>
        )}
    </div>
  );
}

export default PrepaidCard;
