import {
  Box,
  Button, Grid, Typography,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import { useIntroduction } from '../../services/preLoginService';
import useStyles from './preLoginStyle';

const Introduction = () => {
  const classes = useStyles();
  const {
    ready, mobile, step, nextStep, handleOmit,
  } = useIntroduction();

  const renderMobile = () => (
    <Grid container className={classes.containerMobile} justify="center">
      <Grid item xs={12} sm={12}>
        <img src={step.progress} alt="progress" className={classes.progress} />
        <img src={step.imgMobile} alt="mobile" className={classes.banner} />
      </Grid>
      <Grid item xs={10} sm={10}>
        <Typography variant="h1">{step.title}</Typography>
        <Typography>{step.description}</Typography>
        <Box className={classes.ctaMobile}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '40px' }}
            onClick={nextStep}
          >
            {i18n.Introduction.nextCTA}
          </Button>
          <Button
            variant="text"
            color="primary"
            fullWidth
            style={{ marginTop: '8px' }}
            onClick={handleOmit}
          >
            {i18n.Introduction.omitCTA}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  const renderDesktop = () => (
    <Grid container className={classes.containerDesktop}>
      <Grid item md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img src={step.imgDesktop} alt="phone" />
      </Grid>
      <Grid item md={5} alignContent="center">
        <Typography variant="h1">{step.title}</Typography>
        <Typography>{step.description}</Typography>
        <Grid container style={{ marginTop: '50px' }}>
          <Grid item md={4} />
          <Grid item md={4}>
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={handleOmit}
            >
              {i18n.Introduction.omitCTA}
            </Button>
          </Grid>
          <Grid item md={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={nextStep}
            >
              {i18n.Introduction.nextCTA}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (!ready) return null;

  return (
    <>
      {mobile ? renderMobile() : renderDesktop() }
    </>
  );
};

export default Introduction;
