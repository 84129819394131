import {
  Box, Grid, Typography, makeStyles, Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import i18n from '../../common/i18n';
import MobileImage from '../../assets/imgs/phone-question.svg';
import { loginChangeCellphoneSchema } from '../../forms/schemaValidations/schemaValidations';
import TextInput from '../../components/common/TextInput';
import LoadingButton from '../../components/common/LoadingButton';
import { sendVerificationCodeNewPhone, updateCellphone } from '../../api/selfieWebService';
import LoginVerificationCodeStep from '../../components/LoginVerificationCodeStep';
import MessageResponse from '../../components/common/MessageResponse';
import { CELLPHONE_UPDATED } from '../../utils/referenceConstant';
import useHomeNavigation from '../../customHook/useHomeNavigation';
import { ERROR_UPDATE_CUSTOMER_CELLPHONE } from '../../utils/errorCodeConstant';

const useStyles = makeStyles({
  welcome: {
    fontSize: '1.5rem',
  },
  h3: {
    fontFamily: 'Poppins',
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  h4: {
    fontFamily: 'Poppins',
    fontSize: '1.3rem',
  },
});

function LoginChangeCellphoneNumber({ currentLoginData }) {
  const classes = useStyles();

  const methods = useForm({
    resolver: yupResolver(loginChangeCellphoneSchema),
  });

  const goHome = useHomeNavigation();

  const [loading, setLoading] = useState(false);
  const [inputPhone, setInputPhone] = useState(undefined);
  const [showInputCode, setShowInputCode] = useState(false);
  const [invalidPhoneAlert, setInvalidPhoneAlert] = useState(false);
  const [invalidCodeAlert, setInvalidCodeAlert] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [smsCodeDev, setSmsCodeDev] = useState(undefined);

  const sendCode = async ({ cellphone }) => {
    setLoading(true);
    setInvalidPhoneAlert(false);
    try {
      const res = await sendVerificationCodeNewPhone(currentLoginData.customerTaxId, cellphone);
      const { smsCode } = res.data;
      setSmsCodeDev(smsCode);
      setInputPhone(cellphone);
      setShowInputCode(true);
    } catch (error) {
      setInvalidPhoneAlert(true);
    }
    setLoading(false);
  };

  const onSubmitVerificationCode = async ({ smsCode }) => {
    setInvalidCodeAlert(false);
    setLoading(true);
    try {
      const res = await updateCellphone(currentLoginData.customerTaxId, { cellphone: inputPhone, smsCode });
      res.data = undefined;
      setResponse(res);
    } catch (error) {
      let errorCode;
      if (error && error.data) {
        errorCode = error.data.message;
      }
      if (errorCode === ERROR_UPDATE_CUSTOMER_CELLPHONE) {
        setResponse(error);
      } else {
        setInvalidCodeAlert(true);
      }
    }
    setLoading(false);
  };

  const onReSendVerificationCode = () => {
    sendCode({ cellphone: inputPhone });
  };

  const goToLogin = () => {
    goHome();
  };

  return (
    <>
      {!showInputCode && !response && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(sendCode)}>
            <Box m={6} />
            <Grid container justify="center">
              <Grid item xs={11} md={8}>
                <img src={MobileImage} alt="Usuario usando celular" width="100px" />
                <Box m={3} />
                <Typography variant="h2" style={{ fontSize: '2rem' }}>
                  {i18n.loginChangeCellphoneNumberTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={11} md={4}>
                <Box m={6} />
                <TextInput
                  name="cellphone"
                  label={i18n.loginChangeCellphoneNumberInputLabel}
                  variant="outlined"
                  fullWidth
                  helperText={i18n.loginChangeCellphoneNumberInputHelper}
                  mandatory
                  inputProps={{ maxLength: 12 }}
                />
                {invalidPhoneAlert === true && (
                  <>
                    <Box m={1} />
                    <Alert severity="error">{i18n.loginChangeCellphoneNumberInvalidNumber}</Alert>
                  </>
                )}
                <Box m={6} />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  type="submit"
                  loading={loading}
                >
                  {i18n.loginChangeCellphoneNumberSendCode}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
      {showInputCode && !response && (
        <>
          <Box m={4} />
          <Typography component="h3" className={classes.h3}>
            {i18n.loginVerificationCodeWording1}
          </Typography>
          <Typography component="h4" className={classes.h4}>
            {i18n.loginVerificationCodeWording2}
            {inputPhone}
          </Typography>
          <Grid container justify="center">
            <Grid item xs={11} sm={6} md={4}>
              <LoginVerificationCodeStep
                onSubmit={onSubmitVerificationCode}
                loading={loading}
                invalidCodeAlert={invalidCodeAlert}
                onReSendVerificationCode={onReSendVerificationCode}
              />
            </Grid>
          </Grid>
          {smsCodeDev && (
            <Alert>
              <Typography>
                Codigo sms (solo se muestra para pruebas!):
                {' '}
                {smsCodeDev}
              </Typography>
            </Alert>
          )}
        </>
      )}
      {response && (
        <Grid container justify="center">
          <Grid item xs={11} md={11}>
            <MessageResponse
              response={response}
              referenceLabels={CELLPHONE_UPDATED}
              canGoHome={false}
            />
          </Grid>
          <Grid item xs={11} md={4}>
            <Button
              variant="outlined"
              color="primary"
              onClick={goToLogin}
            >
              {i18n.loginChangeCellphoneNumberBack}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

LoginChangeCellphoneNumber.propTypes = {
  currentLoginData: PropTypes.shape({
    customerTaxId: PropTypes.string.isRequired,
    gender: PropTypes.string,
    hash: PropTypes.string,
    fullName: PropTypes.string,
    smsCode: PropTypes.string,
  }).isRequired,
};

export default LoginChangeCellphoneNumber;
