import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const PasswordInput = ({
  name, label, mandatory, helperText, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          label={mandatory ? `${label}*` : label}
          helperText={errors[name]?.message || helperText}
          error={!!errors[name]}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
};

PasswordInput.defaultProps = {
  mandatory: false,
  variant: 'outlined',
  helperText: undefined,
};

export default PasswordInput;
