import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Typography,
} from '@material-ui/core';

const CardHomeOption = ({
  title, urlImage, pathOnClick, isExternalLink,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (isExternalLink) {
      window.open(pathOnClick, '_blank');
    } else {
      history.push(pathOnClick);
    }
  };

  return (
    <Card style={{
      width: '100%', height: '100%', border: '1px solid #E0E0E0', boxShadow: 'none',
    }}
    >
      <CardActionArea onClick={handleClick} style={{ width: '100%', height: '100%' }}>
        <CardContent style={{ width: '100%', height: '100%', padding: '8px 4px 8px 4px' }}>
          <img src={urlImage} alt={title} />
          <Typography style={{ marginTop: '8px', fontSize: '0.75rem' }}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardHomeOption.propTypes = {
  title: PropTypes.string,
  urlImage: PropTypes.string.isRequired,
  pathOnClick: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
};

CardHomeOption.defaultProps = {
  title: '',
  isExternalLink: false,
};

export default CardHomeOption;
