import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Container, Typography, Grid,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import '../index.css';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import CardButton from './CardButton';
import i18n from '../common/i18n';
import imgDc from '../assets/imgs/img_td.svg';
import imgCcMp from '../assets/imgs/img_tc_mp.svg';
import Question from '../assets/imgs/img_question.svg';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import CardCustomerDebtorAccordion from './CardCustomerDebtorAccordion';
import Header from './common/Header';
import useQueryParam from '../customHook/useQueryParam';

const useRowStyles = makeStyles({
  gap: {
    gap: '15px',
  },
});
const PaymentMethodSelection = () => {
  const classes = useRowStyles();
  const history = useHistory();

  const queryParam = useQueryParam();

  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  const [selectedLoanId, setSelectedLoanId] = useState(undefined);

  const paymentMethodCards = [
    {
      key: i18n.PaymentDebitCardFormKey,
      title: i18n.payInstallmentMethodOnlineDcTitle,
      urlImage: imgDc,
    },
    {
      key: i18n.PaymentMPFormKey,
      title: i18n.payInstallmentMethodOnlineCcTitle,
      urlImage: imgCcMp,
    },

  ];
  const handleOnClickMethod = (idMethod) => {
    history.push({
      pathname: getPathRouteFromKey(idMethod),
      search: `id=${selectedLoanId}`,
    });
  };

  const goBack = () => {
    history.push({
      pathname: getPathRouteFromKey(i18n.payInstallmentKey),
      search: `m=${i18n.payInstallmentOnlineKey}`,
    });
  };

  const goToHowToPay = () => {
    history.push(getPathRouteFromKey(i18n.HowPaymentMethodOnlineKey));
  };

  useEffect(() => {
    const loanId = queryParam.get('id');
    if (loanId) {
      setSelectedLoanId(loanId);
    } else {
      goBack();
    }
  }, []);

  return (
    <>
      <Header title={i18n.payInstallmentHeaderTitle} />
      {customerIsUpToDate === false && (
        <CardCustomerDebtorAccordion
          totalInstallmentDebt={totalInstallmentDebt}
          totalPunitiveDebt={totalPunitiveDebt}
          totalDebt={totalDebt}
        />
      )}
      <Container maxWidth="lg">
        <Box m={6} />
        <Typography variant="h2">
          {i18n.payInstallmentMethodOnlineTitle}
          {' '}
          <Button
            style={{ minWidth: '0' }}
            onClick={goToHowToPay}
          >
            <img src={Question} alt="¿Cómo pago?" />
          </Button>
        </Typography>
        <Box m={2} />
        <Typography>
          {i18n.payInstallmentOnlineLoanSelected}
          <span style={{ fontWeight: '700' }}>
            {`Nº ${selectedLoanId}`}
          </span>
        </Typography>
        <Box m={6} />

        <Grid container justify="center" className={classes.gap}>
          {paymentMethodCards.map((method) => (
            <Grid
              item
              key={method.key}
              md={5}
              sm={6}
              xs={12}
            >
              <CardButton
                title={method.title}
                subtitle={method.subtitle}
                urlImage={method.urlImage}
                onClick={() => handleOnClickMethod(method.key)}
              />
            </Grid>
          ))}
        </Grid>
        <Box m={10} />
        <Button
          startIcon={<ArrowBack />}
          onClick={goBack}
        >
          {i18n.payInstallmentMethodOnlineCTA}
        </Button>
      </Container>

    </>

  );
};

export default PaymentMethodSelection;
