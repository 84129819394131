import PropTypes from 'prop-types';
import {
  Typography, Box, Grid,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import i18n from '../common/i18n';
import CardInvoice from './CardInvoices';
import InvoicesTable from './InvoicesTable';

const Invoices = ({ invoices, onClickDownload }) => {
  const [lastInvoice, setLastInvoice] = useState();

  const initSetting = () => {
    const lastInv = invoices.shift();
    setLastInvoice(lastInv);
  };

  useEffect(() => initSetting(), []);

  return (
    <>
      { invoices !== undefined && lastInvoice !== undefined && (
        <>
          <Grid container justify="center">
            <Grid item md={4} xs={12}>
              <CardInvoice
                paymentNumber={lastInvoice.paymentNumber}
                date={lastInvoice.date}
                amount={lastInvoice.total}
                onClickDownload={onClickDownload}
              />
            </Grid>
          </Grid>
          {invoices.length > 0 && (
            <>
              <Box m={6} />
              <Typography variant="h4">{i18n.invoicesAllInvoices}</Typography>
              <Box m={3} />
              <InvoicesTable invoices={invoices} onClickDownload={onClickDownload} />
            </>
          )}
        </>
      )}
    </>
  );
};
Invoices.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({
    paymentNumber: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
  })).isRequired,
  onClickDownload: PropTypes.func.isRequired,
};

export default Invoices;
