import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getCustomerContactDataSchema } from '../forms/schemaValidations/schemaValidations';
import i18n from '../common/i18n';
import LoadingButton from './common/LoadingButton';
import TextInput from './common/TextInput';

const UserContactData = ({
  data, onSubmit, loading, onEditForm, onCancelEditForm,
}) => {
  const [editContactForm, setEditContatForm] = useState(false);

  let emailMandatory = false;
  let phoneMandatory = false;
  let alternativePhoneMandatory = false;

  const resolveFormValidation = (formData) => {
    const schemaValidation = getCustomerContactDataSchema(formData);
    emailMandatory = schemaValidation.fields.email.exclusiveTests.required;
    phoneMandatory = schemaValidation.fields.phone.exclusiveTests.required;
    alternativePhoneMandatory = schemaValidation.fields.alternativePhone.exclusiveTests.required;
    return schemaValidation;
  };

  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(resolveFormValidation(data)),
  });

  useEffect(() => { methods.reset(data); }, [data]);

  const handleClickEditContactForm = () => {
    setEditContatForm(true);
    onEditForm();
  };

  const handleCancelButton = () => {
    methods.reset(data);
    setEditContatForm(false);
    onCancelEditForm();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Box m={1} />
            <Typography variant="h2">{i18n.userContactDataH2}</Typography>
            <Box m={1} />
            {!editContactForm && (
              <Button onClick={handleClickEditContactForm}>
                {i18n.userContactDataCTAUpdate}
              </Button>
            )}
          </Grid>
          <Box m={2} />
          <Grid item container spacing={3} alignItems="top">
            <Grid item sm={6} xs={12}>
              <TextInput
                name="email"
                mandatory={emailMandatory}
                label={i18n.userContactDataInputEmail}
                fullWidth
                disabled={!editContactForm}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                name="phone"
                mandatory={phoneMandatory}
                label={i18n.userContactDataInputPhone}
                fullWidth
                disabled={!editContactForm}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                name="alternativePhone"
                mandatory={alternativePhoneMandatory}
                label={i18n.userContactDataInputAlternativePhone}
                fullWidth
                disabled={!editContactForm}
              />
            </Grid>

            {editContactForm && (
              <Grid item container justify="center" spacing={3}>
                <Grid item sm={4} xs={12}>
                  <LoadingButton
                    style={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={loading}
                  >
                    {i18n.userDataCTAprimary}
                  </LoadingButton>
                  <Button onClick={handleCancelButton}>
                    {i18n.userDataCTAsecondary}
                  </Button>
                </Grid>
              </Grid>
            )}

          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
UserContactData.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    alternativePhone: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onEditForm: PropTypes.func.isRequired,
  onCancelEditForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
UserContactData.defaultProps = {
  data: undefined,

};
export default UserContactData;
