import i18n from '../common/i18n';

export const SECTIONS = [
  { id: 1, title: i18n.FAQs.sections[0].title, subtitle: i18n.FAQs.sections[0].subtitle },
  { id: 2, title: i18n.FAQs.sections[1].title, subtitle: i18n.FAQs.sections[1].subtitle },
  { id: 3, title: i18n.FAQs.sections[2].title, subtitle: i18n.FAQs.sections[2].subtitle },
  { id: 4, title: i18n.FAQs.sections[3].title, subtitle: i18n.FAQs.sections[3].subtitle },
  { id: 5, title: i18n.FAQs.sections[4].title, subtitle: i18n.FAQs.sections[4].subtitle },
];

export const QUESTIONS = [
  {
    idSection: 1,
    title: i18n.FAQs.questions[0].title,
    body: i18n.FAQs.questions[0].body,
  },
  {
    idSection: 1,
    title: i18n.FAQs.questions[1].title,
    body: i18n.FAQs.questions[1].body,
  },
  {
    idSection: 1,
    title: i18n.FAQs.questions[2].title,
    body: i18n.FAQs.questions[2].body,
  },
  {
    idSection: 1,
    title: i18n.FAQs.questions[3].title,
    body: i18n.FAQs.questions[3].body,
  },
  {
    idSection: 1,
    title: i18n.FAQs.questions[4].title,
    body: i18n.FAQs.questions[4].body,
  },
  {
    idSection: 1,
    title: i18n.FAQs.questions[5].title,
    body: i18n.FAQs.questions[5].body,
  },
  {
    idSection: 2,
    title: i18n.FAQs.questions[6].title,
    body: i18n.FAQs.questions[6].body,
  },
  {
    idSection: 2,
    title: i18n.FAQs.questions[7].title,
    body: i18n.FAQs.questions[7].body,
  },
  {
    idSection: 2,
    title: i18n.FAQs.questions[8].title,
    body: i18n.FAQs.questions[8].body,
  },
  {
    idSection: 2,
    title: i18n.FAQs.questions[9].title,
    body: i18n.FAQs.questions[9].body,
  },
  {
    idSection: 2,
    title: i18n.FAQs.questions[10].title,
    body: i18n.FAQs.questions[10].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[11].title,
    body: i18n.FAQs.questions[11].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[12].title,
    body: i18n.FAQs.questions[12].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[13].title,
    body: i18n.FAQs.questions[13].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[14].title,
    body: i18n.FAQs.questions[14].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[15].title,
    body: i18n.FAQs.questions[15].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[16].title,
    body: i18n.FAQs.questions[16].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[17].title,
    body: i18n.FAQs.questions[17].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[18].title,
    body: i18n.FAQs.questions[18].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[19].title,
    body: i18n.FAQs.questions[19].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[20].title,
    body: i18n.FAQs.questions[20].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[21].title,
    body: i18n.FAQs.questions[21].body,
  },
  {
    idSection: 3,
    title: i18n.FAQs.questions[22].title,
    body: i18n.FAQs.questions[22].body,
  },
  {
    idSection: 4,
    title: i18n.FAQs.questions[23].title,
    body: i18n.FAQs.questions[23].body,
  },
  {
    idSection: 4,
    title: i18n.FAQs.questions[24].title,
    body: i18n.FAQs.questions[24].body,
  },
  {
    idSection: 4,
    title: i18n.FAQs.questions[25].title,
    body: i18n.FAQs.questions[25].body,
  },
  {
    idSection: 5,
    title: i18n.FAQs.questions[26].title,
    body: i18n.FAQs.questions[26].body,
  },
];
