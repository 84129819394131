import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import CustomDialog from './common/CustomDialog';
import surveyIcon from '../assets/imgs/survey-icon.svg';
import i18n from '../common/i18n';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { surveyAlreadyAnswered, skipSurvey } from '../api/selfieWebService';
import { getCurrentUser, saveCurrentUser } from '../api/userStorage';
import { sleep } from '../utils/functionsUtil';

const useStyles = makeStyles({
  mainContainer: {
    padding: '30px',
  },
  icon: {
    textAlign: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#F2025D',
    textAlign: 'center',
  },
  description: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
  },
});

const TakeSurveyModal = ({ delay, onClose, force }) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const wasSurveyOpened = () => {
    const user = getCurrentUser();
    return user?.surveyWasOpened;
  };

  const saveSurveyOpened = () => {
    const user = getCurrentUser();
    user.surveyWasOpened = true;
    saveCurrentUser(user);
  };

  const handleAccept = () => {
    setOpen(false);
    history.push(getPathRouteFromKey(i18n.surveyKey));
  };

  const handleCancel = async () => {
    const user = getCurrentUser();
    setOpen(false);
    skipSurvey(user.customerId);
    onClose();
  };

  const init = async () => {
    try {
      if (force) {
        setOpen(true);
        saveSurveyOpened();
      } else {
        const res = await surveyAlreadyAnswered();
        const wasAnswered = res.data;
        await sleep(delay);
        if (!wasAnswered && !wasSurveyOpened()) {
          setOpen(true);
          saveSurveyOpened();
        } else {
          // Si ya fue respondida u ofrecida en la misma session, entonces continua
          onClose();
        }
      }
    } catch (error) {
      setOpen(false);
      onClose();
    }
  };

  useEffect(() => init(), []);

  const getModalBody = () => (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.icon}>
          <img src={surveyIcon} alt="Survey icon" />
        </Grid>
        <Box mt={10} />
        <Grid item xs={12}>
          <Typography className={classes.title}>{i18n.surveyModal.title}</Typography>
        </Grid>
        <Box mt={5} />
        <Grid item xs={12} className={classes.description}>
          <Typography>{i18n.surveyModal.description}</Typography>
        </Grid>
        <Box mt={10} />
        <Grid item xs={12}>
          <Button onClick={handleAccept} variant="contained" color="primary" fullWidth>
            {i18n.surveyModal.primaryCTA}
          </Button>
        </Grid>
        <Box mt={8} />
        <Grid item xs={12}>
          <Button onClick={handleCancel} variant="outlined" fullWidth>{i18n.surveyModal.secondaryCTA}</Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        dialogData={getModalBody}
      />
    </>
  );
};

TakeSurveyModal.propTypes = {
  delay: PropTypes.number,
  onClose: PropTypes.func,
  force: PropTypes.bool,
};

TakeSurveyModal.defaultProps = {
  delay: 0,
  onClose: () => {},
  force: false,
};

export default TakeSurveyModal;
