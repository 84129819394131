import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundImage: 'url("/images/login-background.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '120vh',

    [theme.breakpoints.down('md')]: {
      backgroundImage: 'none',
      height: '100vh',
    },
  },

  ctaContainer: {
    marginTop: '32px',
  },

  [theme.breakpoints.down('sm')]: {
    container: {
      backgroundImage: 'none',
    },
  },
}));

export default useStyle;
