import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Grid, Radio, Typography, makeStyles,
} from '@material-ui/core';
import i18n from '../common/i18n';
import { capitalizeSentence, fillMessageWith, mapCodeToDescription } from '../utils/functionsUtil';
import { moneyFormatter } from '../utils/formatterUtil';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    '@media (max-width:600px)': {
      justifyContent: 'left',
    },
  },
  title: {
    fontSize: '18px',
  },
}));

const PrePaidLoanSelectionRadioOption = ({ loan, checked }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <Box className={classes.container}>
            <Radio
              checked={checked}
              value={loan.externalId}
              name="loan-selector"
              color="primary"
            />
            <Box ml={2}>
              <Typography variant="h5" component="h2" style={{ fontSize: '18px' }}>
                <strong>
                  {parse(fillMessageWith(
                    i18n.prePaidLoanSelectionTitle[0],
                    fillMessageWith(
                      mapCodeToDescription(loan.subproductId, i18n.subproductsGroup),
                      capitalizeSentence(loan.commerceName),
                    ),
                    fillMessageWith(
                      loan.installmentCount > 1 ? i18n.prePaidLoanSelectionTitle[2] : i18n.prePaidLoanSelectionTitle[1],
                      loan.installmentCount,
                      moneyFormatter(loan.currentInstallment.totalAmount),
                    ),
                  ))}
                </strong>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box m={2} />
    </>
  );
};

PrePaidLoanSelectionRadioOption.propTypes = {
  loan: PropTypes.shape().isRequired,
  checked: PropTypes.bool.isRequired,
};

export default PrePaidLoanSelectionRadioOption;
