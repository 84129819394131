import { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { MenuSideBarItems } from '../../utils/menuAndRoutesUtil';

const SideBarMenu = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <List className="menuHamburguesa">
            {MenuSideBarItems.map((item) => (item
                  && (
                    <ListItem key={item?.text}>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <a href={item?.path} style={{ textDecoration: 'none' }}>
                        <Button sx={{ textAlign: 'center' }}>
                          <ListItemText primary={item?.text} className="menuHamburguesa" />
                        </Button>
                      </a>
                    </ListItem>
                  )
            ))}
          </List>
        </Box>
      </Drawer>
      <IconButton
        onClick={handleDrawerToggle}
        className="iconButton"
      >
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default SideBarMenu;
