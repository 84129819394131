import { useEffect, useState } from 'react';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { getRegistrationChallenge, registerCredentials } from '../../api/webAuthnApiService';
import { getEnv, REACT_APP_LENDING_SELFIE_ENDPOINT } from '../../api/env';
import { useAuth } from '../../contexts/AuthContext';
import { capitalizeSentence, isMobileOperatingSystem } from '../../utils/functionsUtil';
import { useToaster } from '../../contexts/ToasterContext';
import { arrayBufferToBase64 } from '../../utils/formatterUtil';
import {
  saveCurrentUser, saveLocalStorageUnlockModeOmitForever, saveLocalStorageUnlockModeRegistered, showWebAuthnRegister,
} from '../../api/userStorage';
import i18n from '../../common/i18n';
import { getPathRouteFromKey } from '../../utils/menuAndRoutesUtil';

const useWebAuthnRegisterController = () => {
  const history = useHistory();
  const { currentUser, setCurrentUser } = useAuth();
  const { showToast } = useToaster();
  const [loading, setLoading] = useState();
  const [omitForever, setOmitForever] = useState();
  const [responseSuccess, setResponseSuccess] = useState();

  const register = async () => {
    try {
      setLoading(true);
      const { data: challenge } = await getRegistrationChallenge();

      const customerName = capitalizeSentence(`${currentUser.customerFirstName} ${currentUser.customerSurname}`);

      const options = {
        challenge: Base64.toUint8Array(challenge),
        rp: {
          id: new URL(getEnv(REACT_APP_LENDING_SELFIE_ENDPOINT)).hostname,
          name: 'Autogestion Credicuotas',
        },
        user: {
          id: Base64.toUint8Array(btoa(currentUser.customerTaxId)),
          name: customerName,
          displayName: customerName,
        },
        pubKeyCredParams: [{ alg: -7, type: 'public-key' }, { alg: -257, type: 'public-key' }],
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        timeout: 60000,
      };

      const credentialsResult = await navigator.credentials.create({ publicKey: options });

      const clientDataJSONEncoded = arrayBufferToBase64(credentialsResult.response.clientDataJSON);
      const attestationObjectEncoded = arrayBufferToBase64(credentialsResult.response.attestationObject);

      const credentials = {
        id: credentialsResult.id,
        clientDataJSONEncoded,
        attestationObjectEncoded,
      };
      const res = await registerCredentials(credentials);
      saveLocalStorageUnlockModeRegistered(currentUser.customerTaxId);
      setResponseSuccess(res);
    } catch (error) {
      if (error?.name !== 'NotAllowedError') {
        showToast('Ocurrió un error al intentar registrar el metodo de desbloqueo', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const omit = () => {
    if (omitForever) {
      saveLocalStorageUnlockModeOmitForever(currentUser.customerTaxId);
    }

    const currentUserAux = currentUser;
    currentUserAux.skipWebAuthnRegister = true;
    // guardo en el contexto y localStorage que omitio para que no le vuelva a
    // aparecer hasta el proximo login
    setCurrentUser(currentUserAux);
    saveCurrentUser(currentUserAux);
    history.push(getPathRouteFromKey(i18n.homeKey));
  };

  const handleChange = () => {
    setOmitForever(!omitForever);
  };

  const onContinue = () => {
    history.push(getPathRouteFromKey(i18n.homeKey));
  };

  const init = () => {
    if (!isMobileOperatingSystem() || !showWebAuthnRegister(currentUser.customerTaxId) || currentUser.skipWebAuthnRegister) {
      history.push(getPathRouteFromKey(i18n.homeKey));
    }
  };

  useEffect(init, []);

  return {
    loading, omitForever, handleChange, omit, register, responseSuccess, onContinue,
  };
};

export default useWebAuthnRegisterController;
