import PropTypes from 'prop-types';
import { FormHelperText, makeStyles } from '@material-ui/core';
import ReactCodeInput from 'react-code-input';
import { Controller, useFormContext } from 'react-hook-form';

const useStyle = makeStyles(() => ({
  customInput: {
    '& input:focus': {
      outline: 'none',
    },

    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}));

const CodeVerificationInput = ({
  name, size, type,
}) => {
  const classes = useStyle();
  const { control, formState: { errors } } = useFormContext();

  const props = {
    inputStyle: {
      fontFamily: 'monospace',
      marginTop: '10px',
      marginRight: '20px',
      marginBottom: '20px',
      MozAppearance: 'textfield',
      width: '50px',
      height: '50px',
      borderRadius: '8px',
      fontSize: '24px',
      paddingLeft: '16px',
      backgroundColor: '#f5f5f5',
      color: '#E72064',
      border: '1px solid #E0E0E0',
    },
  };

  return (
    <Controller
      name="smsCode"
      control={control}
      defaultValue=""
      render={({ field }) => (
        <>
          <ReactCodeInput
            {...field}
            {...props}
            type={type}
            fields={size}
            onChange={field.onChange}
            className={classes.customInput}
          />
          {errors[name] && (
            <FormHelperText style={{ color: 'red' }}>
              {errors[name].message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

CodeVerificationInput.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default CodeVerificationInput;
