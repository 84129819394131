import { useEffect, useState } from 'react';
import {
  ListItem, ListItemIcon, ListItemText, makeStyles, Typography, Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import parse from 'html-react-parser';
import Box from '@material-ui/core/Box';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import imgDeudaLineaCredito from '../assets/imgs/img_deuda_linea_credito.svg';
import imgBack from '../assets/imgs/atras.svg';
import { fillMessageWith } from '../utils/functionsUtil';
import useHomeNavigation from '../customHook/useHomeNavigation';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
  },
  headerSubtitle: {
    fontWeight: '600',
    color: 'white',
  },
  listBullet: {
    color: '#29CC6A',
    fontSize: '20px',
  },
  listText: {
    color: '#000000de',
  },
  link: {
    padding: '0px',
    textDecoration: 'underline',
    paddingBottom: '3px',
  },
  arrowBack: {
    fontWeight: '700',
  },
  bullets: {
    display: 'flex',
    alignItems: 'center',
  },
  ctaContainer: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '20px',
    backgroundColor: 'white',
    borderTop: '1px solid #e0e0e0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px',
  },
}));

const RevolvingLineOffer = () => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const goHome = useHomeNavigation();
  const [showHelp, setShowHelp] = useState(false);

  const {
    customerAccountStatus: {
      customerRevolvingLine = null,
      customerIsUpToDate = true,
    } = {},
  } = useCustomerAccountStatus() || {};

  const goToPay = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const goToNewLoan = () => {
    history.push(`${getPathRouteFromKey(i18n.newLoanKey)}?product=46&subproduct=97`);
  };

  const init = () => {
    // si ya tiene linea de credito creada
    if (customerRevolvingLine?.maxAmount) {
      history.push(`${getPathRouteFromKey(i18n.revolvingLineDetail)}`);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {showHelp ? (
        <>
          <Box component="header" className={classes.header}>
            <Box m={1}>
              <Typography variant="h1">{i18n.RevolvingLine.Offer.headerTitle}</Typography>
            </Box>
          </Box>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <Box m={4}>
                <Typography variant="h2">
                  {i18n.RevolvingLine.Offer.helpTitle}
                </Typography>
              </Box>

              <Box m={4} textAlign="left">
                <Typography aling="left">
                  {parse(i18n.RevolvingLine.Offer.helpText)}
                </Typography>
              </Box>

              <Box m={1} textAlign="left">
                <ul>
                  <li className={classes.listBullet} style={{ color: '#DA0067' }}>
                    <Typography className={classes.listText}>
                      {parse(i18n.RevolvingLine.Offer.helpListItemOne)}
                    </Typography>
                  </li>
                  <Box m={2} />
                  <li className={classes.listBullet} style={{ color: '#DA0067' }}>
                    <Typography className={classes.listText}>
                      {parse(i18n.RevolvingLine.Offer.helpListItemTwo)}
                    </Typography>
                  </li>
                </ul>
              </Box>

              <Box m={8} />

              <Box m={4}>
                <Button
                  variant="tertiary"
                  onClick={() => setShowHelp(false)}
                >
                  <ListItem style={{ padding: '0px' }}>
                    <ListItemIcon style={{ minWidth: '24px' }}>
                      <img src={imgBack} alt="back" width="20px" />
                    </ListItemIcon>
                    <ListItemText
                      primary={(
                        <Typography className={classes.arrowBack}>
                          {i18n.RevolvingLine.Offer.helpArrowBack}
                        </Typography>
                      )}
                    />
                  </ListItem>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box component="header" className={classes.header}>
            <Typography variant="h1">{i18n.RevolvingLine.Offer.headerTitle}</Typography>
          </Box>
          {customerIsUpToDate === false ? (
            <>
              <Box m={3}>
                <img src={imgDeudaLineaCredito} width="100px" alt="img linea de crédito" />
              </Box>
              <Box m={2}>
                <Typography variant="h2">
                  {i18n.RevolvingLine.Offer.customerInDebtTitle}
                </Typography>
              </Box>
              <Box m={4} textAlign="center">
                <Typography>
                  {parse(i18n.RevolvingLine.Offer.customerInDebtBody)}
                </Typography>
              </Box>
              <Box m={4} textAlign="center">
                <Button
                  variant="text"
                  className={classes.link}
                  onClick={() => setShowHelp(true)}
                >
                  {i18n.RevolvingLine.Offer.helpTitle}
                </Button>
              </Box>

              <Box m={8} />

              <Box m={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToPay}
                  fullWidth
                >
                  {i18n.RevolvingLine.Offer.payInstallmentButton}
                </Button>
              </Box>
            </>
          ) : (
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10}>
                <Box m={3}>
                  <img src="/images/oferta-linea-revolvente.svg" width="90px" alt="img linea de crédito" />
                </Box>
                <Box m={3}>
                  <Typography variant="h2">
                    {parse(fillMessageWith(
                      i18n.RevolvingLine.Offer.title,
                      moneyFormatter(customerRevolvingLine?.campaignOffer?.maxAmountOffer),
                    ))}
                  </Typography>
                </Box>

                <Box m={3} textAlign="left">
                  <Typography>
                    {parse(i18n.RevolvingLine.Offer.titleList)}
                  </Typography>
                </Box>

                <Box m={3} textAlign="left">
                  <Box className={classes.bullets}>
                    <img src="/images/bullet.svg" alt="bullet" style={{ marginRight: '5px' }} />
                    <Typography>
                      {parse(i18n.RevolvingLine.Offer.listItemOne)}
                    </Typography>
                  </Box>
                  <Box className={classes.bullets}>
                    <img src="/images/bullet.svg" alt="bullet" style={{ marginRight: '5px' }} />
                    <Typography>
                      {parse(i18n.RevolvingLine.Offer.listItemTwo)}
                    </Typography>
                  </Box>
                  <Box className={classes.bullets}>
                    <img src="/images/bullet.svg" alt="bullet" style={{ marginRight: '5px' }} />
                    <Typography>
                      {parse(i18n.RevolvingLine.Offer.listItemThree)}
                    </Typography>
                  </Box>
                </Box>

                <Button
                  variant="text"
                  className={classes.link}
                  onClick={() => setShowHelp(true)}
                >
                  {i18n.RevolvingLine.Offer.link}
                </Button>

                <Box m={3} mt={3}>
                  <Typography style={{ fontSize: '12px' }}>
                    {parse(i18n.RevolvingLine.Offer.footer)}
                  </Typography>
                </Box>

                <Box m={3} className={isMobile ? classes.ctaContainer : ''}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={goToNewLoan}
                    fullWidth
                  >
                    {i18n.RevolvingLine.Offer.button}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={goHome}
                    fullWidth
                    style={{ marginTop: '10px' }}
                  >
                    {i18n.RevolvingLine.Offer.buttonSecondary}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}

    </>
  );
};

export default RevolvingLineOffer;
