import { useState } from 'react';
import { generateBiometricOnboardingData, validateBiometricResult } from '../api/selfieWebService';
import useQueryParam from '../customHook/useQueryParam';

const useBiometricService = () => {
  const queryParams = useQueryParam();
  const [loading, setLoading] = useState(true);

  const createHiddenInput = (name, value) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = name;
    i.value = value;
    return i;
  };

  const initOnboarding = async (customerTaxId, gender, callbackPath) => {
    try {
      setLoading(true);

      const res = await generateBiometricOnboardingData(customerTaxId, callbackPath);
      const onboardingData = res.data;

      const f = document.createElement('form');
      const method = onboardingData.url.includes(window.location.origin) ? 'get' : 'post';

      f.setAttribute('method', method);
      f.setAttribute('action', onboardingData.url);

      f.appendChild(createHiddenInput('idapp', 'App Cliente'));
      f.appendChild(createHiddenInput('seckey', onboardingData.seckey));
      f.appendChild(createHiddenInput('country', 'ARG'));
      f.appendChild(createHiddenInput('idmaqc_service', onboardingData.idmaqcService));
      f.appendChild(createHiddenInput('profile_services', onboardingData.profileServices));
      f.appendChild(createHiddenInput('services', onboardingData.services));
      f.appendChild(createHiddenInput('externaltxid', onboardingData.externalId));
      f.appendChild(createHiddenInput('dni', onboardingData.customerId));
      f.appendChild(createHiddenInput('sexo', gender));

      document.body.appendChild(f);
      f.submit();
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const processCallbackAndGetResult = async () => {
    const idtx = queryParams.get('idtx');
    const externalID = queryParams.get('externaltxid') || queryParams.get('externaltrxid')
      || queryParams.get('externalID');

    let result;
    if (externalID) {
      const res = await validateBiometricResult(idtx, externalID);
      result = res.data;
    }
    return result;
  };

  return { loading, initOnboarding, processCallbackAndGetResult };
};

export default useBiometricService;
