import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Checkbox } from '@material-ui/core';

const useStyles = makeStyles({
  subtitle: {
    fontSize: '0.8rem',
  },
});

const CheckBox = ({
  title, subtitle, checked, onClick, disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="flex-start" alignItems="center">
        <Grid item xs={2} style={{ flexBasis: '0' }}>
          <Checkbox
            checked={checked}
            onClick={onClick}
            disabled={disabled}
          />
        </Grid>
        <Grid item container xs={10} alignItems="center">
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12}>
              <Typography align="left">
                <b>
                  {title}
                </b>
              </Typography>
            </Grid>
            {subtitle
              ? (
                <Grid item xs={12}>
                  <Typography align="left" className={classes.subtitle}>
                    {subtitle}
                  </Typography>
                </Grid>
              )
              : (
                ''
              )}
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
    </>
  );
};

CheckBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {
  subtitle: ' ',
  onClick: '',
  disabled: false,
};

export default CheckBox;
