import { Grid } from '@material-ui/core';
import AvatarMenu from '../../../components/AvatarMenu';

/* eslint arrow-body-style: ["error", "always"] */
const WikiAvatarMenu = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <AvatarMenu />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiAvatarMenu;
