import {
  Box, Container, Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Header from '../components/common/Header';
import i18n from '../common/i18n';
import ActionWarning from '../components/common/ActionWarning';
import {
  findActiveLoansByCustomerTaxIdAndExcludeRevolvingLine,
  getActiveLoansWithMoreDaysPastDue,
} from '../api/selfieWebService';
import MessageResponse from '../components/common/MessageResponse';
import ActionNotAvailable from '../components/common/ActionNotAvailable';
import LoanSelection from '../components/LoanSelection';
import { LOAN_PAYMENT_VOUCHER } from '../utils/referenceConstant';
import PaymentVoucher from '../components/PaymentVoucher';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import Loading from '../components/common/Loading';

const LoanPaymentVouchers = () => {
  // Context
  const { currentUser } = useAuth();
  const [loans, setLoans] = useState();
  const [noActiveLoans, setNoActiveLoans] = useState(false);
  const [loanSelected, setLoanSelected] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [allActiveInvalidPaymentMethod, setAllActiveInvalidPaymentMethod] = useState(false);
  const [invalidPaymentMethod, setInvalidPaymentMethod] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [showPaymentVoucher, setShowPaymentVoucher] = useState(false);
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
    } = {},
  } = useCustomerAccountStatus() || {};

  const sortLoansBy = async (loansToSort) => {
    const voluntaryMethod = loansToSort.filter((l) => l.paymentMethod === 'VOLUNTARY');
    const cardMethod = loansToSort.filter((l) =>
      (l.paymentMethod === 'DIRECT_DEBIT_CARD' || l.paymentMethod === 'DIRECT_DEBIT'));
    const voluntaryWithDebt = [];
    if (customerIsUpToDate) {
      // ordena los de pago voluntario por valor de cuota (mayor a menor)
      voluntaryMethod.sort((a, b) =>
        ((a.averageInstallmentAmount <= b.averageInstallmentAmount) ? 1 : -1));
    } else {
      try {
        // ordena los de pago voluntario por dias de mora (mayor a menor)
        const loansWithMoreDaysPastDueResponse = await getActiveLoansWithMoreDaysPastDue(currentUser.customerTaxId);
        loansWithMoreDaysPastDueResponse.data.forEach((loanWithDebt) => {
          const foundLoan = voluntaryMethod.find((element) => element.externalId === loanWithDebt.loanId.toString());
          if (foundLoan) {
            voluntaryWithDebt.push(foundLoan);
          }
        });
      } catch (error) {
        setInitError(true);
        setResponse(getError(error));
      }
    }
    if (voluntaryWithDebt.length !== 0) {
      return voluntaryWithDebt.concat(cardMethod);
    }
    return voluntaryMethod.concat(cardMethod);
  };

  const allActiveLoansAreCARDPaymentMethod = (activeLoans) => {
    const loansPaymentMethodCardList = activeLoans.filter((loan) =>
      (loan.paymentMethod === 'DIRECT_DEBIT_CARD' || loan.paymentMethod === 'DIRECT_DEBIT'));
    if (loansPaymentMethodCardList.length === activeLoans.length) {
      return true;
    }
    return false;
  };

  const initLoanPaymentVouchers = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await findActiveLoansByCustomerTaxIdAndExcludeRevolvingLine(
          { customerTaxId: currentUser.customerTaxId },
        );
        if (res.data.length > 0) {
          const allCardPayment = allActiveLoansAreCARDPaymentMethod(res.data);
          const listSorted = await sortLoansBy(res.data);
          setLoans(listSorted);
          if (allCardPayment) {
            setAllActiveInvalidPaymentMethod(true);
          }
        } else {
          setNoActiveLoans(true);
        }
      }
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };
  useEffect(() => initLoanPaymentVouchers(), []);

  const onClickLoan = (idLoan) => {
    // buscar el loan y setearlo como seleccionado
    const loanSel = loans.find((loan) => loan.externalId === idLoan);
    setLoanSelected(loanSel);
    if (loanSel.paymentMethod !== 'VOLUNTARY') {
      setInvalidPaymentMethod(true);
    } else {
      setInvalidPaymentMethod(false);
    }
  };

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initLoanPaymentVouchers();
  };

  const shouldShowPaymentVoucher = () => {
    setShowPaymentVoucher(true);
  };

  return (
    <>
      <Header title={i18n.loanPaymentVouchersTitle} />
      <Box m={3} />
      <Container>
        {response === undefined && initLoading === true && (
          <Loading />
        )}
        {response === undefined && initLoading === false && (
          <>
            <Box m={6} />
            {!noActiveLoans && !allActiveInvalidPaymentMethod && loans !== undefined
              && (
                <>
                  {loans.size > 1 && (
                    <>
                      <Box m={3} />
                      <Typography variant="h5">{i18n.loanPaymentVouchersSubtitle}</Typography>
                      <Box m={3} />
                    </>
                  )}

                  <LoanSelection loans={loans} onClickLoan={onClickLoan} />

                  {loanSelected !== undefined && (
                    <>
                      {!invalidPaymentMethod || showPaymentVoucher ? (
                        <>
                          <Box m={3} />
                          <PaymentVoucher loanData={loanSelected} />
                        </>
                      ) : !showPaymentVoucher && (
                        <ActionWarning onClick={shouldShowPaymentVoucher} />
                      )}
                    </>
                  )}
                </>
              )}
            {allActiveInvalidPaymentMethod && (
              <>
                <LoanSelection loans={loans} onClickLoan={onClickLoan} />
                {!showPaymentVoucher ? (
                  <ActionWarning onClick={shouldShowPaymentVoucher} />
                ) : (
                  <>
                    <Box m={3} />
                    <PaymentVoucher loanData={loanSelected} />
                  </>
                )}
              </>
            )}

            {noActiveLoans && (
              <ActionNotAvailable
                title={i18n.loanPaymentVouchersActionNotAvailableTitle}
              />
            )}
          </>
        )}
        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={LOAN_PAYMENT_VOUCHER}
            isInitError={initError}
            onErrorPrimary={reload}
          />
        )}

      </Container>

    </>
  );
};

export default LoanPaymentVouchers;
