import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const NotificationContext = createContext(undefined);

export const NotificationProvider = ({ notification, children }) => {
  const [contextNotification, setContextNotification] = useState(notification);
  return (
    <NotificationContext.Provider value={{ contextNotification, setContextNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  notification: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

NotificationProvider.defaultProps = {
  notification: undefined,
};

export const useNotification = () => useContext(NotificationContext);
