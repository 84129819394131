import parse from 'html-react-parser';
import {
  Box, Button, Checkbox, Grid, Typography, makeStyles,
} from '@material-ui/core';
import Loading from '../../components/common/Loading';
import HeaderNav from '../../components/header/Header';
import i18n from '../../common/i18n';
import { fillMessageWith, getCardType, isMobileOperatingSystem } from '../../utils/functionsUtil';
import { dateFormatter, moneyFormatter } from '../../utils/formatterUtil';
import LegalFooter from '../../components/LegalFooter';
import { useRefinancingCommonStyles } from './refinancingHome';
import { useRefinancingContext } from '../../contexts/RefinancingContext';
import { useRefinancingSummary } from '../../services/refinancingService';
import MessageResponse from '../../components/common/MessageResponse';
import warningImg from '../../assets/imgs/warning-red.svg';
import { getEnv, URL_WHATSAPP } from '../../api/env';

const useStyles = makeStyles(() => ({
  summary: {
    marginTop: '12px',
    marginBottom: '32px',
    backgroundColor: '#f5f5f5',
    padding: '16px',

    '& .title': {
      fontSize: '16px',
      fontWeight: '700',
    },

    '& p': {
      fontSize: '12px',
    },

    '& .MuiGrid-item': {
      marginTop: '8px',
    },
  },

  steps: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& p': {
      fontSize: '14px',
      textAlign: 'left',
      marginLeft: '9px',
    },
  },
}));

const RefinancingSummary = () => {
  const commonClasses = useRefinancingCommonStyles();
  const classes = useStyles();

  const {
    // eslint-disable-next-line max-len
    loading, btnLoading, agreement, setAgreement, showAgreement, handleBack, handleContinue, handleGoInit, responseInitError, errorMessage, responseError,
  } = useRefinancingSummary();
  const { refinancingContext } = useRefinancingContext();

  const getWhatsappLink = () =>
    fillMessageWith(i18n.RefinancingHome.whatsapp, getEnv(URL_WHATSAPP));

  return (
    <>
      <HeaderNav nomenu />
      {loading && (
        <Loading />
      )}

      {!loading && !responseInitError && !responseError && (
        <>
          <Grid container className={commonClasses.body} mb={20}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" align="left">
                {i18n.RefinancingSummary.title}
              </Typography>
              <Typography align="left">
                {parse(fillMessageWith(i18n.RefinancingSummary.description, refinancingContext.customerFullName))}
              </Typography>
              <Box className={classes.summary}>
                <Typography className="title">
                  {parse(fillMessageWith(
                    i18n.RefinancingSummary.refinancingAmount,
                    moneyFormatter(refinancingContext.totalAmount),
                  ))}
                </Typography>
                <hr style={{ border: '1px solid #E0E0E0' }} />
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align="left">{i18n.RefinancingSummary.refinancingDescription}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="left">{i18n.RefinancingSummary.refinancingItems[0]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right"><b>{moneyFormatter(refinancingContext.debtAmount)}</b></Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="left">{i18n.RefinancingSummary.refinancingItems[1]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right"><b>{moneyFormatter(refinancingContext.refinancingAmount)}</b></Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="left">{i18n.RefinancingSummary.refinancingItems[2]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      <b>
                        {fillMessageWith(
                          i18n.RefinancingSummary.installments,
                          refinancingContext.selectedInstallment.installment,
                        )}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="left">{i18n.RefinancingSummary.refinancingItems[3]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      <b>{moneyFormatter(refinancingContext.selectedInstallment.amount)}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="left">{i18n.RefinancingSummary.refinancingItems[4]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      <b>{dateFormatter(refinancingContext.selectedInstallment.expirationDate)}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.steps} mb={2}>
                <img src="/images/refinanciacion/desembolso.svg" alt="Desembolso" />
                <Typography>{i18n.RefinancingSummary.disbursementDescription}</Typography>
              </Box>
              <hr style={{ border: '1px solid #E0E0E0' }} />
              <Box className={classes.steps} mt={2}>
                <img src="/images/refinanciacion/pago.svg" alt="Desembolso" />
                <Typography>
                  {parse(fillMessageWith(
                    i18n.RefinancingSummary.paymentDescription,
                    getCardType(refinancingContext.paymentInfo.number).toUpperCase(),
                    refinancingContext.paymentInfo.number.slice(-4),
                  ))}
                </Typography>
              </Box>
              <Box mt={3} mb={3}>
                <Typography>
                  {parse(fillMessageWith(
                    i18n.RevolvingLine.SummaryStep.taxes,
                    moneyFormatter(refinancingContext.loanDetailCost.interests),
                    moneyFormatter(refinancingContext.loanDetailCost.outgoings),
                    moneyFormatter(refinancingContext.loanDetailCost.taxes),
                    moneyFormatter(refinancingContext.loanDetailCost.amountToReturn),
                  ))}
                </Typography>
              </Box>
              {isMobileOperatingSystem() ? (
                <LegalFooter loanDetailCost={refinancingContext.loanDetailCost} />
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography align="left">
                        <Checkbox color="primary" onChange={() => setAgreement(!agreement)} />
                        {i18n.RefinancingSummary.agreement[0]}
                        <Button
                          style={{ padding: '0px', marginBottom: '2px' }}
                          onClick={showAgreement}
                          disabled={btnLoading}
                        >
                          {i18n.RefinancingSummary.agreement[1]}
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                      >
                        {i18n.RefinancingSummary.ctaBack}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinue}
                        disabled={!agreement}
                        fullWidth
                      >
                        {i18n.RefinancingSummary.ctaContinue}
                      </Button>
                    </Grid>
                  </Grid>
                  <LegalFooter loanDetailCost={refinancingContext.loanDetailCost} />
                </>
              )}
            </Grid>
          </Grid>
          {isMobileOperatingSystem() && (
            <>
              <Box mt={20} />
              <Grid container className={commonClasses.ctaBox} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    <Checkbox color="primary" onChange={() => setAgreement(!agreement)} />
                    {i18n.RefinancingSummary.agreement[0]}
                    <Button
                      style={{ padding: '0px', marginBottom: '2px' }}
                      disabled={btnLoading}
                      onClick={showAgreement}
                    >
                      {i18n.RefinancingSummary.agreement[1]}
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    fullWidth
                    disabled={!agreement}
                  >
                    {i18n.RefinancingSummary.ctaContinue}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleBack}
                    fullWidth
                  >
                    {i18n.RefinancingSummary.ctaBack}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {responseInitError && (
        <Grid container className={commonClasses.body} justify="center">
          <Grid item xs={10} md={6}>
            <MessageResponse
              response={responseInitError}
              referenceLabels={errorMessage}
            />
          </Grid>
        </Grid>
      )}
      {responseError && (
        <Grid container className={commonClasses.body} justify="center">
          <Grid item xs={10} md={6}>
            <img src={warningImg} alt="WARNING" style={{ marginBottom: '24px' }} />
            <Typography variant="h1" style={{ marginBottom: '20px' }}>{i18n.RefinancingSummary.error[0]}</Typography>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>{i18n.RefinancingSummary.error[1]}</Typography>
            <Typography style={{ marginBottom: '20px' }}>{i18n.RefinancingSummary.error[2]}</Typography>
            <Typography style={{ marginBottom: '20px' }}>
              {i18n.RefinancingSummary.error[3]}
              <a href={getWhatsappLink()} target="_blank" rel="noreferrer">{i18n.RefinancingSummary.error[4]}</a>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleBack}
              style={{ marginBottom: '20px' }}
            >
              {i18n.RefinancingSummary.error[5]}
            </Button>
            <Button
              variant="text"
              fullWidth
              onClick={handleGoInit}
            >
              {i18n.RefinancingSummary.error[6]}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RefinancingSummary;
