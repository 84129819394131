import { Box, Grid, Typography } from '@material-ui/core';
import ActionNotAvailable from '../../../components/common/ActionNotAvailable';

/* eslint arrow-body-style: ["error", "always"] */
const WikiActionNotAvailable = () => {
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Vacio:
          </Typography>
          <Box m={3} />
          <ActionNotAvailable />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo:
          </Typography>
          <Box m={3} />
          <ActionNotAvailable title="Titulo" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo:
          </Typography>
          <Box m={3} />
          <ActionNotAvailable title="Titulo" subTitle="Subtitle" />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo + boton whatsapp + mensaje whatsapp:
          </Typography>
          <Box m={3} />
          <ActionNotAvailable
            title="Titulo"
            subTitle="Subtitle"
            labelForWhatsappButton="Whatsapp"
            textForWhatsappLink="Hola"
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant="h5" noWrap>
            Titulo + subtitulo + boton whatsapp + mensaje whatsapp + mensaje tip  :
          </Typography>
          <Box m={3} />
          <ActionNotAvailable
            title="Titulo"
            subTitle="Subtitle"
            labelForWhatsappButton="Whatsapp"
            textForWhatsappLink="Hola"
            tipMessage="Tip message"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiActionNotAvailable;
