import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Radio } from '@material-ui/core';

const useStyles = makeStyles({
  subtitle: {
    fontSize: '0.8rem',
  },
});

const RadioButtonDebtor = ({
  title, subtitle, value, checked,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="flex-start" alignItems="center">
        <Grid item xs={2} style={{ flexBasis: '0' }}>
          <Radio
            checked={checked}
            value={value}
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        </Grid>
        <Grid item container xs={10} alignItems="center">
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12}>
              <Typography align="left">
                <b>
                  {title}
                </b>
              </Typography>
            </Grid>
            {subtitle
              ? (
                <Grid item xs={12}>
                  <Typography align="left" className={classes.subtitle}>
                    {subtitle}
                  </Typography>
                </Grid>
              )
              : (
                ''
              )}
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
    </>
  );
};

RadioButtonDebtor.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  checked: PropTypes.bool.isRequired,
};

RadioButtonDebtor.defaultProps = {
  subtitle: ' ',

};

export default RadioButtonDebtor;
