import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import RevolvingLineAmountStep from '../components/RevolvingLineAmountStep';
import Loading from '../components/common/Loading';
import RevolvingLineInstallmentsStep from '../components/RevolvingLineInstallmentsStep';
import RevolvingLineBiometricStep from '../components/RevolvingLineBiometricStep';
import RevolvingLineSummaryStep from '../components/RevolvingLineSummaryStep';
import MessageResponse from '../components/common/MessageResponse';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import {
  REVOLVING_LINE, BIOMETRIC_VALIDATION_ERROR, REVOLVING_LINE_SUMMARY_ERROR, INVALID_INSTALLMENT_PLAN,
} from '../utils/referenceConstant';
import {
  disburseLoan, generateBiometricOnboardingData, getDetailLoanCost, getInstallmentPlan,
  getPaymentMethodActiveByCuil, getPrepaidCardLastDigits, revolvineLineCreateLoanRequest, validateBiometricResult,
} from '../api/selfieWebService';
import { useAuth } from '../contexts/AuthContext';
import { getError, sendDataLayerEvent } from '../utils/functionsUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import i18n from '../common/i18n';
import { getRevolvingLineData, saveRevolvingLineData } from '../api/userStorage';
import useQueryParam from '../customHook/useQueryParam';

const RevolvingLine = () => {
  const queryParams = useQueryParam();
  const { currentUser } = useAuth();

  const history = useHistory();

  const [step, setStep] = useState('');
  const [loading, setLoading] = useState(true);
  const [maxAmountAvailable, setMaxAmountAvailable] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [installments, setInstallments] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [loanId, setLoanId] = useState();
  const [loanDetailCost, setLoanDetailCost] = useState();
  const [prepaidCard, setPrepaidCard] = useState();
  const [response, setResponse] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const {
    customerAccountStatus: {
      customerRevolvingLine = null,
    } = {},
  } = useCustomerAccountStatus() || {};

  const loadDataFromLocalStorage = () => {
    const revolvingLineData = getRevolvingLineData();
    setSelectedAmount(revolvingLineData.selectedAmount);
    setSelectedInstallment(revolvingLineData.selectedInstallment);
    setPrepaidCard(revolvingLineData.prepaidCard);
    setLoanId(revolvingLineData.loanId);
    return revolvingLineData;
  };

  const handleContinueForAmountStep = async (amount) => {
    try {
      setLoading(true);
      setSelectedAmount(Number(amount));
      const installmentListResp = await getInstallmentPlan(currentUser.customerTaxId, Number(amount));
      setInstallments(installmentListResp.data);
      setStep('installments');
    } catch (error) {
      setErrorMessage(INVALID_INSTALLMENT_PLAN);
      setResponse(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleContinueForInstallmentsStep = async (installment) => {
    try {
      setLoading(true);
      const createLoanRequest = {
        requestedAmount: selectedAmount,
        installmentAmount: installment.amount,
        installments: installment.installment,
        lineId: installment.lineId,
        disbursementMethod: 'PREPAID_CARD',
      };
      const loanRequestResponse = await revolvineLineCreateLoanRequest(currentUser.customerTaxId, createLoanRequest);
      setLoanId(loanRequestResponse.data.loanId);
      setSelectedInstallment(installment);
      setStep('biometric');
      if (loanRequestResponse.data.loanId) {
        sendDataLayerEvent({
          event: 'lc_cuota',
          monto: createLoanRequest.requestedAmount,
        });
      }
    } catch (error) {
      setErrorMessage(error?.data?.code);
      setResponse(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const createHiddenInput = (name, value) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = name;
    i.value = value;
    return i;
  };

  const generateThirdStep = (onboardingData) => {
    const f = document.createElement('form');
    const method = onboardingData.url.includes(window.location.origin) ? 'get' : 'post';

    f.setAttribute('method', method);
    f.setAttribute(
      'action',
      onboardingData.url,
    );

    f.appendChild(createHiddenInput('idapp', 'App Cliente'));
    f.appendChild(createHiddenInput('seckey', onboardingData.seckey));
    f.appendChild(createHiddenInput('country', 'ARG'));
    f.appendChild(createHiddenInput('idmaqc_service', onboardingData.idmaqcService));
    f.appendChild(createHiddenInput('profile_services', onboardingData.profileServices));
    f.appendChild(createHiddenInput('services', onboardingData.services));
    f.appendChild(createHiddenInput('externaltxid', onboardingData.externalId));
    f.appendChild(createHiddenInput('dni', onboardingData.customerId));
    f.appendChild(createHiddenInput('sexo', currentUser.gender));
    document.body.appendChild(f);
    f.submit();
  };

  const handleContinueForBiometricStep = async () => {
    try {
      setLoading(true);

      const res = await generateBiometricOnboardingData(
        currentUser.customerTaxId, getPathRouteFromKey(i18n.revolvingLine),
      );

      // Guardo la info en localStorage para que este lista luego del callback de la biometria
      saveRevolvingLineData({
        selectedAmount, selectedInstallment, prepaidCard, loanId,
      });

      generateThirdStep(res.data);
    } catch (error) {
      console.log();
    } finally {
      setLoading(false);
    }
  };

  const handleContinueForSummaryStep = async () => {
    try {
      setLoading(true);
      const res = await disburseLoan(currentUser.customerTaxId, loanId);
      res.data = { amount: selectedAmount };
      setResponse(res);
      sendDataLayerEvent({
        event: 'lc_finalizado',
        monto: selectedAmount,
        cant_cuotas: selectedInstallment,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const retry = () => {
    setResponse(undefined);
    setErrorMessage(undefined);
  };

  const goToMyCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardKey));
  };

  const onHit = async () => {
    try {
      setLoading(true);
      const revolvingLineData = loadDataFromLocalStorage();

      const detailLoanCostResponse = await getDetailLoanCost(revolvingLineData.loanId);
      setLoanDetailCost(detailLoanCostResponse.data);

      const prepaidCardInfoResponse = await getPrepaidCardLastDigits(currentUser.customerTaxId);
      setPrepaidCard(prepaidCardInfoResponse.data);

      const paymentMethodResponse = await getPaymentMethodActiveByCuil(
        currentUser.customerTaxId, revolvingLineData.loanId,
      );

      if (paymentMethodResponse.data?.debitCardNumber) {
        setPaymentMethod(paymentMethodResponse.data);
      } else {
        throw new Error();
      }

      setStep('summary');

      sendDataLayerEvent({
        event: 'lc_confirmacion',
        monto: selectedAmount,
        cant_cuotas: selectedInstallment,
      });
    } catch (error) {
      setErrorMessage(REVOLVING_LINE_SUMMARY_ERROR);
      setResponse({ status: 400, data: {} });
    } finally {
      setLoading(false);
    }
  };

  const clearQueryParams = () => {
    queryParams.delete('step');
    queryParams.delete('idtx');
    queryParams.delete('externalID');
    queryParams.delete('externaltrxid');
    queryParams.delete('externaltxid');
    queryParams.delete('decision');
    history.replace({
      search: queryParams.toString(),
    });
  };

  const processBiometricResult = async () => {
    try {
      setLoading(true);
      const idtx = queryParams.get('idtx');
      const externalID = queryParams.get('externaltxid') || queryParams.get('externaltrxid')
        || queryParams.get('externalID');

      const res = await validateBiometricResult(idtx, externalID);

      const { decision } = res.data;
      if (decision === 'HIT') {
        sendDataLayerEvent({
          event: 'lc_validacion',
          monto: selectedAmount,
          cant_cuotas: selectedInstallment,
        });
        onHit();
      } else {
        loadDataFromLocalStorage();
        setStep('biometric');
        setErrorMessage(BIOMETRIC_VALIDATION_ERROR);
        setResponse({ status: 400, data: {} });
        setLoading(false);
      }
    } catch (error) {
      loadDataFromLocalStorage();
      setStep('biometric');
      setLoading(false);
    }
  };

  const handleBiometricCallback = () => {
    const externalID = queryParams.get('externaltxid') || queryParams.get('externaltrxid')
      || queryParams.get('externalID');
    const stepBiometric = queryParams.get('step');

    if (stepBiometric) {
      setStep('biometric');
      clearQueryParams();
      setLoading(false);
    } else if (externalID) {
      processBiometricResult();
      clearQueryParams();
    } else {
      if (customerRevolvingLine?.remainingAmount) {
        setMaxAmountAvailable(customerRevolvingLine.remainingAmount);
      }
      setStep('amount');
      setLoading(false);
    }
  };

  const init = () => {
    try {
      handleBiometricCallback();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {loading && <Loading />}

      {response ? (
        <MessageResponse
          response={response}
          referenceLabels={errorMessage || REVOLVING_LINE}
          onSuccessPrimary={goToMyCard}
          onErrorPrimary={retry}
          canGoHome
        />
      ) : (
        <>
          {/* Pantalla ingreso de monto a solicitar */}
          {step === 'amount' && !loading && (
            <RevolvingLineAmountStep maxAmountAvailable={maxAmountAvailable} onContinue={handleContinueForAmountStep} />
          )}

          {/* Pantalla seleccion plan de cuotas */}
          {step === 'installments' && !loading && (
            <RevolvingLineInstallmentsStep
              amount={selectedAmount}
              installments={installments}
              onContinue={handleContinueForInstallmentsStep}
            />
          )}

          {/* Pantalla de biometria */}
          {step === 'biometric' && !loading && (
            <RevolvingLineBiometricStep onContinue={handleContinueForBiometricStep} />
          )}

          {/* Pantalla de resumen */}
          {step === 'summary' && !loading && (
            <RevolvingLineSummaryStep
              amount={selectedAmount}
              installment={selectedInstallment}
              loanDetailCost={loanDetailCost}
              prepaidCard={prepaidCard}
              loanId={loanId}
              paymentMethod={paymentMethod}
              onContinue={handleContinueForSummaryStep}
            />
          )}
        </>
      )}
    </>
  );
};

export default RevolvingLine;
