import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import Container from '@material-ui/core/Container';
import Header from '../components/common/Header';
import CardButton from '../components/CardButton';
import imgOtherServicesLogo from '../assets/imgs/logo_otro_servicios.svg';
import imgCelu from '../assets/imgs/celular.svg';
import imgFactura from '../assets/imgs/factura.png';
import i18n from '../common/i18n';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { sendDataLayerEvent } from '../utils/functionsUtil';

function OtherServices() {
  const history = useHistory();

  const onClickRecharge = () => {
    history.push(`${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=69`);
  };

  const onClickServices = () => {
    sendDataLayerEvent({
      event: 'pagar_servicios_y_facturas',
    });
    history.push(`${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=91`);
  };

  return (
    <>
      <Header title={i18n.otherServicesHeaderTitle} />
      <Box m={3} />
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid item xs={10} md={10}>
            <Box m={3} />
            <img
              src={imgOtherServicesLogo}
              className="img-other-services-logo"
              height="120px"
              alt=" "
            />
            <Box m={4} />
            <Typography variant="h5">{i18n.otherServicesHeaderSubTitle}</Typography>
            <Box m={6} />
          </Grid>
          <Grid item md={4} xs={11}>
            <CardButton
              title={i18n.otherServicesRechargeTitle}
              urlImage={imgCelu}
              onClick={onClickRecharge}
            />
          </Grid>
          <Box m={4} />
          <Grid item md={4} xs={11}>
            <CardButton
              title={i18n.otherServicesBillPaymentTitle}
              urlImage={imgFactura}
              onClick={onClickServices}
            />
          </Grid>
        </Grid>
        <Box m={3} />
      </Container>
    </>
  );
}

export default OtherServices;
