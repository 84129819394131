import {
  Box, Button, Grid, makeStyles, Typography,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import i18n from '../../common/i18n';
import ErrorImage from '../../assets/imgs/error.svg';
import Header from '../../components/header/Header';
import { getEnv, URL_WHATSAPP } from '../../api/env';

const useStyles = makeStyles({
  whatsapp: {
    borderRadius: '25px',
    backgroundColor: '#00bb2d',
    color: 'white',
    textTransform: 'none',
    fontWeight: '700',
    fontFamily: 'Open sans',
    '&:hover': {
      backgroundColor: '#00911f',
    },
  },
});

function LoginCodeNoReception() {
  const classes = useStyles();

  const goWhatsappCac = () => {
    window.open(getEnv(URL_WHATSAPP) + i18n.whatsappCACCodeNoReception);
  };

  return (
    <>
      <Header />
      <Box m={6} mt={16} />
      <Grid container justify="center">
        <Grid item xs={11} md={8}>
          <img src={ErrorImage} alt="Error" width="100px" />
          <Box m={3} />
          <Typography variant="h2" style={{ fontSize: '2rem' }}>{i18n.loginCodeNoReceptionTitle}</Typography>
          <Box m={3} />
          <Typography variant="h5" component="h3">{i18n.loginCodeNoReceptionSubtitle}</Typography>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={11} md={3}>
          <Box m={6} />
          <Button
            variant="contained"
            className={classes.whatsapp}
            onClick={goWhatsappCac}
            startIcon={<WhatsAppIcon />}
          >
            {i18n.loginCodeNoReceptionCTA}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default LoginCodeNoReception;
