import {
  Box, Grid, Container, makeStyles, Typography, Button,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getPrepaidCardSecuredInfoUrl } from '../api/selfieWebService';
import imgIconShowInfo from '../assets/imgs/icon-show-info.svg';
import imgIconHideInfo from '../assets/imgs/icon-hide-info.svg';
import imgPrepaidHideInfoBackground from '../assets/imgs/prepaidcard-hide-info.svg';
import imgPrepaidShowInfoBackground from '../assets/imgs/prepaidcard-show-info.svg';
import i18n from '../common/i18n';
import Header from '../components/common/Header';
import MessageResponse from '../components/common/MessageResponse';
import Loading from '../components/common/Loading';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import { PRE_PAID_CARD_DETAILS } from '../utils/referenceConstant';
import {
  getEnv, PREPAID_CARD_STYLE_URL,
} from '../api/env';

const useStyles = makeStyles({
  containerCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardInfoMessage: {
    fontFamily: 'Open sans',
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'justify',
    position: 'relative',
    width: '400px',
  },
  buttonShowInfoAction: {
    fontFamily: 'Open sans',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-evenly',
    position: 'relative',
  },
  exampleImgWrapper: {
    overflow: 'hidden',
    border: 0,
    position: 'relative',
    width: '400px',
    height: '280px',
    top: 0,
    left: 0,
  },
  iframeWrapper: {
    overflow: 'hidden',
    border: 0,
    position: 'relative',
    width: '400px',
    height: '280px',
    top: 0,
    left: 0,
    backgroundImage: `url(${imgPrepaidShowInfoBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '400px 280px',
  },
});

const PrePaidCardDetails = () => {
  // Context
  const classes = useStyles();
  const { currentUser } = useAuth();
  const history = useHistory();

  const stylesUrlParam = `&styles=${getEnv(PREPAID_CARD_STYLE_URL)}`;
  const localeParam = '&locale=es';
  const countryParam = '&country=ARG';

  const [cardInfoUrl, setCardInfoUrl] = useState(undefined);
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [initLoading, setInitLoading] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const initPrePaidCard = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await getPrepaidCardSecuredInfoUrl(currentUser.customerTaxId);
        if (res.data.length > 0) {
          setCardInfoUrl(`${res.data}${stylesUrlParam}${localeParam}${countryParam}`);
        } else {
          setResponse(getError(undefined));
        }
      }
      setInitLoading(false);
    } catch (error) {
      setResponse(error);
    }
  };

  const reload = () => {
    setResponse(undefined);
    initPrePaidCard();
  };

  useEffect(() => initPrePaidCard(), []);

  return (
    <>
      <Header title={i18n.PrepaidCardHeaderTitle} />
      <Box m={1} />
      <Container>

        {response !== undefined && (
          <MessageResponse
            response={response}
            referenceLabels={PRE_PAID_CARD_DETAILS}
            onErrorPrimary={reload}
            canGoHome
          />
        )}

        {response === undefined && initLoading === true && (
          <Loading />
        )}

        {response === undefined && initLoading === false && (
          <>
            <Grid container alignContent="left">
              <Grid item>
                <Button
                  startIcon={<ArrowBack />}
                  onClick={goBack}
                  style={{ color: 'black' }}
                >
                  {i18n.prePaidCardActivateArrowButtonGoBack}
                </Button>
                <Box m={1} />
              </Grid>
            </Grid>
            <Grid container className={classes.containerCenter}>
              <Grid item>
                <Typography className={classes.cardInfoMessage}>
                  {i18n.prePaidCardDetailsMessage}
                </Typography>
              </Grid>
              <Grid item hidden={showCardInfo}>
                <Box m={3} />
                <img src={imgPrepaidHideInfoBackground} className={classes.exampleImgWrapper} alt="cardInfoExample" />
              </Grid>
              <Grid item hidden={!showCardInfo}>
                <Box m={3} />
                <iframe
                  src={cardInfoUrl}
                  title="cardInfo"
                  className={classes.iframeWrapper}
                  allow="clipboard-write"
                />
              </Grid>
              <Grid item>
                {!showCardInfo
                  ? (
                    <Button
                      onClick={() => {
                        setShowCardInfo(true);
                      }}
                      variant="outlined"
                      color="primary"
                      className={classes.buttonShowInfoAction}
                      style={{ width: '160px' }}
                    >
                      <img src={imgIconShowInfo} alt="showInfoIcon" />
                      {i18n.prePaidCardDetailsButtonViewInfo}
                    </Button>
                  )
                  : (
                    <Button
                      onClick={() => {
                        setShowCardInfo(false);
                      }}
                      variant="outlined"
                      color="primary"
                      className={classes.buttonShowInfoAction}
                      style={{ width: '180px' }}
                    >
                      <img src={imgIconHideInfo} alt="hideInfoIcon" />
                      {i18n.prePaidCardDetailsButtonHideInfo}
                    </Button>
                  )}
              </Grid>
            </Grid>
          </>
        )}

      </Container>
    </>
  );
};

export default PrePaidCardDetails;
