import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import CardCustomerDebtorAccordion from './CardCustomerDebtorAccordion';
import CardOfferWrapper from './CardOfferWrapper';
import PaymentSelection from './PaymentSelection';
import PaymentLoanSelection from './PaymentLoanSelection';
import i18n from '../common/i18n';

const Payment = ({
  paymentMethodKey, loans, onResetMethodSelection, onSubmitPayment, loading,
}) => {
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  return (
    <>
      {customerIsUpToDate === false && (
        <CardCustomerDebtorAccordion
          totalInstallmentDebt={totalInstallmentDebt}
          totalPunitiveDebt={totalPunitiveDebt}
          totalDebt={totalDebt}
        />
      )}
      <Box mt={3} />
      <Grid container justify="center">
        <CardOfferWrapper useSmallBackgroundImage />
      </Grid>
      {paymentMethodKey === i18n.PaymentDebitCardFormKey
        || paymentMethodKey === i18n.PaymentMPFormKey
        || paymentMethodKey === i18n.payInstallmentQRKey ? (
        // primero debe seleccionar el credito
          <PaymentLoanSelection
            loans={loans}
            onSubmit={onSubmitPayment}
            paymentMethodKey={paymentMethodKey}
            onResetMethodSelection={onResetMethodSelection}
            loading={loading}
          />
        ) : (
        // Otros pagos
          <PaymentSelection
            loans={loans}
            onSubmit={onSubmitPayment}
            paymentMethodKey={paymentMethodKey}
            onResetMethodSelection={onResetMethodSelection}
            loading={loading}
          />
        )}
    </>

  );
};

Payment.propTypes = {
  paymentMethodKey: PropTypes.string.isRequired,
  loans: PropTypes.arrayOf(PropTypes.shape({ externalId: PropTypes.string.isRequired })).isRequired,
  onResetMethodSelection: PropTypes.func.isRequired,
  onSubmitPayment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Payment.defaultProps = {
  loading: false,
};

export default Payment;
