import {
  Box,
  Button,
  Grid, Hidden, Typography,
  useMediaQuery,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import Header from '../../components/header/Header';
import LoginIdentityResolverStep from '../../components/LoginIdentityResolverStep';
import MessageResponse from '../../components/common/MessageResponse';
import { LOGIN_IDENTITY_RESOLVER } from '../../utils/referenceConstant';
import { useLoginIdentityResolver } from '../../services/loginService';
import { useLoginData } from '../../contexts/LoginDataContext';
import useStyle from './loginStyle';
import Loading from '../../components/common/Loading';

function LoginIdentityResolver() {
  window.scrollTo(0, 0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyle();
  const { currentLoginData } = useLoginData();
  const {
    initLoading, response, goToNext, goToDniValidationStep,
  } = useLoginIdentityResolver();

  return (
    <>
      <Header />
      <Grid container className={classes.container}>
        <Grid item xs={1} md={1} />
        <Grid item xs={10} md={5} style={{ marginTop: '120px' }}>
          {initLoading ? (
            <Loading />
          ) : (
            <>
              {response === undefined ? (
                <>
                  <Box style={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px',
                  }}
                  >
                    <Typography
                      variant="h1"
                      align="left"
                      style={{
                        color: '#212121', fontFamily: 'Open sans', fontSize: '1.5rem',
                      }}
                    >
                      {i18n.loginIdentityResolverTitle}
                    </Typography>
                    <Hidden only={['xs', 'sm']}>
                      <Button onClick={goToDniValidationStep}>
                        {i18n.loginIdentityResolverNone}
                      </Button>
                    </Hidden>
                  </Box>
                  <LoginIdentityResolverStep
                    users={currentLoginData.users}
                    goToNext={goToNext}
                  />
                  <Hidden only={['md', 'lg', 'xl']}>
                    <Box style={{ display: 'flex', marginTop: '8px' }}>
                      <Button onClick={goToDniValidationStep}>
                        {i18n.loginIdentityResolverNone}
                      </Button>
                    </Box>
                  </Hidden>
                  <Box className={classes.ctaContainer}>
                    <Button variant={isMobile ? 'text' : 'outlined'} onClick={goToDniValidationStep}>
                      {i18n.loginIdentityResolverGoBack}
                    </Button>
                  </Box>
                </>
              ) : (
                <MessageResponse
                  response={response}
                  referenceLabels={LOGIN_IDENTITY_RESOLVER}
                  onErrorPrimary={goToDniValidationStep}
                  canGoHome={false}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={1} md={6} />
      </Grid>
    </>
  );
}
export default LoginIdentityResolver;
