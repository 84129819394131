import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Grid, Typography, makeStyles,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import { fillMessageWith } from '../../utils/functionsUtil';
import {
  moneyFormatter, moneyFormatterWithoutDecimals, dateFormatter,
} from '../../utils/formatterUtil';
import RevolvingLineDetailAccordion from './RevolvingLineDetailAccordion';
import { DISBURSEMENT_MODES } from '../../constants/selfieConstants';

const useStyles = makeStyles(() => ({
  loanAmount: {
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#212121',
  },
  loanDetail: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    color: '#424242',
  },
  loanDetailTitle: {
    fontSize: '14px',
    color: '#757575',
  },
  loanDetailInfo: {
    fontWeight: '400',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    color: '#424242',
    lineHeight: '24px',
  },
}));

const RevolvingLineDetailLoansInstallments = ({ loanInstallments }) => {
  const classes = useStyles();

  const isPaymentQR = (movement) => movement.disbursementMode === DISBURSEMENT_MODES.PAYMENT_QR;
  // Si no es pago con QR asumimos es recarga

  return (
    <>
      <Grid container style={{ marginTop: '5px' }}>
        {loanInstallments.map((inst) => (
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            <Typography className={classes.loanAmount} style={{ marginBottom: '5px' }}>
              {parse(fillMessageWith(i18n.RevolvingLine.Detail.loanAmount[isPaymentQR(inst) ? 1 : 0],
                moneyFormatterWithoutDecimals(inst.totalDisburse)))}
            </Typography>
            {isPaymentQR(inst) && (
            <Typography className={classes.loanDetail}>
              {inst.commerceName}
            </Typography>
            )}
            <Typography className={classes.loanDetail} style={{ marginTop: '5px' }}>
              {fillMessageWith(i18n.RevolvingLine.Detail.loanDueDate[isPaymentQR(inst) ? 1 : 0],
                dateFormatter(inst.createdDate))}
            </Typography>
            <RevolvingLineDetailAccordion
              title={(
                <span className={classes.loanDetailTitle}>
                  {i18n.RevolvingLine.Detail.loanDetail}
                </span>
              )}
            >
              <Typography className={classes.loanDetailInfo}>
                {/*  si tiene installmentNumber es un credito en curso, cuando es un cancelado viene null */}
                {inst.installmentNumber
                  ? fillMessageWith(i18n.RevolvingLine.Detail.loanInstallments,
                    inst.installmentNumber, inst.installments, moneyFormatter(inst.installmentAmount))
                  : fillMessageWith(i18n.RevolvingLine.Detail.loanInstallmentsFinished[
                    inst.installments > 1 ? 1 : 0], inst.installments, moneyFormatter(inst.installmentAmount))}
              </Typography>
            </RevolvingLineDetailAccordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

RevolvingLineDetailLoansInstallments.propTypes = {
  loanInstallments: PropTypes.arrayOf(PropTypes.shape({
    idLoan: PropTypes.number,
    totalDisburse: PropTypes.number,
    createdDate: PropTypes.string,
    installmentNumber: PropTypes.number,
    installments: PropTypes.number,
    installmentAmount: PropTypes.number,
  })).isRequired,
};

export default RevolvingLineDetailLoansInstallments;
