import { TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const TextInput = ({
  name, label, mandatory, helperText, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          helperText={errors[name]?.message || helperText}
          error={!!errors[name]}
          label={mandatory ? `${label}*` : label}
        />
      )}
    />
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
};

TextInput.defaultProps = {
  mandatory: false,
  variant: 'outlined',
  helperText: undefined,
};

export default TextInput;
