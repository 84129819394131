import {
  Box,
  Grid, TextField, makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import Cards from 'react-credit-cards';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import 'react-credit-cards/es/styles-compiled.css';
import LoadingButton from './common/LoadingButton';
import {
  formatExpirationDate, formatCVC, formatCreditCardNumber, formatDNI, formatName,
} from '../utils/cardUtils';

const useStyles = makeStyles(() => ({
  margin10: {
    marginBottom: '10px',
  },
}));

const DebitCardFormNew = ({ onCTA }) => {
  const stateInit = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    dni: '',
  };

  const [state, setState] = useState(stateInit);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    dni: false,
    number: false,
    name: false,
    expiry: false,
    cvc: false,
  });

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.name });
  };

  const applyMask = (field, value) => {
    switch (field) {
      case 'number': return formatCreditCardNumber(value);
      case 'expiry': return formatExpirationDate(value);
      case 'cvc': return formatCVC(value);
      case 'dni': return formatDNI(value);
      case 'name': return formatName(value);
      default: return value;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maskedValue = applyMask(name, value);
    setState({ ...state, [name]: maskedValue });
  };

  const isExpiryDateError = () => {
    let hasError = false;
    if (state.expiry.trim().length < 5) {
      hasError = true;
    } else {
      const month = state.expiry.trim().split('/')[0];
      if (month < 1 || month > 12) {
        hasError = true;
      }

      const year = state.expiry.trim().split('/')[1];
      const currentYear = new Date().getFullYear().toString().substr(-2);

      if (year < currentYear) {
        hasError = true;
      }

      const currentMonth = new Date().getMonth() + 1;
      if (year === currentYear && month <= currentMonth) {
        hasError = true;
      }
    }
    return hasError;
  };

  const isValidForm = () => {
    const formErrors = { ...errors };

    formErrors.dni = (state.dni.trim().length < 6);
    formErrors.number = (state.number.trim().length < 12);
    formErrors.name = (state.name.trim().length === 0);
    formErrors.expiry = isExpiryDateError();
    formErrors.cvc = (state.cvc.trim().length !== 3);

    setErrors(formErrors);

    return !(formErrors.dni || formErrors.number || formErrors.name || formErrors.expiry || formErrors.cvc);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidForm()) {
      setLoading(true);
      onCTA(state);
    }
  };

  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center">
          <Grid item xs={11} sm={6} ms={4} id="PaymentForm">
            <Cards
              cvc={state.cvc}
              expiry={state.expiry}
              focused={state.focus}
              name={state.name}
              number={state.number}
            />
            <Box m={4} />
          </Grid>
        </Grid>
        <Grid container justify="center" alignContent="center">
          <Grid item container xs={11} md={6} spacing={1}>
            <Grid item xs={12} className={classes.margin10}>
              <TextField
                variant="outlined"
                name="dni"
                label={i18n.debitCardFormCardIdNumber}
                value={state.dni}
                inputProps={{ maxLength: 8 }}
                fullWidth
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                error={errors.dni}
                helperText={errors.dni ? i18n.debitCardFormValidationDni : ''}
              />
            </Grid>
            <Grid item xs={12} className={classes.margin10}>
              <TextField
                variant="outlined"
                name="number"
                label={i18n.debitCardFormCardNumber}
                value={state.number}
                fullWidth
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                error={errors.number}
                helperText={errors.number ? i18n.debitCardFormValidationNumber : ''}
              />
            </Grid>
            <Grid item xs={12} className={classes.margin10}>
              <TextField
                variant="outlined"
                name="name"
                label={i18n.debitCardFormName}
                value={state.name}
                fullWidth
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                error={errors.name}
                helperText={errors.name ? i18n.debitCardFormValidationName : i18n.debitCardFormHintName}
              />
            </Grid>
            <Grid item xs={6} className={classes.margin10}>
              <TextField
                variant="outlined"
                name="expiry"
                label={i18n.debitCardFormDueDate}
                fullWidth
                value={state.expiry}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                error={errors.expiry}
                helperText={errors.expiry ? i18n.debitCardFormValidationExpiry : ''}
              />
            </Grid>
            <Grid item xs={6} className={classes.margin10}>
              <TextField
                variant="outlined"
                name="cvc"
                label={i18n.debitCardFormSecurityCode}
                value={state.cvc}
                fullWidth
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                error={errors.cvc}
                helperText={errors.cvc ? i18n.debitCardFormValidationCvc : ''}
              />
            </Grid>
            <Box m={2} />
            <Grid item container xs={12} justify="center">
              <Grid item xs={12} md={4}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  type="submit"
                  loading={loading}
                >
                  {i18n.debitCardFormCTA}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

DebitCardFormNew.propTypes = {
  onCTA: PropTypes.func.isRequired,
};

export default DebitCardFormNew;
