import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import {
  Box, Button, Grid, IconButton, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import i18n from '../common/i18n';
import QRCodePayment from './QRCodePayment';
import PrePaidLoanInfoBox from './PrePaidLoanInfoBox';
import ShareIcon from '../assets/imgs/share-icon.svg';
import { getQrCodePayment } from '../api/selfieWebService';

const PrePaidLoanPaymentMethodQR = ({ loan, cancelationData, onChange }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [QR, setQR] = useState();
  const [loading, setLoading] = useState(true);

  const exportRef = useRef();

  const onShare = async () => {
    const canvas = await html2canvas(exportRef.current);
    const dataUrl = canvas.toDataURL('image/png');
    const blob = await (await fetch(dataUrl)).blob();
    const filesArray = [new File([blob], 'qrcode.png', { type: blob.type, lastModified: new Date().getTime() })];
    const shareData = {
      files: filesArray,
    };
    navigator.share(shareData);
  };

  const init = async () => {
    try {
      setLoading(true);
      const res = await getQrCodePayment(loan.externalId);
      if (res.data) {
        setQR(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => init(), []);

  return (
    <Grid container justify="center">
      <Grid item xs={11} md={6}>
        <Box m={3} />
        <Typography variant="h2">{i18n.prePaidLoanPaymentMethodQRTitle}</Typography>
        <Box m={5} />
        <Typography variant="h5">
          {i18n.prePaidLoanPaymentMethodQRDescription}
        </Typography>
        {QR && (
          <Box mt={5} ref={exportRef}>
            <QRCodePayment value={QR} loading={loading} />
          </Box>
        )}
        <Box m={5} />
        <PrePaidLoanInfoBox loan={loan} cancelationData={cancelationData} />
        <Box mt={5} />
        <Typography>
          <strong>{i18n.prePaidLoanPaymentMethodAskFreeDebt}</strong>
        </Typography>
        <Box mt={5} />
        {isMobile && (
          <>
            <Box mt={5} />
            <Typography variant="h6">
              {i18n.payInstallmentQRCodeShareImageMessage}
            </Typography>
            <Box m={3} />
            <Button
              onClick={onShare}
              variant="outlined"
              color="primary"
              fullWidth
            >
              <Typography>
                {i18n.payInstallmentQRCodeShareImageButton}
                <IconButton>
                  <img src={ShareIcon} alt="share" />
                </IconButton>
              </Typography>
            </Button>
          </>
        )}
        <Box mt={3} />
        <Button
          onClick={onChange}
          color="primary"
        >
          {i18n.prePaidLoanPaymentMethodChangeMethod}
        </Button>
      </Grid>
    </Grid>
  );
};

PrePaidLoanPaymentMethodQR.propTypes = {
  loan: PropTypes.shape().isRequired,
  cancelationData: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PrePaidLoanPaymentMethodQR;
