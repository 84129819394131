import { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { components } from './wikiConstants';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: theme.spacing(8), // Agrega margen superior para el espacio del encabezado
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8), // Agrega margen superior para el espacio del encabezado
  },
}));

const WikiComponents = () => {
  const classes = useStyles();
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleComponentClick = (component) => {
    setSelectedComponent(component);
    setMenuOpen(false); // Cerrar el menú al seleccionar una opción
  };

  const renderComponent = () => selectedComponent?.component;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            WIKI - Componentes
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={menuOpen}
        onClose={handleMenuToggle}
      >
        <div className={classes.drawerContainer}>
          <List>
            {components.map((component) => (
              <ListItem button onClick={() => handleComponentClick(component)}>
                <ListItemText primary={component.name} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Container>
          {/* Renderiza el componente seleccionado */}
          {renderComponent()}
        </Container>
      </main>
    </div>
  );
};

export default WikiComponents;
