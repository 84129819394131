import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext(undefined);

// wrapper for the provider
export const AuthProvider = ({ user, children }) => {
  const [currentUser, setCurrentUser] = useState(user);
  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  user: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
AuthProvider.defaultProps = {
  user: undefined,
};

export const useAuth = () => useContext(AuthContext);
