import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  card: {
    boxShadow: '0px 0px 1px 0px',
    backgroundColor: 'white',
    height: '123px',
    width: '130px',
    '@media (max-width:600px)': {
      height: '123px',
      width: '103px',
    },
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '0.95rem',
    fontWeight: '700',
    lineHeight: '1.5',
  },
});

function CardHome({
  title, urlImage, pathOnClick, isExternalLink,
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleOnClick = () => {
    if (isExternalLink) {
      window.open(pathOnClick, '_blank');
    } else {
      history.push(pathOnClick);
    }
  };

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardActionArea disableRipple onClick={handleOnClick}>
          <CardContent style={{ padding: '10px' }}>
            <img src={urlImage} height="50px" alt="" />
            <Box m={1} />
            <Typography className={classes.title}>
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

CardHome.propTypes = {
  title: PropTypes.string,
  urlImage: PropTypes.string.isRequired,
  pathOnClick: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
};
CardHome.defaultProps = {
  title: '',
  isExternalLink: false,
};

export default CardHome;
