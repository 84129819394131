import fetch from './api';
import { API_SELFIE, getEnv } from './env';

const urlBackend = getEnv(API_SELFIE);

export const hasCredentials = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/authenticate/${customerTaxId}/credentials`,
    method: 'get',
  });

export const setCredentials = (credentials) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/authenticate',
    method: 'put',
    data: credentials,
  });
