import { Box, Grid, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Header from '../components/common/Header';
import i18n from '../common/i18n';
import MessageResponse from '../components/common/MessageResponse';
import { useAuth } from '../contexts/AuthContext';
import {
  findActiveLoansByCustomerTaxId,
  getActiveLoansWithMoreDaysPastDue,
  getPaymentVoucherPdf,
  getPaymentVoucherFile,
} from '../api/selfieWebService';
import ActionNotAvailable from '../components/common/ActionNotAvailable';
import { PAY_INSTALLMENT } from '../utils/referenceConstant';
import PaymentMethodSelection from '../components/PaymentMethodSelection';
import Payment from '../components/Payment';
import CardOfferWrapper from '../components/CardOfferWrapper';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import useHomeNavigation from '../customHook/useHomeNavigation';
import { getEnv, URL_PAY_ONLINE } from '../api/env';
import { generateLinkWithFileInResponse, getError } from '../utils/functionsUtil';
import Toast from '../components/common/Toast';
import Loading from '../components/common/Loading';
import useQueryParam from '../customHook/useQueryParam';

const PayInstallment = () => {
  // Context
  const { currentUser } = useAuth();
  const [loans, setLoans] = useState();
  const [loansWithoutRevolvingLineLoans, setLoansWithoutRevolvingLineLoans] = useState(undefined);
  const [loansAndRevolvingLineLoans, setLoansAndRevolvingLineLoans] = useState(undefined);
  const [loanWithMoreDaysPastDue, setLoanWithMoreDaysPastDue] = useState(undefined);
  const [noActiveLoans, setNoActiveLoans] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [initError, setInitError] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const queryParam = useQueryParam();

  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  const [loading, setLoading] = useState(false);
  const [downloadFileSuccess, setDownloadFileSuccess] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);

  const goHome = useHomeNavigation();

  const goToHome = () => {
    goHome();
  };

  const processPayInstallmentCashSubmit = async (data) => {
    setLoading(true);
    try {
      if (customerIsUpToDate) {
        const res = await getPaymentVoucherFile(currentUser.customerId, data.optionToPay);
        generateLinkWithFileInResponse(res);
        setDownloadFileSuccess(true);
        setOpenToast(true);
      } else {
        const amount = data.partialAmount !== undefined ? data.partialAmount : totalDebt;
        const res = await getPaymentVoucherPdf(currentUser.customerTaxId, amount);
        generateLinkWithFileInResponse(res);
        setDownloadFileSuccess(true);
        setOpenToast(true);
      }
    } catch (error) {
      setDownloadFileSuccess(false);
      setOpenToast(true);
    }
    setLoading(false);
  };

  const processPayInstallmentOnlineSubmit = (data) => {
    setLoading(true);
    if (customerIsUpToDate) {
      window.open(`${getEnv(URL_PAY_ONLINE)}?idcredito=${data.optionToPay}`);
    } else {
      window.open(`${getEnv(URL_PAY_ONLINE)}?idcredito=${loanWithMoreDaysPastDue}`);
    }
    setResponse({ status: 200 });
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setDownloadFileSuccess(undefined);
  };

  const onSubmitPayment = (data) => {
    switch (paymentMethodSelected) {
      case i18n.PaymentDebitCardFormKey: processPayInstallmentOnlineSubmit(data); break;
      case i18n.PaymentMPFormKey: processPayInstallmentOnlineSubmit(data); break;
      case i18n.payInstallmentCashKey: processPayInstallmentCashSubmit(data); break;
      case i18n.payInstallmentBankTrxKey: goToHome(); break;
      case i18n.payInstallmentEPaymentKey: goToHome(); break;
      default: break;
    }
  };

  const setPaymentMethod = (idMethod) => {
    console.log(idMethod);
    switch (idMethod) {
      case i18n.payInstallmentQRKey:
        setLoans(loansAndRevolvingLineLoans);
        break;
      case i18n.payInstallmentBankTrxKey:
        setLoans(loansAndRevolvingLineLoans);
        break;
      default:
        setLoans(loansWithoutRevolvingLineLoans);
        break;
    }
    setPaymentMethodSelected(idMethod);
    console.log(idMethod);
  };

  const resetPaymentMethodSelection = () => {
    setPaymentMethodSelected(undefined);
  };

  const getLoansWithoutRevolvingLine = (loansData) => loansData.filter((loan) => loan.productId !== 46);

  const getLoansAndRevolvingLineLoans = (loansData) => {
    const filteredLoans = [];
    let firstRevolvingLineLoan = false;
    loansData.forEach((loan) => {
      if (loan.productId !== 46) {
        filteredLoans.push(loan);
      } else if (!firstRevolvingLineLoan) {
        filteredLoans.push(loan);
        firstRevolvingLineLoan = true;
      }
    });
    return filteredLoans;
  };

  const initPaymentInstallment = async () => {
    try {
      setInitLoading(true);
      if (currentUser.customerTaxId !== undefined) {
        const res = await findActiveLoansByCustomerTaxId({ customerTaxId: currentUser.customerTaxId });
        if (res.data.length > 0) {
          setLoansWithoutRevolvingLineLoans(getLoansWithoutRevolvingLine(res.data));
          setLoansAndRevolvingLineLoans(getLoansAndRevolvingLineLoans(res.data));
          setLoans(res.data);

          if (!customerIsUpToDate) {
            const loansWithMoreDaysPastDueResponse = await getActiveLoansWithMoreDaysPastDue(currentUser.customerTaxId);
            setLoanWithMoreDaysPastDue(loansWithMoreDaysPastDueResponse.data[0].loanId);
          }

          const method = queryParam.get('m');
          if (method) {
            setPaymentMethod(method);
          }
        } else {
          setNoActiveLoans(true);
        }
      }
      setInitLoading(false);
    } catch (error) {
      setInitError(true);
      setResponse(getError(error));
    }
  };
  useEffect(() => initPaymentInstallment(), []);

  const reload = () => {
    setResponse(undefined);
    setInitError(false);
    initPaymentInstallment();
  };

  return (
    <>
      <Header title={i18n.payInstallmentHeaderTitle} />
      {response === undefined && initLoading === true && (
        <Loading />
      )}
      {response === undefined && initLoading === false && (
        <>

          {!noActiveLoans && loans !== undefined && (
            <>
              {paymentMethodSelected === undefined ? (
                <PaymentMethodSelection onClickMethod={setPaymentMethod} loans={loans} />
              ) : (
                <>
                  <Payment
                    paymentMethodKey={paymentMethodSelected}
                    loans={loans}
                    onResetMethodSelection={resetPaymentMethodSelection}
                    onSubmitPayment={onSubmitPayment}
                    loading={loading}
                  />
                </>
              )}
            </>
          )}
          {noActiveLoans && (
            <>
              <ActionNotAvailable
                title={i18n.payInstallmentActionNotAvailableTitle}
                subTitle={i18n.payInstallmentActionNotAvailableSubTitle}
                goHomeButton={false}
              />
              <Grid container justify="center">
                <CardOfferWrapper useSmallBackgroundImage />
              </Grid>
              <Box m={2} />
              <Grid container justify="center">
                <Grid item xs={10} md={4}>
                  <Button onClick={goToHome} style={{ marginTop: '10px' }}>
                    {i18n.backToTop}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {response !== undefined && (
        <>
          <Box m={6} />
          <Grid container justify="center">
            <Grid item xs={10} sm={6}>
              <MessageResponse
                response={response}
                referenceLabels={PAY_INSTALLMENT}
                onSuccessPrimary={goToHome}
                isInitError={initError}
                onErrorPrimary={reload}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={11} md={11}>
        {downloadFileSuccess && (
          <Toast
            messageOnSuccess={i18n.loanPaymentVouchersToastSuccessMessage}
            open={openToast}
            onClose={handleCloseToast}
          />
        )}
        {!downloadFileSuccess && (
          <Toast
            messageOnError={i18n.loanPaymentVouchersToastErrorMessage}
            open={openToast}
            onClose={handleCloseToast}
          />
        )}
      </Grid>

    </>
  );
};

export default PayInstallment;
