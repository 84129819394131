import {
  Box,
  Button, Grid, Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import QRCode from 'react-qr-code';
import { Alert } from '@material-ui/lab';
import i18n from '../../common/i18n';
import { useInstallApp } from '../../services/preLoginService';
import useStyles from './preLoginStyle';
import { usePreLoginContext } from '../../contexts/PreLoginContext';

const InstallApp = () => {
  const classes = useStyles();
  const { preLoginContext } = usePreLoginContext();
  const {
    ready, mobile, qrCode, handleContinue, handleOnInstall, goToApp,
  } = useInstallApp();

  const renderMobile = () => (
    <Grid container className={classes.containerMobile} justifyContent="center">
      <Grid item xs={12} sm={12}>
        <img src="/images/progress-bar-1.png" alt="progress" className={classes.progress} />
        <img src="/images/prelogin_mobile.jpg" alt="mobile" className={classes.banner} />
      </Grid>
      <Grid item xs={10} sm={10}>
        <Typography variant="h1">{i18n.InstallApp.title}</Typography>
        <Typography>{i18n.InstallApp.description[1]}</Typography>
        {preLoginContext?.installing ? (
          <Alert severity="info" style={{ marginTop: '40px' }}>
            {i18n.InstallApp.installingMessage}
          </Alert>
        ) : (
          <Box className={classes.ctaMobile}>
            {preLoginContext?.appInstalled ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '40px' }}
                onClick={goToApp}
              >
                {i18n.InstallApp.cta[2]}
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<GetAppIcon />}
                  style={{ marginTop: '40px' }}
                  onClick={handleOnInstall}
                >
                  {i18n.InstallApp.cta[0]}
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '8px' }}
                  onClick={handleContinue}
                >
                  {i18n.InstallApp.cta[1]}
                </Button>
              </>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );

  const renderDesktop = () => (
    <Grid container className={classes.containerDesktop}>
      <Grid item md={6} alignContent="center">
        <img src="/images/mobile-mockup.png" alt="phone" />
      </Grid>
      <Grid item md={4} alignContent="center">
        <Typography variant="h1">{i18n.InstallApp.title}</Typography>
        <Typography style={{ marginBottom: '32px' }}>
          {i18n.InstallApp.description[0]}
        </Typography>
        {qrCode && (
          <QRCode
            size={128}
            className={classes.qr}
            value={qrCode}
            viewBox="0 0 256 256"
          />
        )}
        <Button
          variant="text"
          color="primary"
          fullWidth
          onClick={handleContinue}
          style={{ marginTop: '28px' }}
        >
          {i18n.InstallApp.cta[1]}
        </Button>
      </Grid>
    </Grid>
  );

  if (!ready) return null;

  return (
    <>
      {mobile ? renderMobile() : renderDesktop() }
    </>
  );
};

export default InstallApp;
