import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid, makeStyles, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#FFDCE9',
    margin: '0px !important',
    border: '0px !important',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '0.9rem',
    textAlign: 'left',
  },
}));
const CardCustomerDebtorAccordion = ({
  totalInstallmentDebt,
  totalPunitiveDebt,
  totalDebt,

}) => {
  const classes = useStyles();

  return (
    <>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography className={classes.title}>
                <b>
                  {fillMessageWith(i18n.customerDebtorTotalDebt, moneyFormatter(totalDebt))}
                </b>
                <br />
                <span style={{ fontSize: '0.9rem' }}>{i18n.customerDebtorIncludes}</span>
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography className={classes.subtitle}>
                <b>{i18n.customerDebtorDetails}</b>
              </Typography>
              <Typography style={{ marginRight: '20px' }} className={classes.subtitle}>
                {i18n.customerDebtorResidue}
                <b>{moneyFormatter(totalInstallmentDebt)}</b>
              </Typography>
              <Typography style={{ marginRight: '20px' }} className={classes.subtitle}>
                {i18n.customerDebtorPunitive}
                <b>{moneyFormatter(totalPunitiveDebt)}</b>
              </Typography>
              <Typography style={{ marginRight: '20px' }} className={classes.subtitle}>
                {i18n.customerDebtorBalance}
                <b>{moneyFormatter(totalDebt)}</b>
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

CardCustomerDebtorAccordion.propTypes = {
  totalDebt: PropTypes.number.isRequired,
  totalInstallmentDebt: PropTypes.number.isRequired,
  totalPunitiveDebt: PropTypes.number.isRequired,

};

export default CardCustomerDebtorAccordion;
