import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    borderColor: '#E0E0E0',
    backgroundColor: '#F5F5F5',
    textTransform: 'none',
  },
  cardTitle: {
    fontFamily: 'Open sans',
    fontWeight: 700,
    textAlign: 'left',
  },
  cardSubtitle: {
    fontFamily: 'Open sans',
    fontWeight: 400,
    textAlign: 'left',
  },
}));

function CardButton({
  name, cuit, onClick,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography className={classes.cardTitle}>
                {name}
              </Typography>
              <Typography className={classes.cardSubtitle}>
                {cuit}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
CardButton.propTypes = {
  name: PropTypes.string,
  cuit: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
CardButton.defaultProps = {
  name: '',
  cuit: '',
};
export default CardButton;
