import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import { PublicRoutesList } from '../utils/menuAndRoutesUtil';
import { isMobileOperatingSystem, sendDataLayerEvent } from '../utils/functionsUtil';
import { getEnv, REACT_APP_LENDING_SELFIE_ENDPOINT } from '../api/env';
import { usePreLoginContext } from '../contexts/PreLoginContext';
import i18n from '../common/i18n';
import { isPreLoginRead, markPreLoginAsRead } from '../api/userStorage';

/* ********************* Funciones comunes pre login ********************* */
// Saco en una funcion la preparacion del evento beforeinstallprompt para
// reutilizarla en el splash y en la pantalla de instalarApp por si recarga o cae directo en installApp.
const prepareInstallAppEvent = (context, setContext) => {
  const beforeInstallPromptHandler = (event) => {
    event.preventDefault(); // Evita que el navegador muestre el prompt automáticamente
    setContext({
      ...context,
      supportsPWA: true,
      promptInstall: event,
    });
  };

  window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

  // Esto asegura que el event listener se elimine cuando el componente ya no esté montado
  return () => {
    window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  };
};

const checkStandalone = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
  return isStandalone || false;
};

const shouldShowInstallApp = (context) =>
  context?.supportsPWA && !checkStandalone();

const onAppInstalled = (context, setContext) => {
  // Agrego 4 segundos de delay por la demora de la instalacion de la app para mostrar el boton
  setTimeout(() => {
    setContext({
      ...context,
      installing: false,
      appInstalled: true,
    });
  }, 4000);
};

/* ********************* SPLASH ********************* */
export const useSplash = () => {
  const history = useHistory();
  const { preLoginContext, setPreLoginContext } = usePreLoginContext();
  const [expanded, setExpanded] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const onAnimationEnd = () => {
    setTimeout(() => {
      setFadeOut(true);
    }, 1000);
    setTimeout(() => {
      if (isMobileOperatingSystem() || checkStandalone()) {
        if (shouldShowInstallApp(preLoginContext)) {
          history.push(PublicRoutesList.installApp);
        } else {
          history.push(PublicRoutesList.loginDNIValidationStep);
        }
      } else {
        history.push(PublicRoutesList.installApp);
      }
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setExpanded(true);
    }, 100);

    window.addEventListener('appinstalled', () => {
      onAppInstalled(preLoginContext, setPreLoginContext);
    });

    return prepareInstallAppEvent(preLoginContext, setPreLoginContext);
  }, []);

  return {
    expanded, onAnimationEnd, fadeOut,
  };
};

/* ********************* INSTALAR APP ********************* */
export const useInstallApp = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();
  const { preLoginContext, setPreLoginContext } = usePreLoginContext();
  const [ready, setReady] = useState(false);
  const [qrCode] = useState(`${getEnv(REACT_APP_LENDING_SELFIE_ENDPOINT)}${PublicRoutesList.splash}`);

  const handleContinue = () => {
    history.push(PublicRoutesList.intro);
  };

  const handleOnInstall = async (e) => {
    e.preventDefault();
    if (!preLoginContext?.promptInstall) {
      return;
    }
    preLoginContext?.promptInstall.prompt();
    const { outcome } = await preLoginContext?.promptInstall.userChoice;
    if (outcome === 'accepted') {
      sendDataLayerEvent({ event: 'app_instalada' });
      setPreLoginContext({ ...preLoginContext, userAccept: true, installing: true });
    } else {
      setPreLoginContext({ ...preLoginContext, userAccept: false });
    }
  };

  const goToApp = () => {
    window.open(getEnv(REACT_APP_LENDING_SELFIE_ENDPOINT), '_blank');
  };

  const init = () => {
    setReady(true);

    window.addEventListener('appinstalled', () => {
      onAppInstalled(preLoginContext, setPreLoginContext);
    });

    return prepareInstallAppEvent(preLoginContext, setPreLoginContext);
  };

  useEffect(init, []);

  return {
    ready, mobile, handleOnInstall, qrCode, handleContinue, goToApp,
  };
};

/* ********************* INTRODUCCION ********************* */
export const useIntroduction = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [step, setStep] = useState(i18n.Introduction.steps[0]);

  const handleOmit = () => {
    markPreLoginAsRead();
    history.push(PublicRoutesList.loginDNIValidationStep);
  };

  const nextStep = () => {
    if (step.stepIndex + 1 === i18n.Introduction.steps.length) {
      // si el actual fue el ultimo paso, sigo al login (hago lo mismo que omitir en este caso)
      handleOmit();
    } else {
      // siguiente step
      setStep(i18n.Introduction.steps[step.stepIndex + 1]);
    }
  };

  const init = () => {
    if (isPreLoginRead()) {
      history.push(PublicRoutesList.loginDNIValidationStep);
    }
    setReady(true);
  };

  useEffect(init, []);

  return {
    ready, mobile, step, nextStep, handleOmit,
  };
};
