import Box from '@material-ui/core/Box';
import {
  Button, Grid, Typography, ListItem, ListItemIcon, ListItemText, makeStyles,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { getEnv, URL_DISMISS } from '../api/env';
import i18n from '../common/i18n';
import imgCalendar from '../assets/imgs/prepaid-card-calendar-icon.svg';
import imgCredit from '../assets/imgs/prepaid-card-credit-icon.svg';
import imgForm from '../assets/imgs/prepaid-card-form-icon.svg';
import imgCall from '../assets/imgs/prepaid-card-call-icon.svg';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
  },
  headerTitle: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily: 'Poppins',
    color: 'white',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 700,
    color: '#212121',
    textAlign: 'left',
  },
  text: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    color: '#424242',
    textAlign: 'left',
  },
  card: {
    border: '1px groove ',
    borderRadius: '19px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '15px',
    paddingRight: '20px',
    marginBottom: '15px',
  },
}));

const RevolvingLineDismiss = () => {
  console.log('RevolvingLineDismiss');
  const classes = useStyles();
  const urlDismiss = getEnv(URL_DISMISS);
  const history = useHistory();

  const goToDismiss = () => {
    window.open(urlDismiss);
  };

  const onGoBack = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDetail));
  };

  return (
    <>
      <Box component="header" className={classes.header}>
        <Typography className={classes.headerTitle}>
          {i18n.RevolvingLine.Header.title}
        </Typography>
      </Box>
      <Grid container justify="center" style={{ marginTop: '20px' }}>
        <Grid item style={{ maxWidth: '540px', paddingLeft: '5%', paddingRight: '5%' }}>
          <Grid container justify="center">
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Grid container justify="center">

                <Grid item xs={11} md={6}>
                  <Box m={1} />
                  <Typography className={classes.title}>
                    {i18n.RevolvingLine.Dissmiss.title}
                  </Typography>
                  <Box m={2} />
                  <Typography className={classes.text}>
                    {i18n.RevolvingLine.Dissmiss.subtitle}
                  </Typography>
                  <Box m={2} />
                </Grid>

                <Grid item xs={11} md={6}>
                  <Box m={1} textAlign="left">
                    <ListItem className={classes.card}>
                      <ListItemIcon>
                        <img src={imgCalendar} width="40px" alt="img-icon-calendar" />
                      </ListItemIcon>
                      <ListItemText
                        primary={(
                          <Typography className={classes.text}>
                            {i18n.RevolvingLine.Dissmiss.itemCalendar}
                          </Typography>
                        )}
                      />
                    </ListItem>
                    <ListItem className={classes.card}>
                      <ListItemIcon>
                        <img src={imgCredit} width="40px" alt="img-icon-credit" />
                      </ListItemIcon>
                      <ListItemText
                        primary={(
                          <Typography className={classes.text}>
                            {i18n.RevolvingLine.Dissmiss.itemCredit}
                          </Typography>
                        )}
                      />
                    </ListItem>
                    <ListItem className={classes.card}>
                      <ListItemIcon>
                        <img src={imgForm} width="40px" alt="img-icon-form" />
                      </ListItemIcon>
                      <ListItemText
                        primary={(
                          <Typography className={classes.text}>
                            {i18n.RevolvingLine.Dissmiss.itemForm}
                          </Typography>
                        )}
                      />
                    </ListItem>
                    <ListItem className={classes.card}>
                      <ListItemIcon>
                        <img src={imgCall} width="40px" alt="img-icon-call" />
                      </ListItemIcon>
                      <ListItemText
                        primary={(
                          <Typography className={classes.text}>
                            {i18n.RevolvingLine.Dissmiss.itemCall}
                          </Typography>
                        )}
                      />
                    </ListItem>
                  </Box>
                </Grid>

                <Grid item xs={11} md={11}>
                  <Grid container justify="center">
                    <Grid item xs={12} md={6}>
                      <Box m={5} />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToDismiss}
                        fullWidth
                      >
                        {i18n.RevolvingLine.Dissmiss.button}
                      </Button>
                      <Box m={3} />
                      <Button
                        onClick={onGoBack}
                        color="primary"
                        startIcon={<ArrowBack />}
                      >
                        {i18n.RevolvingLine.Dissmiss.arrowBack}
                      </Button>
                      <Box m={3} />
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RevolvingLineDismiss;
