import { Grid, Typography } from '@material-ui/core';
import CustomAccordion from '../../../components/common/CustomAccordion';

const WikiCustomerAccordion = () => (
  <>
    <Grid container justify="center">
      <Grid item xs={10}>
        <CustomAccordion title="Titulo">
          <>
            <Typography>Aquí puede ir un mensaje, otro componente, o html embebido</Typography>
          </>
        </CustomAccordion>
      </Grid>
    </Grid>
  </>
);

export default WikiCustomerAccordion;
