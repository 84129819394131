import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/headerStyle.css';

const Dropdown = ({ subMenu, dropDown }) => {
  const [dropDownExpanded, setDropdownExpanded] = useState(false);
  return (
    <ul
      className={`dropdown ${((dropDown) || (dropDownExpanded)) ? 'show' : ''}`}
      onMouseEnter={() => setDropdownExpanded((prev) => !prev)}
      onMouseLeave={() => setDropdownExpanded((prev) => !prev)}
    >
      {subMenu.map((item) => (
        <Fragment key={item.title + item.path}>
          <li className="menu-items dropdownbtn">
            <a
              href={item.path}
              className="headerTitles"
            >
              {item.text}
            </a>
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

Dropdown.propTypes = {
  subMenu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dropDown: PropTypes.bool.isRequired,
};

export default Dropdown;
