import { Button, Box, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useState } from 'react';
import { getFreeDebtFile } from '../api/selfieWebService';
import imgDownload from '../assets/imgs/download.svg';
import i18n from '../common/i18n';

import { generateLinkWithFileInResponse } from '../utils/functionsUtil';
import Toast from './common/Toast';
import { useAuth } from '../contexts/AuthContext';

const FreeDebt = () => {
  const download = imgDownload;
  // Context
  const { currentUser } = useAuth();

  const [downloadFileSuccess, setDownloadFileSuccess] = useState(undefined);
  const [openToast, setOpenToast] = useState(false);

  const downloadFreeDebt = async () => {
    try {
      const res = await getFreeDebtFile(currentUser.customerId);
      generateLinkWithFileInResponse(res);
      setDownloadFileSuccess(true);
      setOpenToast(true);
    } catch (error) {
      setDownloadFileSuccess(false);
      setOpenToast(true);
    }
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setDownloadFileSuccess(undefined);
  };
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11} md={3}>
          <Box m={6} />
          <img src={download} className="Descargar" width="130px" alt="" />
          <Box m={6} />
          <Button
            onClick={downloadFreeDebt}
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            fullWidth
            disableRipple
          >
            {i18n.freeDebtCTA}
          </Button>
        </Grid>
        {downloadFileSuccess && (
          <Toast
            messageOnSuccess={i18n.customerFreeDebtToastSuccessMessage}
            open={openToast}
            onClose={handleCloseToast}
          />

        )}
        {!downloadFileSuccess && (
          <Toast
            messageOnError={i18n.customerFreeDebtToastErrorMessage}
            open={openToast}
            onClose={handleCloseToast}
          />

        )}

      </Grid>
    </>
  );
};

export default FreeDebt;
