import PropTypes from 'prop-types';
import {
  Box, Grid, Typography,
} from '@material-ui/core';
import Header from './common/Header';
import i18n from '../common/i18n';
import biometricImg from '../assets/imgs/selfies_1.png';
import ActionFooterFixed from './common/ActionFooterFixed';

const RevolvingLineBiometricStep = ({ onContinue }) => (
  <>
    <Header title={i18n.RevolvingLine.Header.title} />
    <Box mt={5} mb={10}>
      <Grid container justify="center">
        <Grid item xs={10}>
          <Typography variant="h2">{i18n.RevolvingLine.BiometricStep.title}</Typography>
          <Typography variant="h5" style={{ marginTop: '20px' }} align="left">
            {i18n.RevolvingLine.BiometricStep.description[0]}
          </Typography>
          <Typography variant="h5" style={{ marginTop: '10px' }} align="left">
            {i18n.RevolvingLine.BiometricStep.description[1]}
          </Typography>
          <Box mt={10} />
          <img src={biometricImg} alt="Biometria" />
        </Grid>
      </Grid>
    </Box>
    <ActionFooterFixed
      ctaLabel={i18n.RevolvingLine.BiometricStep.ctaContinue}
      onCTA={onContinue}
    />
  </>
);

RevolvingLineBiometricStep.propTypes = {
  onContinue: PropTypes.func.isRequired,
};

export default RevolvingLineBiometricStep;
