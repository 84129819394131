import WikiActionNotAvailable from './components/WikiActionNotAvailable';
import WikiActionWarning from './components/WikiActionWarning';
import WikiAvatarMenu from './components/WikiAvatarMenu';
import WikiAvatarUser from './components/WikiAvatarUser';
import WikiBindBankInfo from './components/WikiBindBankInfo';
import WikiCardButton from './components/WikiCardButton';
import WikiCardButtonPrepaidCard from './components/WikiCardButtonPrepaidCard';
import WikiCardCustomerDebtor from './components/WikiCardCustomerDebtor';
import WikiCardCustomerDebtorAccordion from './components/WikiCardCustomerDebtorAccordion';
import WikiCardCustomerLegalStudy from './components/WikiCardCustomerLegalStudy';
import WikiCardHome from './components/WikiCardHome';
import WikiCardInstallments from './components/WikiCardInstallments';
import WikiCardInvoice from './components/WikiCardInvoice';
import WikiCardRenewersCampaignOffer from './components/WikiCardRenewersCampaignOffer';
import WikiCardUserConfirmation from './components/WikiCardUserConfirmation';
import WikiCardVerticalButton from './components/WikiCardVerticalButton';
import WikiCheckBox from './components/WikiCheckBox';
import WikiCopyToClipboardButton from './components/WikiCopyToClipboardButton';
import WikiHeader from './components/WikiHeader';
import WikiLoading from './components/WikiLoading';
import WikiLoadingButton from './components/WikiLoadingButton';
import WikiMessageResponse from './components/WikiMessageResponse';
import WikiSimpleSelect from './components/WikiSimpleSelect';
import WikiTakeSurveyModal from './components/WikiTakeSurveyModal';
import WikiTextInput from './components/WikiTextInput';
import WikiToast from './components/WikiToast';
import WikiUploadFile from './components/WikiUploadFile';
import WikiRatingInput from './components/WikiRatingInput';
import WikiNotificationDialogHandler from './components/WikiNotificationDialogHandler';
import WikiCustomerAccordion from './components/WikiCustomAccordion';

/* eslint-disable import/prefer-default-export */
export const components = [
  {
    name: 'Header',
    component: <WikiHeader />,
  },
  {
    name: 'ActionNotAvailable',
    component: <WikiActionNotAvailable />,
  },
  {
    name: 'ActionWarning',
    component: <WikiActionWarning />,
  },
  {
    name: 'CheckBox',
    component: <WikiCheckBox />,
  },
  {
    name: 'CopyToClipboardButton',
    component: <WikiCopyToClipboardButton />,
  },
  {
    name: 'Loading',
    component: <WikiLoading />,
  },
  {
    name: 'LoadingButton',
    component: <WikiLoadingButton />,
  },
  {
    name: 'MessageResponse',
    component: <WikiMessageResponse />,
  },
  {
    name: 'SimpleSelect',
    component: <WikiSimpleSelect />,
  },
  {
    name: 'TextInput',
    component: <WikiTextInput />,
  },
  {
    name: 'Toast',
    component: <WikiToast />,
  },
  {
    name: 'UploadFile',
    component: <WikiUploadFile />,
  },
  {
    name: 'AvatarMenu',
    component: <WikiAvatarMenu />,
  },
  {
    name: 'AvatarUser',
    component: <WikiAvatarUser />,
  },
  {
    name: 'BindBankInfo',
    component: <WikiBindBankInfo />,
  },
  {
    name: 'CardButton',
    component: <WikiCardButton />,
  },
  {
    name: 'CardButtonPrepaidCard',
    component: <WikiCardButtonPrepaidCard />,
  },
  {
    name: 'CardCustomerDebtor',
    component: <WikiCardCustomerDebtor />,
  },
  {
    name: 'CardCustomerDebtorAccordion',
    component: <WikiCardCustomerDebtorAccordion />,
  },
  {
    name: 'CardCustomerLegalStudy',
    component: <WikiCardCustomerLegalStudy />,
  },
  {
    name: 'CardHome',
    component: <WikiCardHome />,
  },
  {
    name: 'CardInstallments',
    component: <WikiCardInstallments />,
  },
  {
    name: 'CardInvoice',
    component: <WikiCardInvoice />,
  },
  {
    name: 'CardRenewersCampaignOffer',
    component: <WikiCardRenewersCampaignOffer />,
  },
  {
    name: 'CardUserConfirmation',
    component: <WikiCardUserConfirmation />,
  },
  {
    name: 'CardVerticalButton',
    component: <WikiCardVerticalButton />,
  },
  {
    name: 'TakeSurveyModal',
    component: <WikiTakeSurveyModal />,
  },
  {
    name: 'RatingInput',
    component: <WikiRatingInput />,
  },
  {
    name: 'NotificationDialogHandler',
    component: <WikiNotificationDialogHandler />,
  },
  {
    name: 'CustomerAccordion',
    component: <WikiCustomerAccordion />,
  },
];
