import {
  Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCardNumbers from '../../assets/imgs/payment/credit-card-numbers.svg';
import CreditCardDate from '../../assets/imgs/payment/credit-card-date.svg';
import CreditCardCode from '../../assets/imgs/payment/credit-card-code.svg';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  details: {
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  padding: {
    padding: '25px',
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid #D5D5D5',
  },
});

function PaymentMethodOnline() {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: '0.8rem', textAlign: 'left' }}>
          ¿Cómo pago con tarjeta de débito?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="center">
          <Grid item container justify="center">
            <Grid item xs={12} md={12}>
              <Typography className={classes.title}>
                Te pediremos los siguientes datos:
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                src={CreditCardNumbers}
                width="auto"
                height="100px"
                alt="Imagen de tarjeta de débito"
                style={{ marginTop: '25px' }}
              />
              <Typography className={classes.details}>
                1. Todos los números del frente de la tarjeta
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                src={CreditCardDate}
                width="auto"
                height="100px"
                alt="Imagen de tarjeta de débito"
                style={{ marginTop: '25px' }}
              />
              <Typography className={classes.details}>
                2. La fecha de vencimiento
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                src={CreditCardCode}
                width="auto"
                height="100px"
                alt="Imagen de tarjeta de débito"
                style={{ marginTop: '25px' }}
              />
              <Typography className={classes.details}>
                3. El código de seguridad
              </Typography>
            </Grid>
            <Box m={2} />
            <Grid xs={12}>
              <Typography className={classes.details}>
                Una vez pago recordá subir el comprobante.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PaymentMethodOnline;
