import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {
  Grid, Typography, makeStyles, ListItem, ListItemIcon, ListItemText, Button,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import imgBack from '../assets/imgs/atras.svg';
import { fillMessageWith } from '../utils/functionsUtil';
import {
  moneyFormatter, moneyFormatterWithoutDecimals, dateFormatter, dateLongFormatter,
} from '../utils/formatterUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { getRevolvingLineNexExpirationDetail } from '../api/selfieWebService';
import { useAuth } from '../contexts/AuthContext';
import Loading from '../components/common/Loading';

const useStyles = makeStyles((theme) => ({

  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
  },
  headerTitle: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily: 'Poppins',
    color: 'white',
  },
  titleInstallments: {
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: '14px',
    textAlign: 'left',
  },
  listBulletInstallment: {
    color: '#FBB615',
    fontSize: '20px',
  },
  listTextInstallment: {
    color: '#424242',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Open Sans',
  },
  listDueDateDetail: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    backgroundColor: '#f0f0f0',
    borderRadius: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  card: {
    border: '1px groove ',
    borderRadius: '12px',
    paddingTop: '8px',
    paddingBottom: '12px',
    paddingLeft: '15px',
    paddingRight: '20px',
  },
  nextDueDateTitleDetail: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '22px',
  },
  installmentAmountDetail: {
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#212121',
  },
  installmentDueDateDetail: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    color: '#212121',
  },
  arrowBack: {
    fontFamily: 'Open Sans',
    fontWeight: '700',
    fontSize: '14px',
  },
}));

const RevolvingLineDetailInstallments = () => {
  const { currentUser } = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const [nextExpirationDetailList, setNextExpirationDetailList] = useState();
  const [loading, setLoading] = useState(true);

  const {
    customerAccountStatus: {
      customerRevolvingLine = null,
    } = {},
  } = useCustomerAccountStatus() || {};

  const goToRevolvingLineDetail = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDetail));
  };

  const goToPayInstallment = () => {
    history.push({
      pathname: getPathRouteFromKey(i18n.payInstallmentKey),
      state: {
        isRevolvingLine: true,
      },
    });
  };

  const init = async () => {
    try {
      setLoading(true);
      const res = await getRevolvingLineNexExpirationDetail(currentUser.customerTaxId);
      setNextExpirationDetailList(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Box component="header" className={classes.header}>
        <Typography className={classes.headerTitle}>
          {i18n.RevolvingLine.Header.title}
        </Typography>
      </Box>

      {loading && <Loading />}

      {customerRevolvingLine?.nextExpirationInfo && !loading && (
        <Grid container justify="center" style={{ marginTop: '20px' }}>
          <Grid item style={{ maxWidth: '540px', paddingLeft: '5%', paddingRight: '5%' }}>
            <Grid container justify="center">

              {/* totalAmount and dueDate */}
              <Grid item xs={12}>
                <Typography className={classes.nextDueDateTitleDetail}>
                  {i18n.RevolvingLine.DetailInstallments.title}
                </Typography>
                <Grid container justify="center" className={classes.card} style={{ marginTop: '10px' }}>
                  <div style={{ width: 'fit-content' }}>
                    <Typography className={classes.installmentAmountDetail} style={{ width: 'fit-content' }}>
                      {parse(fillMessageWith(i18n.RevolvingLine.DetailInstallments.installmentAmount,
                        moneyFormatter(customerRevolvingLine.nextExpirationInfo.amount)))}
                    </Typography>
                    <Typography className={classes.installmentDueDateDetail} style={{ width: 'fit-content' }}>
                      {fillMessageWith(i18n.RevolvingLine.DetailInstallments.installmentDueDate,
                        dateLongFormatter(customerRevolvingLine.nextExpirationInfo.vto))}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              {/* installments details */}
              <Grid item xs={12} style={{ textAlign: 'left', marginTop: '20px' }}>
                <Typography className={classes.titleInstallments}>
                  {i18n.RevolvingLine.DetailInstallments.subTitle}
                </Typography>
                {/* foreach installments */}
                {nextExpirationDetailList && (
                  <>
                    <ul style={{ paddingLeft: '15px', margin: '5px' }}>
                      {nextExpirationDetailList.map((inst) => (
                        <>
                          <li className={classes.listBulletInstallment} style={{ paddingTop: '5px' }}>
                            <Typography className={classes.listTextInstallment}>
                              {parse(fillMessageWith(i18n.RevolvingLine.DetailInstallments.installmentNumberAndAmount,
                                inst.installment, moneyFormatterWithoutDecimals(inst.installmentAmount)))}
                            </Typography>
                          </li>
                          <Typography className={classes.listDueDateDetail}>
                            {parse(fillMessageWith(i18n.RevolvingLine.DetailInstallments.installmentDate,
                              dateFormatter(inst.date),
                              moneyFormatterWithoutDecimals(inst.disbursementAmount)))}
                          </Typography>
                        </>
                      ))}
                    </ul>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={8} style={{ marginTop: '80px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToPayInstallment}
                  fullWidth
                >
                  {i18n.RevolvingLine.DetailInstallments.btnPayment}
                </Button>
              </Grid>

              <Grid item xs={12} sm={8} style={{ marginTop: '10px' }}>
                <Button
                  variant="tertiary"
                  onClick={goToRevolvingLineDetail}
                >
                  <ListItem style={{ padding: '0px' }}>
                    <ListItemIcon style={{ minWidth: '24px' }}>
                      <img src={imgBack} alt="back" width="20px" />
                    </ListItemIcon>
                    <ListItemText
                      primary={(
                        <Typography className={classes.arrowBack}>
                          {i18n.RevolvingLine.DetailInstallments.arrowBack}
                        </Typography>
                    )}
                    />
                  </ListItem>
                </Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RevolvingLineDetailInstallments;
